import React from "react";
import { useTheme } from "@material-ui/styles";
import xWinLib from '../../../xWinLib';
import appGlobalConfig from '../../../appGlobalConfig';
import ReactApexChart from "react-apexcharts";
import format from 'format-number';
import xWinUtils from '../../../xWinUtils';

// styles
import useStyles from "../styles";
import Widget from "../../../components/Widget";


export default function MyFarmValue(props) {
  var classes = useStyles();
  var theme = useTheme();
  const { vaultCollections, farmCollections, overallStat, loading, ...rest } = props;

  const getLPPrice = (address) => {
    
    for(let i = 0; i< vaultCollections.length; i++){
      let v = vaultCollections[i]
      if(v[2] === address){
        return v[1]
      }
    }
    return 0
  }

  let farmStats
  let totalFarmReward = 0
  let totalFarmValue = 0
  let seriesValue = []
  let keyValue = []
  farmCollections.forEach(farm => {

    if(farm[1] > 0){
      let lpToken = farm[2]
      let price = 0
      totalFarmReward = totalFarmReward + farm[1]
      if(lpToken.toLowerCase() == appGlobalConfig.mainnetXWIN.toLowerCase()){
        price = overallStat.xwinPrice
      }else if(lpToken.toLowerCase() == appGlobalConfig.mainnetXWINBNBLPPoolV2.toLowerCase() || lpToken.toLowerCase() === appGlobalConfig.mainnetXWINBNBLPPoolApeSwap.toLowerCase()){
        price = overallStat.WBNBXWINPrice
      }else if(lpToken.toLowerCase() == appGlobalConfig.mainnetXWINUSDTLPPool.toLowerCase() || lpToken.toLowerCase() == appGlobalConfig.mainnetXWINUSDTAPELPPool.toLowerCase()){
        price = overallStat.BabyLPPrice
      }else if(lpToken.toLowerCase() == appGlobalConfig.mainnetJPYC.toLowerCase()){
        price = overallStat.JPYUSD
      }else{
        price = getLPPrice(lpToken)
      }
      let value = parseFloat(farm[0]) * parseFloat(price)
      totalFarmValue = totalFarmValue + value
      seriesValue.push(value)
      keyValue.push(farm[4])
    }
    
  });

  farmStats = {
    "totalFarmValue": totalFarmValue,
    "totalFarmReward": totalFarmReward
  }
    
    let myBalDataPercent = []
    let myPayLabel = []
    let myBalData = []

    farmCollections.forEach(v => {

      if(v[0] > 0){
        let price = 0
        let lpToken = v[2]
        myPayLabel.push(v[4])
        if(lpToken === appGlobalConfig.mainnetXWIN){
          price = overallStat.xwinPrice
        }else if(lpToken === appGlobalConfig.mainnetXWINBNBLPPoolV2 || lpToken === appGlobalConfig.mainnetXWINBNBLPPoolApeSwap){
          price = overallStat.WBNBXWINPrice
        }else if(lpToken === appGlobalConfig.mainnetJPYC){
          price = overallStat.JPYUSD
        }else{
          price = getLPPrice(lpToken)
        }
        let amount = v[0] * price
        let portRatio = xWinLib.roundTo(amount / farmStats.totalFarmValue * 100, 1)
        myBalDataPercent.push(portRatio)
        myBalData.push({
          symbol: v[4],
          key : v[4],
          balance: amount
        })
      }
    })
 
  const values = {
    series2: seriesValue, //[144, 55, 41, 17, 15],
  };

  const themeOptions = theme => {
    return {
      labels: keyValue, 
      maintainAspectRatio: false,
      legend: {
        display: true,
        position: 'bottom'
      },
      responsive: [
        {
          // breakpoint: 480,
          // options: {
          //   chart: {
          //     width: 200
          //   },
          //   legend: {
          //     position: "bottom"
          //   }
          // }
        }
      ],
      colors: [
        theme.palette.primary.main,
        theme.palette.secondary.main,
        theme.palette.warning.main,
        theme.palette.success.light,
        theme.palette.info.main
      ],
    };
  };

  return (
    <Widget
          disableWidgetMenu
          title={xWinUtils.displayBalance(loading, format({prefix: "$ ", suffix: ''})(xWinLib.roundTo(farmStats.totalFarmValue,0)))}
          className={classes.card}
          bodyClass={classes.alignStandaloneElement}
          subtitle= {"Farming Breakdown"}
        >
          <ReactApexChart
            options={themeOptions(theme)}
            series={values.series2}
            type="donut"
            height="380"
            stroke={""}
          />
    </Widget>
  );
}
