import React, { useState, forwardRef, useImperativeHandle, useRef } from "react";
import xWinLib from '../../xWinLib';
import xWinUtils from '../../xWinUtils';
import format from 'format-number';
import xWinFundV5 from '../../abi/xWinFundV5.json'
import "react-toastify/dist/ReactToastify.css";
import { Typography, Button, Avatar } from "../../components/Wrappers/Wrappers";
import CancelIcon from '@material-ui/icons/Cancel';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SnackbarMessage from '../snackbarmessage/SnackbarMessage'

import {
  Box,
  CardHeader,
  IconButton,
  TableRow,
  Table,
  TableCell,
  Dialog,
  DialogContent,
  DialogActions,
  Chip,
  ButtonGroup,
  TextField,
  InputAdornment,
} from "@material-ui/core";
// components
import useStyles from "./styles";


const redeem = forwardRef((props, ref) => {
  const { className, fundData, tokensMaster, userData, myxWinProtocol, port, globalWeb3, networkName, uniswapV2FactoryProtocol, ...rest } = props;
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [openAdd, setOpenAdd] = React.useState(false);
  const [slippageAmt, setSlippageAmt] = React.useState(0.5);
  const [subsAmt, setsubsAmt] = useState("");
  const MAX_ALLOWANCE = "115792089237316195423570985008687907853269984665640564039457584007913129639935";
  
  const refsnackbar = useRef(null);
  const showSnackBar = (status, msg) => {
    refsnackbar.current.handleOpen(status, msg);
  }

  const handleOpen = () => {
    setOpenAdd(true);
  }
  const [isApproval, setIsApproval] = useState(false);
  
  const handleCloseAdd = () => {
    setLoading(false)
    setOpenAdd(false);
  }

  useImperativeHandle(ref, () => {
    return {
      handleOpen: handleOpen
    };
  });


  const isTokenApproved = async (subsAmount) => {
    
    let isapprove = await xWinUtils.getIsApproved(globalWeb3, subsAmount, userData.selectedWallet, port)
    return isapprove
  }

  const handleClickAmt = (ratio) => async event => {
    event.preventDefault();
    
    let redeemAmt = xWinLib.roundDown(ratio * userData.userfundbal, 14)
    if(redeemAmt > userData.userfundbal) redeemAmt = userData.userfundbal 
    setsubsAmt(redeemAmt)
    const isApp = await isTokenApproved(redeemAmt)
    setIsApproval(isApp)
  }

  const handleClickClearAmt = () => event => {
    event.preventDefault();
    setsubsAmt(0)
  };

  const handleClickApprove = async event => {
    event.preventDefault();
    
    setLoading(true)
    
    const sourceToken = new globalWeb3.eth.Contract(xWinFundV5, port?.contractaddress);
    
    xWinLib.approveContract(sourceToken, MAX_ALLOWANCE, myxWinProtocol._address, userData.selectedWallet, globalWeb3)
    .then(res => {
      setLoading(false)
      setIsApproval(true)
      // setMsgStatus("success")
      // setResponseMsg(res)
      // showMessage()
      //props.parentCallback(true);
    }
  )
  .catch(err => {
    setLoading(false)
    console.log(err)
    setIsApproval(false)
    // setResponseMsg(err)
    // setMsgStatus("error")
    // showMessage()
    //props.parentCallback(true);
  })
    
  };

  const handleAmtChange = () => async event => {
    
    if(event.target.value === "") return

    const val = parseFloat(event.target.value)
    if(val >= 0 && val !== undefined){
      setsubsAmt(val)
    }

    const isApp = await isTokenApproved(val)
    setIsApproval(isApp)
  }

  const handleClickRedeem = async event => {
    event.preventDefault();
    
    if(subsAmt <= 0){
      showSnackBar("error", "Please input withdraw amount")
      return
    } 

    setLoading(true)
    
    const subsAmtInWei = globalWeb3.utils.toWei(subsAmt.toString())
    
    xWinLib.RedeemAsync(
        myxWinProtocol, 
        port?.contractaddress, 
        userData.selectedWallet, 
        subsAmtInWei, 
        slippageAmt
      )
    .then(res => {
        setLoading(false)
        showSnackBar("success", res)
        props.parentCallback(true);
      }
    )
    .catch(err => {
      setLoading(false)
      console.log(err)
      showSnackBar("error", err)
      props.parentCallback(false);
    })
  };
  
  const handleSlippageChange = () => event => {
    if(event.target.value < 0) return
    setSlippageAmt(event.target.value)
  }

  const getHeader = () => {

    return (
    <Box>
      <Chip 
        size="large" 
        //variant="contained"
        label={port?.symbol + format({prefix: " :", suffix: ''})(xWinLib.roundTo(userData?.userfundbal,10))}
        color="secondary" avatar={<Avatar src={"../../../images/logos/"+port?.logo} />} />
    </Box>
    )
  }

  return (
    <div>
    <Dialog 
          open={openAdd} 
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          fullScreen={false}
          //maxWidth = {'md'}
          >
            <SnackbarMessage ref={refsnackbar} />
            <CardHeader
              action={
                <IconButton 
                  className={classes.title}
                  onClick={handleCloseAdd}
                  aria-label="settings">
                  <CancelIcon />
                </IconButton>
              }
              title={getHeader()}
            />
          <DialogContent>
              {xWinUtils.getProgress(loading)}
                  
                <Table size="small">                  
                  <TableRow>
                  <TableCell align="right">
                    <ButtonGroup size="small" color="primary" aria-label="">
                            <Button
                              className={classes.columnHide}
                              fullWidth
                              color="primary"
                              size="small"
                              variant="outlined"
                              onClick={handleClickAmt(0.25)}
                            >
                              {'25%'}
                            </Button>
                            <Button
                              fullWidth
                              color="primary"
                              size="small"
                              variant="outlined"
                              onClick={handleClickAmt(0.50)}
                            >
                              {'50%'}
                            </Button>
                            <Button
                              className={classes.columnHide}
                              fullWidth
                              color="primary"
                              size="small"
                              variant="outlined"
                              onClick={handleClickAmt(0.75)}
                            >
                              {'75%'}
                            </Button>
                            <Button
                              fullWidth
                              color="primary"
                              size="small"
                              variant="outlined"
                              onClick={handleClickAmt(1)}
                            >
                              {'100%'}
                            </Button>
                            <Button
                            fullWidth
                            color="primary"
                            size="small"
                            variant="outlined"
                            onClick={handleClickClearAmt()}
                          >
                            {'Clear'}
                          </Button>
                          </ButtonGroup>
                    </TableCell>
                  </TableRow>
                  <TableRow className={classes.tableRow}>
                    <TableCell>
                          <TextField
                              type="number" 
                              className={classes.text}
                              label={'Amount'}
                              margin="dense"
                              name="weight"
                              onChange={handleAmtChange()}
                              required
                              variant="outlined"
                              value={subsAmt}
                              fullWidth
                              InputProps={{
                                classes: {
                                  input: classes.negativeNum,
                                },
                                style: { textAlign: "right" },
                                step:"0.1", 
                                startAdornment: <InputAdornment position="start">{port?.symbol}</InputAdornment>,
                              }}                              
                            />
                        </TableCell>
                  </TableRow>
                  <TableRow className={classes.tableRow}>
                    <TableCell>
                      <Box display="flex" alignItems="left" justifyContent={"left"}>
                        <TextField
                              type="number" 
                              className={classes.text}
                              label={'Slippage'}
                              margin="dense"
                              name="slippage"
                              onChange={handleSlippageChange()}
                              required
                              variant="outlined"
                              value={slippageAmt}
                              fullWidth
                              InputProps={
                                { min: "0.1", max: "20", step: "0.1" },
                                {startAdornment: <InputAdornment position="start">%</InputAdornment>,}
                              }
                            />
                            </Box>
                            <Typography
                              color="text"
                              colorBrightness={"hint"}
                              variant={"caption"}
                              style={{ marginRight: 5 }}
                            >
                              *** Increase slippage infavor to your trades
                            </Typography>
                          
                    </TableCell>
                  </TableRow>
                  <TableRow className={classes.tableRow}>
                    <TableCell>
                    <Box display="flex" alignItems="left" justifyContent={"left"}>
                      <Typography
                        color="text"
                        //colorBrightness={"hint"}
                        variant={"caption"}
                        style={{ marginRight: 5 }}
                      >
                      *** 0.50% platform fee applied for redemption
                      </Typography>
                      </Box>
                    </TableCell>
                   </TableRow> 
                </Table>
          
          </DialogContent>
          <DialogContent>
          </DialogContent>
          <DialogActions>
              
          <ButtonGroup size="small" color="primary" aria-label="large outlined primary button group">
            <Button 
                disabled={isApproval}
                onClick={handleClickApprove} 
                color="secondary"
                variant="contained"
                startIcon={<AddCircleIcon />}>
              {'Approve'}
              </Button>
              <Button 
                disabled={!isApproval}
                onClick={handleClickRedeem} 
                color="secondary"
                variant="contained"
                startIcon={<AddCircleIcon />}>
              {'Withdraw'}
              </Button>
            </ButtonGroup>
            
          </DialogActions>
        </Dialog>
        </div>
  );
});


export default redeem;
