import 'firebase/firestore';
import Icon from '@mdi/react';
import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import firebase from 'firebase/app';
import {
  Fab,
  IconButton,
} from '@material-ui/core';
import {
  mdiFacebookBox as FacebookIcon,
  mdiGithubBox as GithubIcon,
  mdiMedium as MediumIcon,
  mdiSettings as SettingsIcon,
  mdiTelegram as TelegramIcon,
  mdiTwitterBox as TwitterIcon,
} from '@mdi/js';
import { Route, Switch, withRouter } from 'react-router-dom';

import BonusClaim from '../../pages/bonusclaim';
import BuyLP from '../../pages/buyLP';
import BuySell from '../../pages/buysell';
import ColorChangeThemePopper from './components/ColorChangeThemePopper';
import Compensation from '../../pages/compensation';
import Dashboard from '../../pages/dashboard';
import FarmingPage from '../../pages/farming';
import FixedRatePage from '../../pages/fixedrate';
import CharityPage from '../../pages/charity';
import Footer from '../Footer';
import FundDetailPage from '../../pages/funddetail/FundDetail';
import Header from '../Header';
import LuckyDraw from '../../pages/luckydraw/LuckyDraw';
import PrivateInvestor from '../../pages/privateinvestor';
import PrivateVaultPage from '../../pages/privateVault';
import Referral from '../../pages/referral';
import ReferralAction from '../../pages/referralaction';
import Sidebar from '../Sidebar';
import TeamMember from '../../pages/teammember/TeamMember';
import TeamRewards from '../../pages/teamrewards';
import Toppy from '../../pages/toppy';
import TradingPage from '../../pages/tradings';
import XwinNews from '../../pages/xwinnews/XwinNews';
import XwinVideo from '../../pages/xwinvideo/XwinVideo';
import YieldsPage from '../../pages/yields';
import structure from '../Sidebar/SidebarStructure';
import useStyles from './styles';
import { Link, Typography } from '../Wrappers';
import { useLayoutState } from '../../context/LayoutContext';


function Layout(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const id = open ? 'add-section-popover' : undefined;

  const handleClick = (event) => {
    setAnchorEl(open ? null : event.currentTarget);
  };

  // global
  const layoutState = useLayoutState();
  const [portfolios, setPortfolios] = useState([]);
  const [tokensMaster, setTokensMaster] = useState([]);

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection('xwinfunds')
      .onSnapshot((snapshot) => {
        const newValues = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setPortfolios(newValues);
      });

    return () => { unsubscribe(); };
  }, []);

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection('tokens')
      .onSnapshot((snapshot) => {
        const newValues = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setTokensMaster(newValues);
      });

    return () => { unsubscribe(); };
  }, []);

  return (
    <div className={classes.root}>
      <Header history={props.history} />
      <Sidebar structure={structure} />
      <div
        className={classnames(classes.content, {
          [classes.contentShift]: layoutState.isSidebarOpened,
        })}
      >
        <div className={classes.fakeToolbar} />
        <Switch>
          <Route path="/app/dashboard" render={() => <Dashboard portfolios={portfolios} />} />
          <Route path="/app/xwin/buysell" render={() => <BuySell />} />
          <Route path="/app/xwin/buyLP" render={() => <BuyLP />} />
          <Route path="/app/xwin/bonusclaim" render={() => <BonusClaim />} />
          <Route path="/app/teamrewards" render={() => <TeamRewards />} />
          <Route path="/app/privateinvestor" render={() => <PrivateInvestor />} />
          <Route path="/app/compensation" render={() => <Compensation />} />
          <Route path="/app/luckydraw" render={() => <LuckyDraw />} />
          <Route path="/app/teammember" component={TeamMember} />
          <Route path="/app/xwinvideo" component={XwinVideo} />
          <Route path="/app/xwinnews" component={XwinNews} />
          <Route path="/app/tradings/trading" component={TradingPage}>
            <TradingPage
              tokensMaster={tokensMaster}
              portfolios={portfolios}
              fundtype="tradingv2"
            />
          </Route>
          <Route path="/app/tradings/old/tradingold" component={TradingPage}>
            <TradingPage
              tokensMaster={tokensMaster}
              portfolios={portfolios}
              fundtype="trading"
            />
          </Route>
          <Route path="/app/tradings/old/indexold" component={TradingPage}>
            <TradingPage
              tokensMaster={tokensMaster}
              portfolios={portfolios}
              fundtype="index"
            />
          </Route>
          <Route path="/app/yields/old/pcsold" component={YieldsPage}>
            <YieldsPage
              tokensMaster={tokensMaster}
              portfolios={portfolios}
              fundtype="liquidity2"
            />
          </Route>
          <Route path="/app/tradings/old/liquidityold" component={TradingPage}>
            <TradingPage
              tokensMaster={tokensMaster}
              portfolios={portfolios}
              fundtype="liquidity"
            />
          </Route>
          <Route path="/app/tradings/index" component={TradingPage}>
            <TradingPage
              tokensMaster={tokensMaster}
              portfolios={portfolios}
              fundtype="indexv2"
            />
          </Route>
          <Route path="/app/yields/pcs">
            <YieldsPage
              portfolios={portfolios}
              tokensMaster={tokensMaster}
              fundtype="liquidityv2"
            />
          </Route>
          <Route path="/app/fixedrate/" component={FixedRatePage}>
            <FixedRatePage
              tokensMaster={tokensMaster}
              portfolios={portfolios}
              fundtype="fixedrate"
            />
          </Route>
          <Route path="/app/charity/" component={CharityPage}>
            <CharityPage
              tokensMaster={tokensMaster}
              portfolios={portfolios}
              fundtype="charity"
            />
          </Route>
          <Route path="/app/privatevault/" component={PrivateVaultPage}>
            <PrivateVaultPage
              tokensMaster={tokensMaster}
              portfolios={portfolios}
              fundtype="privatevault"
            />
          </Route>
          <Route path="/app/farming/:id" component={FarmingPage}>
            <FarmingPage
              tokensMaster={tokensMaster}
              portfolios={portfolios}
              fundtype="farming"
              category="all"
            />
          </Route>
          {/* <Route path="/app/staking/" component={FarmingPage}>
                        <FarmingPage
                            // tokensMaster={tokensMaster}
                            portfolios={portfolios}
                            fundtype={"farming"}
                            category={"singleasset"}
                            />
                    </Route> */}
          <Route path="/app/funddetail/:id" component={FundDetailPage}>
            <FundDetailPage portfolios={portfolios} />
          </Route>
          <Route path="/app/toppy" component={Toppy} />
          <Route path="/app/referralhome" component={Referral} />
          <Route path="/app/referral/:id" component={ReferralAction}>
            <ReferralAction />
          </Route>
        </Switch>
        <Fab
          color="primary"
          aria-label="settings"
          onClick={(e) => handleClick(e)}
          className={classes.changeThemeFab}
          style={{ zIndex: 100 }}
        >
          <Icon path={SettingsIcon} size={1} color="#fff" />
        </Fab>
        <ColorChangeThemePopper
          id={id}
          open={open}
          anchorEl={anchorEl}
        />
        <Footer>
          <div>
            <Link
              href="https://www.t.me/xwinfinance"
              target="_blank"
            >
              <IconButton aria-label="telegram">
                <Icon
                  path={TelegramIcon}
                  size={1}
                  color="#6E6E6E99"
                />
              </IconButton>
            </Link>
            <Link
              href="https://www.facebook.com/toppy.xwin"
              target="_blank"
            >
              <IconButton aria-label="facebook">
                <Icon
                  path={FacebookIcon}
                  size={1}
                  color="#6E6E6E99"
                />
              </IconButton>
            </Link>
            <Link
              href="https://twitter.com/xwinfinance"
              target="_blank"
            >
              <IconButton aria-label="twitter">
                <Icon
                  path={TwitterIcon}
                  size={1}
                  color="#6E6E6E99"
                />
              </IconButton>
            </Link>
            <Link
              href="https://github.com/xwinfinance/xWinBSC"
              target="_blank"
            >
              <IconButton aria-label="github">
                <Icon
                  path={GithubIcon}
                  size={1}
                  color="#6E6E6E99"
                />
              </IconButton>
            </Link>
            <Link
              href="https://xwin.medium.com/"
              target="_blank"
            >
              <IconButton aria-label="github">
                <Icon
                  path={MediumIcon}
                  size={1}
                  color="#6E6E6E99"
                />
              </IconButton>
            </Link>
          </div>
          <div>
            <Typography
              color="text"
              colorBrightness="hint"
              variant="caption"
            >
              Powered by &copy; xWIN Technology Sdn. Bhd
              {' '}
              {new Date().getFullYear()}
            </Typography>
          </div>
        </Footer>
      </div>
    </div>
  );
}

export default withRouter(Layout);
