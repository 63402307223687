import React, { useState, useEffect, useRef  } from "react";
import xWinLib from '../../../xWinLib';
import format from 'format-number';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';
import SnackbarMessage from '../../snackbarmessage/SnackbarMessage'

// styles
import useStyles from "../styles";
import { Typography, Button, Link } from "../../../components/Wrappers/Wrappers";
import Dot from "../../../components/Sidebar/components/Dot";
import xWinProtocolV5 from '../../../abi/xWinProtocolV5.json'
import xWinUtils from '../../../xWinUtils';
import TermCondition from '../../termcondition/termcondition'
import Subscribe from '../../subscribe/subscribe'
import Redeem from '../../redeem/redeem'
import { Link as RouterLink } from 'react-router-dom';
import {
  Grid,
  Box,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelActions,
  Tooltip,
} from "@material-ui/core";
import {
  ExpandMore as ExpandMoreIcon,
  Favorite as FavoriteIcon,
  MoreHoriz as MoreHorizIcon
} from "@material-ui/icons";

const Tradinglist = props => {
  var classes = useStyles();
  const { portfolios, fundtype, port, selectedWallet, ETHBalance, globalWeb3, web3static, tokensMaster, gasPrices, networkName, refreshAccount } = props;
  let tmp = []
  const [fundData, setFundData] = useState({
    "fundvalue": 0,
    "tokenNames": tmp,
    "uniprice": 0,
    "unipriceInUSD": 0,
    "totalSupply":  0,
    "fundETHbalance": 0,
    "networkName": "bscmain"
  });

  
  const [loading, setLoading] = React.useState(false);
  const [myxWinProtocol, setMyxWinProtocol] = React.useState([]);
  const [userData, setUserData] = useState({
    "walletETHBalance" :  0, 
    "selectedWallet" : "",
    "userfundbal" : 0,
    "referral" : ""
  });
  const refTermCondition = useRef(null);
  const refsubsdirect = useRef(null);
  const refredeem = useRef(null);  
  const refsnackbar = useRef(null);
  const showSnackBar = (status, msg) => {
    refsnackbar.current.handleOpen(status, msg);
  }

  useEffect(() => {
    const controller = new AbortController();
    loadFundData()
    return () => {
      controller.abort();
    }
  }, [portfolios, fundtype]);
  
  const loadFundData = async () => {

    if(globalWeb3 === undefined) {
      setUserData({
        "walletETHBalance" :  0, 
        "selectedWallet" : "---",
        "userfundbal" : 0,
      })  
      return 
    }
    setLoading(true)

    let xWinProtocol = new globalWeb3.eth.Contract(xWinProtocolV5, port.protocoladdress);
    setMyxWinProtocol(xWinProtocol)
    let fundHolderData = await xWinUtils.getUserFundHolding(web3static, selectedWallet, port.contractaddress)
    let referral = await xWinUtils.getReferral(xWinProtocol, selectedWallet) 
    
    if(selectedWallet !== "---"){
      setUserData({
        "walletETHBalance" :  ETHBalance, 
        "selectedWallet" : selectedWallet,
        "userfundbal" : fundHolderData.userfundbal,
        "referral" : referral
      })  
    }else{
      setUserData({
        "walletETHBalance" :  0, 
        "selectedWallet" : "---",
        "userfundbal" : 0,
        "referral" : ""
      })  
    }
    
    let myFundData = await xWinUtils.getFundAllData(fundHolderData.xWinFund)
    let tokenNames = []
    
    setFundData({
      ...fundData,
      "fundvalue": xWinUtils.convertFromWei(web3static, myFundData?.fundvalue?.toString()), //fundvalue / PRECISION,
      "tokenNames": tokenNames,
      "uniprice": xWinUtils.convertFromWei(web3static, myFundData?.uniprice.toString()), //uniprice / PRECISION,
      "unipriceInUSD": xWinUtils.convertFromWei(web3static, myFundData?.unipriceInUSD.toString()), //uniprice / PRECISION,
      "totalSupply":  xWinUtils.convertFromWei(web3static, myFundData?.totalSupply.toString()), // totalSupply / PRECISION,
      "fundETHbalance": xWinUtils.convertFromWei(web3static, myFundData?.fundETHbalance.toString()), //fundETHbalance / PRECISION
      "networkName": networkName
    })

    setLoading(false)
    
  }

  const handleClickSubsDirect = event => {
    if(port.showTnC && !xWinUtils.getAgreeTermConditionByPortfolio(port.symbol)){
      if(refTermCondition.current != null)  refTermCondition.current.handleOpen(port.symbol)
      return
    }
    refsubsdirect.current.handleOpen();
  }

  const handleClickRedeem = event => {
    refredeem.current.handleOpen();
  }

  const handleCallback = (refresh) =>{
    if(refresh) {
      refreshAccount()
      loadFundData()
    }
  }

  const handleCallbackTermCondition = (refresh) =>{
    if(refresh) refsubsdirect.current.handleOpen()
  }

  const AddToMetamask = async event => {
    
    setLoading(true)
    xWinLib.AddToMetamask(port.contractaddress, port.symbol, 18)
      .then(res =>
        { 
          console.log(res)
          setLoading(false)
          showSnackBar("info", res)
        }
      )
      .catch(err => {
        console.log(err)
        setLoading(false)
        showSnackBar("error", err)
        }
      )
  };

  return (
    <div>
        <TermCondition 
          ref={refTermCondition} 
          parentCallback={handleCallbackTermCondition}
        />
         <SnackbarMessage ref={refsnackbar} />
        <Redeem 
          ref={refredeem} 
          port={port}
          fundData={fundData}
          tokensMaster={tokensMaster}
          userData={userData}
          myxWinProtocol={myxWinProtocol}
          gasPrices={gasPrices}
          globalWeb3={globalWeb3}
          networkName={networkName}
          parentCallback={handleCallback}
          />
        <Subscribe 
          ref={refsubsdirect} 
          port={port}
          fundData={fundData}
          tokensMaster={tokensMaster}
          userData={userData}
          myxWinProtocol={myxWinProtocol}
          gasPrices={gasPrices}
          globalWeb3={globalWeb3}
          networkName={networkName}
          parentCallback={handleCallback}
          />
          <ExpansionPanel defaultExpanded={false} square={false}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                classes={{ expanded: classes.expanded }}
              >
                <div className={clsx(classes.column1)}>
                  <img alt={port.symbol} src={"../../../images/logos/"+port.logo} className={classes.avatar}/>
                </div>
                <div className={clsx(classes.column3, classes.columnHide, classes.helper)}>
                    <Typography
                      color="text"
                      colorBrightness={"hint"}
                      variant={"caption"}
                      style={{ marginRight: 5 }}
                    >
                      {port.name}
                    </Typography>
                    <Typography variant={'body2'} className={classes.subTitle}>{port.symbol}</Typography>
                </div>
                <div className={clsx(classes.column2, classes.columnHide, classes.helper)}>
                  <Typography
                      color="text"
                      colorBrightness={"hint"}
                      variant={"caption"}
                      style={{ marginRight: 5 }}
                    >
                      Unit Price
                    </Typography>
                  <Typography variant={'body2'} className={classes.subTitle}>
                    {format({prefix: " $", suffix: ''})(xWinLib.roundTo(fundData.unipriceInUSD,2))}
                  </Typography>
                </div>
                <div className={clsx(classes.column4, classes.helper)}>
                  <Grid item>
                    <Typography
                      color="text"
                      colorBrightness={"hint"}
                      variant={"caption"}
                      style={{ marginRight: 5 }}
                    >
                      TVL
                    </Typography>
                    <Box display="flex" alignItems="center" justifyContent={"center"}>
                      <Typography
                        size="md"
                        weight={"medium"}
                        style={{ marginRight: 8 }}
                      >
                        {xWinLib.getMarketCap(fundData)}
                      </Typography>
                      <Dot size='medium' color="success" />
                    </Box>
                  </Grid>
                </div>
                <div className={clsx(classes.column5, classes.helper)}>
                <Grid item>
                    <Typography
                      color="text"
                      colorBrightness={"hint"}
                      variant={"caption"}
                      style={{ marginRight: 5 }}
                    >
                      Balance
                    </Typography>
                    <Box display="flex" alignItems="center" justifyContent={"center"}>
                      <Typography
                        size="md"
                        weight={"medium"}
                        style={{ marginRight: 8 }}
                      >
                        {xWinUtils.displayBalance(loading, format({prefix: "", suffix: ""})(xWinLib.roundTo(userData.userfundbal,4)))}
                      </Typography>
                      {(!loading && userData.userfundbal > 0.000000001) ? <Dot size='medium' color="secondary" /> : ""} 
                    </Box>
                  </Grid>
                </div>
              </ExpansionPanelSummary>
              <ExpansionPanelActions>
              <Tooltip title="Add Token To Metamask">
                <Button variant="outlined" size="small" color="secondary">
                  <FavoriteIcon 
                    onClick={() => AddToMetamask()}
                    />
                </Button>
              </Tooltip>
              <Tooltip title="Deposit">
                <Button 
                  disabled={(port.contractaddress === "0xf05026B86E497a726aA4d52c493aE4519df582BF") || xWinUtils.shouldDisable(fundtype)}
                  onClick={handleClickSubsDirect}
                  variant="contained" size="small" color="primary">
                        +
                </Button>
              </Tooltip>
              <Tooltip title="Withdrawal">
                <Button 
                  onClick={handleClickRedeem}
                  variant="contained" size="small" color="secondary">
                  -
                </Button>
              </Tooltip>
                <Link 
                  component={RouterLink}
                  to={{
                  pathname: "/app/funddetail/"+ port.contractaddress,
                  state: {
                    port: port,
                    tokensMaster: tokensMaster,
                    //gasPrices:gasPrices,
                    fundtype: fundtype
                  }
                }}>
                <Tooltip title="See Details">  
                  <Button variant="contained" size="small" color="primary">
                    <MoreHorizIcon />
                  </Button>
                </Tooltip>
                </Link>
              </ExpansionPanelActions>
            </ExpansionPanel>
            
            
    </div>
  );
}
export default withRouter(Tradinglist);

