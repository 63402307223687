import React from "react";
import { useTheme } from "@material-ui/styles";
import ReactApexChart from "react-apexcharts";
import xWinLib from '../../../xWinLib';
import format from 'format-number';
// components
import Widget from "../../../components/Widget";
import xWinUtils from '../../../xWinUtils';


export default function MyVaultValue(props) {
  const theme = useTheme();
  const { vaultCollections, farmCollections, overallStat, loading, ...rest } = props;

  const totalValue = vaultCollections.reduce((sumFarm, mc) => {
      return parseFloat(sumFarm) + parseFloat(mc);
  }, 0);

    let myBalDataPercent = []
    let myPayLabel = []
    let myBalData = []
    let myBalDataValueOnly = []

    vaultCollections.forEach(v => {

      if(v[0] > 0){
        myPayLabel.push(v[3])
        let portRatio = xWinLib.roundTo(v[0] / totalValue * 100, 1)
        myBalDataPercent.push(portRatio)
        myBalData.push({
          symbol: v[3],
          key : v[3],
          balance: v[0] 
        })
        myBalDataValueOnly.push(v[0])
      }
    })
 
  const values = {
    series: [
      {
        data: myBalDataValueOnly
      }
    ],
  };
  
  // local
  const themeOptions = theme => {
    return {
      plotOptions: {
        bar: {
          horizontal: false
        }
      },
      dataLabels: {
        enabled: true
      },
      xaxis: {
        categories: myPayLabel
      },
      stroke: {
        curve: "smooth"
      },
      legend: {
        show: false
      },
      chart: {
        stacked: false,
        toolbar: {
          show: false
        }
      },
      tooltip: {
        theme: true,
      },
      colors: [theme.palette.secondary.main],
      options: {
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: "top"
            }
          }
        },
        dataLabels: {
          enabled: true,
          offsetX: -6,
          style: {
            fontSize: "12px",
            colors: ["#fff"]
          }
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"]
        },
  
        xaxis: {
          categories: [2013, 2014, 2015, 2016, 2017, 2018, 2019]
        },
        colors: [theme.palette.primary.main, theme.palette.success.light]
      },
    };
  };
  
  return (
    <Widget 
      title={xWinUtils.displayBalance(loading, format({prefix: "$ ", suffix: ''})(xWinLib.roundTo(totalValue,0)))}
      disableWidgetMenu
      noBodyPadding
      subtitle= {"Vault Breakdown"}>
      <ReactApexChart
        options={themeOptions(theme)}
        series={values?.series}
        type="bar"
        height="350"
      />
    </Widget>

  );
}
