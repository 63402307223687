import React, { useState, useEffect  } from "react";
import { 
  Grid, 
  Container
 } from "@material-ui/core";
import useStyles from "./styles";
import firebase from "firebase/app";
import "firebase/firestore";
import NewsWidget from "./components/NewsWidget";
//components
import Widget from "../../components/Widget/Widget";


function XwinNews() {
  const classes = useStyles();
  const [news, setNews] = useState([])
  const [listings, setListing] = useState([])
  
  useEffect(() => {
    const unsubscribe = firebase
    .firestore()
    .collection('xwinnews')
    .onSnapshot((snapshot) => {
      const newValues = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }))
      getFilterNews(newValues)
    })
    return () => { unsubscribe() }

  }, [])

  const getFilterNews = (rawNews) => {

    let newsArr = rawNews.filter(x=>x.type === "news")
    let listingArr2 = rawNews.filter(x=>x.type === "listing")
    setNews(newsArr)
    setListing(listingArr2)
  }

 

  return (
    <Container>
    <Grid container spacing={5}>
      <Grid item 
        lg={6}
        sm={12}
        xl={6}
        xs={12}>
          <Widget 
              className={classes.widgetLayout}
              title="Press News"
              disableWidgetMenu
            >
            <NewsWidget listings={news} />
            
            </Widget>
      </Grid>
      <Grid item 
        lg={6}
        sm={12}
        xl={6}
        xs={12}>
          
          <Widget 
              className={classes.widgetLayout}
              title="Listing News"
              disableWidgetMenu
            >
            <NewsWidget listings={listings} />
            
            </Widget>
      </Grid>

    </Grid>
    </Container>
  );
}

export default XwinNews;
