import React, { useState, forwardRef, useImperativeHandle, useRef } from "react";
import xWinLib from '../../xWinLib';
import xWinUtils from '../../xWinUtils';
import format from 'format-number';
// components
import { Typography, Button, Avatar } from "../../components/Wrappers/Wrappers";
import CancelIcon from '@material-ui/icons/Cancel';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Link as RouterLink } from 'react-router-dom';
import SnackbarMessage from '../snackbarmessage/SnackbarMessage'

import {
  Box,
  Link,
  Slide,
  CardHeader,
  IconButton,
  TableRow,
  Table,
  TableCell,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Chip,
  ButtonGroup,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import useStyles from "./styles";

const subscribe = forwardRef((props, ref) => {
  const { className, fundData, tokensMaster, userData, myxWinProtocol, port, globalWeb3, networkName, uniswapV2FactoryProtocol, ...rest } = props;
  const classes = useStyles();
  const [loadingTxn, setLoadingTxn] = React.useState(false);
  const [openAdd, setOpenAdd] = React.useState(false);
  const [slippageAmt, setSlippageAmt] = React.useState(0.5);
  const [subsAmt, setsubsAmt] = useState("");
  const [referraladdress, setReferraladdress] = useState(0);
 
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  const [open, setOpen] = React.useState(false);
  
  const refsnackbar = useRef(null);
  const showSnackBar = (status, msg) => {
    refsnackbar.current.handleOpen(status, msg);
  }


  const handleClickOpen = () => {
    setOpen(true);
  };
  
  const handleOpen = () => {
    
    setReferraladdress(userData.referral)
    setOpenAdd(true);
  }

  const handleClose = () => {
    setOpenAdd(false);
    setOpen(false);
    props.parentCallback(true)
    //window.location.reload()
  };

  const handleCloseAdd = () => {
    setLoadingTxn(false)
    setOpenAdd(false)
  }

  useImperativeHandle(ref, () => {
    return {
      handleOpen: handleOpen
    };
  });

  const handleClickSubs = event => {
    event.preventDefault();
    
    if(subsAmt <= 0){
      showSnackBar("error", "Please input subscribe amount")
      return
    } 

    setLoadingTxn(true)
    const subsAmtInWei = globalWeb3.utils.toWei(subsAmt.toString())
    xWinLib.SubscribeDirectAsync(
      myxWinProtocol, 
      port.contractaddress, 
      userData, 
      subsAmtInWei,
      slippageAmt
      )
    .then(res =>
      { 
        console.log(res)
        setLoadingTxn(false)
        showSnackBar("success", res)
        if(port.xwinfarmpool){ handleClickOpen()
        }else{ props.parentCallback(true) }
        
      }
    )
    .catch(err => {
      setLoadingTxn(false)
      console.log(err)
      showSnackBar("error", err)
      props.parentCallback(false);
    }
    )
  };

  const handleClickAmt = (ratio) => event => {
    event.preventDefault();

    const subAmount = xWinLib.roundTo(ratio * userData.walletETHBalance, 5)
    setsubsAmt(subAmount)

  };

  const handleClickClearAmt = () => event => {
    event.preventDefault();
    setsubsAmt("")
  };


  const handleAmtChange = event => {
    
    event.preventDefault()
    const subsAmount = parseFloat(event.target.value)
    if(subsAmount >= 0 && subsAmount !== undefined){
      setsubsAmt(subsAmount)
    }
  }

  const handleSlippageChange = () => event => {
    if(event.target.value < 0) return
    setSlippageAmt(event.target.value)
  }

  const getHeader = () => {

    return (
    <Box>
      <Chip 
        size="large" 
        label={format({prefix: " BNB Balance ", suffix: ''})(xWinLib.roundTo(userData?.walletETHBalance,5))}
        color="primary" avatar={<Avatar src="/images/icons/color/bnb.png" />} />
    </Box>
    )
  }

  return (
    <div>
      <Dialog 
          open={openAdd} 
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          fullScreen={false}
          //maxWidth = {'sm'}
          >
            <Dialog
              open={open}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleClose}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle id="alert-dialog-slide-title">{"Farm in xWIN"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  Do you want to continue to farm in xWIM farm?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Link 
                      component={RouterLink}
                      to={{
                      pathname: "/app/farming/"+ port?.contractaddress,
                    }}>
                <Button color="primary">
                    Yes              
                </Button>
                </Link>
                <Button onClick={handleClose} color="primary">
                    No              
                </Button>
              </DialogActions>
            </Dialog>
          <SnackbarMessage ref={refsnackbar} />
            <CardHeader
              action={
                <IconButton 
                  className={classes.title}
                  onClick={handleCloseAdd}
                  aria-label="settings">
                  <CancelIcon />
                </IconButton>
              }
              title={getHeader()}
            />
          <DialogContent className={classes.root}>
              {xWinUtils.getProgress(loadingTxn)}
                  
                <Table size="small">                  
                  <TableRow>
                  <TableCell align="right">
                      <ButtonGroup size="small" color="primary" aria-label="">
                          <Button
                            className={classes.columnHide}
                            fullWidth
                            color="primary"
                            size="small"
                            variant="outlined"
                            onClick={handleClickAmt(0.25)}
                          >
                            {'25%'}
                          </Button>
                          <Button
                            fullWidth
                            color="primary"
                            size="small"
                            variant="outlined"
                            onClick={handleClickAmt(0.50)}
                          >
                            {'50%'}
                          </Button>
                          <Button
                            className={classes.columnHide}
                            fullWidth
                            color="primary"
                            size="small"
                            variant="outlined"
                            onClick={handleClickAmt(0.75)}
                          >
                            {'75%'}
                          </Button>
                          <Button
                            fullWidth
                            color="primary"
                            size="small"
                            variant="outlined"
                            onClick={handleClickAmt(0.99)}
                          >
                            {'100%'}
                          </Button>
                          <Button
                          
                          fullWidth
                          color="primary"
                          size="small"
                          variant="outlined"
                          onClick={handleClickClearAmt()}
                        >
                          {'Clear'}
                        </Button>
                        </ButtonGroup>
                      </TableCell>
                  </TableRow> 
                      
                   <TableRow className={classes.tableRow}>
                      <TableCell>
                        
                        <TextField
                            type="number" 
                            label={'Amount'}
                            margin="dense"
                            name="weight"
                            onChange={handleAmtChange}
                            required
                            variant="outlined"
                            value={subsAmt}
                            fullWidth
                            InputProps={{
                              classes: {
                                input: classes.positiveNum,
                              },
                              style: { textAlign: "right" },
                              startAdornment: <InputAdornment position="start">{xWinLib.getBaseCcy(networkName)}</InputAdornment>,
                            }}
                          />
                          
                          
                        
                      </TableCell>
                   </TableRow> 
                   <TableRow className={classes.tableRow}>
                    <TableCell>
                      <TextField
                            type="number" 
                            label={'Slippage'}
                            margin="dense"
                            name="slippage"
                            onChange={handleSlippageChange()}
                            required
                            variant="outlined"
                            value={slippageAmt}
                            fullWidth
                            // InputProps={{
                            //   classes: {
                            //     input: classes.positiveNum,
                            //   },
                            //   style: { textAlign: "right" },
                            //   startAdornment: <InputAdornment position="start">%</InputAdornment>,
                            // }}
                            InputProps={
                              { step: "0.01" },
                              { step: "0.01",
                                startAdornment: <InputAdornment position="start">%</InputAdornment>
                              }
                            }
                          />
                          <Typography
                            color="text"
                            colorBrightness={"hint"}
                            variant={"caption"}
                            style={{ marginRight: 5 }}
                          >
                            *** Increase slippage infavor to your trades
                          </Typography>
                    </TableCell>
                   </TableRow>
                  </Table>
          </DialogContent>
          <DialogContent>
          </DialogContent>
          <DialogActions>
              
            <ButtonGroup size="small" color="primary" aria-label="large outlined primary button group">
              <Button 
                onClick={handleClickSubs} 
                color="primary"
                variant="contained"
                startIcon={<AddCircleIcon />}>
              {'Deposit'}
              </Button>
            </ButtonGroup>
            
          </DialogActions>
        </Dialog>
    </div>
  );
});


export default subscribe;
