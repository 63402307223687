import format from 'format-number';
import moment from 'moment';
import { BigNumber } from "bignumber.js";
import appGlobalConfig from './appGlobalConfig';
const ETH_ADDRESS = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee';
const BNB_ADDRESS = '0x0000000000000000000000000000000000000000';
const EMPTY_ADDRESS = '0x0000000000000000000000000000000000000000';

function roundTo(number, float) {
  if (number === 0) return '0';
  return parseFloat(number).toFixed(float);
}

function formatNumber(number) {
  if (number > 0) {
    return `+${ number }`;
  }

  return number;
}

const xWinLib = {

  ToBigNumber(toConvertNumber) {
    const xAmountBig = new BigNumber(toConvertNumber);

    return Math.floor(xAmountBig.toFixed()).toString();
  },

  GetETH_ADDRESS() {
    return ETH_ADDRESS;
  },

  GetBNB_ADDRESS() {
    return BNB_ADDRESS;
  },

  GetChainID(networkName) {
    let id = 1;

    switch (networkName.toString().toLowerCase()) {
      case 'mainnet':
        id = 1;
        break;
      case 'ropsten':
        id = 3;
        break;
      case 'kovan':
        id = 42;
        break;
      case 'bsctest':
        id = 97;
        break;
      case 'bscmain':
        id = 56;
        break;
      default:
        id = 1;
    }

    return id;
  },

  FormatDate(_date) {
    return moment(new Date(_date.seconds * 1000)).format('L');
  },

  FormatUnixDate(_date) {
    const timestamp = moment.unix(_date);

    return timestamp.format('YYYY-MM-DD HH:mm:ss');
  },

  DirectSwapAsync(xWinProtocol, xfundaddress, FromAddress, _amountOutMin, _destAddress, subsAmtInWei) {
    return new Promise((resolve, reject) => {
      const deadline = Math.floor(Date.now() / 1000) + 60 * 15; //

      xWinProtocol.methods.DirectSwap(
        subsAmtInWei,
        xfundaddress,
        _destAddress,
        _amountOutMin,
        deadline,
      ).send({
        from: FromAddress,
        value: subsAmtInWei,
      })
        .on('confirmation', (confirmationNumber, receipt) => {
          resolve(receipt.transactionHash);
        })
        .on('error', (error, receipt) => {
          if (receipt !== undefined) reject(receipt.transactionHash);
        })
        .catch((err) => {
          console.log('error: %j', err);
          reject(err.message);
        });
    });
  },

  CreateTargetAsync(xWinProtocol, FromAddress, xfundaddress, positions) {
    return new Promise((resolve, reject) => {
      const destAddress = [];
      const aTokenAddress = [];
      const chainlinkAddress = [];
      const targetWgts = [];

      positions.forEach((p) => {
        destAddress.push(p.taddress);
        aTokenAddress.push(p.atokenaddress);
        chainlinkAddress.push(p.chainlinkaddress);
        targetWgts.push(roundTo(p.weight * 100, 0));
      });

      xWinProtocol.methods.CreateTarget(destAddress, targetWgts, xfundaddress).send({
        from: FromAddress,
        value: 0,
      })
        .on('confirmation', (confirmationNumber, receipt) => {
          resolve(receipt.transactionHash);
        })
        .on('error', (error, receipt) => {
          if (receipt !== undefined) reject(receipt.transactionHash);
        })
        .catch((err) => {
          console.log('error: %j', err);
          reject(err.message);
        });
    });
  },

  RebalanceAllInOne(xWinProtocol, xfundaddress, FromAddress, slippageAmt, positions) {
    return new Promise((resolve, reject) => {
      const tradeParam = {
        xFundAddress: xfundaddress,
        amount: 0,
        priceImpactTolerance: slippageAmt * 100,
        deadline: Math.floor(Date.now() / 1000) + 60 * 15,
        returnInBase: true,
        referral: EMPTY_ADDRESS,
      };

      const destAddress = [];
      const targetWgts = [];

      positions.forEach((p) => {
        destAddress.push(p.taddress);
        targetWgts.push(roundTo(p.weight * 100, 0));
      });

      xWinProtocol.methods.RebalanceAllInOne(
        tradeParam,
        destAddress,
        targetWgts,
      ).send({
        from: FromAddress,
        value: 0,
      })
        .on('confirmation', (confirmationNumber, receipt) => {
          resolve(receipt.transactionHash);
        })
        .on('error', (error, receipt) => {
          if (receipt !== undefined) reject(receipt.transactionHash);
        })
        .catch((err) => {
          console.log('error: %j', err);
          reject(err.message);
        });
    });
  },

  MoveNonBMAsync(xWinProtocol, xfundaddress, FromAddress, slippageAmt, tokenAddress) {
    return new Promise((resolve, reject) => {
      xWinProtocol.methods.MoveNonIndexNameToBase(
        xfundaddress,
        tokenAddress,
        Math.floor(Date.now() / 1000) + 60 * 15,
        slippageAmt * 100,
      ).send({
        from: FromAddress,
        value: 0,
      })
        .on('confirmation', (confirmationNumber, receipt) => {
          resolve(receipt.transactionHash);
        })
        .on('error', (error, receipt) => {
          if (receipt !== undefined) reject(receipt.transactionHash);
        })
        .catch((err) => {
          console.log('error: %j', err);
          reject(err.message);
        });
    });
  },

  WithdrawFarmAsync(xWinProtocol, pid, amtInWei, wallet) {
    return new Promise((resolve, reject) => {
      xWinProtocol.methods.WithdrawFarm(pid, amtInWei).send({
        from: wallet,
        value: 0,
        gasPrice: appGlobalConfig.gasPrice,
      })
        .on('confirmation', (confirmationNumber, receipt) => {
          resolve(receipt.transactionHash);
        })
        .on('error', (error, receipt) => {
          if (receipt !== undefined) reject(receipt.transactionHash);
        })
        .catch((err) => {
          console.log('error: %j', err);
          reject(err.message);
        });
    });
  },

  FarmingDepositAsync(xWinProtocol, pid, subsAmtInWei, wallet) {
    return new Promise((resolve, reject) => {
      xWinProtocol.methods.DepositFarm(pid, subsAmtInWei).send({
        from: wallet,
        value: 0,
        gasPrice: appGlobalConfig.gasPrice,
      })
        .on('confirmation', (confirmationNumber, receipt) => {
          resolve(receipt.transactionHash);
        })
        .on('error', (error, receipt) => {
          if (receipt !== undefined) reject(receipt.transactionHash);
        })
        .catch((err) => {
          console.log('error: %j', err);
          reject(err.message);
        });
    });
  },

  AMMBuyAsync(xWinAMM, option, subsAmtInWei, BNBEst, userData) {
    return new Promise((resolve, reject) => {
      const deadline = Math.floor(Date.now() / 1000) + 60 * 15;

      xWinAMM.methods.buyToken(subsAmtInWei, option, deadline).send({
        from: userData.selectedWallet,
        value: BNBEst,
      })
        .on('confirmation', (confirmationNumber, receipt) => {
          resolve(receipt.transactionHash);
        })
        .on('error', (error, receipt) => {
          if (receipt !== undefined) reject(receipt.transactionHash);
        })
        .catch((err) => {
          console.log('error: %j', err);
          reject(err.message);
        });
    });
  },

  AMMWithdrawAsync(xWinAMM, userData) {
    return new Promise((resolve, reject) => {
      xWinAMM.methods.withdrawToken().send({
        from: userData.selectedWallet,
      })
        .on('confirmation', (confirmationNumber, receipt) => {
          resolve(receipt.transactionHash);
        })
        .on('error', (error, receipt) => {
          if (receipt !== undefined) reject(receipt.transactionHash);
        })
        .catch((err) => {
          console.log('error: %j', err);
          reject(err.message);
        });
    });
  },

  WithdrawAccRewardsAsync(xWinProtocol, wallet) {
    return new Promise((resolve, reject) => {
      xWinProtocol.methods.WithdrawReward().send({
        from: wallet,
        gasPrice: appGlobalConfig.gasPrice,
      })
        .on('confirmation', (confirmationNumber, receipt) => {
          resolve(receipt.transactionHash);
        })
        .on('error', (error, receipt) => {
          if (receipt !== undefined) reject(receipt.transactionHash);
        })
        .catch((err) => {
          console.log('error: %j', err);
          reject(err.message);
        });
    });
  },

  FarmReinvestAsync(xWinProtocol, xfundaddress, userData, subsAmtInWei, slippageAmt) {
    return new Promise((resolve, reject) => {
      const tradeParam = {
        xFundAddress: xfundaddress,
        amount: 0,
        priceImpactTolerance: slippageAmt * 100,
        deadline: Math.floor(Date.now() / 1000) + 60 * 15,
        returnInBase: true,
        // referral: EMPTY_ADDRESS
        referral: userData.referral,
      };

      xWinProtocol.methods.reInvest(tradeParam).send({
        from: userData.selectedWallet,
        value: 0,
      })
        .on('confirmation', (confirmationNumber, receipt) => {
          resolve(receipt.transactionHash);
        })
        .on('error', (error, receipt) => {
          if (receipt !== undefined) reject(receipt.transactionHash);
        })
        .catch((err) => {
          console.log('error: %j', err);
          reject(err.message);
        });
    });
  },

  SubscribeDirectAsync(xWinProtocol, xfundaddress, userData, subsAmtInWei, slippageAmt) {
    return new Promise((resolve, reject) => {
      const tradeParam = {
        xFundAddress: xfundaddress,
        amount: subsAmtInWei,
        priceImpactTolerance: slippageAmt * 100,
        deadline: Math.floor(Date.now() / 1000) + 60 * 15,
        returnInBase: true,
        // referral: EMPTY_ADDRESS
        referral: userData.referral === null ? '0x0000000000000000000000000000000000000000' : userData.referral,
      };

      xWinProtocol.methods.Subscribe(tradeParam).send({
        from: userData.selectedWallet,
        value: subsAmtInWei,
        gasPrice: appGlobalConfig.gasPrice,
      })
        .on('confirmation', (confirmationNumber, receipt) => {
          resolve(receipt.transactionHash);
        })
        .on('error', (error, receipt) => {
          if (receipt !== undefined) reject(receipt.transactionHash);
        })
        .catch((err) => {
          console.log('error: %j', err);
          reject(err.message);
        });
    });
  },

  RedeemAsync(xWinProtocol, xfundaddress, FromAddress, amtRedeem, slippageAmt) {
    return new Promise((resolve, reject) => {
      const tradeParam = {
        xFundAddress: xfundaddress,
        amount: amtRedeem,
        priceImpactTolerance: slippageAmt * 100,
        deadline: Math.floor(Date.now() / 1000) + 60 * 15,
        returnInBase: true,
        referral: EMPTY_ADDRESS,
      };

      xWinProtocol.methods.Redeem(tradeParam).send({
        from: FromAddress,
        value: 0,
        gasPrice: appGlobalConfig.gasPrice,
      })
        .on('confirmation', (confirmationNumber, receipt) => {
          resolve(receipt.transactionHash);
        })
        .on('error', (error, receipt) => {
          if (receipt !== undefined) reject(receipt.transactionHash);
        })
        .catch((err) => {
          console.log('error: %j', err);
          reject(err.message);
        });
    });
  },

  AddLiquidityAsync(globalWeb3, myxWinProtocol, userData) {
    return new Promise((resolve, reject) => {
      const bnbAmtInWei = globalWeb3.utils.toWei(userData.EstimateBNBNeeded.toString());
      const deadline = Math.floor(Date.now() / 1000) + 60 * 15;

      myxWinProtocol.methods.StakeMyReward(
        deadline,
      ).send({
        from: userData.selectedWallet,
        value: bnbAmtInWei,
        gasPrice: appGlobalConfig.gasPrice,
      })
        .on('confirmation', (confirmationNumber, receipt) => {
          resolve(receipt.transactionHash);
        })
        .on('error', (error, receipt) => {
          if (receipt !== undefined) reject(receipt.transactionHash);
        })
        .catch((err) => {
          console.log('error: %j', err);
          reject(err.message);
        });
    });
  },

  ClaimRewardAsync(xWinProtocol, FromAddress) {
    return new Promise((resolve, reject) => {
      xWinProtocol.methods.WithdrawReward().send({
        from: FromAddress,
        value: 0,
        gasPrice: appGlobalConfig.gasPrice,
      })
        .on('confirmation', (confirmationNumber, receipt) => {
          resolve(receipt.transactionHash);
        })
        .on('error', (error, receipt) => {
          if (receipt !== undefined) reject(receipt.transactionHash);
        })
        .catch((err) => {
          console.log('error: %j', err);
          reject(err.message);
        });
    });
  },

  AddToMetamask(tokenAddress, tokenSymbol, decimals) {
    return new Promise((resolve, reject) => {
      window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: tokenAddress,
            symbol: tokenSymbol,
            decimals,
            // image: 'https://foo.io/token-image.svg',
          },
        },
      })
        .then((success) => {
          if (success) {
            resolve(`${ tokenSymbol } successfully added to wallet!`);
          } else {
            reject('Ops..! Please try again!');
          }
        })
        .catch((err) => {
          console.log('error: %j', err);
          reject('Ops..! Please try again!');
        });
    });
  },

  RebalanceAsync(xWinProtocol, xfundaddress, FromAddress, slippageAmt) {
    return new Promise((resolve, reject) => {
      const tradeParam = {
        xFundAddress: xfundaddress,
        amount: 0,
        priceImpactTolerance: slippageAmt * 100,
        deadline: Math.floor(Date.now() / 1000) + 60 * 15,
        returnInBase: true,
        referral: EMPTY_ADDRESS,
      };

      xWinProtocol.methods.Rebalance(
        tradeParam,
      ).send({
        from: FromAddress,
        value: 0,
        gasPrice: appGlobalConfig.gasPrice,
      })
        .on('confirmation', (confirmationNumber, receipt) => {
          resolve(receipt.transactionHash);
        })
        .on('error', (error, receipt) => {
          if (receipt !== undefined) reject(receipt.transactionHash);
        })
        .catch((err) => {
          console.log('error: %j', err);
          reject(err.message);
        });
    });
  },

  ClaimBonusAsync(xWinSaleBonusProtocol, userData) {
    return new Promise((resolve, reject) => {
      xWinSaleBonusProtocol.methods.claimMyBonus().send({
        from: userData.selectedWallet,
        value: 0,
        gasPrice: appGlobalConfig.gasPrice,
      })
        .on('confirmation', (confirmationNumber, receipt) => {
          resolve(receipt.transactionHash);
        })
        .on('error', (error, receipt) => {
          if (receipt !== undefined) reject(receipt.transactionHash);
        })
        .catch((err) => {
          console.log('error: %j', err);
          reject(err.message);
        });
    });
  },


  ClaimBonusLPAsync(xWinSaleBonusProtocol, userData) {
    return new Promise((resolve, reject) => {
      // xWinSaleBonusProtocol.methods.claimMyBonus().send({
      xWinSaleBonusProtocol.methods.claimMyLPandBonus().send({
        from: userData.selectedWallet,
        value: 0,
        gasPrice: appGlobalConfig.gasPrice,
      })
        .on('confirmation', (confirmationNumber, receipt) => {
          resolve(receipt.transactionHash);
        })
        .on('error', (error, receipt) => {
          if (receipt !== undefined) reject(receipt.transactionHash);
        })
        .catch((err) => {
          console.log('error: %j', err);
          reject(err.message);
        });
    });
  },

  ClaimBonusXWINAsync(xWinSaleBonusProtocol, userData) {
    return new Promise((resolve, reject) => {
      xWinSaleBonusProtocol.methods.claimXWINandBonus().send({
        from: userData.selectedWallet,
        value: 0,
        gasPrice: appGlobalConfig.gasPrice,
      })
        .on('confirmation', (confirmationNumber, receipt) => {
          resolve(receipt.transactionHash);
        })
        .on('error', (error, receipt) => {
          if (receipt !== undefined) reject(receipt.transactionHash);
        })
        .catch((err) => {
          console.log('error: %j', err);
          reject(err.message);
        });
    });
  },

  BuyLPTokenAsync(xWinSaleProtocol, subsAmtInWei, userData) {
    return new Promise((resolve, reject) => {
      xWinSaleProtocol.methods.buyLPToken().send({
        from: userData.selectedWallet,
        value: subsAmtInWei,
        gasPrice: appGlobalConfig.gasPrice,
      })
        .on('confirmation', (confirmationNumber, receipt) => {
          resolve(receipt.transactionHash);
        })
        .on('error', (error, receipt) => {
          if (receipt !== undefined) reject(receipt.transactionHash);
        })
        .catch((err) => {
          console.log('error: %j', err);
          reject(err.message);
        });
    });
  },

  BuyDirectAsync(xWinSaleProtocol, subsAmtInWei, userData) {
    return new Promise((resolve, reject) => {
      xWinSaleProtocol.methods.buyToken().send({
        from: userData.selectedWallet,
        value: subsAmtInWei,
        gasPrice: appGlobalConfig.gasPrice,
      })
        .on('confirmation', (confirmationNumber, receipt) => {
          resolve(receipt.transactionHash);
        })
        .on('error', (error, receipt) => {
          if (receipt !== undefined) reject(receipt.transactionHash);
        })
        .catch((err) => {
          console.log('error: %j', err);
          reject(err.message);
        });
    });
  },

  SellDirectAsync(xWinSaleProtocol, sellAmtInWei, userData) {
    return new Promise((resolve, reject) => {
      xWinSaleProtocol.methods.sellToken(sellAmtInWei).send({
        from: userData.selectedWallet,
        value: 0,
        gasPrice: appGlobalConfig.gasPrice,
      })
        .on('confirmation', (confirmationNumber, receipt) => {
          resolve(receipt.transactionHash);
        })
        .on('error', (error, receipt) => {
          if (receipt !== undefined) reject(receipt.transactionHash);
        })
        .catch((err) => {
          console.log('error: %j', err);
          reject(err.message);
        });
    });
  },

  ClaimTeamRewardsAsync(protocol, userData, pid) {
    return new Promise((resolve, reject) => {
      protocol.methods.claimMyToken(pid).send({
        from: userData.selectedWallet,
        value: 0,
        gasPrice: appGlobalConfig.gasPrice,
      })
        .on('confirmation', (confirmationNumber, receipt) => {
          resolve(receipt.transactionHash);
        })
        .on('error', (error, receipt) => {
          if (receipt !== undefined) reject(receipt.transactionHash);
        })
        .catch((err) => {
          console.log('error: %j', err);
          reject(err.message);
        });
    });
  },

  PrivateInvestorAsync(protocol, userData) {
    return new Promise((resolve, reject) => {
      protocol.methods.claimMyToken().send({
        from: userData.selectedWallet,
        value: 0,
        gasPrice: appGlobalConfig.gasPrice,
      })
        .on('confirmation', (confirmationNumber, receipt) => {
          resolve(receipt.transactionHash);
        })
        .on('error', (error, receipt) => {
          if (receipt !== undefined) reject(receipt.transactionHash);
        })
        .catch((err) => {
          console.log('error: %j', err);
          reject(err.message);
        });
    });
  },

  CompensationRegisterAsync(protocol, userData) {
    return new Promise((resolve, reject) => {
      protocol.methods.registerMe().send({
        from: userData.selectedWallet,
        value: 0,
        gasPrice: appGlobalConfig.gasPrice,
      })
        .on('confirmation', (confirmationNumber, receipt) => {
          resolve(receipt.transactionHash);
        })
        .on('error', (error, receipt) => {
          if (receipt !== undefined) reject(receipt.transactionHash);
        })
        .catch((err) => {
          console.log('error: %j', err);
          reject(err.message);
        });
    });
  },

  CompensationWithdrawAsync(protocol, userData) {
    return new Promise((resolve, reject) => {
      protocol.methods.withdrawCompensation().send({
        from: userData.selectedWallet,
        value: 0,
        gasPrice: appGlobalConfig.gasPrice,
      })
        .on('confirmation', (confirmationNumber, receipt) => {
          resolve(receipt.transactionHash);
        })
        .on('error', (error, receipt) => {
          if (receipt !== undefined) reject(receipt.transactionHash);
        })
        .catch((err) => {
          console.log('error: %j', err);
          reject(err.message);
        });
    });
  },

  StakeDepositAsync(protocol, userData, pid, amount) {
    return new Promise((resolve, reject) => {
      protocol.methods.stakeToken(
        pid,
        amount,
      ).send({
        from: userData.selectedWallet,
        value: 0,
        gasPrice: appGlobalConfig.gasPrice,
      })
        .on('confirmation', (confirmationNumber, receipt) => {
          resolve(receipt.transactionHash);
        })
        .on('error', (error, receipt) => {
          if (receipt !== undefined) reject(receipt.transactionHash);
        })
        .catch((err) => {
          console.log('error: %j', err);
          reject(err.message);
        });
    });
  },

  UnStakeDepositAsync(protocol, userData, pid, amount) {
    return new Promise((resolve, reject) => {
      protocol.methods.unStakeToken(
        pid,
      ).send({
        from: userData.selectedWallet,
        value: 0,
        gasPrice: appGlobalConfig.gasPrice,
      })
        .on('confirmation', (confirmationNumber, receipt) => {
          resolve(receipt.transactionHash);
        })
        .on('error', (error, receipt) => {
          if (receipt !== undefined) reject(receipt.transactionHash);
        })
        .catch((err) => {
          console.log('error: %j', err);
          reject(err.message);
        });
    });
  },

  UnStakePrivateVaultAsync(protocol, userData, pid, amount) {
    return new Promise((resolve, reject) => {
      protocol.methods.unStakeToken(
        pid,
        amount,
      ).send({
        from: userData.selectedWallet,
        value: 0,
        gasPrice: appGlobalConfig.gasPrice,
      })
        .on('confirmation', (confirmationNumber, receipt) => {
          resolve(receipt.transactionHash);
        })
        .on('error', (error, receipt) => {
          if (receipt !== undefined) reject(receipt.transactionHash);
        })
        .catch((err) => {
          console.log('error: %j', err);
          reject(err.message);
        });
    });
  },

  harvestXWINPrivateVaultAsync(protocol, userData, pid) {
    return new Promise((resolve, reject) => {
      protocol.methods.harvestXWIN(
        pid,
      ).send({
        from: userData.selectedWallet,
        value: 0,
        gasPrice: appGlobalConfig.gasPrice,
      })
        .on('confirmation', (confirmationNumber, receipt) => {
          resolve(receipt.transactionHash);
        })
        .on('error', (error, receipt) => {
          if (receipt !== undefined) reject(receipt.transactionHash);
        })
        .catch((err) => {
          console.log('error: %j', err);
          reject(err.message);
        });
    });
  },

  registerLuckyDrawAsync(protocol, account) {
    return new Promise((resolve, reject) => {
      protocol.methods.registerMe().send({ from: account, value: 0 })
        .on('confirmation', (confirmationNumber, receipt) => {
          resolve(receipt.transactionHash);
        })
        .on('error', (error) => {
          reject(error.message);
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  },

  redeemLuckyDrawAsync(protocol, account, round) {
    return new Promise((resolve, reject) => {
      protocol.methods.claimPrizes(round).send({ from: account, value: 0 })
        .on('confirmation', (confirmationNumber, receipt) => {
          resolve(receipt.transactionHash);
        })
        .on('error', (error) => {
          reject(error.message);
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  },

/**
 * CharityDonateAsync
 * Added for xWin Charity contract
 * @param {*} protocol 
 * @param {*} userData 
 * @param {*} charityID 
 * @param {*} amount 
 * @returns 
 */
  CharityDonateAsync(protocol, userData, charityID, amount) {
    return new Promise((resolve, reject) => {
      protocol.methods.donate(
        charityID,
        amount,
      ).send({
        from: userData.selectedWallet,
        value: 0,
        gasPrice: appGlobalConfig.gasPrice,
      })
        .on('confirmation', (confirmationNumber, receipt) => {
          resolve(receipt.transactionHash);
        })
        .on('error', (error, receipt) => {
          if (receipt !== undefined) reject(receipt.transactionHash);
        })
        .catch((err) => {
          console.log('error: %j', err);
          reject(err.message);
        });
    });
  },

  /*CharityWithdrawByOwner */
  CharityWithdrawByCharityOwner(protocol, userData, charityID, amount) {
    return new Promise((resolve, reject) => {
      protocol.methods.withdrawByCharityOwner(
        charityID,
        amount,
      ).send({
        from: userData.selectedWallet,
        value: 0,
        gasPrice: appGlobalConfig.gasPrice,
      })
        .on('confirmation', (confirmationNumber, receipt) => {
          resolve(receipt.transactionHash);
        })
        .on('error', (error, receipt) => {
          if (receipt !== undefined) reject(receipt.transactionHash);
        })
        .catch((err) => {
          console.log('error: %j', err);
          reject(err.message);
        });
    });
  },

  CharityHarvestAsync(protocol, userData) {
    return new Promise((resolve, reject) => {
      protocol.methods.harvestByCharityOwner(
      ).send({
        from: userData.selectedWallet,
        value: 0,
        gasPrice: appGlobalConfig.gasPrice,
      })
        .on('confirmation', (confirmationNumber, receipt) => {
          resolve(receipt.transactionHash);
        })
        .on('error', (error, receipt) => {
          if (receipt !== undefined) reject(receipt.transactionHash);
        })
        .catch((err) => {
          console.log('error: %j', err);
          reject(err.message);
        });
    });
  },

  AddCharity(protocol, userData, name, daysToDue, targetAmount) {
    return new Promise((resolve, reject) => {
      protocol.methods.addCharity(
        name,
        daysToDue,
        targetAmount,
      ).send({
        from: userData.selectedWallet,
        value: 0,
        gasPrice: appGlobalConfig.gasPrice,
      })
        .on('confirmation', (confirmationNumber, receipt) => {
          resolve(receipt.transactionHash);
        })
        .on('error', (error, receipt) => {
          if (receipt !== undefined) reject(receipt.transactionHash);
        })
        .catch((err) => {
          console.log('error: %j', err);
          reject(err.message);
        });
    });
  },

  getLogoSVG(logoname) {
    return `/images/svg/${ logoname }`;
  },

  getLogo(logoname) {
    return `/images/logos/${ logoname }`;
  },

  getIcon(logoname) {
    return `images/icons/color/${ logoname }`;
  },


  getBaseCcy(networkName) {
    return 'BNB';
  },

  getMarketCap(fundData) {
    return format({ prefix: ' $', suffix: '' })(xWinLib.roundTo(fundData.unipriceInUSD * fundData.totalSupply, 2));
  },

  async getNetwork(web3) {
    if (web3 === undefined) return 'mainnet';

    let filterEnv = 'mainnet';
    const netId = await web3.eth.net.getId();

    switch (netId) {
      case 1:
        filterEnv = 'mainnet';
        break;
      case 4:
        filterEnv = 'rinkeby';
        break;
      case 3:
        filterEnv = 'ropsten';
        break;
      case 42:
        filterEnv = 'kovan';
        break;
      case 97:
        filterEnv = 'bsctest';
        break;
      case 56:
        filterEnv = 'bscmain';
        break;
      default:
    }

    return filterEnv;
  },


  getEtherLink(networkName, address, type) {
    let uri = '';

    switch (networkName) {
      case 'Mainnet':
        uri = `https://etherscan.io/${ type }/`;
        break;
      case 'Ropsten':
        uri = `https://ropsten.etherscan.io/${ type }/`;
        break;
      case 'Kovan':
        uri = `https://kovan.etherscan.io/${ type }/`;
        break;
      case 'bsctest':
        uri = `https://testnet.bscscan.com/${ type }/`;
        break;
      case 'bscmain':
        uri = `https://bscscan.com/${ type }/`;
        break;
      default:
        uri = `https://bscscan.com/${ type }/`;
    }

    return uri + address;
  },

  approveContract(sourceToken, allowance, contractAddr, FromAddress) {
    return new Promise((resolve, reject) => {
      sourceToken.methods.approve(contractAddr, allowance).send({
        from: FromAddress,
        value: 0,
        gasPrice: appGlobalConfig.gasPrice,
      })
        .on('confirmation', (confirmationNumber, receipt) => {
          resolve(receipt.transactionHash);
        })
        .on('error', (error, receipt) => {
          if (receipt !== undefined) reject(receipt.transactionHash);
        })
        .catch((err) => {
          console.log('error: %j', err);
          reject(err.message);
        });
    });
  },

  getTokenWeight(tokenName, fundTotalValue) {
    return tokenName.fundTokenValue / fundTotalValue * 100;
  },

  getTokenWeightExcludeETH(tokenName, fundTotalValue, ethToken) {
    return this.roundTo(tokenName.fundTokenValue / (fundTotalValue - ethToken.fundTokenValue) * 100, 2);
  },

  getTokenActiveWeight(tokenName, fundTotalValue) {
    const fundWgt = this.getTokenWeight(tokenName, fundTotalValue);
    const targetWgt = tokenName.targetweight / 100;

    return (targetWgt - fundWgt);
  },

  getTokenName(tokensMaster, address, networkName) {
    if (address === ETH_ADDRESS) return 'ETH';

    if (address === BNB_ADDRESS) return 'BNB';

    if (tokensMaster === undefined) return '';
    const tokenname = tokensMaster.find((t) => t.address.toLowerCase() === address.toLowerCase() && t.env === networkName);

    if (tokenname === undefined) return '';
    return tokenname.name;
  },

  roundToFormat(number, float) {
    if (number === 0) return '';
    return formatNumber(parseFloat(number).toFixed(float));
  },


  toPercent(number, float) {
    if (number === 0) return '';
    const percent = `${ parseFloat(number * 100).toFixed(float) }%`;

    return percent;
  },

  numberWithThousand(x) {
    return format({ prefix: '', suffix: 'k' })(xWinLib.roundTo(x / 1000, 0));
  },


  numberWithCommas(x) {
    return format({ prefix: appGlobalConfig.BaseCurrency, suffix: '' })(roundTo(x, 2));
  },

  roundTo(number, float) {
    if (isNaN(number)) return 0;

    if (number === 0) return 0;
    return roundTo(number, float);
  },

  roundDown(number, float) {
    if (isNaN(number)) return 0;

    if (number === 0) return 0;

    float = float || 0;
    return (Math.floor(number * Math.pow(10, float)) / Math.pow(10, float));
  },

  compareValues(key, order = 'asc') {
    return function innerSort(a, b) {
      if (! a.hasOwnProperty(key) || ! b.hasOwnProperty(key)) {
      // property doesn't exist on either object
        return 0;
      }

      const varA = (typeof a[key] === 'string')
        ? a[key].toUpperCase() : a[key];

      const varB = (typeof b[key] === 'string')
        ? b[key].toUpperCase() : b[key];

      let comparison = 0;

      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = - 1;
      }

      return (
        (order === 'desc') ? (comparison * - 1) : comparison
      );
    };
  },


};

export default xWinLib;
