import React from "react";
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import { ToastContainer } from "react-toastify";
import { Close as CloseIcon } from "@material-ui/icons";
import useStyles from './styles';
// components
import Analytics from "./Analytics/Analytics";
import Layout from "./Layout";
import firebase from "firebase/app";
import "firebase/firestore";

// pages
import Error from "../pages/error";
import Home from "../pages/home";
import config from "../config";

// context
// import { useUserState } from "../context/UserContext";

firebase.initializeApp(config.firebaseConfig);

// Initialize other services on firebase instance
firebase.firestore();

export default function App() {
  const classes = useStyles();
  function CloseButton({ closeToast, className }) {
    return <CloseIcon className={className} onClick={closeToast} />;
  }

  return (
    <>
    <ToastContainer
      className={classes.toastsContainer}
      closeButton={
        <CloseButton className={classes.notificationCloseButton} />
      }
      closeOnClick={false}
      progressClassName={classes.notificationProgress}
    />
      <BrowserRouter>
        <Analytics />
        <Switch>
          {/* <Route exact path="/" component={Home} /> */}
          {/* <Route exact path="/home" render={() => <Redirect to="/" />} />  /> */}
          <Route exact path="/home" render={() => <Redirect to="/" />} />
          {/* <Route exact path="/" render={() => <Redirect to="/app/profile" />} /> */}
          <Route
            exact
            path="/"
            render={() => <Redirect to="/app/dashboard" />}
          />
          <PublicRoute path="/app" component={Layout} />
          <Route component={Error} />
        </Switch>
      </BrowserRouter>
    </>
  );

  // #######################################################################

  // function PrivateRoute({ component, ...rest }) {
  //   return (
  //     <Route
  //       {...rest}
  //       render={props =>
  //           isAuth ? (
  //           React.createElement(component, props)
  //         ) : (
  //           <Redirect to={"/login"} />
  //         )
  //       }
  //     />
  //   );
  // }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
            React.createElement(component, props)
        }
      />
    );
  }
}
