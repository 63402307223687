import React, { createContext, useState } from "react";
import useNetwork from '../useNetwork';

// see also https://stackoverflow.com/questions/63007702

export const AppContext = createContext();

const AppProvider = ({ children }) => {
  // appMsg is {variant, message}
  // variant is "default" | "error" | "success" | "warning" | "info"
  const [appMsg, setAppMsg_] = useState(["info", ""])
  const [{ ETHBalance, wallet, network, account, theWeb3, web3static, isConnected, globalProvider }, refreshAccount, connectNetwork, disconnectNetwork] = useNetwork()

  const setAppMsg = (appMsg_) => {
    //console.log(appMsg_)
    setAppMsg_(appMsg_)
  }

  const value = {
    globalProvider, ETHBalance, wallet, network, account, theWeb3, web3static, isConnected, appMsg,
    refreshAccount, connectNetwork, disconnectNetwork, setAppMsg
  };

  // console.log(value)
  return (
    <AppContext.Provider value={value}>
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
