import React, { useState, useEffect  } from "react";
import { 
  Grid, 
  MobileStepper,
  Card,
  CardHeader,
  CardContent,
  Container
 } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import useStyles from "./styles";
import firebase from "firebase/app";
import "firebase/firestore";

//components
import { Button } from "../../components/Wrappers/Wrappers";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function XwinVideo() {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [maxSteps, setMaxSteps] = React.useState(0);
  const [activeStep2, setActiveStep2] = React.useState(0);
  const [maxSteps2, setMaxSteps2] = React.useState(0);
  
  const [videos, setVideos] = useState([])
  const [videos2, setVideos2] = useState([])
  
  useEffect(() => {
    const unsubscribe = firebase
    .firestore()
    .collection('xwinvideo')
    .onSnapshot((snapshot) => {
      const newValues = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }))
      getFilterVideo(newValues)
    })
    return () => { unsubscribe() }

  }, [])

  const getFilterVideo = (rawvideos) => {

    let videoArr = []
    let videoArr2 = []
    let half = rawvideos.length / 2
    let count = 0
    rawvideos.forEach(v => {
      if(count < half){
        videoArr.push({
          "id" : v.id,
          "url" : v.url,
          "title" : v.title
        })
      }else{
        videoArr2.push({
          "id" : v.id,
          "url" : v.url,
          "title" : v.title
        })
      }
      count ++ 
    })
    setMaxSteps(videoArr.length)
    setMaxSteps2(videoArr2.length)
    setVideos(videoArr)
    setVideos2(videoArr2)
  }

  const handleNext2 = () => {
    setActiveStep2(prevActiveStep => prevActiveStep + 1);
  };

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack2 = () => {
    setActiveStep2(prevActiveStep => prevActiveStep - 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleStepChange2 = step => {
    setActiveStep2(step);
  };

  const handleStepChange = step => {
    setActiveStep(step);
  };

  return (
    <Container>
    <Grid container spacing={5}>
      <Grid item 
        lg={6}
        sm={12}
        xl={6}
        xs={12}>
          <AutoPlaySwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
            autoplay={false}
            // interval={10000}
          >
            {videos.map((step, index) => (
              <div key={step.id}>
                {Math.abs(activeStep - index) <= 2 ? (
                  <Card>
                    <CardHeader
                      className={classes.primaryHeading}
                      subheader= {step.title}
                      subheaderTypographyProps={{variant:'subtitle1' }}
                    />
                    <CardContent>
                    <div
                      className="video"
                      style={{
                        position: "relative",
                        paddingBottom: "56.25%",
                        paddingTop: 25,
                        height: 0
                      }}
                    >
                      <iframe title={step.title}
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%"
                        }}
                        src={step.url}
                        frameBorder="0"
                        allowfullscreen
                      />
                    </div>
                  </CardContent> 
                  </Card>
                ) : null}
              </div>
            ))}
          </AutoPlaySwipeableViews>
          <MobileStepper
            steps={maxSteps}
            position="static"
            variant="dots"
            activeStep={activeStep}
            nextButton={
              <Button
                size="small"
                onClick={handleNext}
                disabled={activeStep === maxSteps - 1}
              >
                Next
                {theme.direction === "rtl" ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                {theme.direction === "rtl" ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowLeft />
                )}
                Back
              </Button>
            }
          />
      </Grid>
      <Grid item 
        lg={6}
        sm={12}
        xl={6}
        xs={12}>
          <AutoPlaySwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={activeStep2}
            onChangeIndex={handleStepChange2}
            enableMouseEvents
            autoplay={false}
            // interval={10000}
          >
            {videos2.map((step, index) => (
              <div key={step.id}>
                {Math.abs(activeStep2 - index) <= 2 ? (
                  <Card>
                    <CardHeader
                      className={classes.primaryHeading}
                      subheader= {step.title}
                      subheaderTypographyProps={{variant:'subtitle1' }}
                    />
                    <CardContent>
                    <div
                      className="video"
                      style={{
                        position: "relative",
                        paddingBottom: "56.25%",
                        paddingTop: 25,
                        height: 0
                      }}
                    >
                      <iframe
                         title={step.title}
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%"
                        }}
                        src={step.url}
                        frameBorder="0"
                        allowfullscreen
                      />
                    </div>
                  </CardContent> 
                  </Card>
                ) : null}
              </div>
            ))}
          </AutoPlaySwipeableViews>
          <MobileStepper
            steps={maxSteps2}
            position="static"
            variant="dots"
            activeStep={activeStep2}
            nextButton={
              <Button
                size="small"
                onClick={handleNext2}
                disabled={activeStep2 === maxSteps2 - 1}
              >
                Next
                {theme.direction === "rtl" ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={handleBack2}
                disabled={activeStep2 === 0}
              >
                {theme.direction === "rtl" ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowLeft />
                )}
                Back
              </Button>
            }
          />
      </Grid>

    </Grid>
    </Container>
  );
}

export default XwinVideo;
