import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => (
  {
    root: {
      "&:hover": {
        boxShadow: theme.customShadows.widgetWide
      },
    }, 
    expansion: {
      backgroundColor: theme.palette.primary.light,
      color: 'white '
    },
    newsHeader: {
      textDecoration: 'none',
      textTransform: 'uppercase',
      color: '#536DFE',
    },
    subTitle: {
      color: theme.palette.warning.dark,
    },
    avatar: {
      height: 40,
      width: 40
    },
    columnHide: {
      [theme.breakpoints.down('xs')]: {
        display: "none"
      }
    },
    columnShow: {
      display: "none",
      [theme.breakpoints.down('xs')]: {
        display: "inline"
      }
    },
    notificationItem: {
      marginTop: theme.spacing(2),
    },
    snackbarWarning: {
      backgroundColor: theme.palette.warning.main,
    },
    snackbarSuccess: {
      backgroundColor: theme.palette.success.main,
      color: "black"
    },
    negativeNum: {
      textAlign: "right",
      fontSize: 15,
      color: theme.palette.warning.main,
    },
  }
))