import React, { useEffect, useState, useContext } from "react";
import CharityDetails from "./components/CharityDetails";
import {AppContext}  from '../../context/AppProvider';
import xWinStaking from '../../abi/xWinStaking.json'
import xWinCharity from '../../abi/xWinCharity.json'
import xWinUtils from '../../xWinUtils';
import BEP20 from '../../abi/BEP20.json'
import xWinProtocolV5 from '../../abi/xWinProtocolV5.json'

import {
  Container,
  Grid,
} from "@material-ui/core";

// components
import xWinLib from '../../xWinLib'
// import xWinUtils from '../../xWinUtils'
import Widget from "../../components/Widget/Widget";
import useStyles from "./styles";
import appGlobalConfig from "../../appGlobalConfig";

export default function Charity(props) {
  
  const {portfolios, fundtype } = props;
  const [networkName, setNetworkName] = useState("")
  // const [filterPortfolios, setFilterPortfolios] = useState([])
  const [phases, setPhases] = useState([])
  const [winStake, setWinStake] = useState([])
  const [winCharity, setWinCharity] = useState([])
  
  const { ETHBalance, isConnected, globalProvider, account, theWeb3, refreshAccount } = useContext(AppContext);
  
  if(globalProvider !== undefined){
    globalProvider.on("accountsChanged", (accounts) => {
      loadWeb3()
    });
  }

  const classes = useStyles();
  
  useEffect(() => {
    const controller = new AbortController();
    loadWeb3()
    return () => {
      controller.abort();
    }
  }, [account]);

  
  const loadWeb3 = async () => {


    if(theWeb3 === undefined) return

    const promises = appGlobalConfig.charity.map(async port => {
      let myPhases = []
      const myxWinCharity= new theWeb3.eth.Contract(xWinCharity, port.contractaddress); 
      setWinCharity(myxWinCharity)
      let xWinCharityOwner = await myxWinCharity.methods.charityOwnerWallet().call()
      let adminOwner = await myxWinCharity.methods.owner().call()
      let numOfCharities = await myxWinCharity.methods.getNumberOfCharities().call()
      let xWINRewards = await myxWinCharity.methods.getXWINRewards(0).call()
      xWINRewards = xWinUtils.convertFromWei(theWeb3, xWINRewards)
      let burnFee = await myxWinCharity.methods.burnFee().call()
      burnFee = burnFee / 100;

      for(let i=0; i< numOfCharities; i++){
        let charityInfo = await myxWinCharity.methods.charityInfo(i).call()
        let startBlock = charityInfo.startblock
        let dueBlock = charityInfo.dueBlock
        let targetAmount = xWinUtils.convertFromWei(theWeb3, charityInfo.tergetAmount)
        let donatedTotal = xWinUtils.convertFromWei(theWeb3, charityInfo.donatedTotal)
        let stakedTotal  = xWinUtils.convertFromWei(theWeb3, charityInfo.stakedTotal)
        
        let daysToDue = charityInfo.daysToDue
        myPhases.push({
          "mainName" :  port.charityName,
          "name" : charityInfo.name,
          "targetAmount" : targetAmount,
          "donatedTotal" : donatedTotal,
          "xWINRewards" : xWINRewards,
          "daysToDue" : daysToDue,
          "xWinCharityOwner" : xWinCharityOwner,
          "adminOwner" : adminOwner,
          "stakedTotal" : stakedTotal,
          "totalInterestPaid": "",
          "dueBlock" : dueBlock,
          "startBlock" : startBlock,
          "isApproved" : false,
          "burnFee" : burnFee,
          "pid" : i,
          "numOfCharities" : numOfCharities,
          "port": port
        })
      }
      return myPhases

    });
    const tmpPhases = await Promise.all(promises);
    let tmp = []
    let count = 0
    tmpPhases.forEach(p => {
      tmpPhases[count].forEach(p => {
        tmp.push(p)
      });     
      count++   
    });
    setPhases(tmp)  
  }

  const displayPortfolios = () => {
    
    if(phases === undefined) return ""

    let funds = phases
    
    return (
      phases.map(phase => (
        <Grid
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
        > 
           <CharityDetails 
            portfolios={portfolios}
            globalWeb3={theWeb3}
            selectedWallet={account}
            networkName={networkName}
            fundtype={fundtype}
            refreshAccount={refreshAccount}
            ETHBalance={ETHBalance}
            phase={phase}
            winStake={winStake}
            theWeb3={theWeb3}
            winCharity={winCharity}
            loadWeb3={loadWeb3}
            /> 
          </Grid> 
        )) 
    )    
  }

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item md={12}>
          <Widget
            className={classes.newsHeader}
            title={"xWIN Charities"}
            disableWidgetMenu
            inheritHeight
            enableWalletDisplay
            selectedWallet={account}
          >
            
             {displayPortfolios()}
            
          </Widget>
        </Grid>
      </Grid>
    </Container>
  );
}
