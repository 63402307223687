import React, { useState, useEffect, useRef, useContext  } from "react";
import xWinLib from '../../xWinLib';
import xWinUtils from '../../xWinUtils';
import xWinSale from '../../abi/xWinSaleV2.json'
import xWinSaleBonus from '../../abi/xWinSaleBonusV2.json'
import xWinSalev1 from '../../abi/xWinSale.json'
import xWinSaleBonusv1 from '../../abi/xWinSaleBonus.json'
import BEP20 from '../../abi/BEP20.json'

import appGlobalConfig from '../../appGlobalConfig';
import { Alert } from '@material-ui/lab';
import format from 'format-number';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import TimerIcon from '@material-ui/icons/Timer';
import SnackbarMessage from '../snackbarmessage/SnackbarMessage'
import {AppContext}  from '../../context/AppProvider';
import confetti from 'canvas-confetti'

import {
  Grid,
  Button,
  Container,
  Snackbar,
  Box,
  TextField,
  InputAdornment,
  Chip,
  MenuItem,
  FormControl,
  Select
} from "@material-ui/core";

// styles
import useStyles from "./styles";

// components
import Widget from "../../components/Widget/Widget";
import { Typography, Avatar } from "../../components/Wrappers/Wrappers";

const BonusClaim = props => {
  var classes = useStyles();
  const [loadingTxn, setLoadingTxn] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [contractChoice, setContractChoice] = React.useState(1);
  
  const [userData, setUserData] = useState({
    walletETHBalance: 0,
    selectedWallet: ""
  });
  const [currentBlock, setCurrentBlock] = useState(0)
  const [xWinSaleBonusProtocol, setxWinSaleBonusProtocol] = useState("")
  const [xWinSaleBonusProtocolv1, setxWinSaleBonusProtocolv1] = useState("")
  const [openMsg, setOpenMsg] = React.useState(false);
  const { ETHBalance, isConnected, account, theWeb3 } = useContext(AppContext);
  
  const refsnackbar = useRef(null);
  
  // if(theWeb3 !== undefined){
  //   theWeb3.currentProvider.on("accountsChanged", (accounts) => {
  //     loadWeb3();
  //   });
  //   theWeb3.currentProvider.on("chainChanged", (accounts) => {
  //     loadWeb3();
  //   });
  // }

  const hideMessage = () => {
    setOpenMsg(false);
  };

  useEffect(() => {
    const controller = new AbortController();
    loadWeb3()
    return () => {
      controller.abort();
    }
  }, []);

  const showSnackBar = (status, msg) => {
    refsnackbar.current.handleOpen(status, msg);
  }

  const loadWeb3 = async () => {
    
    if(!isConnected) return
    if(theWeb3 === undefined) return
    
    setLoading(true)
    
    let myBonusProtocol = new theWeb3.eth.Contract(xWinSaleBonus, appGlobalConfig.mainnetxWinSaleBonus);
    let myBonusProtocolv1 = new theWeb3.eth.Contract(xWinSaleBonusv1, appGlobalConfig.mainnetxWinSaleBonusv1);
    setxWinSaleBonusProtocol(myBonusProtocol)
    setxWinSaleBonusProtocolv1(myBonusProtocolv1)
    let mySaleProtocol = new theWeb3.eth.Contract(xWinSale, appGlobalConfig.mainnetxWinSale);
    let mySaleProtocolv1 = new theWeb3.eth.Contract(xWinSalev1, appGlobalConfig.mainnetxWinSalev1);
    
    let xWINBEP20 = new theWeb3.eth.Contract(BEP20, appGlobalConfig.mainnetXWIN);
    let xWINBalance = await xWINBEP20.methods.balanceOf(appGlobalConfig.mainnetxWinSaleBonus).call()
    //let xWINBalancev1 = await xWINBEP20.methods.balanceOf(appGlobalConfig.mainnetxWinSaleBonusv1).call()
    
    let currBlock = await theWeb3.eth.getBlockNumber()
    setCurrentBlock(currBlock)
    let myXWINBalance = 0
    let userInfoV1
    let userInfoLP
    let userInfoXWIN
    let daysReleaseLP = 0
    let daysReleaseXWIN = 0
    let daysReleaseV1 = 0
    if(account !== "---"){
      
      myXWINBalance = await xWINBEP20.methods.balanceOf(account).call()
      myXWINBalance = xWinUtils.convertFromWei(theWeb3, xWINBalance.toString())
      userInfoLP = await mySaleProtocol.methods.userInfoLP(account).call()
      userInfoXWIN = await mySaleProtocol.methods.userInfoXWIN(account).call()
      userInfoV1 = await mySaleProtocolv1.methods.getUserBonusInfo(account).call()
      daysReleaseLP = userInfoLP.releaseDay - currBlock < 0 ? 0 : (userInfoLP.releaseDay - currBlock) / 28800
      daysReleaseXWIN = userInfoXWIN.releaseDay - currBlock < 0 ? 0 : (userInfoXWIN.releaseDay - currBlock) / 28800
      daysReleaseV1 = userInfoV1[0] - currBlock < 0 ? 0 : (userInfoV1[0] - currBlock) / 28800

    }

    setUserData({
      "walletETHBalance" :  ETHBalance, 
      "selectedWallet" : account,
      "myXWINBalance" : myXWINBalance,
      "accBonusLP" : xWinUtils.convertFromWei(theWeb3, userInfoLP?.accBonus.toString()),
      "buyamountLP" : xWinUtils.convertFromWei(theWeb3, userInfoLP?.buyAmount.toString()),
      "daysReleaseLP" : daysReleaseLP,
      "releaseBlocksLP" : userInfoLP === undefined ? 0 : userInfoLP?.releaseDay,
      "accBonusXWIN" : xWinUtils.convertFromWei(theWeb3, userInfoXWIN?.accBonus.toString()),
      "buyamountXWIN" : xWinUtils.convertFromWei(theWeb3, userInfoXWIN?.buyAmount.toString()),
      "daysReleaseXWIN" : daysReleaseXWIN,
      "releaseBlocksXWIN" : userInfoXWIN === undefined ? 0 : userInfoXWIN.releaseDay,
      "accBonusV1" : userInfoV1 === undefined ? 0 : xWinUtils.convertFromWei(theWeb3, userInfoV1[1]?.toString()),
      "buyamountV1" : userInfoV1 === undefined ? 0 : xWinUtils.convertFromWei(theWeb3, userInfoV1[2]?.toString()),
      "daysReleaseV1" : daysReleaseV1,
      "releaseBlocksV1" : userInfoV1 === undefined ? 0 : userInfoV1[0],
    })  
    
    setLoading(false)
  };

  const handleClickClaim = event => {
    event.preventDefault();
    
    if(contractChoice === 1) handleClickClaimXWIN()
    else if(contractChoice === 2) handleClickClaimLP()
    else if(contractChoice === 3) handleClickClaimV1()
    
  };


  const handleClickClaimV1 = () => {
    
    setLoadingTxn(true)
    xWinLib.ClaimBonusAsync(
      xWinSaleBonusProtocolv1,
      userData
      )
    .then(res =>
      { 
        console.log(res)
        setLoadingTxn(false)
        showSnackBar("success", res)
        loadWeb3()
      }
    )
    .catch(err => {
      setLoadingTxn(false)
      console.log(err)
      showSnackBar("error", err)
    }
    )
  };

  const handleClickClaimLP = () => {
    
    setLoadingTxn(true)
    xWinLib.ClaimBonusLPAsync(
      xWinSaleBonusProtocol,
      userData
      )
    .then(res =>
      { 
        console.log(res)
        setLoadingTxn(false)
        showSnackBar("success", res)
        confetti()
        loadWeb3()
      }
    )
    .catch(err => {
      setLoadingTxn(false)
      console.log(err)
      showSnackBar("error", err)
    }
    )
  };

  const handleClickClaimXWIN = () => {
    
    setLoadingTxn(true)
    xWinLib.ClaimBonusXWINAsync(
      xWinSaleBonusProtocol,
      userData
      )
    .then(res =>
      { 
        console.log(res)
        setLoadingTxn(false)
        showSnackBar("success", res)
        loadWeb3()
      }
    )
    .catch(err => {
      setLoadingTxn(false)
      console.log(err)
      showSnackBar("error", err)
    }
    )
  };


  const handleChoice = (event) => {
    setContractChoice(event.target.value);
  };


  const goToBSCscan = event => {

    if(contractChoice === 1 && userData.releaseBlocksXWIN > 0) window.open("https://bscscan.com/block/countdown/"+ userData.releaseBlocksXWIN, "_blank")
    else if(contractChoice === 2 && userData.releaseBlocksLP > 0) window.open("https://bscscan.com/block/countdown/"+ userData.releaseBlocksLP, "_blank")
    else if(contractChoice === 3 && userData.releaseBlocksV1 > 0) window.open("https://bscscan.com/block/countdown/"+ userData.releaseBlocksV1, "_blank")
	}

  const getTotalBuyAmount = () => {

    if(contractChoice === 1) return userData.buyamountXWIN
    else if(contractChoice === 2) return userData.buyamountLP
    else if(contractChoice === 3) return userData.buyamountV1
  }

  const getTotalBonus = () => {

    if(contractChoice === 1) return userData.accBonusXWIN
    else if(contractChoice === 2) return userData.accBonusLP
    else if(contractChoice === 3) return userData.accBonusV1
  }

  const getReleaseBlocks = () => {

    if(contractChoice === 1) return userData.releaseBlocksXWIN
    else if(contractChoice === 2) return userData.releaseBlocksLP
    else if(contractChoice === 3) return userData.releaseBlocksV1
  }

  const shouldDisable = () => {

    
    if(contractChoice === 1) {
      
      if(userData.releaseBlocksXWIN === undefined || userData.releaseBlocksXWIN === "0") return true
      else return userData.releaseBlocksXWIN > currentBlock
    }
    else if(contractChoice === 2) {

      if(userData.releaseBlocksLP === undefined || userData.releaseBlocksLP === "0") return true
      else return userData.releaseBlocksLP > currentBlock
    }else if(contractChoice === 3) {
      if(userData.releaseBlocksV1 === 0) return true
      else return userData.releaseBlocksV1 > currentBlock
    }
  }


  const getTotalBoughtCaption = () => {

    if(contractChoice === 1) return "Total XWIN Bought"
    else if(contractChoice === 2) return "Total XWIN-BNB LP Bought"
    else if(contractChoice === 3) return "Total XWIN Bought"
  }

  const getChipDisplay = () => {

    if(contractChoice === 2){
      return(
        <Chip 
          size="large" 
          label={"XWIN-BNB LP"}
          color="primary" avatar={<Avatar src="/images/logos/bnb-xwin-lp.png" />} />
      )
    }else{

      return (
        <Chip 
        size="large" 
        label={"XWIN"}
        color="primary" avatar={<Avatar src="/images/icons/color/xwin.png" />} />
      )

    }
    

  }

  return (
    <Container>
      <Snackbar 
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        TransitionComponent={"TransitionUp"}
        open={openMsg} autoHideDuration={60000}>
          <Alert onClose={hideMessage} severity={"warning"}>
           Please coonect your wallet or make sure you choose BSC Mainnet
          </Alert>
      </Snackbar>
      <SnackbarMessage ref={refsnackbar} />
    <Grid container spacing={3}>
      {xWinUtils.getProgress(loadingTxn)}
      {xWinUtils.getProgress(loading)}
      <Grid item lg={8} sm={12} xs={12}>
        <Widget 
          className={classes.widgetLayout}
          title="Claim"
          disableWidgetMenu
          enableWalletDisplay
          selectedWallet={account}
        >
          <br/>
          <Box>
            <div className={classes.topPart}>
              <FormControl variant="outlined" className={classes.formControl}>
                {/* <InputLabel classes={{ root: classes.inputPosition }} id="demo-simple-select-outlined-label">Action</InputLabel> */}
                <Select
                  classes={{ root: classes.selectPadding }}
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={contractChoice}
                  onChange={handleChoice}
                  label="Date"
                >
                  <MenuItem value={1}>Claim Token & Bonus From Buy/Sell $XWIN v2</MenuItem>
                  <MenuItem value={2}>Claim Token & Bonus From XWIN-BNB LP v2</MenuItem>
                  <MenuItem value={3}>Claim Token & Bonus From v1 (Old)</MenuItem>
                </Select>
              </FormControl>
            </div>
          </Box>          
          <div className="mb-1 mt-3">
          <Typography
              color="text"
              //colorBrightness={"hint"}
              variant={"caption"}
              style={{ marginRight: 5 }}
              noWrap
            >
              {getTotalBoughtCaption()}
            </Typography>
          </div>
          <Box display="flex" alignItems="center" justifyContent={"left"}>
            <TextField
                  variant="outlined"
                  value={format({prefix: "", suffix: ''})(xWinLib.roundTo(getTotalBuyAmount(), 4))}
                  // value={userData.buyamount}
                  fullWidth
                  InputProps={{
                    classes: {
                      input: classes.positiveNum,
                    },
                    readOnly: true,
                    style: { textAlign: "right" },
                    startAdornment: 
                      <InputAdornment position="start">
                        {getChipDisplay()}
                      </InputAdornment>,
                  }}
                />
          </Box>
          <div className="mb-1 mt-2">
            <Typography
                color="text"
                //colorBrightness={"hint"}
                variant={"caption"}
                style={{ marginRight: 5 }}
                noWrap
              >
                {"Total XWIN Bonus Balance"}
            </Typography>
          </div>
          <Box display="flex" alignItems="center" justifyContent={"left"}>
            <TextField
                  variant="outlined"
                  value={format({prefix: "", suffix: ''})(xWinLib.roundTo(getTotalBonus(), 4))}
                  fullWidth
                  InputProps={{
                    classes: {
                      input: classes.positiveNum,
                    },
                    readOnly: true,
                    style: { textAlign: "right" },
                    startAdornment: 
                      <InputAdornment position="start">
                        
                        <Chip 
                        size="large" 
                        label={"XWIN"}
                        //label={xWinUtils.displayBalance(false, format({prefix: "", suffix: " "})(xWinLib.roundTo(userData.myXWINBalance,4)))}
                        color="primary" avatar={<Avatar src="/images/icons/color/xwin.png" />} />
                      </InputAdornment>,
                  }}
                />
          </Box>
          <div className="mb-1 mt-2">
          <Typography
              color="text"
              variant={"caption"}
              style={{ marginRight: 5 }}
              noWrap
            >
              {"Release After Blocks"}
            </Typography>
          </div>
          <Box display="flex" alignItems="center" justifyContent={"left"}>
            <TextField
                  variant="outlined"
                  value={format({prefix: "", suffix: ''})(getReleaseBlocks())}
                  onClick={goToBSCscan}
                  fullWidth
                  InputProps={{
                    classes: {
                      input: classes.positiveNum,
                    },
                    readOnly: true,
                    style: { textAlign: "right" },
                    startAdornment : 
                      <InputAdornment position="end">
                        <TimerIcon />
                      </InputAdornment>,
                    endAdornment : 
                    <InputAdornment position="end">
                      Blocks
                    </InputAdornment>,
                    }
                  }
                />
          </Box>
          <div className="py-3" />
          <Box display="flex" alignItems="center" justifyContent={"left"}>
            <Button 
                disabled={shouldDisable()}
                onClick={handleClickClaim} 
                color="primary"
                variant="contained"
                fullWidth
                startIcon={<AddCircleIcon />}>
                  Claim XWIN
              </Button>
          </Box>
          
        </Widget> 
      </Grid>
      
    </Grid>
    </Container>
  );
}


export default BonusClaim;
