import React, { useState, useEffect, useContext  } from "react";
import firebase from "firebase/app";
import xWinLib from '../../xWinLib';
import xWinUtils from '../../xWinUtils';
import xWinFundV4 from '../../abi/xWinFundV4.json'
import xWinProtocolV5 from '../../abi/xWinProtocolV5.json'
import appGlobalConfig from '../../appGlobalConfig';
import Iframe from 'react-iframe'
//import { Alert } from '@material-ui/lab';
import format from 'format-number';
import {AppContext}  from '../../context/AppProvider';
import ReactInterval from 'react-interval'
import Announcement from 'react-announcement'
import Logo from '../../images/logo/xwinlogo.jpg'

import {
  Grid,
  Button,
  Container,
  //Snackbar,
  Chip
} from "@material-ui/core";

import {
  Favorite as FavoriteIcon
} from "@material-ui/icons";

import ApexLineChart from "./components/ApexLineChart";
import MyFarmValue from "./components/MyFarmValue";
import MyRewardValue from "./components/MyRewardValue";
import MyVaultValue from "./components/MyVaultValue";
import TotalLockValue from "./components/TotalLockValue";
import Tokenomics from "./components/Tokenomics";
import MarketCapUI from "./components/MarketCap";

// styles
import useStyles from "./styles";

// components
import Widget from "../../components/Widget";
import { Typography, Avatar } from "../../components/Wrappers";
import Dot from "../../components/Sidebar/components/Dot";


const Dashboard = props => {
  const {portfolios } = props;

  var classes = useStyles();
  const [mainData, setMainData] = React.useState([]);
  const [filterFarmPortfolios, setFilterFarmPortfolios] = useState([])
  const [filterAllport, setFilterAllport] = useState([])
  const [overallStat, setOverallStat] = useState([])
  const [fundValues, setFundValues] = useState([])
  const [farmCollections, setFarmCollections] = useState([])
  const [vaultCollections, setVaultCollections] = useState([])
  // const [claimableReward, setClaimableReward] = useState([])
  const [myxWinProtocol, setMyxWinProtocol] = React.useState([]);
  const [xwinStats, setXwinStats] = useState([])
  const [totalValueLock, setTotalValueLock] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [privateVaultTVL, setPrivateVaultTVL] = React.useState(0);
  
  
  const { isConnected, ETHBalance, account, theWeb3, web3static, globalProvider, network } = useContext(AppContext);
  
  // if(globalProvider !== undefined){
  //   globalProvider.on("accountsChanged", (accounts) => {
  //     loadWeb3()
  //   });
  //   // globalProvider.on("chainChanged", (accounts) => {
  //   //   if(network !== "56") showSnackBar("info", "Please connect to BSC Mainnet")
  //   // });
    
  // }

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection('performance2')
      .doc(appGlobalConfig.mainnetXWIN.toLowerCase()).onSnapshot(doc => {
        setMainData(doc.data())
    })
    return () => { unsubscribe() }
  }, [])

  // const hideMessage = () => {
  //   setOpenMsg(false);
  // };

  useEffect(() => {
    const controller = new AbortController();
    fetchMarketData()
    fetchTVL()
    return () => {
      controller.abort();
    }
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    loadWeb3()
    return () => {
      controller.abort();
    }
  }, [portfolios, account, isConnected]);

  async function refreshData() {

    if(theWeb3 === undefined) return
    if(account === "---") return
    
    const promises = filterAllport.map(async port => {
      if(xWinUtils.isValidFundType(port)){
          const [marketcap, fundUnitPrice, contractAddr, symbol] = await getMyVaultValue(port, web3static, account)
          return [marketcap, fundUnitPrice, contractAddr, symbol]
      }
    });
    const marketcapColl = await Promise.all(promises);
    setFundValues(marketcapColl)
    const sum = marketcapColl.reduce((sum, mc) => {
        return parseFloat(sum) + parseFloat(mc);
    }, 0);
  
    const promisesFarm = filterFarmPortfolios.map(async port => {
      if(port.fundtype === "farming"){
        const marketcap = await getMyFarmValue(port, web3static, account)
        return marketcap
      }
    });

    const marketcapCollFarm = await Promise.all(promisesFarm);
    setFarmCollections(marketcapCollFarm)
    setVaultCollections(marketcapColl)
  }

  const loadWeb3 = async () => {
    
    try {
      setLoading(true)
    let filterEnv = await xWinLib.getNetwork(theWeb3)
    
    let filterport = []
    let filterportFarm = []
    

    let xWinProtocol
    if(filterEnv !== "bscmain"){
      //setOpenMsg(true)
      setOverallStat({
        "xwinPrice": "0",
        "xwinPriceInBNB": "0",
        "BNBPrice": "0",
        "WBNBXWINPrice": "0"
      })
      filterport = portfolios.filter(x=>x.env.toLowerCase() === filterEnv.toLowerCase() && x.fundtype !== "farming")
      filterportFarm = portfolios.filter(x=>x.env.toLowerCase() === filterEnv.toLowerCase() && x.fundtype === "farming")

    }else{
      // let privateTVL = await xWinUtils.getPrivateVaultTVL(theWeb3)
      // setPrivateVaultTVL(privateTVL)
      filterport = portfolios.filter(x=>x.env.toLowerCase() === filterEnv.toLowerCase() && x.fundtype !== "farming")
      filterportFarm = portfolios.filter(x=>x.env.toLowerCase() === filterEnv.toLowerCase() && x.fundtype === "farming")
      xWinProtocol = new theWeb3.eth.Contract(xWinProtocolV5, appGlobalConfig.mainnetxWinProtocol);
      setMyxWinProtocol(xWinProtocol)
      //get xwin price in USD
      let prices = await xWinUtils.getAllPrices(web3static)
      setOverallStat({
        "xwinPrice": prices.xwinPriceInUSD,
        "xwinPriceInBNB": xWinUtils.convertFromWei(web3static, prices.unipriceInBNB.toString()),
        "BNBPrice": xWinUtils.convertFromWei(web3static, prices.BNBUSDT.toString()),
        "WBNBXWINPrice": prices.pcsLPPrice,
        "JPYUSD" : prices.JPYUSD,
        "BabyLPPrice" : prices.babyLPPrice
      })  
    }
    
    
    setFilterFarmPortfolios(filterportFarm)
    setFilterAllport(filterport)
    
    if(isConnected && filterEnv === "bscmain"){
      
      if(account !== "---"){
        const promises = filterport.map(async port => {
          if(xWinUtils.isValidFundType(port)){
              const [marketcap, fundUnitPrice, contractAddr, symbol] = await getMyVaultValue(port, web3static, account)
              return [marketcap, fundUnitPrice, contractAddr, symbol]
          }
        });
        const marketcapColl = await Promise.all(promises);
        setFundValues(marketcapColl)
        const sum = marketcapColl.reduce((sum, mc) => {
            return parseFloat(sum) + parseFloat(mc);
        }, 0);
      
        const promisesFarm = filterportFarm.map(async port => {
          if(port.fundtype === "farming"){
            const marketcap = await getMyFarmValue(port, web3static, account)
            return marketcap
          }
        });

        let claimableBal = await xWinProtocol.methods.GetEstimateReward(account).call({from: account});
        const marketcapCollFarm = await Promise.all(promisesFarm);
        setFarmCollections(marketcapCollFarm)
        setVaultCollections(marketcapColl)
      }else{
        setVaultCollections([])
        setFarmCollections([])
        // setClaimableReward("0")
      }
      
      setLoading(false)
    }
    setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
    
  }

  //fetch market data from compound
  const fetchMarketData = async () => {
    
    let cancel = false;
    fetch(appGlobalConfig.apiGetWinStats)
          .then((response) => {
          if (response.ok) {
            return response.json().then(data => {
              if (cancel) {
                return;
              }
              setXwinStats(data)
            });
          }
          return Promise.reject(response);
        })
        .catch(/* similar stuff here */);
  };

  const fetchTVL = async () => {
    
    let cancel = false;
    fetch(appGlobalConfig.apiGetTVL)
        .then((response) => {
          if (response.ok) {
            return response.json().then(async data => {
              if (cancel) {
                return;
              }
              // let privateTVL = await xWinUtils.getPrivateVaultTVL()
              // let total = parseFloat(privateTVL) + parseFloat(data)
              setTotalValueLock(data)
            });
          }
          return Promise.reject(response);
        })
        .catch(/* similar stuff here */);
  };


  const getMyFarmValue = async (port, web3, wallet) => {
    
    let xWinProtocol = new web3.eth.Contract(xWinProtocolV5, port.protocoladdress);
    let userInfo = await xWinProtocol.methods.userInfo(port.pid, wallet).call()
    let pendingXwin = await xWinProtocol.methods.pendingXwin(port.pid, wallet).call()
    let poolInfo = await xWinProtocol.methods.poolInfo(port.pid).call()
    let userAmt = xWinUtils.convertFromWei(web3, userInfo.amount.toString())
    pendingXwin = xWinUtils.convertFromWei(web3, pendingXwin.toString())
    return [userAmt, pendingXwin, poolInfo.lpToken, port.pid, port.symbol]
  }

  const getMyVaultValue = async (port, web3, wallet) => {

    const myxWinFunds = new web3.eth.Contract(xWinFundV4, port.contractaddress);
    let userfundbal = await myxWinFunds.methods.balanceOf(wallet).call();
    userfundbal = xWinUtils.convertFromWei(web3, userfundbal.toString()) 
    let myFundData = await xWinUtils.getFundAllData(myxWinFunds)
    let unipriceInUSD = xWinUtils.convertFromWei(web3, myFundData.unipriceInUSD.toString())
    let myfundvalue = xWinLib.roundTo(parseFloat(unipriceInUSD) * parseFloat(userfundbal), 2)
    return [myfundvalue, unipriceInUSD, port.contractaddress, port.symbol]
  }

  const AddToMetamask = async event => {
    
    setLoading(true)
    xWinLib.AddToMetamask(appGlobalConfig.mainnetXWIN, "XWIN", 18)
      .then(res =>
        { 
          setLoading(false)
          // setMsgStatus("success")
          // setResponseMsg(res)
          // showMessage()
          // props.parentCallback(true);
        }
      )
      .catch(err => {
        setLoading(false)
        //console.log(err)
        // setResponseMsg(err)
        // setMsgStatus("error")
        // showMessage()
        // props.parentCallback(true);
        }
      )
    
  };

  const handleCallback = (refresh) =>{
    if(refresh) loadWeb3()
  }

  return (
    <Container>
    <ReactInterval timeout={20000} enabled={true}
          callback={() => refreshData()} />
      <Announcement
				title="Toppy Marketplace Launched!"
				subtitle="You can now create and sell your NFT in Toppy Market. You can buy Toppy Mystery Box and Stake for high APR in XWIN token"
				link="https://toppy.market"
				imageSource={Logo}
			/>
      <Widget 
          className={classes.widgetLayout}
          title="Dashboard"
          disableWidgetMenu
          enableWalletDisplay
          selectedWallet={account}
        >
    <Grid container spacing={3}>
      {xWinUtils.getProgress(loading)}
      
      <Grid item lg={3} sm={6} xs={12} className={classes.hoverHighlight}>
        <MyRewardValue 
          selectedWallet={account}
          overallStat={overallStat}
          fundValues={fundValues}
          farmCollections={farmCollections}
          vaultCollections={vaultCollections}
          filterPortfolios={filterFarmPortfolios}
          myxWinProtocol={myxWinProtocol}
          loading={loading}
          parentCallback={handleCallback}
          />
      </Grid>
      <Grid item lg={3} sm={6} xs={12} className={classes.hoverHighlight}>
        <MarketCapUI 
          xwinStats={xwinStats}
          overallStat={overallStat}
        />
      </Grid>
      <Grid item lg={3} sm={6} xs={12} className={classes.hoverHighlight}>
        <Tokenomics 
          xwinStats={xwinStats}
        />
      </Grid>
      <Grid item lg={3} sm={6} xs={12} className={classes.hoverHighlight}>
      < TotalLockValue 
          totalValueLock={totalValueLock}
        />
      </Grid>
      <Grid item lg={6} sm={12} xs={12} className={classes.hoverHighlight}>
        <MyVaultValue 
          farmCollections={farmCollections}
          vaultCollections={vaultCollections}
          overallStat={overallStat}
          loading={loading}
          />
      </Grid>
      <Grid item lg={6} sm={12} xs={12} className={classes.hoverHighlight}>
        <MyFarmValue 
          farmCollections={farmCollections}
          vaultCollections={vaultCollections}
          overallStat={overallStat}
          loading={loading}
          />
      </Grid>
      <Grid item lg={12} sm={12} xs={12} className={classes.hoverHighlight}>
        <Widget
          bodyClass={classes.mainChartBody}
          header={
            <div className={classes.mainChartHeader}>
              <Typography
                variant="h6"
                color="text"
                weight={"medium"}
                colorBrightness="secondary"
              >
                $XWIN Live Trading Chart
              </Typography>
              <div className={classes.mainChartHeaderLabels}>
                <div className={classes.mainChartHeaderLabel}>
                  <Dot color="warning" />
                  <Typography
                    color="text"
                    colorBrightness={"hint"}
                    variant={"caption"}
                    style={{ marginLeft: 5 }}
                    justifyContent={"center"}
                  >
                  Sponsored by DexGuru
                  </Typography>
                </div>
              </div>
            </div>
          }
        >
          <Iframe url="https://dex.guru/token/0xd88ca08d8eec1e9e09562213ae83a7853ebb5d28-bsc"
            width="100%"
            height="450px"
            id="myId"
            className="myClassname"
            display="initial"
            position="relative"/>
        </Widget>
      </Grid>
      <Grid item lg={12} sm={12} xs={12} className={classes.hoverHighlight}>
        <Widget
          bodyClass={classes.mainChartBody}
          header={
            <div className={classes.mainChartHeader}>
              <Typography
                variant="h6"
                color="text"
                weight={"medium"}
                colorBrightness="secondary"
              >
                xWIN Daily Price
              </Typography>
              <Typography
                variant="h6"
                color="text"
                weight={"small"}
                colorBrightness="secondary"
              >
                <Chip 
                  size="large" 
                  label={xWinUtils.displayBalance(false, format({prefix: "$", suffix: ""})(xWinLib.roundTo(overallStat.xwinPrice,2)))}
                  color="primary" avatar={<Avatar src="/images/icons/color/xwin.png" />} 
                  />
                </Typography>
              
              <div className={classes.mainChartHeaderLabels}>
                <div className={classes.mainChartHeaderLabel}>
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => AddToMetamask()}
                    color="secondary"
                  >
                    <FavoriteIcon />
                    Add $XWIN
                  </Button>
                </div>
              </div>
            </div>
          }
        >
          <ApexLineChart mainData={mainData} />
          
        </Widget>
      </Grid>
   
      
    </Grid>
    </Widget> 
    </Container>
  );
}


export default Dashboard;
