import React, { useState, forwardRef, useImperativeHandle, useRef } from "react";
import xWinLib from '../../xWinLib';
import xWinUtils from '../../xWinUtils';
import format from 'format-number';
import xWinFundV5 from '../../abi/xWinFundV5.json'
import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone';
import TextRotationNoneTwoToneIcon from '@material-ui/icons/TextRotationNoneTwoTone';
import { Typography, Button, Avatar } from "../../components/Wrappers/Wrappers";
import CancelIcon from '@material-ui/icons/Cancel';
import SnackbarMessage from '../snackbarmessage/SnackbarMessage'

import {
  CardHeader,
  IconButton,
  TableRow,
  Table,
  TableCell,
  Dialog,
  DialogContent,
  DialogActions,
  ButtonGroup,
  TextField,
  InputAdornment,
} from "@material-ui/core";

import useStyles from "./styles";

const subscribefarm = forwardRef((props, ref) => {
  const { className, fundData, tokensMaster, userData, myxWinProtocol, port, globalWeb3, networkName, uniswapV2FactoryProtocol, ...rest } = props;
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [openAdd, setOpenAdd] = React.useState(false);
  const [subsAmt, setsubsAmt] = useState(0);
  const MAX_ALLOWANCE = "115792089237316195423570985008687907853269984665640564039457584007913129639935";
  const [isApproval, setIsApproval] = useState(false);

  const refsnackbar = useRef(null);
  const showSnackBar = (status, msg) => {
    refsnackbar.current.handleOpen(status, msg);
  }

  const handleOpen = () => {
    setOpenAdd(true);
    props.parentCallback(false);
  }

  const getOpenAdd = () => {
    return openAdd
  }

  const handleCloseAdd = () => {
    setLoading(false)
    setOpenAdd(false)
  }

  useImperativeHandle(ref, () => {
    return {
      handleOpen: handleOpen,
      getOpenAdd: getOpenAdd,
      openAdd : openAdd
    };
  });

  const handleClickSubs = event => {
    event.preventDefault();
    
    if(subsAmt <= 0){
      showSnackBar("error", "Please input farming amount")
      return
    } 

    setLoading(true)
    const subsAmtInWei = globalWeb3.utils.toWei(subsAmt.toString())
    xWinLib.FarmingDepositAsync(
      myxWinProtocol, 
      port.pid,
      subsAmtInWei,
      userData.selectedWallet
      )
    .then(res =>
      { 
        console.log(res)
        setLoading(false)
        showSnackBar("success", res)
        setOpenAdd(false)
        props.parentCallback(true) 
      }
    )
    .catch(err => {
      setLoading(false)
      console.log(err)
      showSnackBar("error", err)
      props.parentCallback(false) 
      }
    )
  };

  const isTokenApproved = async (subsAmount) => {
    if(parseFloat(subsAmount) === NaN) return false
    let isapprove = await xWinUtils.getIsApproved(globalWeb3, subsAmount, userData.selectedWallet, port)
    return isapprove
  }

  const handleClickApprove = async event => {
    event.preventDefault();
    
    setLoading(true)
    
    const sourceToken = new globalWeb3.eth.Contract(xWinFundV5, port.contractaddress);
    
    xWinLib.approveContract(sourceToken, MAX_ALLOWANCE, myxWinProtocol._address, userData.selectedWallet)
    .then(res => 
      setLoading(false),
      setIsApproval(true)
    )
    .catch(err => {
      setLoading(false)
      setIsApproval(false)
      console.log(err)
    })
    
  };

  const handleClickClearAmt = () => event => {
    event.preventDefault();
    setsubsAmt(0)
  };

  const handleAmtChange = () => async event => {
    event.preventDefault()
    let subsAmount = parseFloat(event.target.value)
    if(subsAmount >= 0 && subsAmount !== undefined && subsAmount !== NaN){
      setsubsAmt(subsAmount)
    }else{
      setsubsAmt(0)
      subsAmount = 0
    }
    const isApp = await isTokenApproved(subsAmount)
    setIsApproval(isApp)
  }

  const handleClickAmt = (ratio) => async event => {
    event.preventDefault();

    const subAmount = xWinLib.roundDown(ratio * userData.userfundbal, 8)
    setsubsAmt(subAmount)
    const isApp = await isTokenApproved(subAmount)
    setIsApproval(isApp)

  };

  const getSubHeader = () => {

    return (
      <Typography className={classes.subTitle}>
        {"Available ("+ port.symbol +"): " + format({prefix: " ", suffix: ''})(xWinLib.roundTo(userData?.userfundbal,10))}
      </Typography>
    )
  }

  return (
    
    <Dialog 
          open={openAdd} 
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          fullScreen={false}
          >
            <SnackbarMessage ref={refsnackbar} />
            
            <CardHeader
              action={
                <IconButton 
                  className={classes.title}
                  onClick={handleCloseAdd}
                  aria-label="settings">
                  <CancelIcon />
                </IconButton>
              }
              avatar={
                <Avatar variant="square"> 
                  {<img alt={port.symbol} src={"../../../images/logos/"+port.sublogo} className={classes.avatar}/>}
                 </Avatar>
              }
              subheader={getSubHeader()}
            />
          <DialogContent>
              {xWinUtils.getProgress(loading)}
                  
                <Table size="small">  
                <TableRow className={classes.tableRow}>
                <TableCell align="right">
                        
                        <ButtonGroup size="small" color="primary" aria-label="">
                          <Button
                            className={classes.columnHide}
                            fullWidth
                            color="primary"
                            size="small"
                            variant="outlined"
                            onClick={handleClickAmt(0.25)}
                          >
                            {'25%'}
                          </Button>
                          <Button
                            fullWidth
                            color="primary"
                            size="small"
                            variant="outlined"
                            onClick={handleClickAmt(0.50)}
                          >
                            {'50%'}
                          </Button>
                          <Button
                            className={classes.columnHide}
                            fullWidth
                            color="primary"
                            size="small"
                            variant="outlined"
                            onClick={handleClickAmt(0.75)}
                          >
                            {'75%'}
                          </Button>
                          <Button
                            fullWidth
                            color="primary"
                            size="small"
                            variant="outlined"
                            onClick={handleClickAmt(1)}
                          >
                            {'100%'}
                          </Button>
                          <Button
                          fullWidth
                          color="primary"
                          size="small"
                          variant="outlined"
                          onClick={handleClickClearAmt()}
                        >
                          {'Clear'}
                        </Button>
                        </ButtonGroup>
                        </TableCell>
                   </TableRow> 
                   <TableRow className={classes.tableRow}>
                      <TableCell>
                        
                        <TextField
                            type="number" 
                            className={classes.text}
                            label={'Amount'}
                            margin="dense"
                            name="weight"
                            onChange={handleAmtChange()}
                            required
                            variant="outlined"
                            value={subsAmt}
                            fullWidth
                            InputProps={{
                              classes: {
                                input: classes.positiveNum,
                              },
                              style: { textAlign: "right" },
                              startAdornment: <InputAdornment position="start">{port.symbol}</InputAdornment>,
                            }}
                          />
                          
                          
                      </TableCell>
                   </TableRow> 
                   
                  </Table>
          
          </DialogContent>
          <DialogContent>
          </DialogContent>
          <DialogActions>
              
            <ButtonGroup size="small" color="primary" aria-label="large outlined primary button group">
              <Button 
                disabled={isApproval}
                onClick={handleClickApprove} 
                color="secondary"
                variant="contained"
                startIcon={<TextRotationNoneTwoToneIcon />}
                >
              {'Approve'}
              </Button>
              <Button 
                disabled={!isApproval}
                onClick={handleClickSubs} 
                color="secondary"
                variant="contained"
                startIcon={<AddCircleTwoToneIcon />}
                >
              {'Farm!'}
              </Button>
            </ButtonGroup>
            
          </DialogActions>
        </Dialog>
    
  );
});


export default subscribefarm;
