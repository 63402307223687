import React, { useEffect, useState, useContext } from "react";
import FixedRatelist from "./components/FixedRatelist";
import {AppContext}  from '../../context/AppProvider';
import xWinStaking from '../../abi/xWinStaking.json'
import xWinUtils from '../../xWinUtils';
import BEP20 from '../../abi/BEP20.json'
import xWinProtocolV5 from '../../abi/xWinProtocolV5.json'
import PropTypes from 'prop-types';
import {
  LocalAtm as LocalAtmIcon,
  MoneyOff as MoneyOffIcon
} from '@material-ui/icons';
import {
  Container,
  Grid,
  Tabs,
  Tab,
  Box,
  Typography
} from "@material-ui/core";

// components
import xWinLib from '../../xWinLib'
// import xWinUtils from '../../xWinUtils'
import Widget from "../../components/Widget/Widget";
import useStyles from "./styles";
import appGlobalConfig from "../../appGlobalConfig";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function FixedRate(props) {
  
  const {portfolios, fundtype } = props;
  const [networkName, setNetworkName] = useState("")
  // const [filterPortfolios, setFilterPortfolios] = useState([])
  const [phases, setPhases] = useState([])
  const [phasesRetired, setPhasesRetired] = useState([])
  const [winStake, setWinStake] = useState([])
  
  const { ETHBalance, isConnected, globalProvider, account, theWeb3, web3static, refreshAccount } = useContext(AppContext);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // if(globalProvider !== undefined){
  //   globalProvider.on("accountsChanged", (accounts) => {
  //     loadWeb3()
  //   });
  // }

  const classes = useStyles();
  
  useEffect(() => {
    const controller = new AbortController();
    loadWeb3()
    loadRetiredFunds()
    return () => {
      controller.abort();
    }
  }, [account]);

  const loadRetiredFunds = async () => {

    if(theWeb3 === undefined) return

    try {

      let allprices = await xWinUtils.getAllPrices(web3static)
      const promises = appGlobalConfig.fixedRateRetiredVaults.map(async port => {
        let myPhases = []
        let xWinProtocol = new web3static.eth.Contract(xWinProtocolV5, port.protocoladdress);
        const myxWinStakeDisplay = new web3static.eth.Contract(xWinStaking, port.contractaddress); 
        const myxWinStake= new theWeb3.eth.Contract(xWinStaking, port.contractaddress); 
        setWinStake(myxWinStake)
        let xwinPID = await myxWinStakeDisplay.methods.xwinpid().call()
        let xwinpoolInfo = await xWinProtocol.methods.poolInfo(xwinPID).call()   
        let numberOfPool = await myxWinStakeDisplay.methods.getNumberOfPools().call()
        let entryFee = await myxWinStakeDisplay.methods.entryFee().call()
        let oneDayBlock = await myxWinStakeDisplay.methods.oneDayBlock().call()
        let rate = await xWinUtils.getExchangeRate(web3static, port.sourceToken, "USDT")
        for(let i=0; i< numberOfPool; i++){
          let poolInfo = await myxWinStakeDisplay.methods.poolInfo(i).call()
          let tvlinlocal = xWinUtils.convertFromWei(web3static, poolInfo.totalLockedSupply.toString())
          let tempXWINARP = await getEstReturns(xwinpoolInfo, poolInfo.xwinMultiplier, rate, allprices)
          myPhases.push({
            "name" : poolInfo.name,
            "maxLimit" : xWinUtils.convertFromWei(web3static, poolInfo.maxLimit.toString()),
            "interestPerDay" : xWinUtils.convertFromWei(web3static, poolInfo.interestPerDay.toString()),
            "interestPerYear" : getAnnualInterest(poolInfo.interestPerDay),
            "totalAPR" : getAnnualInterest(poolInfo.interestPerDay) + parseFloat(tempXWINARP),
            "xwinAPR" : tempXWINARP,
            "totalLockedSupply": parseFloat(rate) * parseFloat(tvlinlocal),
            "totalInterestPaid": xWinUtils.convertFromWei(web3static, poolInfo.totalInterestPaid.toString()),
            "DurationBlocks" : poolInfo.lockDays * parseFloat(oneDayBlock),
            "oneDayBlock" : oneDayBlock,
            "lockDays" : poolInfo.lockDays,
            "entryFee" : entryFee / 100,
            "pid" : i,
            "port": port
          })
        }
        return myPhases

      });
      const tmpPhases = await Promise.all(promises);
      let tmp = []
      let count = 0
      tmpPhases.forEach(p => {
        tmpPhases[count].forEach(p => {
          tmp.push(p)
        });     
        count++   
      });
      setPhasesRetired(tmp)  
      
    } catch (error) {
      console.log(error)
    }
    
  }

  
  const loadWeb3 = async () => {

    if(theWeb3 === undefined) return

    try {

      let allprices = await xWinUtils.getAllPrices(web3static)
    
      const promises = appGlobalConfig.fixedRateVaults.map(async port => {
        let myPhases = []
        let xWinProtocol = new web3static.eth.Contract(xWinProtocolV5, port.protocoladdress);
        const myxWinStakeDisplay = new web3static.eth.Contract(xWinStaking, port.contractaddress); 
        const myxWinStake= new theWeb3.eth.Contract(xWinStaking, port.contractaddress); 
        setWinStake(myxWinStake)
        let xwinPID = await myxWinStakeDisplay.methods.xwinpid().call()
        let xwinpoolInfo = await xWinProtocol.methods.poolInfo(xwinPID).call()   
        let numberOfPool = await myxWinStakeDisplay.methods.getNumberOfPools().call()
        let entryFee = await myxWinStakeDisplay.methods.entryFee().call()
        let oneDayBlock = await myxWinStakeDisplay.methods.oneDayBlock().call()
        let rate = await xWinUtils.getExchangeRate(web3static, port.sourceToken, "USDT")
          
        for(let i=0; i< numberOfPool; i++){
          let poolInfo = await myxWinStakeDisplay.methods.poolInfo(i).call()
          let tvlinlocal = xWinUtils.convertFromWei(web3static, poolInfo.totalLockedSupply.toString())
          let tempXWINARP = await getEstReturns(xwinpoolInfo, poolInfo.xwinMultiplier, rate, allprices)
          myPhases.push({
            "name" : poolInfo.name,
            "maxLimit" : xWinUtils.convertFromWei(web3static, poolInfo.maxLimit.toString()),
            "interestPerDay" : xWinUtils.convertFromWei(web3static, poolInfo.interestPerDay.toString()),
            "interestPerYear" : getAnnualInterest(poolInfo.interestPerDay),
            "totalAPR" : getAnnualInterest(poolInfo.interestPerDay) + parseFloat(tempXWINARP),
            "xwinAPR" : tempXWINARP,
            "totalLockedSupply": parseFloat(rate) * parseFloat(tvlinlocal),
            "totalInterestPaid": xWinUtils.convertFromWei(web3static, poolInfo.totalInterestPaid.toString()),
            "DurationBlocks" : poolInfo.lockDays * parseFloat(oneDayBlock),
            "oneDayBlock" : oneDayBlock,
            "lockDays" : poolInfo.lockDays,
            "entryFee" : entryFee / 100,
            "pid" : i,
            "port": port
          })
        }
        return myPhases

      });
      const tmpPhases = await Promise.all(promises);
      let tmp = []
      let count = 0
      tmpPhases.forEach(p => {
        tmpPhases[count].forEach(p => {
          tmp.push(p)
        });     
        count++   
      });
      setPhases(tmp)  
      
    } catch (error) {
      console.log(error)
    }
    
  }

  const getEstReturns = async (xwinpoolInfo, xwinMultiplier, rate, prices) => {
    
    // let prices = await xWinUtils.getAllPrices(theWeb3)
    let oneTokenReward = getEstPerOneToken(xwinpoolInfo, xwinMultiplier)
    let proceeds = oneTokenReward * 365 * prices.xwinPriceInUSD
    return xWinLib.roundTo(proceeds / rate * 100, 2)
  }

  const getEstPerOneToken = (xwinpoolInfo, xwinMultiplier) => {
    let oneday = xWinLib.roundTo(xwinpoolInfo.rewardperblock *  28800 * xwinpoolInfo.multiplier/100 * xwinMultiplier / 10000, 0)
    return xWinUtils.convertFromWei(theWeb3, oneday.toString())
  };


  const getAnnualInterest = (interestPerDay) => {

    let intPerDay = parseFloat(interestPerDay) / 10000
    let annual = parseFloat(intPerDay) * 365
    return annual

  }

  const displayPortfolios = (phases, discontinued) => {
    
    if(phases === undefined) return ""

    return (
      phases.map(phase => (
        <Grid
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
        > 
           <FixedRatelist 
            portfolios={portfolios}
            globalWeb3={theWeb3}
            web3static={web3static}
            selectedWallet={account}
            networkName={networkName}
            fundtype={fundtype}
            refreshAccount={refreshAccount}
            ETHBalance={ETHBalance}
            phase={phase}
            winStake={winStake}
            discontinued={discontinued}
            loadWeb3={loadWeb3}
            /> 
           
          </Grid> 
        )) 
    )    
  }

  const getTitle = () => {

    if(fundtype === "liquidity2") return "(Old) Yield Vault PCS"
    else if(fundtype === "liquidityv2") return "Yield Vault PCS"     
  }

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item md={12}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab 
            icon={<LocalAtmIcon />}  
            iconPosition="start" 
            label="Fixed Rate Staking" {...a11yProps(0)} />
          <Tab 
            icon={<MoneyOffIcon />}  
            iconPosition="start"             
            label="Discontinued" {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <Widget
              className={classes.newsHeader}
              // title={"Fixed Rate Staking"}
              disableWidgetMenu
              inheritHeight
              enableWalletDisplay
              selectedWallet={account}
            >
              {displayPortfolios(phases, false)}
              
            </Widget>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Widget
              className={classes.newsHeader}
              // title={"Fixed Rate Staking"}
              disableWidgetMenu
              inheritHeight
              enableWalletDisplay
              selectedWallet={account}
            >
              {displayPortfolios(phasesRetired, true)}
              
            </Widget>
        </TabPanel>
        </Grid>
      </Grid>
    </Container>
  );
}
