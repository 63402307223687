import React, { useEffect, useRef  } from "react";
import xWinLib from '../../../xWinLib';
import GetAppTwoToneIcon from '@material-ui/icons/GetAppTwoTone';
import format from 'format-number';
import SnackbarMessage from '../../snackbarmessage/SnackbarMessage'
import CountUp from 'react-countup'

import {
  Card,
  Button,
  CardHeader,
  CardActions
} from "@material-ui/core";

// styles
import useStyles from "../styles";
import { Avatar } from "../../../components/Wrappers";
import xWinUtils from "../../../xWinUtils";

export default function MyRewardValue(props) {
  var classes = useStyles();
  const { loading, overallStat, farmCollections, filterPortfolios, myxWinProtocol, selectedWallet, ...rest } = props;
  const [farmStats, setFarmStats] = React.useState([]);
  const [loadingTxn, setLoadingTxn] = React.useState(false);
  
  const refsnackbar = useRef(null);

  useEffect(() => {
    const controller = new AbortController();
    calcStats()
    return () => {
      controller.abort();
    }
  }, [farmCollections, overallStat]);

  const calcStats = () => {
     
    if(farmCollections === undefined) return

    let totalFarmReward = 0
    let count = 0
    farmCollections.forEach(farm => {

      if(farm[0] > 0){
        totalFarmReward = totalFarmReward + parseFloat(farm[1])
        count++
      }
    });

    setFarmStats({
      "totalFarmReward": totalFarmReward,
      "count" : count
    })
  }

  const handleClickHarvest = async event => {
    event.preventDefault();
    
    setLoadingTxn(true)
    const promises = farmCollections.map(async farm => {

      if(farm[0] > 0){

        const [res] = await xWinLib.FarmingDepositAsync(
          myxWinProtocol, 
          farm[3],
          0,
          selectedWallet)
        return [res]
      }
    });

    // Using .catch:
    await Promise.all(promises)
    .then(values => {
      setLoadingTxn(false)
      //showSnackBar("success", values)
      props.parentCallback(true);
    })
    .catch(err => {
      setLoadingTxn(false)
      //showSnackBar("error", err)
      props.parentCallback(false);
      console.log(err)
    });

  };


  return (
    <Card>
      {xWinUtils.getProgress(loadingTxn)}
      <SnackbarMessage ref={refsnackbar} />
      <CardHeader
        subheader= {"Farming Earn"}
        title={
          <CountUp 
                          separator=","
                          decimals={5}
                          decimal="."
                          prefix=" "   
                          duration={1.75}  
                          start={farmStats.totalFarmReward/2}                
                          end={farmStats.totalFarmReward} />

          // xWinUtils.displayBalance(loading, format({prefix: "", suffix: ''})(xWinLib.roundTo(farmStats.totalFarmReward,5)))
        }
        avatar={
          <Avatar 
            aria-label="" 
            className={classes.avatar}>
            {xWinUtils.getIcons("xwin")}  
           </Avatar>
        }
        titleTypographyProps={{variant:'body1' }}
        subheaderTypographyProps={{variant:'body2' }}
      />
      <CardActions>
        <Button
          color="primary"
          variant="outlined"
          fullWidth
          onClick={handleClickHarvest}
        >
            <GetAppTwoToneIcon />
            Harvest all ({farmStats.count})
        </Button>
      </CardActions>
    </Card>
    
  );
}
