import React, { useState, useEffect, useRef  } from "react";
import xWinLib from '../../../xWinLib';
import appGlobalConfig from '../../../appGlobalConfig';
import format from 'format-number';
import clsx from 'clsx';
// styles
import useStyles from "../styles";
import { Typography, Button } from "../../../components/Wrappers";
import Dot from "../../../components/Sidebar/components/Dot";
import xWinProtocolV5 from '../../../abi/xWinProtocolV5.json'
import BEP20 from '../../../abi/BEP20.json'
import xWinUtils from '../../../xWinUtils';
import SubscribeFarm from '../../subscribefarm/subscribefarm'
import RedeemFarm from '../../redeemfarm/redeemfarm'
import SnackbarMessage from '../../snackbarmessage/SnackbarMessage'
import ReactInterval from 'react-interval'
import CountUp from 'react-countup'
import confetti from 'canvas-confetti'

import {
  Grid,
  Box,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  CardActions,
} from "@material-ui/core";
import {
  ExpandMore as ExpandMoreIcon
} from "@material-ui/icons";

export default function Farmlist(props) {
  var classes = useStyles();
  const { prices, portfolios, port, globalWeb3, web3static, ETHBalance, tokensMaster, selectedWallet, gasPrices, networkName, refreshAccount } = props;
  const [fundData, setFundData] = useState({
    uniprice: 0,
    fundvalue: 0
  });

  //console.log(prices)
  
  const [loadingTxn, setLoadingTxn] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const refsubsfarm = useRef(null);
  const refredeemfarm = useRef(null);
  const [myxWinProtocol, setMyxWinProtocol] = React.useState([]);  
  const [userData, setUserData] = useState({
    walletETHBalance: 0,
    selectedWallet: ""
  });

  const refsnackbar = useRef(null);
  const showSnackBar = (status, msg) => {
    refsnackbar.current.handleOpen(status, msg);
  }

  async function refreshAccountLocal() {
    // setLoading(true)

    let pendingXwin = 0
    let userInfo 
    let walletData = await xWinUtils.getUserWalletInfoNew(web3static, selectedWallet)
    // let xWinProtocol = new web3static.eth.Contract(xWinProtocolV5, port.protocoladdress);
    let xWinProtocolDisplay = new web3static.eth.Contract(xWinProtocolV5, port.protocoladdress);
    let fundHolderData = await xWinUtils.getUserFundHolding(web3static, walletData.selectedWallet, port.contractaddress)
    if(walletData?.selectedWallet !== undefined){
      userInfo = await xWinProtocolDisplay.methods.userInfo(port.pid, walletData.selectedWallet).call()
      pendingXwin = await xWinProtocolDisplay.methods.pendingXwin(port.pid, walletData.selectedWallet).call()
      setUserData({
        "walletETHBalance" :  walletData.walletETHBalance, 
        "selectedWallet" : walletData.selectedWallet,
        "userfundbal" : fundHolderData.userfundbal,
        "userstakebal" : xWinUtils.convertFromWei(web3static, userInfo?.amount.toString()),
        "pendingXwin" : xWinUtils.convertFromWei(web3static, pendingXwin.toString()),
      })  
    }
    // setLoading(false)
  }

  async function refreshEarning() {

    try {
      if(globalWeb3 === undefined) return
      let pendingXwin = 0
      if(selectedWallet !== "---"){
        // let xWinProtocol = new globalWeb3.eth.Contract(xWinProtocolV5, port.protocoladdress);
        let xWinProtocolDisplay = new web3static.eth.Contract(xWinProtocolV5, port.protocoladdress);
        pendingXwin = await xWinProtocolDisplay.methods.pendingXwin(port.pid, selectedWallet).call()
        setUserData({
          ...userData,
          "pendingXwin" : xWinUtils.convertFromWei(web3static, pendingXwin?.toString()),
        })  
      }else{
        setUserData({
          ...userData,
          "pendingXwin" : "0",
        })  
      }  
    } catch (error) {
      console.log(error)
    }finally{
      setLoading(false)    
    }
    
  }


  useEffect(() => {
    const controller = new AbortController();
    loadFundData()
    return () => {
      controller.abort();
    }
  }, [portfolios, selectedWallet, prices]);

  const loadFundData = async () => {

    try {
      if(!globalWeb3) return
      // setLoading(true)

      let xWinProtocol = new globalWeb3.eth.Contract(xWinProtocolV5, port.protocoladdress);
      let xWinProtocolDisplay = new web3static.eth.Contract(xWinProtocolV5, port.protocoladdress);
      setMyxWinProtocol(xWinProtocol)
  
      let pendingXwin = 0
      let userInfo 
      let fundHolderData = await xWinUtils.getUserFundHolding(web3static, selectedWallet, port.contractaddress)
      if(selectedWallet !== "---"){
        userInfo = await xWinProtocolDisplay.methods.userInfo(port.pid, selectedWallet).call()
        pendingXwin = await xWinProtocolDisplay.methods.pendingXwin(port.pid, selectedWallet).call()
        setUserData({
          "walletETHBalance" :  ETHBalance, 
          "selectedWallet" : selectedWallet,
          "userfundbal" : fundHolderData?.userfundbal,
          "userstakebal" : xWinUtils.convertFromWei(web3static, userInfo?.amount.toString()),
          "pendingXwin" : xWinUtils.convertFromWei(web3static, pendingXwin?.toString()),
        })  
      }else{
        setUserData({
          "walletETHBalance" : "0", 
          "selectedWallet" : "---",
          "userfundbal" : "0",
          "userstakebal" : "0",
          "pendingXwin" : "0",
        })  
      }
  
      //get xwin price in USD
      //let prices = await xWinUtils.getAllPrices(globalWeb3)
      let poolInfo = await xWinProtocolDisplay.methods.poolInfo(port.pid).call()   
      const myxWinFunds = fundHolderData.xWinFund //new globalWeb3.eth.Contract(xWinFundV5, port.contractaddress);
      let fundName = await myxWinFunds.methods.name().call();
      let symbol = await myxWinFunds.methods.symbol().call();
  
      let unipriceInUSD = prices.xwinPriceInUSD
      let BEP20Protocol = new web3static.eth.Contract(BEP20, port.contractaddress);
      let TokenBalance = await BEP20Protocol.methods.balanceOf(port.protocoladdress).call()
      TokenBalance = xWinUtils.convertFromWei(web3static, TokenBalance?.toString()) 
      let rewardRemaining = await xWinProtocol.methods.rewardRemaining().call()
      rewardRemaining = xWinUtils.convertFromWei(web3static, rewardRemaining?.toString()) 
      
      let TVLInUSD = 0
      
      if(port.contractaddress.toLowerCase() === appGlobalConfig.mainnetJPYC) { 
        TVLInUSD = TokenBalance * prices.JPYUSD
        unipriceInUSD = prices.JPYUSD
      }
      
      if(port.isxwinfund){
        let myFundData = await myxWinFunds.methods.GetFundDataAll().call();
        unipriceInUSD = myFundData ? xWinUtils.convertFromWei(web3static, myFundData[10]?.toString()) : 0
        TVLInUSD = TokenBalance * unipriceInUSD
      }
      
      if(port.contractaddress.toLowerCase() === appGlobalConfig.mainnetXWIN.toLowerCase()) { 
        TokenBalance = TokenBalance - rewardRemaining
        TVLInUSD = TokenBalance * prices.xwinPriceInUSD
        unipriceInUSD = prices.xwinPriceInUSD
      }
  
      if(port.contractaddress.toLowerCase() === appGlobalConfig.mainnetXWINBNBLPPool.toLowerCase() 
          || port.contractaddress.toLowerCase() === appGlobalConfig.mainnetXWINBNBLPPoolV2.toLowerCase()
          || port.contractaddress.toLowerCase() === appGlobalConfig.mainnetXWINBNBLPPoolApeSwap.toLowerCase()
          ) { 
        unipriceInUSD = prices.pcsLPPrice //await xWinUtils.getLPCakePrice(globalWeb3, prices.xwinPriceInUSD)
        TVLInUSD = parseFloat(TokenBalance) * parseFloat(unipriceInUSD)
      }
  
      if(port.contractaddress.toLowerCase() === appGlobalConfig.mainnetXWINUSDTLPPool.toLowerCase() || port.contractaddress.toLowerCase() === appGlobalConfig.mainnetXWINUSDTAPELPPool.toLowerCase()) { 
        unipriceInUSD = prices.babyLPPrice //await xWinUtils.getLPCakePrice(globalWeb3, prices.xwinPriceInUSD)
        TVLInUSD = parseFloat(TokenBalance) * parseFloat(unipriceInUSD)
      }
  
      setFundData({
        ...fundData,
        "fundName": fundName,
        "symbol": symbol,
        "unipriceInUSD": unipriceInUSD, //uniprice / PRECISION,
        "networkName": networkName,
        "multiplier": poolInfo?.multiplier,
        "rewardperblock" : poolInfo?.rewardperblock,
        "xwinPriceInUSD" : prices.xwinPriceInUSD,
        "tokenBalance" : TokenBalance,
        "TVLInUSD" : TVLInUSD
      })
  
      // setLoading(false)      
    } catch (error) {
      console.log(error)
      setLoading(false)    
    }finally{
      setLoading(false)    
    }
  }

  const getEstPerOneToken = () => {
    
    if(globalWeb3 === undefined) return 0
    if(isNaN(fundData.rewardperblock)) return 0
    let oneday = fundData.rewardperblock *  28800 * fundData.multiplier/100
    return xWinUtils.convertFromWei(globalWeb3, oneday.toString())
  };

  const getEstReturns = () => {
    let oneTokenReward = getEstPerOneToken()
    let proceeds = oneTokenReward * 365 * prices.xwinPriceInUSD
    return xWinLib.roundTo(proceeds / fundData.unipriceInUSD * 100, 2)
  };

  const handleClickHarvest = event => {
    event.preventDefault();
    
    setLoadingTxn(true)
    xWinLib.FarmingDepositAsync(
      myxWinProtocol, 
      port.pid,
      0,
      selectedWallet
      )
    .then(res =>
      { 
        console.log(res)
        setLoadingTxn(false)
        showSnackBar("success", res)
        refreshAccount()
        refreshAccountLocal()
        confetti()
      }
    )
    .catch(err => {
      setLoadingTxn(false)
      console.log(err)
      showSnackBar("error", err)
      }
    )
  };

  const handleClickSubsFarm = event => {
    refsubsfarm.current.handleOpen();
  }

  const handleClickRedeemFarm = event => {
    refredeemfarm.current.handleOpen();
  }

  const handleCallback = (refresh) =>{
    if(refresh){
      confetti()
      loadFundData()
    } 
  }

  
  return (
    <div>
          <ReactInterval timeout={30000} enabled={true}
          callback={() => refreshEarning()} />

           {xWinUtils.getProgress(loadingTxn)}
          <SnackbarMessage ref={refsnackbar} />
          <RedeemFarm 
            ref={refredeemfarm} 
            port={port}
            fundData={fundData}
            tokensMaster={tokensMaster}
            userData={userData}
            myxWinProtocol={myxWinProtocol}
            gasPrices={gasPrices}
            globalWeb3={globalWeb3}
            networkName={networkName}
            parentCallback={handleCallback}
            />
          <SubscribeFarm 
            ref={refsubsfarm} 
            port={port}
            fundData={fundData}
            tokensMaster={tokensMaster}
            userData={userData}
            myxWinProtocol={myxWinProtocol}
            gasPrices={gasPrices}
            globalWeb3={globalWeb3}
            networkName={networkName}
            parentCallback={handleCallback}
            />
          <ExpansionPanel className={classes.hoverHighlight}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                classes={{ expanded: classes.expanded }}
              >
                <div className={clsx(classes.column1)}>
                    <img alt={port.symbol} src={"../../../images/logos/"+port.sublogo} className={classes.avatar}/>
                </div>
                <div className={clsx(classes.column2, classes.columnHide, classes.helper)}>
                  <Typography
                      color="text"
                      colorBrightness={"hint"}
                      variant={"caption"}
                      style={{ marginRight: 5 }}
                      noWrap
                    >
                      {port.name}
                  </Typography>
                  <Typography>{port.symbol}</Typography>
                </div>
                <div className={clsx(classes.column4, classes.columnHide, classes.helper)}>
                    <Typography
                      color="text"
                      colorBrightness={"hint"}
                      variant={"caption"}
                      style={{ marginRight: 5 }}
                      noWrap
                    >{"Earned"}</Typography>
                    <Box display="flex" alignItems="center" justifyContent={"left"}>
                      <Typography
                        size="md"
                        weight={"medium"}
                        style={{ marginRight: 8 }}
                        className={classes.subTitle}
                      >
                        <CountUp 
                          separator=","
                          decimals={4}
                          decimal="."
                          prefix=" "   
                          duration={1.25}  
                          start={userData.pendingXwin/2}                
                          end={userData.pendingXwin} />
                      </Typography>
                      {!loading && userData.pendingXwin > 0 ? <Dot size='medium' color="success" /> : ""} 
                    </Box>
                </div>
                <div className={clsx(classes.column4, classes.helper)}>
                  <Grid item>
                    <Typography
                      color="text"
                      colorBrightness={"hint"}
                      variant={"caption"}
                      style={{ marginRight: 5 }}
                      noWrap
                    >
                      APR
                    </Typography>
                    <Box display="flex" alignItems="center" justifyContent={"left"}>
                      <Typography
                        size="md"
                        weight={"medium"}
                        style={{ marginRight: 8 }}
                      >
                        {format({prefix: "", suffix: '%'})(getEstReturns())}
                      </Typography>
                      <Dot size='medium' color="info" />
                    </Box>
                  </Grid>
                </div>
                <div className={clsx(classes.column5, classes.helper)}>
                  <Grid item>
                    <Typography
                      color="text"
                      colorBrightness={"hint"}
                      variant={"caption"}
                      style={{ marginRight: 5 }}
                      noWrap
                    >
                      Balance
                    </Typography>
                    <Box display="flex" alignItems="center" justifyContent={"left"}>
                      <Typography
                        size="md"
                        weight={"medium"}
                        style={{ marginRight: 8 }}
                      >
                      {xWinUtils.displayBalance(loading, format({prefix: "", suffix: " "})(xWinLib.roundTo(userData.userfundbal,4)))}

                      </Typography>
                      {!loading && userData.userfundbal > 0.000000001 ? <Dot size='medium' color="secondary" /> : ""} 
                    </Box>
                  </Grid>
                  <Grid item className={classes.columnShow}>
                    <Typography
                        color="text"
                        colorBrightness={"hint"}
                        variant={"caption"}
                        style={{ marginRight: 5 }}
                        noWrap
                      >{"Earned"}</Typography>
                      <Box display="flex" alignItems="center" justifyContent={"left"}>
                        <Typography
                          size="md"
                          weight={"medium"}
                          style={{ marginRight: 8 }}
                          className={classes.subTitle}
                        >
                          <CountUp 
                            separator=","
                            decimals={4}
                            decimal="."
                            prefix=" "   
                            duration={1.75}  
                            start={userData.pendingXwin/2}                
                            end={userData.pendingXwin} />
                          {/* {xWinUtils.displayBalance(loading, format({prefix: "", suffix: " "})(xWinLib.roundTo(userData.pendingXwin,5)))} */}
                        </Typography>
                        {!loading && userData.pendingXwin > 0 ? <Dot size='medium' color="success" /> : ""} 
                      </Box>
                    </Grid>
                </div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails classes={classes.expansion}>
                <div className={clsx(classes.column3, classes.columnHide)}/>
                <div className={clsx(classes.column6)}>
                    <Typography
                      color="text"
                      colorBrightness={"hint"}
                      variant={"caption"}
                      style={{ marginRight: 5 }}
                      noWrap
                    >
                      Earned
                    </Typography>
                    <Box display="flex" alignItems="center" justifyContent={"left"}>
                      <Dot size='medium' color="warning" />
                      <Typography
                        size="md"
                        weight={"medium"}
                        style={{ marginLeft: 8 }}
                      >
                        {xWinUtils.displayBalance(loading, format({prefix: "", suffix: " "})(xWinLib.roundTo(userData.pendingXwin,5)))}
                      </Typography>
                    </Box>
                 
                    <CardActions>
                      <Button 
                        disabled={selectedWallet === ""}
                        fullWidth
                        variant="outlined" 
                        size="small" 
                        color="primary"
                        onClick={handleClickHarvest}
                      >
                        Harvest
                      </Button>
                    </CardActions>
                </div>
                <div className={clsx(classes.column6)}>
                  <Typography
                      color="text"
                      colorBrightness={"hint"}
                      variant={"caption"}
                      style={{ marginRight: 5 }}
                      noWrap
                    >
                      Total Staked:
                    </Typography>
                    <Box display="flex" alignItems="center" justifyContent={"left"}>
                      <Dot size='medium' color="success" />
                      <Typography
                        size="md"
                        weight={"medium"}
                        style={{ marginLeft: 8 }}
                      >
                        {xWinUtils.displayBalance(loading, format({prefix: "", suffix: " "})(xWinLib.roundTo(userData.userstakebal,5)))}
                      </Typography>
                      
                    </Box>
                  
                    <CardActions>
                      <Button 
                        disabled={selectedWallet === ""}
                        fullWidth
                        onClick={handleClickSubsFarm}
                        variant="outlined" size="small" color="primary">
                        +
                      </Button>
                      <Button 
                        disabled={selectedWallet === ""}
                        fullWidth
                        onClick={handleClickRedeemFarm}
                        variant="outlined" size="small" color="primary">
                        -
                      </Button>
                    </CardActions>
                 
                </div>
                <div className={clsx(classes.column3, classes.columnHide)}>
                  <Typography
                      color="text"
                      colorBrightness={"hint"}
                      variant={"caption"}
                      style={{ marginRight: 5 }}
                      noWrap
                    >
                      TVL
                    </Typography>
                    <Box display="flex" alignItems="center" justifyContent={"center"}>
                      <Typography
                        size="md"
                        weight={"medium"}
                        style={{ marginRight: 8 }}
                      >
                       {format({prefix: "$", suffix: " "})(xWinLib.roundTo(fundData.TVLInUSD,2))}
                      </Typography>
                      <Dot size='medium' color="success" />
                    </Box>

                    
                    <Typography
                      color="text"
                      colorBrightness={"hint"}
                      variant={"caption"}
                      style={{ marginRight: 5 }}
                      noWrap
                    >
                      Token Contract
                    </Typography>
                    <Box display="flex" alignItems="center" justifyContent={"center"}>
                      <Typography variant={"p"}>
                          <a className={classes.link} href={xWinLib.getEtherLink(fundData.networkName, port.contractaddress, "address")} target="blank">View in BSCScan</a>
                      </Typography>
                    </Box>
                  </div>  
                <div className={clsx(classes.column3, classes.columnHide)}/>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            
    </div>
  );
}
