import React, { useEffect, useState, useContext } from "react";
import Yieldlist from "./components/Yieldlist";
import {AppContext}  from '../../context/AppProvider';

import {
  Container,
  Grid,
} from "@material-ui/core";

// components
import xWinLib from '../../xWinLib'
// import xWinUtils from '../../xWinUtils'
import Widget from "../../components/Widget/Widget";
import useStyles from "./styles";

export default function Yields(props) {
  
  const {portfolios, fundtype } = props;
  const [networkName, setNetworkName] = useState("")
  const [filterPortfolios, setFilterPortfolios] = useState([])
  const { ETHBalance, isConnected, account, theWeb3, web3static, refreshAccount } = useContext(AppContext);
  
  const classes = useStyles();
  
  useEffect(() => {
    const controller = new AbortController();
    loadWeb3()
    return () => {
      controller.abort();
    }
  }, [portfolios, account]);

  
  const loadWeb3 = async () => {

    let filterEnv = await xWinLib.getNetwork(theWeb3)
    
    setNetworkName(filterEnv)

    let filterport = []
    if(filterEnv !== "bscmain"){
      filterport = portfolios.filter(x=>x.env.toLowerCase() === "bscmain" && x.fundtype === fundtype)
    }else{
      filterport = portfolios.filter(x=>x.env.toLowerCase() === filterEnv.toLowerCase() && x.fundtype === fundtype)
    }
    setFilterPortfolios(filterport)
  }

  
  const displayPortfolios = () => {
    
    if(filterPortfolios === undefined) return ""

    let funds = filterPortfolios
    
    return (
      funds.map(port => (
        <Grid
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
        > 
           <Yieldlist 
            port={port}
            portfolios={portfolios}
            globalWeb3={theWeb3}
            web3static={web3static}
            selectedWallet={account}
            networkName={networkName}
            fundtype={fundtype}
            refreshAccount={refreshAccount}
            /> 
           
          </Grid> 
        )) 
    )    
  }

  const getTitle = () => {

    if(fundtype === "liquidity2") return "(Old) Yield Vault PCS"
    else if(fundtype === "liquidityv2") return "Yield Vault PCS"     
  }

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item md={12}>
          <Widget
            className={classes.newsHeader}
            title={getTitle()}
            disableWidgetMenu
            inheritHeight
            enableWalletDisplay
            selectedWallet={account}
          >
             {displayPortfolios()}
            
          </Widget>
        </Grid>
      </Grid>
    </Container>
  );
}
