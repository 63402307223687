import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
    root: {
        maxWidth: 400,
        flexGrow: 1
      },
      header: {
        display: "flex",
        alignItems: "center",
        height: 50,
        paddingLeft: theme.spacing(4),
        backgroundColor: theme.palette.background.default
      },
      img: {
        height: 255,
        display: "block",
        maxWidth: "100%",
        overflow: "hidden",
        width: "100%"
      },
    avatar: {
        height: 85,
        width: 85
      },
    card: {
        padding: theme.spacing(2,2),
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(1,1)
        },
        minHeight: 300,
      },
    primaryHeading: {
        color: theme.palette.primary.main,
        fontWeight: 100,
    }
}));
