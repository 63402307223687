import React, { useState, useEffect, useRef, useContext  } from "react";
import xWinLib from '../../xWinLib';
import xWinUtils from '../../xWinUtils';
import xWinTeamRewards from '../../abi/xWinTeamRewards.json'
import BEP20 from '../../abi/BEP20.json'
import Autocomplete from '@material-ui/lab/Autocomplete';

import appGlobalConfig from '../../appGlobalConfig';
import { Alert } from '@material-ui/lab';
import format from 'format-number';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SnackbarMessage from '../snackbarmessage/SnackbarMessage'
import firebase from "firebase/app";
import "firebase/firestore";
import {AppContext}  from '../../context/AppProvider';

import {
  Grid,
  Button,
  Container,
  Snackbar,
  Box,
  TextField,
  InputAdornment,
  Chip,
  FormControl,
} from "@material-ui/core";

// styles
import useStyles from "./styles";

// components
import Widget from "../../components/Widget/Widget";
import { Typography, Avatar } from "../../components/Wrappers/Wrappers";

const TeamRewards = props => {
  var classes = useStyles();
  const [loadingTxn, setLoadingTxn] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [poolID, setPoolID] = React.useState(0);
  const { ETHBalance, isConnected, account, theWeb3, refreshAccount } = useContext(AppContext);
  
  const [userData, setUserData] = useState({
    walletETHBalance: 0,
    selectedWallet: ""
  });
  const [teamRewardProtocol, setTeamRewardProtocol] = useState("")
  const [openMsg, setOpenMsg] = React.useState(false);
  const [teamRewards, setTeamRewards] = React.useState([]);
  
  const refsnackbar = useRef(null);
  
  useEffect(() => {
    const unsubscribe = firebase
    .firestore()
    .collection('xwinteamrewards')
    .onSnapshot((snapshot) => {
      const newValues = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }))
      setTeamRewards(newValues)
    })
    return () => { unsubscribe() }

  }, [])

  const hideMessage = () => {
    setOpenMsg(false);
  };

  useEffect(() => {
    const controller = new AbortController();
    loadWeb3()
    return () => {
      controller.abort();
    }
  }, [poolID, account]);

  const showSnackBar = (status, msg) => {
    refsnackbar.current.handleOpen(status, msg);
  }

  const loadWeb3 = async () => {
    
    if(theWeb3 === undefined) return
    setLoading(true)
    
    let myRewardsProtocol = new theWeb3.eth.Contract(xWinTeamRewards, appGlobalConfig.mainnetxWinTeamRewards);
    setTeamRewardProtocol(myRewardsProtocol)
    
    let xWINBEP20 = new theWeb3.eth.Contract(BEP20, appGlobalConfig.mainnetXWIN);
    let myuserInfo
    let poolInfo
    let claimedBalance = 0
    let myRewards = 0
    let myRatio = 0
    let totalRewards = 0
    if(account !== "---"){
    
      poolInfo = await myRewardsProtocol.methods.poolInfo(poolID).call();
      totalRewards = xWinUtils.convertFromWei(theWeb3, poolInfo.totalRewards.toString())
      myuserInfo = await myRewardsProtocol.methods.getMyUserInfo(poolID).call({from: account});
      claimedBalance = xWinUtils.convertFromWei(theWeb3, myuserInfo.claimedBalance.toString())
      myRewards = xWinUtils.convertFromWei(theWeb3, myuserInfo.myRewards.toString())
      //myRewards = parseFloat(totalRewards) * parseFloat(myRatio) / 10000  
    }

    setUserData({
      "walletETHBalance" :  ETHBalance, 
      "selectedWallet" : account,
      "claimedBalance" : claimedBalance,
      "myRewards" : myRewards
    })  
    
    setLoading(false)
  }

  const handleClickClaim = event => {
    event.preventDefault();
    
    setLoadingTxn(true)
    xWinLib.ClaimTeamRewardsAsync(
      teamRewardProtocol,
      userData,
      poolID
      )
    .then(res =>
      { 
        console.log(res)
        setLoadingTxn(false)
        showSnackBar("success", res)
        loadWeb3()
      }
    )
    .catch(err => {
      setLoadingTxn(false)
      console.log(err)
      showSnackBar("error", err)
    }
    )
    
  };

  const getChipDisplay = () => {

    return (
      <Chip 
      size="large" 
      label={"XWIN"}
      color="primary" avatar={<Avatar src="/images/icons/color/xwin.png" />} />
    )
  }

  return (
    <Container>
      <Snackbar 
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        TransitionComponent={"TransitionUp"}
        open={openMsg} autoHideDuration={60000}>
          <Alert onClose={hideMessage} severity={"warning"}>
           Please connect your wallet or make sure you choose BSC Mainnet
          </Alert>
      </Snackbar>
      <SnackbarMessage ref={refsnackbar} />
    <Grid container spacing={3}>
      {xWinUtils.getProgress(loadingTxn)}
      {xWinUtils.getProgress(loading)}
      <Grid item lg={8} sm={12} xs={12}>
        <Widget 
          className={classes.widgetLayout}
          title="Team Member Rewards"
          disableWidgetMenu
          enableWalletDisplay
          selectedWallet={account}
        >
          <br/>
          <Box>
            <div className={classes.topPart}>
              <FormControl variant="outlined" className={classes.formControl}>
                <Autocomplete
                  id="rewardtitle"
                  style={{ width: 300 }}
                  name="rewardtitle"
                  options={teamRewards}
                  getOptionLabel={option => option.name}
                  onChange={(event, newValue) => {
                    setPoolID(newValue == null? 0 : newValue.pid);
                  }}
                  renderInput={params => <TextField {...params} label="Select Period" margin="dense" variant="outlined" />}
                />
              </FormControl>
            </div>
          </Box>          
          <br/>
          <Typography
              color="text"
              //colorBrightness={"hint"}
              variant={"caption"}
              style={{ marginRight: 5 }}
              noWrap
            >{"Eligible Token"}</Typography>
          <br/>
          <Box display="flex" alignItems="center" justifyContent={"left"}>
            <TextField
                  variant="outlined"
                  value={format({prefix: "", suffix: ''})(xWinLib.roundTo(userData.myRewards, 4))}
                  // value={userData.buyamount}
                  fullWidth
                  InputProps={{
                    classes: {
                      input: classes.positiveNum,
                    },
                    readOnly: true,
                    style: { textAlign: "right" },
                    startAdornment: 
                      <InputAdornment position="start">
                        {getChipDisplay()}
                      </InputAdornment>,
                  }}
                />
          </Box>
          <br/>
          <Typography
              color="text"
              //colorBrightness={"hint"}
              variant={"caption"}
              style={{ marginRight: 5 }}
              noWrap
            >{"Already Claimed"}</Typography>
          <br/>
          <Box display="flex" alignItems="center" justifyContent={"left"}>
            <TextField
                  variant="outlined"
                  value={format({prefix: "", suffix: ''})(xWinLib.roundTo(userData.claimedBalance, 4))}
                  fullWidth
                  InputProps={{
                    classes: {
                      input: classes.positiveNum,
                    },
                    readOnly: true,
                    style: { textAlign: "right" },
                    startAdornment: 
                      <InputAdornment position="start">
                        {getChipDisplay()}
                      </InputAdornment>,
                  }}
                />
          </Box>
          <br/>
          <Box display="flex" alignItems="center" justifyContent={"left"}>
            <Button 
                disabled={userData.claimedBalance > 0}
                onClick={handleClickClaim} 
                color="primary"
                variant="contained"
                fullWidth
                startIcon={<AddCircleIcon />}>
                  Claim My Rewards
              </Button>
          </Box>
        </Widget> 
      </Grid>
      
    </Grid>
    </Container>
  );
}


export default TeamRewards;
