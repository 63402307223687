import BEP20 from '../../abi/BEP20.json'
import React, { useContext, useEffect, useRef, useState  } from "react";
import xWinLib from '../../xWinLib';
import xWinSale from '../../abi/xWinSaleV2.json';
import xWinUtils from '../../xWinUtils';

import AddCircleIcon from '@material-ui/icons/AddCircle';

import SnackbarMessage from '../snackbarmessage/SnackbarMessage';
import appGlobalConfig from '../../appGlobalConfig';

import format from 'format-number';

import { AppContext } from '../../context/AppProvider';

import {
  Box,
  ButtonGroup,
  Chip,
  Container,
  Fade,
  Grid,
  InputAdornment,
  TextField,
  Tooltip,
} from '@material-ui/core';
import {
  Help as HelpIcon,
  Star as StarIcon,
} from '@material-ui/icons';


// styles
import useStyles from './styles';

// components
import Dot from '../../components/Sidebar/components/Dot';
import Widget from '../../components/Widget/Widget';
import { Avatar, Button, Typography } from '../../components/Wrappers/Wrappers';


const BuySell = (props) => {
  const {} = props;

  const classes = useStyles();

  const [loadingTxn, setLoadingTxn] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [subsAmt, setsubsAmt] = useState('0');
  const [sellAmt, setSellAmt] = useState('0');
  const [tokenOutAmt, setTokenOutAmt] = useState('0');
  const [bnbOutAmt, setBnbOutAmt] = useState('0');
  const [pricePerBNB, setPricePerBNB] = useState('0');
  const [pricePerXWIN, setPricePerXWIN] = useState('0');
  const [pricePerXWINPriceImpact, setPricePerXWINPriceImpact] = useState('0');
  const [pricePerBNBPriceImpact, setPricePerBNBPriceImpact] = useState('0');
  const [extraXWIN, setExtraXWIN] = useState('0');
  const [bonusLockPeriod, setBonusLockPeriod] = useState('0');

  const [fundData, setFundData] = useState([]);
  const [userData, setUserData] = useState({
    walletETHBalance: 0,
    selectedWallet: '',
  });

  const [xWinSaleProtocol, setxWinSaleProtocol] = useState('');
  const [xWinSaleProtocolDisplay, setxWinSaleProtocolDisplay] = useState('');
  const MAX_ALLOWANCE = '115792089237316195423570985008687907853269984665640564039457584007913129639935';
  const [isApproval, setIsApproval] = useState(false);
  const {
    isConnected, ETHBalance, account, theWeb3, web3static
  } = useContext(AppContext);

  const refsnackbar = useRef(null);

  // if (theWeb3 !== undefined) {
  //   theWeb3.currentProvider.on('accountsChanged', (accounts) => {
  //     loadWeb3();
  //   });

  //   theWeb3.currentProvider.on('chainChanged', (accounts) => {
  //     loadWeb3();
  //   });
  // }

  useEffect(() => {
    const controller = new AbortController();

    loadWeb3();

    return () => {
      controller.abort();
    };
  }, [account]);

  const loadWeb3 = async () => {

    // if(!isConnected) return
    if (theWeb3 === undefined) return;

    setLoading(true);
    const myProtocol = new theWeb3.eth.Contract(xWinSale, appGlobalConfig.mainnetxWinSale);
    const myProtocolDisplay = new web3static.eth.Contract(xWinSale, appGlobalConfig.mainnetxWinSale);

    setxWinSaleProtocol(myProtocol);
    setxWinSaleProtocolDisplay(myProtocolDisplay);
    const xWINBEP20 = new web3static.eth.Contract(BEP20, appGlobalConfig.mainnetXWIN);
    const xWINBalance = await xWINBEP20.methods.balanceOf(appGlobalConfig.mainnetxWinSale).call();

    let myXWINBalance = 0;
    let userInfoForXWIN;

    if (account !== '---') {
      myXWINBalance = await xWINBEP20.methods.balanceOf(account).call();
      myXWINBalance = xWinUtils.convertFromWei(web3static, myXWINBalance.toString());
      userInfoForXWIN = await myProtocol.methods.userInfoXWIN(account).call();
    }

    // let extraBonus = await myProtocol.methods.accBonusPercent().call()
    const maxBuy = 5;// await myProtocol.methods.maxBuy().call()
    const extraBonus = await myProtocolDisplay.methods.accXWINBonusPercent().call();
    const lockPeriod = await myProtocolDisplay.methods.lockperiod().call();
    const lockperiodNextBuy = await myProtocolDisplay.methods.lockperiodNextBuy().call();
    const currentBlock = await web3static.eth.getBlockNumber();

    setFundData({
      xWINBalance: xWinUtils.convertFromWei(web3static, xWINBalance.toString()),
      extraBonus: extraBonus / 10000,
      lockPeriod,
      lockperiodNextBuy,
      maxBuy,
    });

    setUserData({
      walletETHBalance: ETHBalance,
      selectedWallet: account,
      myXWINBalance,
      nextBuyBlock: userInfoForXWIN? "0" : userInfoForXWIN?.nextBuyBlock,
      currentBlock,
      allowToBuy: currentBlock > userInfoForXWIN?.nextBuyBlock,
    });

    setLoading(false);
  };

  const handleAmtChange = () => (event) => {
    event.preventDefault();
    const subsAmount = parseFloat(event.target.value);

    setExtraXWIN(0);

    if (subsAmount >= 0 && subsAmount !== undefined) {
      setsubsAmt(subsAmount);
      getBNBToXWIN(subsAmount);
    }
  };

  const handleAmtChangeSell = () => async (event) => {
    event.preventDefault();
    const sellAmount = parseFloat(event.target.value);

    if (sellAmount >= 0 && sellAmount !== undefined) {
      setSellAmt(sellAmount);
      getXWINToBNB(sellAmount);
      const isApp = await isTokenApproved(sellAmount);

      setIsApproval(isApp);
    }
  };

  const getBNBToXWIN = async (amount) => {
    if (xWinSaleProtocolDisplay === '' || xWinSaleProtocolDisplay === undefined) return;
    const subsAmtInWei = theWeb3.utils.toWei(amount.toString());
    let getAmountOut = await xWinSaleProtocolDisplay.methods.getBNBToXWIN(subsAmtInWei).call();

    getAmountOut = xWinUtils.convertFromWei(theWeb3, getAmountOut.toString());
    setTokenOutAmt(parseFloat(getAmountOut));
    const pPerBNB = amount / getAmountOut;

    setPricePerBNB(pPerBNB);

    const one = 1e18;
    let getAmountOutFor1 = await xWinSaleProtocolDisplay.methods.getBNBToXWIN(one.toString()).call();

    getAmountOutFor1 = xWinUtils.convertFromWei(theWeb3, getAmountOutFor1.toString());
    const theoryAmountOut = amount * getAmountOutFor1;
    const priceImpact = getAmountOut / theoryAmountOut - 1;

    setPricePerBNBPriceImpact(priceImpact * 100);

    const extra = xWinLib.roundTo(getAmountOut * fundData.extraBonus, 2);

    setExtraXWIN(extra);

    const lockDays = xWinLib.roundTo(fundData.lockPeriod / 28800, 2);

    setBonusLockPeriod(lockDays);
  };

  const getXWINToBNB = async (amount) => {
    if (xWinSaleProtocolDisplay === '' || xWinSaleProtocolDisplay === undefined) return;
    const subsAmtInWei = theWeb3.utils.toWei(amount.toString());
    let getAmountOut = await xWinSaleProtocolDisplay.methods.getXWINToBNB(subsAmtInWei).call();

    getAmountOut = xWinUtils.convertFromWei(theWeb3, getAmountOut.toString());
    setBnbOutAmt(getAmountOut);
    const pPerXWIN = amount / getAmountOut;

    setPricePerXWIN(pPerXWIN);

    const one = 1e18;
    let getAmountOutFor1 = await xWinSaleProtocolDisplay.methods.getXWINToBNB(one.toString()).call();

    getAmountOutFor1 = xWinUtils.convertFromWei(theWeb3, getAmountOutFor1.toString());
    const theoryAmountOut = amount * getAmountOutFor1;
    const priceImpact = getAmountOut / theoryAmountOut - 1;

    setPricePerXWINPriceImpact(priceImpact * 100);
  };

  const handleClickAmtSell = (ratio) => async (event) => {
    event.preventDefault();

    const sellAmount = xWinLib.roundTo(ratio * userData.myXWINBalance, 5);

    setSellAmt(sellAmount);
    getXWINToBNB(sellAmount);
    const isApp = await isTokenApproved(sellAmount);

    setIsApproval(isApp);
  };

  const handleClickAmt = (ratio) => (event) => {
    event.preventDefault();

    const subAmount = xWinLib.roundTo(ratio * ETHBalance, 5);

    setsubsAmt(subAmount);
    getBNBToXWIN(subAmount);
  };

  const handleClickClearAmt = () => (event) => {
    event.preventDefault();
    setsubsAmt(0);
    setTokenOutAmt(0);
    setExtraXWIN(0);
  };

  const handleClickClearAmtSell = () => (event) => {
    event.preventDefault();
    setSellAmt(0);
    setBnbOutAmt(0);
  };

  const isTokenApproved = async (subsAmount) => {
    if (userData.selectedWallet === '') return false;
    const sourceToken = new theWeb3.eth.Contract(BEP20, appGlobalConfig.mainnetXWIN);
    const contractAllowance = await sourceToken.methods
      .allowance(userData.selectedWallet, appGlobalConfig.mainnetxWinSale)
      .call();

    return parseFloat(contractAllowance) > parseFloat(subsAmount);
  };

  const handleClickBuy = (event) => {
    event.preventDefault();

    if (parseFloat(subsAmt) <= 0) {
      showSnackBar('error', 'Please input BNB');
      return;
    }

    const totalXWINNeeded = (parseFloat(tokenOutAmt) * 2) + parseFloat(extraXWIN);

    if (totalXWINNeeded > parseFloat(fundData.xWINBalance)) {
      showSnackBar('error', 'Apologize. Out of inventory');
      return;
    }

    if (parseFloat(subsAmt) > parseFloat(fundData.maxBuy)) {
      showSnackBar('error', `Cannot buy more than ${ fundData.maxBuy } each time`);
      return;
    }

    showSnackBar('info', `$XWIN and $XWIN Bonus can be claimed in Claim Token & Bonus page after ${ bonusLockPeriod } days`);

    setLoadingTxn(true);
    const subsAmtInWei = theWeb3.utils.toWei(subsAmt.toString());

    xWinLib.BuyDirectAsync(
      xWinSaleProtocol,
      subsAmtInWei,
      userData,
    )
      .then((res) => {
        console.log(res);
        setLoadingTxn(false);
        showSnackBar('success', res);
        setsubsAmt(0);
        setTokenOutAmt(0);
        setExtraXWIN(0);
        loadWeb3();
      })
      .catch((err) => {
        setLoadingTxn(false);
        console.log(err);
        showSnackBar('error', err);
      });
  };

  const showSnackBar = (status, msg) => {
    refsnackbar.current.handleOpen(status, msg);
  };


  const handleClickSell = (event) => {
    event.preventDefault();

    if (parseFloat(sellAmt) > parseFloat(userData.myXWINBalance)) {
      showSnackBar('error', "Can't sell more than available balance");
      return;
    }

    setLoadingTxn(true);
    let sellAmtInWei = 0;

    if (sellAmt === '') {
      sellAmtInWei = 0;
    } else {
      sellAmtInWei = theWeb3.utils.toWei(sellAmt.toString());
    }

    xWinLib.SellDirectAsync(
      xWinSaleProtocol,
      sellAmtInWei,
      userData,
    )
      .then((res) => {
        console.log(res);
        setLoadingTxn(false);
        showSnackBar('success', res);
        setSellAmt(0);
        setBnbOutAmt(0);
        loadWeb3();
      })
      .catch((err) => {
        setLoadingTxn(false);
        console.log(err);
        showSnackBar('error', err);
      });
  };

  const handleClickApprove = async (event) => {
    event.preventDefault();

    if (userData.selectedWallet === '') return;

    setLoadingTxn(true);

    const sourceToken = new theWeb3.eth.Contract(BEP20, appGlobalConfig.mainnetXWIN);

    xWinLib.approveContract(sourceToken, MAX_ALLOWANCE, appGlobalConfig.mainnetxWinSale, userData.selectedWallet, theWeb3)
      .then((res) => {
        setLoadingTxn(false);
        setIsApproval(true);
        showSnackBar('success', res);
      })
      .catch((err) => {
        setLoadingTxn(false);
        console.log(err);
      });
  };

  const displayStar = () => {
    if (extraXWIN > 0) {
      return (
        <div>
          <StarIcon className={classes.starIcon} />
          <StarIcon className={classes.starIcon} />
        </div>
      );
    }
  };

  const goToBSCscan = (event) => {
    if (userData.nextBuyBlock > 0)	window.open(`https://bscscan.com/block/countdown/${ userData.nextBuyBlock }`, '_blank');
  };

  const getButtonAction = () => {
    
    return (
      <Button
        onClick={xWinUtils.buyInPCS}
        color="primary"
        variant="contained"
        fullWidth
        startIcon={<AddCircleIcon />}
      >
        Out of Stock. Swap in Pancakeswap!
      </Button>
    );

    // if (fundData.xWINBalance <= 2) {
    //   return (
    //     <Button
    //       onClick={xWinUtils.buyInPCS}
    //       color="primary"
    //       variant="contained"
    //       fullWidth
    //       startIcon={<AddCircleIcon />}
    //     >
    //       Out of Stock. Swap in Pancakeswap!
    //     </Button>
    //   );
    // }

    // return (
    //   <Button
    //     //disabled={fundData.xWINBalance <= 2}
    //     //disabled={fundData.xWINBalance <= 2}
    //     onClick={userData.allowToBuy ? handleClickBuy : goToBSCscan}
    //     color="primary"
    //     variant="contained"
    //     fullWidth
    //     startIcon={<AddCircleIcon />}
    //   >
    //     {userData.allowToBuy ? 'Buy XWIN' : `Next Buying After Block ${ userData.nextBuyBlock }`}
    //   </Button>
    // );
  };

  return (
    <Container>
      <SnackbarMessage ref={refsnackbar} />
      <Widget
        className={classes.widgetLayout}
        title="Buy / Sell $XWIN"
        disableWidgetMenu
        enableWalletDisplay
        selectedWallet={userData.selectedWallet}
      >
        <Grid container spacing={3}>
          {xWinUtils.getProgress(loadingTxn)}

          <Grid item lg={6} sm={12} xs={12}>
            <Widget
              className={classes.widgetLayout}
              title="Buy"
              disableWidgetMenu
              subtitle={`Available XWIN in Pool ${ format({ prefix: '', suffix: ' ' })(xWinLib.roundTo(fundData.xWINBalance, 4)) }`}
            >
              <Box display="flex" alignItems="center" justifyContent="center">
                <ButtonGroup fullWidth size="large" color="primary" aria-label="">
                  <Button
                    fullWidth
                    // disabled={"true"}
                    color="primary"
                    size="small"
                    variant="outlined"
                    onClick={handleClickAmt(0.25)}
                  >
                    25%
                  </Button>
                  <Button
                    fullWidth
                    // disabled={"true"}
                    color="primary"
                    size="small"
                    variant="outlined"
                    onClick={handleClickAmt(0.50)}
                  >
                    50%
                  </Button>
                  <Button
                    className={classes.hide}
                    // disabled={"true"}
                    fullWidth
                    color="primary"
                    size="small"
                    variant="outlined"
                    onClick={handleClickAmt(0.75)}
                  >
                    75%
                  </Button>
                  <Button
                    fullWidth
                    // disabled={"true"}
                    color="primary"
                    size="small"
                    variant="outlined"
                    onClick={handleClickAmt(0.99)}
                  >
                    100%
                  </Button>
                  <Button
                  // disabled={"true"}
                    fullWidth
                    color="primary"
                    size="small"
                    variant="outlined"
                    onClick={handleClickClearAmt()}
                  >
                    Clear
                  </Button>
                </ButtonGroup>

              </Box>
              <div className="mb-1 mt-3">
                <Typography
                  color="text"
                  colorBrightness="hint"
                  variant="caption"
                  style={{ marginRight: 5 }}
                  noWrap
                >
                  From
                </Typography>
              </div>
              <Box display="flex" alignItems="center" justifyContent="left">
                <TextField
                  type="number"
                  name="weight"
                  onChange={handleAmtChange()}
                  required
                  // disabled={true}
                  variant="outlined"
                  value={subsAmt}
                  fullWidth
                  InputProps={{
                    classes: {
                      input: classes.positiveNum,
                    },
                    style: { textAlign: 'right' },
                    startAdornment:
  <InputAdornment position="start">
    <Chip
      size="large"
      label={xWinUtils.displayBalance(false, format({ prefix: '', suffix: ' ' })(xWinLib.roundTo(ETHBalance, 4)))}
      color="primary"
      avatar={<Avatar src="/images/icons/color/bnb.png" />}
    />
  </InputAdornment>,
                  }}
                />
              </Box>
              <div className="mb-1 mt-2">
                <Typography
                  color="text"
                  colorBrightness="hint"
                  variant="caption"
                  style={{ marginRight: 5 }}
                  noWrap
                >
                  To
                </Typography>
              </div>
              <Box display="flex" alignItems="center" justifyContent="left">

                <TextField
                  variant="outlined"
                  value={xWinUtils.displayBalance(loading, format({ prefix: '', suffix: '' })(xWinLib.roundTo(tokenOutAmt, 2)))}
                  fullWidth
                  InputProps={{
                    classes: {
                      input: classes.positiveNum,
                    },
                    readOnly: true,
                    style: { textAlign: 'right' },
                    startAdornment:
  <InputAdornment position="start">
    <Chip
      size="large"
      color="primary"
      avatar={<Avatar src="/images/icons/color/xwin.png" />}
    />
  </InputAdornment>,
                  }}
                />

              </Box>
              <br />

              <Typography
                color="text"
                colorBrightness="hint"
                variant="caption"
                style={{ marginRight: 5 }}
                noWrap
              >
                {`via XWIN Pool + Get ${ format({ prefix: '', suffix: '%' })(fundData.extraBonus * 100) } Bonus`}

              </Typography>


              <br />
              <Box display="flex" alignItems="center" justifyContent="left">
                <Typography
                  color="text"
                  variant="caption"
                  style={{ marginRight: 5 }}
                  noWrap
                >
                  {`Price: ${ format({ prefix: '', suffix: ' ' })(xWinLib.roundTo(pricePerBNB, 4)) } BNB per XWIN`}

                </Typography>
                <Dot size='medium' color="success" />
              </Box>
              <Box display="flex" alignItems="center" justifyContent="left">
                <Typography
                  color="text"
                  variant="caption"
                  style={{ marginRight: 5 }}
                  noWrap
                >
                  {`Price Impact: ${ format({ prefix: '', suffix: '%' })(xWinLib.roundTo(pricePerBNBPriceImpact, 2)) }`}

                </Typography>
                <Dot size='medium' color={parseFloat(Math.abs(pricePerBNBPriceImpact)) > 2.00 ? 'secondary' : 'success'} />
              </Box>
              <br />
              <Box display="flex" alignItems="center" justifyContent="left">
                {getButtonAction()}
                {/* <Button
                  disabled={fundData.xWINBalance <= 2}
                  onClick= {userData.allowToBuy ? handleClickBuy : goToBSCscan}
                  color="primary"
                  variant="contained"
                  fullWidth
                  startIcon={<AddCircleIcon />}>
                    {userData.allowToBuy ? "Buy XWIN" : "Next Buying After Block " + userData.nextBuyBlock}
                </Button> */}
                {/* <Button
                      onClick= {xWinUtils.buyInPCS}
                      color="primary"
                      variant="contained"
                      fullWidth
                      startIcon={<AddCircleIcon />}>
                    {"Out of Stock. Swap in Pancakeswap!"}
                </Button> */}
              </Box>
              <br />
              <Box display="flex" alignItems="center" justifyContent="center">

                {displayStar()}
                <Typography
                  color="text"
                  variant="large"
                  style={{ marginRight: 5 }}
                  className={classes.subTitle}
                  noWrap
                >
                  {`Est Bonus XWIN ${ format({ prefix: '', suffix: '' })(extraXWIN) }`}
                </Typography>
                {displayStar()}
              </Box>
              <br />
              <Box display="flex" alignItems="center" justifyContent="left">
                <Typography
                  color="text"
                  colorBrightness="hint"
                  variant="caption"
                  style={{ marginRight: 5 }}
                >
                  {`*** ${ bonusLockPeriod } days locked period applied to the $XWIN Proceeds + Bonus token. Timer reset everytime you buy from the platform`}
                </Typography>
                <Tooltip
                  TransitionComponent={Fade}
                  title={(
                    <h4 style={{ color: 'lightblue' }}>
                      {`$XWIN Proceeds and $XWIN Bonus can be claimed in Claim Token & Bonus page after ${ bonusLockPeriod } days`}
                    </h4>
              )}

                  arrow
                >
                  <HelpIcon />
                </Tooltip>
              </Box>
              <br />
              <Box display="flex" alignItems="center" justifyContent="left">
                <Typography
                  color="text"
                  colorBrightness="hint"
                  variant="caption"
                  style={{ marginRight: 5 }}
                >
                  <a className={classes.link} href={xWinLib.getEtherLink(fundData.networkName, appGlobalConfig.mainnetxWinSale, 'address')} target="blank">Contract</a>
                </Typography>
              </Box>
            </Widget>
          </Grid>
          <Grid item lg={6} sm={12} xs={12}>
            <Widget
              className={classes.widgetLayout}
              title="Sell"
              disableWidgetMenu
            >
              <Box display="flex" alignItems="center" justifyContent="center">
                <ButtonGroup fullWidth size="small" color="primary" aria-label="">
                  <Button
                    fullWidth
                    color="secondary"
                    size="small"
                    variant="outlined"
                    onClick={handleClickAmtSell(0.25)}
                  >
                    25%
                  </Button>
                  <Button
                    fullWidth
                    color="secondary"
                    size="small"
                    variant="outlined"
                    onClick={handleClickAmtSell(0.50)}
                  >
                    50%
                  </Button>
                  <Button
                    className={classes.hide}
                    fullWidth
                    color="secondary"
                    size="small"
                    variant="outlined"
                    onClick={handleClickAmtSell(0.75)}
                  >
                    75%
                  </Button>
                  <Button
                    fullWidth
                    color="secondary"
                    size="small"
                    variant="outlined"
                    onClick={handleClickAmtSell(1)}
                  >
                    100%
                  </Button>
                  <Button
                    fullWidth
                    color="secondary"
                    size="small"
                    variant="outlined"
                    onClick={handleClickClearAmtSell()}
                  >
                    Clear
                  </Button>
                </ButtonGroup>
              </Box>
              <div className="mb-1 mt-3">
                <Typography
                  color="text"
                  colorBrightness="hint"
                  variant="caption"
                  style={{ marginRight: 5 }}
                  noWrap
                >
                  From
                </Typography>
              </div>
              <Box display="flex" alignItems="center" justifyContent="left">
                <TextField
                  type="number"
                  color="secondary"
                  name="weight"
                  onChange={handleAmtChangeSell()}
                  required
                  variant="outlined"
                  value={sellAmt}
                  fullWidth
                  InputProps={{
                    classes: {
                      input: classes.negativeNum,
                    },
                    style: { textAlign: 'right' },
                    startAdornment:
  <InputAdornment position="start">
    <Chip
      label={xWinUtils.displayBalance(false, format({ prefix: '', suffix: ' ' })(xWinLib.roundTo(userData.myXWINBalance, 4)))}
      size="large"
      color="secondary"
      avatar={<Avatar src="/images/icons/color/xwin.png" />}
    />
  </InputAdornment>,
                  }}
                />
              </Box>
              <div className="mb-1 mt-2">
                <Typography
                  color="text"
                  colorBrightness="hint"
                  variant="caption"
                  style={{ marginRight: 5 }}
                  noWrap
                >
                  To
                </Typography>
              </div>
              <Box display="flex" alignItems="center" justifyContent="left">

                <TextField
                  color="secondary"
                  name="weight"
                  required
                  variant="outlined"
                  value={xWinUtils.displayBalance(loading, format({ prefix: '', suffix: '' })(xWinLib.roundTo(bnbOutAmt, 4)))}
                  fullWidth
                  InputProps={{
                    classes: {
                      input: classes.negativeNum,
                    },
                    readOnly: true,
                    style: { textAlign: 'right' },
                    startAdornment:
  <InputAdornment position="start">
    <Chip
      size="large"
      color="secondary"
      avatar={<Avatar src="/images/icons/color/bnb.png" />}
    />
  </InputAdornment>,
                  }}
                />
              </Box>
              <br />
              <Typography
                color="text"
                colorBrightness="hint"
                variant="caption"
                style={{ marginRight: 5 }}
                noWrap
              >
                via Pancakeswap V2

              </Typography>
              <br />
              <Box display="flex" alignItems="center" justifyContent="left">
                <Typography
                  color="text"
                  variant="caption"
                  style={{ marginRight: 5 }}
                  noWrap
                >
                  {`Price: ${ format({ prefix: '', suffix: ' ' })(xWinLib.roundTo(pricePerXWIN, 4)) } XWIN per BNB`}

                </Typography>
                <Dot size='medium' color="success" />
              </Box>
              <Box display="flex" alignItems="center" justifyContent="left">
                <Typography
                  color="text"
                  variant="caption"
                  style={{ marginRight: 5 }}
                  noWrap
                >
                  {`Price Impact: ${ format({ prefix: '', suffix: '%' })(xWinLib.roundTo(pricePerXWINPriceImpact, 2)) }`}

                </Typography>
                <Dot size='medium' color={parseFloat(Math.abs(pricePerXWINPriceImpact)) > 2 ? 'secondary' : 'success'} />
              </Box>
              <br />
              <Box display="flex" alignItems="center" justifyContent="left">
                <Button
                  onClick={isApproval ? handleClickSell : handleClickApprove}
                  color="secondary"
                  variant="contained"
                  fullWidth
                  startIcon={<AddCircleIcon />}
                >
                  {isApproval ? 'SELL XWIN' : 'ENABLE'}
                </Button>
              </Box>

            </Widget>
          </Grid>
        </Grid>
      </Widget>
    </Container>
  );
};


export default BuySell;
