import React, { useState, useEffect, useContext} from "react";
import xWinUtils from '../../xWinUtils';
import xWinInvestorLock from '../../abi/xWinInvestorLock.json'
import Phase from "./components/Phase";
import { Alert } from '@material-ui/lab';
import HomeIcon from '@material-ui/icons/Home';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Widget from "../../components/Widget/Widget";
import {AppContext}  from '../../context/AppProvider';

import {
  Grid,
  Container,
  Snackbar,
  Box,
  TextField,
  InputAdornment,
  Button
} from "@material-ui/core";

// styles
import useStyles from "./styles";


const PrivateInvestor = props => {
  var classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [poolID, setPoolID] = React.useState(0);
  const { ETHBalance, isConnected, account, theWeb3, refreshAccount } = useContext(AppContext);
  
  const [userData, setUserData] = useState({
    walletETHBalance: 0,
    selectedWallet: ""
  });
  const [myProtocol, setMyProtocol] = useState("")
  const [openMsg, setOpenMsg] = React.useState(false);
  const [phases, setPhases] = React.useState([]);
  const [smartContactAddress, setSmartContactAddress] = React.useState([]);
  const [eligibleAddress, setEligibleAddress] = React.useState([]);
  
  const hideMessage = () => {
    setOpenMsg(false);
  };

  useEffect(() => {
    const controller = new AbortController();
    loadWeb3()
    return () => {
      controller.abort();
    }
  }, [account]);

  const loadWeb3 = async () => {
    setLoading(true)
    
    setUserData({
      "walletETHBalance" :  ETHBalance, 
      "selectedWallet" : account,
    })  
    
    setLoading(false)
  }

  const loadSmartContract = async () => {
    
    try{
      setLoading(true)
      let myPIProtocol = new theWeb3.eth.Contract(xWinInvestorLock, smartContactAddress);
      setMyProtocol(myPIProtocol)
  
      let releaseInfoCount = await myPIProtocol.methods.getTotalPhases().call() //3
      let eligibleAddr = await myPIProtocol.methods.eligibleAddress().call();
      setEligibleAddress(eligibleAddr)  
      // if(eligibleAddress === userData.selectedWallet){
            
            let currentBlock = await theWeb3.eth.getBlockNumber()
            let nextID = await myPIProtocol.methods.nextID().call();
            setPoolID(nextID)
            let temp = []
            for(let i=0; i< releaseInfoCount; i++){
              let releaseInfo = await myPIProtocol.methods.releaseInfo(i).call();
              let releaseBalance = xWinUtils.convertFromWei(theWeb3, releaseInfo.releaseBalance.toString())
              temp.push({
                "id" : i,
                "releaseBalance" : releaseBalance,
                "releaseBlock" : releaseInfo.releaseBlock,
                "currentBlock" : currentBlock,
                "canRelease" : currentBlock > releaseInfo.releaseBlock
              })
            }
            setPhases(temp)
        // }
        
        setLoading(false)
      }catch{
      setLoading(false)
      setPhases([])
    }
    
  }

  const handleClickRead = event => {
    event.preventDefault();
    loadSmartContract()
  }
  
  const displayPhases = () => {
    
    return (
      phases.map(p => (
          <Grid item lg={4} sm={6} xs={12}>
            <Phase 
              myProtocol={myProtocol}
              userData={userData} 
              phase={p} 
              poolID={poolID} /> 
          </Grid> 
        )) 
    )    
  }

  const handleChange = () => event => {
    event.preventDefault()
    setSmartContactAddress(event.target.value)
  }

  return (
    <Container>
      <Snackbar 
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        TransitionComponent={"TransitionUp"}
        open={openMsg} autoHideDuration={60000}>
          <Alert onClose={hideMessage} severity={"warning"}>
           Please connect your wallet or make sure you choose BSC Mainnet
          </Alert>
      </Snackbar>
    <Grid container spacing={3}>
      {xWinUtils.getProgress(loading)}
        <Grid item lg={12} sm={12} xs={12}>
          <Widget 
            className={classes.widgetLayout}
            title="Private Investor"
            disableWidgetMenu
            //subtitle={"Available XWIN in Pool "+ format({prefix: "", suffix: " "})(xWinLib.roundTo(fundData.xWINBalance,4))}
            enableWalletDisplay
            selectedWallet={account}
          >
            <Box display="flex" alignItems="center" justifyContent={"left"}>
              <TextField
                  className={classes.inputText}
                  fullWidth
                  label={'Smart Contract Address'}
                  margin="dense"
                  name="taddress"
                  onChange={handleChange()}
                  // value={xWinUtils.getAddress(ticker, tokensMaster, networkName).mainAddress}
                  required
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <HomeIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.button}
                    startIcon={<AddCircleIcon />}
                    onClick={handleClickRead}
                  >
                  {"Read"}
                    
                  </Button>
            </Box>
            </Widget>
        </Grid>
          {displayPhases()}
    </Grid>
    </Container>
  );
}


export default PrivateInvestor;
