import React from 'react';
import moment from 'moment';

import { Typography } from '@material-ui/core';
import useStyles from "../styles";
import xWinLib from '../../../xWinLib';

const NewsWidget = (props) => {
  const classes = useStyles();
  const { listings } = props;

  listings.sort(xWinLib.compareValues('date', 'desc'));

  const getLogo = (item) => {
    if (item.image === undefined) {
      return (
        <span className={`${ classes.icon }`} style={{ backgroundColor: '#FFC260' }}>
          <i className="fa fa-star" />
        </span>
      );
    }

    return (
      <span className={`${ classes.icon }`} style={{ backgroundColor: '#FFC260' }}>
        <img alt={item.title} src={item.image} className={classes.avatar} />
      </span>
    );
  };


  return (
    <ul className={classes.newsList}>
      {listings.map((item) => (
        <li className={classes.listRow} key={item.title}>
          <div className={classes.iconBlock}>
            {getLogo(item)}
          </div>
          <div className={classes.newsItemInfo}>
            <Typography variant="h5"><a className={classes.newsHeader} href={item.url} add target="_blank" rel="noopener noreferrer">{item.title}</a></Typography>
            <time className={classes.timeBlock}>
              {moment(new Date(item.date.seconds * 1000)).format('ll')}
            </time>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default NewsWidget;
