import React, { useState, useEffect  } from "react";
import xWinLib from '../../../xWinLib';
import format from 'format-number';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import {
  Box,
  CardContent,
  CardActions
} from "@material-ui/core";

// styles
import useStyles from "../styles";
import Widget from "../../../components/Widget/Widget";
import { Typography } from "../../../components/Wrappers/Wrappers";
import Dot from "../../../components/Sidebar/components/Dot";




export default function FundReturns(props) {
  var classes = useStyles();
  const { priceData } = props;
  const [returnChoice, setReturnChoice] = useState(1)
  const [priceReturns, setPriceReturns] = useState(0)
  
  useEffect(() => {
    const controller = new AbortController();
    setReturns(priceData, 1)
    return () => {
      controller.abort();
    }
  }, [priceData]);

  const setReturns = (data, choice) => {

    if(data === undefined) return
    if(data.length === 0) return

    if(data !== undefined){
      let dataarray = []
      Object.keys(data).forEach(function (key) {
        dataarray.push({
          value: data[key]
        }) 
      })

      let returns = dataarray[dataarray.length-1].value / dataarray[0].value -1
      if(choice === 1) returns = dataarray[dataarray.length-1].value / dataarray[dataarray.length-2]?.value -1
      if(choice === 2){
        let start = dataarray.length > 7 ? dataarray.length - 7: dataarray.length - 1;
        returns = dataarray[dataarray.length-1].value / dataarray[start].value -1
      } 
      setPriceReturns(returns * 100)
    }
  }

  const handleClickReturnChoice = (event, choice) => {
    setReturnChoice(choice)
    setReturns(priceData, choice)
  };

  

  return (
    <Widget
      disableWidgetMenu
      bodyClass={classes.fullHeightBody}
      className={classes.card}
      >
        {/* <Card> */}
            <CardContent>
                <Typography
                  color="text"
                  colorBrightness={"hint"}
                  variant={"caption"}
                  style={{ marginRight: 5 }}
                >
                  Returns
                </Typography>
                <Box display="flex" alignItems="center" justifyContent={"center"}>
                  <Dot size='medium' color={priceReturns > 0? "success": "secondary"} />
                  <Typography
                    size="md"
                    weight={"medium"}
                    style={{ marginLeft: 8 }}
                    className={priceReturns > 0? classes.positiveNum: classes.negativeNum}
                  >
                    {/* <Typography variant="h3" className={priceReturns > 0? classes.mainHeading: classes.negativeNum}> */}
                    {priceReturns > 0? <ArrowUpwardIcon/>: <ArrowDownwardIcon/>}
                    {format({prefix: '', suffix: '%'})(xWinLib.roundTo(priceReturns,2)) }
                  {/* </Typography>   */}
                  </Typography>
                </Box>
            </CardContent> 
            <CardActions>
                <ToggleButtonGroup size="small" value={returnChoice} exclusive onChange={handleClickReturnChoice}>
                  <ToggleButton value={1}>
                    1 Day
                  </ToggleButton>
                  <ToggleButton value={2}>
                    1 Week
                  </ToggleButton>
                  <ToggleButton value={3}>
                    All
                  </ToggleButton>
                </ToggleButtonGroup>
            </CardActions>
          {/* </Card> */}
    </Widget>
    
  );
}
