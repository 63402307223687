import React, { useState, useEffect, useRef, useContext  } from "react";
import xWinLib from '../../xWinLib';
import xWinUtils from '../../xWinUtils';
import xWinProtocolV5 from '../../abi/xWinProtocolV5.json'
import BEP20 from '../../abi/BEP20.json'
import { useParams } from 'react-router-dom'
import UnitPrice from "./components/UnitPrice";
import firebase from "firebase/app";
import format from 'format-number';
import { withRouter } from "react-router-dom";
import { Link as RouterLink } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ApexLineChart from "./components/ApexLineChart";
import TargetAllocation from "./components/TargetAllocation";
import SupplyStats from "./components/SupplyStats";
import ManagerStats from "./components/ManagerStats";
import FundReturns from "./components/FundReturns";
import TermCondition from '../termcondition/termcondition'
import Subscribe from '../subscribe/subscribe'
import Redeem from '../redeem/redeem'
import CreateTarget from '../createtarget/CreateTarget'
import Rebalance from '../rebalance/Rebalance'
import MoveNonBM from '../movenonbm/MoveNonBM'
import {AppContext}  from '../../context/AppProvider';
import confetti from 'canvas-confetti'

import {
  Grid,
  Link,
  Box,
  CardContent,
  Card,
  CardHeader,
  TableRow,
  Table,
  TableCell,
  TableBody,
  Avatar,
  IconButton,
  TableHead,
  Tooltip,
  ButtonGroup
} from "@material-ui/core";
import { Typography, Button } from "../../components/Wrappers/Wrappers";
import useStyles from "./styles";


const FundDetail = props => {
  const classes = useStyles();
  const [networkName, setNetworkName] = useState("")
  const [mainData, setMainData] = React.useState([]);
  const [myxWinProtocol, setMyxWinProtocol] = React.useState([]);
  const [priceData, setPriceData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  
  const [userData, setUserData] = useState({
    "walletETHBalance" :  0, 
    "selectedWallet" : "",
    "userfundbal" : 0,
    "referral" : ""
  });
  const [fundData, setFundData] = useState([]);
  const { id } = useParams()
  
  const refTermCondition = useRef(null);
  const refsubsdirect = useRef(null);
  const refredeem = useRef(null);
  const refcreatetarget = useRef(null);
  const refrebalance = useRef(null);
  const refMoveNonBM = useRef(null);
  const { isConnected, account, theWeb3, web3static } = useContext(AppContext);
  
  const { port, tokensMaster, fundtype } = props.location?.state || {}
  
  useEffect(() => {
    loadFundData()
    const unsubscribe = firebase
    .firestore()
    .collection('performance2')
    .doc(id).onSnapshot(doc => {
      setMainData(doc.data())
      setPriceData(doc.data())
  })
  return () => { unsubscribe() }

}, [id, account])

  
  const getProtocol = () => {

    return port === undefined ? "0x1Bf7fe7568211ecfF68B6bC7CCAd31eCd8fe8092" : port.protocoladdress
  }

  const loadFundData = async () => {

    if(tokensMaster === undefined) return

    setLoading(true)

    try {

      let filterEnv = await xWinLib.getNetwork(theWeb3)
      let env = await xWinLib.getNetwork(theWeb3)
      setNetworkName(env)
      
      if(theWeb3){
        let xWinProtocol = new theWeb3.eth.Contract(xWinProtocolV5, getProtocol());
        setMyxWinProtocol(xWinProtocol)
      }
      let xWinProtocolDisplay = new web3static.eth.Contract(xWinProtocolV5, getProtocol());

      let walletData = await xWinUtils.getUserWalletInfoNew(web3static, account)
      let referral = await xWinUtils.getReferral(xWinProtocolDisplay, walletData.selectedWallet) 
      let fundHolderData = await xWinUtils.getUserFundHolding(web3static, walletData.selectedWallet, id)          
      if(walletData.selectedWallet !== "---"){
        let claimableBal = await xWinProtocolDisplay.methods.GetEstimateReward(walletData.selectedWallet).call({from: walletData.selectedWallet});
        setUserData({
          "walletETHBalance" :  walletData?.walletETHBalance || "0", 
          "selectedWallet" : walletData?.selectedWallet || "---",
          "userfundbal" : fundHolderData?.userfundbal || "0",
          "claimableBal" : xWinUtils.convertFromWei(theWeb3, claimableBal.toString()),
          "referral" : referral
        })  
      }else{
        setUserData({
          "walletETHBalance" :  "0", 
          "selectedWallet" : "0",
          "userfundbal" : "0",
          "claimableBal" : "0",
          "referral" : ""
        }) 
      }
      
      let myFundData = await xWinUtils.getFundAllData(fundHolderData.xWinFund)
      let tokenNames = []

      let targetaddressNew = []
      for(let i=0; i< myFundData.targetaddress.length; i++){
        targetaddressNew.push(myFundData.targetaddress[i])
      }

      
      for(let i=0; i< targetaddressNew.length; i++){
          let targetweight = await fundHolderData.xWinFund.methods.getTargetWeight(targetaddressNew[i]).call();
          //let targetBal = await fundHolderData.xWinFund.methods.getBalance(targetaddressNew[i]).call();
          let targetBEP20 = new web3static.eth.Contract(BEP20, targetaddressNew[i]);
          let decimals = await targetBEP20.methods.decimals().call()
          let targetBal = await targetBEP20.methods.balanceOf(port.contractaddress).call();
          let fundTokenValue = await fundHolderData.xWinFund.methods.getTokenValues(targetaddressNew[i]).call();
          
          let AllBal = await fundHolderData.xWinFund.methods.getBalance(targetaddressNew[i]).call();
          let diffBal = AllBal - targetBal
          let tname = ""
          let token = tokensMaster.find(t=>t.address.toLowerCase() === targetaddressNew[i].toLowerCase() && t.env === networkName.toString().toLowerCase())
          if(token !== undefined)
            
          tname = token.name
            let price = fundTokenValue / (AllBal === 0? 1: AllBal)
            tokenNames.push({
            "key": i,
            "address": targetaddressNew[i],
            "targetweight": targetweight,
            "balance": parseFloat(decimals) === 18? xWinUtils.convertFromWei(web3static, targetBal.toString()) : parseFloat(targetBal) / 10 ** decimals, // targetBal / PRECISION,
            "fundTokenValue": xWinUtils.convertFromWei(web3static, fundTokenValue.toString()), //fundTokenValue / PRECISION,
            "aTokenBal": xWinUtils.convertFromWei(web3static, diffBal.toString()), //diffBal / PRECISION
            "name": tname,
            "price" : price,
            "decimals" : decimals
          })
      }
      tokenNames.push({
        "key": 999,
        "address": xWinLib.GetBNB_ADDRESS(), //env === "bsctest"? xWinLib.GetBNB_ADDRESS():  xWinLib.GetETH_ADDRESS(),
        "targetweight": 0,
        "balance": xWinUtils.convertFromWei(web3static, myFundData.fundETHbalance.toString()), //fundETHbalance / PRECISION,
        "fundTokenValue": xWinUtils.convertFromWei(web3static, myFundData.fundETHbalance.toString()),
        "aTokenBal": 0,
        "name": "BNB", //env === "bsctest"? "BNB" :  "ETH",
        "price": 1,
        "decimals" : 18
      })
      
      setFundData({
        ...fundData,
        "targetaddress": myFundData.targetaddress,
        "fundvalue": xWinUtils.convertFromWei(web3static, myFundData.fundvalue.toString()), //fundvalue / PRECISION,
        "tokenNames": tokenNames,
        "uniprice": xWinUtils.convertFromWei(web3static, myFundData.uniprice.toString()), //uniprice / PRECISION,
        "totalSupply":  xWinUtils.convertFromWei(web3static, myFundData.totalSupply.toString()), // totalSupply / PRECISION,
        "fundName": myFundData.fundName,
        "fundmanager": myFundData.fundmanager,
        "fundETHbalance": xWinUtils.convertFromWei(web3static, myFundData.fundETHbalance.toString()), //fundETHbalance / PRECISION
        "symbol": myFundData.symbol,
        "managerFee": myFundData.managerFee / 100,
        "unipriceInUSD": xWinUtils.convertFromWei(web3static, myFundData.unipriceInUSD.toString()), 
        "networkName": env
      })
      setLoading(false)
      
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
    
  }

  const getFundDesc = () => {

    if(port === undefined) return

    if(fundtype === "liquidity2" || fundtype === "liquidityv2") return ""
    return (
      <Card>
      <CardContent>
              <div dangerouslySetInnerHTML={{__html:port.desc}} />
      </CardContent>

      </Card>
    );

  }

  const getSubHeader = () => {

    return (
      <div>
        <Typography color={"success"}>
          { port.symbol + " Balance: " + format({prefix: " ", suffix: ''})(xWinLib.roundTo(userData.userfundbal,4))}
        </Typography>  
        <Typography 
          color="text"
          colorBrightness={"hint"}
          variant={"caption"}
          //style={{ marginRight: 5 }}
          >
          Inception Date: {xWinLib.FormatDate(port.inception)}
        </Typography>  
        <Typography 
          color="text"
          colorBrightness={"hint"}
          variant={"caption"}
          //style={{ marginRight: 5 }}
          >
          <a className={classes.link} href={xWinLib.getEtherLink(fundData.networkName, port.contractaddress, "address")} target="blank">View on BSCScan</a>
        </Typography>
      </div>
    )
      
  }

  const getHeader = () => {

    return (
      <div>
        {port.name}
      </div>
    )
      
  }
  
  const getBackHistory = () => {

    if(fundtype === "liquidityv2") { 
        
      return ({
        pathname: `/app/yields/pcs` ,
        state: {
          fundtype: fundtype
        }}
      )
    }
    else if(fundtype === "liquidity2"){ 

      return ({
        pathname: `/app/yields/old/pcsold` ,
        state: {
          fundtype: fundtype
        }}
      )
    }
    else if(fundtype === "tradingv2"){ 

      return ({
        pathname: "/app/tradings/trading",
        state: {
          fundtype: fundtype
        }}
      )
    }else if(fundtype === "indexv2"){ 
      return ({
        pathname: "/app/tradings/index",
        state: {
          fundtype: fundtype
        }}
      )
    }else if(fundtype === "trading"){ 
      return ({
        pathname: "/app/tradings/old/tradingold",
        state: {
          fundtype: fundtype
        }}
      )
    }else if(fundtype === "index"){ 
      return ({
        pathname: "/app/tradings/old/indexold",
        state: {
          fundtype: fundtype
        }}
      )
    }else if(fundtype === "liquidity"){ 
      return ({
        pathname: "/app/tradings/old/liquidityold",
        state: {
          fundtype: fundtype
        }}
      )
    }else if(fundtype === "trading"){ 
      return ({
        pathname: "/app/tradings/old/tradingold",
        state: {
          fundtype: fundtype
        }}
      )
    }
  }

  const getActWgt = (target) => {

    const val = xWinLib.getTokenActiveWeight(target, fundData.fundvalue)
    if(val < 0){
        return (
            <Typography className={classes.negativeNum}>
              {format({prefix: '', suffix: '%'})(xWinLib.roundTo(val,2))}
            </Typography>
            )
    }else{
      return (
        <Typography className={classes.secondaryHeading}>
          {format({prefix: '', suffix: '%'})(xWinLib.roundTo(val,2))}
        </Typography>
        )
    }
    
  }

  const getPriceChart = () => {

    if(port === undefined) return 

    return (
          <Card>
            <CardHeader
              action={
                <Link 
                  component={RouterLink}
                  to={
                    getBackHistory()
                  }
                >
                <IconButton 
                  className={classes.mainHeading}
                  aria-label="settings">
                  <ArrowBackIcon />
                  
                </IconButton>
                </Link>
              }
              avatar={
                <Avatar
                  variant={"square"}
                  className={classes.avatar}
                  src={xWinLib.getLogo(port.logo)}
                />
              }
              className={classes.primaryHeading}
              title={getHeader()}
              subheader= {getSubHeader()}
              titleTypographyProps={{variant:'h3' }}
            />
              <Box display="flex" alignItems="center" justifyContent={"center"}>
                <ButtonGroup>
                <Button
                    disabled={fundtype == "liquidityv2" || port.contractaddress === "0xf05026B86E497a726aA4d52c493aE4519df582BF" || userData.selectedWallet === "" || xWinUtils.shouldDisable(fundtype)}
                    size="medium"
                    color="primary"
                    variant="contained"
                    onClick={handleClickSubsDirect}
                  >
                    Deposit
                   </Button>
                  <Button
                    disabled={userData.selectedWallet === ""}
                    size="medium"
                    color="secondary"
                    variant="contained"
                    onClick={handleClickRedeem}
                  >
                    Redeem
                  </Button>
                  </ButtonGroup>
              </Box>
                  
              {getManagerRebalanceButton()}

              <ApexLineChart mainData={mainData} fundData={fundData} />
          </Card>
       
      )
  }

  const getTargets = (targets) => {
    if(targets === undefined ) return ""

    return (
      <Card>
      <Table size="small">
        <TableHead>
        <TableRow>
              <TableCell className={classes.cellHideSmall}>
                
              </TableCell>
              <TableCell>
                Token
              </TableCell>
              <TableCell>
                Target %
              </TableCell>
              <TableCell className={classes.tablecell}>
                Fund %
              </TableCell>
              <TableCell className={classes.tablecell}>
                Active %
              </TableCell>
              <TableCell >
                Quantity
              </TableCell>
              <TableCell className={classes.tablecell}>
                Token Address
              </TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
        {
          Object.keys(targets).map((key, i) => (
            <TableRow hover key={key}>
              <TableCell className={classes.cellHideSmall}>
                <Tooltip title={xWinLib.getTokenName(tokensMaster, targets[key].address, networkName.toString().toLowerCase())} arrow={true}>
                  {xWinUtils.getIcons(xWinLib.getTokenName(tokensMaster, targets[key].address, networkName.toString().toLowerCase()))}
                </Tooltip>
              </TableCell>
              <TableCell>
                <Typography variant="body1" className={classes.secondaryHeading}>
                  {xWinLib.getTokenName(tokensMaster, targets[key].address, networkName.toString().toLowerCase())}
                </Typography>
              </TableCell>
              <TableCell>
              <Typography variant="body1" className={classes.secondaryHeading}>
              {format({prefix: '', suffix: '%'})(xWinLib.roundTo(targets[key].targetweight / 100,2))}
              </Typography>
              </TableCell>
              <TableCell align={"right"} className={classes.tablecell}>
              {
                format({prefix: '', suffix: '%'})(xWinLib.roundTo(xWinLib.getTokenWeight(targets[key], fundData.fundvalue),2))
              }
              </TableCell>
              <TableCell align={"right"} className={classes.tablecell}>
              {
                getActWgt(targets[key])
              }
              </TableCell>
              <TableCell align="right" className={classes.secondaryHeading}>
              {format({prefix: '', suffix: ''})(xWinLib.roundTo(targets[key].balance,5))}
              </TableCell>
              <TableCell align="center" className={classes.tablecell}>
              <Typography variant="body2" className={classes.secondaryHeading}>
                <a className={classes.link} href={xWinLib.getEtherLink(fundData.networkName, targets[key].address, "address")} target="blank">View</a>
              </Typography>
              </TableCell>
            </TableRow>
          ))
        }
        </TableBody>
        </Table>
      </Card>
    );
  }

  const getFundTarget = () =>{

    if(fundtype === "liquidity" || fundtype === "liquidity2" || fundtype === "liquidityv2") return ""
    return (
      <Grid
          item
          lg={8}
          md={12}
          xl={8}
          xs={12}
        >
          {getTargets(fundData.tokenNames)}  
        </Grid>
    )
  }

  const getPieChart = ()=>{

    if(fundtype === "liquidity" || fundtype === "liquidity2" || fundtype === "liquidityv2") return ""

    return (
          <TargetAllocation
            fundData={fundData}
            tokensMaster={tokensMaster}
            networkName={networkName}
          />
    )
  }

  const getManagerRebalanceButton = () => {
  
    if(userData.selectedWallet !== fundData.fundmanager) return ""
    return (
      <Box display="flex" alignItems="center" justifyContent={"center"}>      
        <ButtonGroup>
            <Tooltip title={"Fund owner uses this to setup target when first launch the vaults"} arrow={true}>
              <Button
                  // fullWidth
                  color="primary"
                  variant="outlined"
                  onClick={handleClickCreateTarget}
                  // startIcon={<SettingsIcon/>}
                >
                  Setup
                </Button>
            </Tooltip>
          
                <Tooltip title={"Use this to move any name not in the benchmark i.e. airdrop names. It swapped it to BNB token if the token pairs is available in pancakeswap"} arrow={true}>
                <Button
                  // fullWidth
                  size="medium"
                  color="primary"
                  variant="outlined"
                  onClick={handleClickMoveNonBM}
                >
                  Move Non-BM
                </Button>
                </Tooltip>
                <Tooltip title={"Use this to add/delete/update benchmark name. It updates weight and perform swap according to new target weight"} arrow={true}>
                <Button
                  // fullWidth
                  size="medium"
                  color="primary"
                  variant="outlined"
                  onClick={handleClickRebalanceAllInOne}
                >
                  Rebalance
                </Button>
              </Tooltip>
        </ButtonGroup>
      </Box>
    )
  }

  const handleClickSubsDirect = event => {
    
    if(port.showTnC && !xWinUtils.getAgreeTermConditionByPortfolio(port.symbol)){
      if(refTermCondition.current != null)  refTermCondition.current.handleOpen(port.symbol)
      return
    }
    refsubsdirect.current.handleOpen();
  }

  const handleClickRedeem = event => {
    refredeem.current.handleOpen();
  }

  const handleClickCreateTarget = event => {
    refcreatetarget.current.handleOpen();
  }

  const handleClickRebalanceAllInOne = event => {
    refrebalance.current.handleOpen();
  }

  const handleCallback = (refresh) =>{
    if(refresh){
      confetti()
      loadFundData()
    } 
  }

  const handleCallbackTermCondition = (refresh) =>{
    if(refresh) refsubsdirect.current.handleOpen()
  }

  const handleClickMoveNonBM = event => {
    refMoveNonBM.current.handleOpen();
  }


  return (
    <Grid container spacing={2}>
        <TermCondition 
          ref={refTermCondition} 
          parentCallback={handleCallbackTermCondition}
        />
        <MoveNonBM 
          ref={refMoveNonBM} 
          selectedport={port}
          fundData={fundData}
          tokensMaster={tokensMaster}
          userData={userData}
          myxWinProtocol={myxWinProtocol}
          globalWeb3={theWeb3}
          networkName={networkName}
          parentCallback={handleCallback}
          />
        <Rebalance 
          ref={refrebalance}
          selectedport={port}
          fundData={fundData}
          tokensMaster={tokensMaster}
          userData={userData}
          myxWinProtocol={myxWinProtocol}
          globalWeb3={theWeb3}
          networkName={networkName}
          parentCallback={handleCallback}
          />
        <CreateTarget
          ref={refcreatetarget} 
          //port={port}
          selectedport={port}
          fundData={fundData}
          tokensMaster={tokensMaster}
          userData={userData}
          myxWinProtocol={myxWinProtocol}
          // gasPrices={gasPrices}
          globalWeb3={theWeb3}
          networkName={networkName}
          parentCallback={handleCallback}
          />
        <Redeem 
          ref={refredeem} 
          port={port}
          fundData={fundData}
          tokensMaster={tokensMaster}
          userData={userData}
          myxWinProtocol={myxWinProtocol}
          // gasPrices={gasPrices}
          globalWeb3={theWeb3}
          networkName={networkName}
          parentCallback={handleCallback}
          />
        <Subscribe 
          ref={refsubsdirect} 
          port={port}
          fundData={fundData}
          tokensMaster={tokensMaster}
          userData={userData}
          myxWinProtocol={myxWinProtocol}
          // gasPrices={gasPrices}
          globalWeb3={theWeb3}
          networkName={networkName}
          parentCallback={handleCallback}
          />
      {/* <Widget 
          className={classes.widgetLayout}
          title="Fund Details"
          disableWidgetMenu
          enableWalletDisplay
          selectedWallet={userData.selectedWallet}
        >  */}
        <Grid container spacing={3}>
        <Grid item lg={12} sm={12} xs={12}>
          {getPriceChart()}
        </Grid>
        <Grid item lg={3} sm={6} xs={12} className={classes.hoverHighlight}>
          <UnitPrice loading={loading} fundData={fundData}/>
        </Grid>
        <Grid item lg={3} sm={6} xs={12} className={classes.hoverHighlight}>
          <SupplyStats loading={loading} fundData={fundData}/>
        </Grid>
        <Grid item lg={3} sm={6} xs={12} className={classes.hoverHighlight}>
          <ManagerStats loading={loading} fundData={fundData}/>
        </Grid>
        <Grid item lg={3} sm={6} xs={12} className={classes.hoverHighlight}>
          <FundReturns fundData={fundData} priceData={priceData}/>
        </Grid>
      
        
        <Grid
          item lg={4} md={12} xl={4} xs={12}
          className={classes.hoverHighlight}>
          {getPieChart()}
        </Grid>
        {getFundTarget()}

        <Grid item lg={12} sm={12} xs={12}>
          {getFundDesc()}
        </Grid>
        </Grid>
        {/* </Widget>    */}
    </Grid>
    
  );
}

export default withRouter(FundDetail);
