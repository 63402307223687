import React, { useEffect } from "react";
import { withRouter, useParams } from 'react-router-dom'
import useStyles from "./styles";


const ReferralAction = props => {
  const classes = useStyles();
  const { id } = useParams()

  useEffect(() => {
    localStorage.setItem('xwinreferral-id', id);
    props.history.replace('/app/dashboard')
  }, [id])

  return (
    <div className={classes.root} >
    {/* {localStorage.getItem('xwinreferral-id')} */}
    </div>
  );
};

export default withRouter(ReferralAction);
