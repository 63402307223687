import React, { useState, useEffect, useContext, useRef  } from "react";
import xWinLib from '../../xWinLib';
import xWinUtils from '../../xWinUtils';
import xWinProtocolV5 from '../../abi/xWinProtocolV5.json'
import Farmlist from "./components/Farmlist";
import { useParams } from 'react-router-dom'

// components
import { Typography } from "../../components/Wrappers";
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import PanoramaFishEyeIcon from '@material-ui/icons/PanoramaFishEye';
import ArchiveIcon from '@material-ui/icons/Archive';
import {AppContext}  from '../../context/AppProvider';
import SnackbarMessage from '../snackbarmessage/SnackbarMessage'

import {
  Grid,
  Tooltip,
  Container,
} from "@material-ui/core";
import InsertChartIcon from '@material-ui/icons/InsertChart';
import PieChartIcon from '@material-ui/icons/PieChart';
// components
import Widget from "../../components/Widget/Widget";
import useStyles from "./styles";

export default function Farming(props) {
  const classes = useStyles();
  const { portfolios, fundtype, category } = props;
  const [networkName, setNetworkName] = useState("")
  const [filterPortfolios, setFilterPortfolios] = useState([])
  const [filterMyPortfolios, setFilterMyPortfolios] = useState([])
  const [filterCategory, setFilterCategory] = useState("all")
  const [filterPortfoliosByCategory, setFilterPortfoliosByCategory] = useState([])
  const [showMyFund, setShowMyFund] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [prices, setPrices] = useState([])
  const refsnackbar = useRef(null);
  
  const { ETHBalance, isConnected, network, account, theWeb3, web3static, globalProvider, refreshAccount } = useContext(AppContext);
  
  const { id } = useParams()
  
  // if(globalProvider !== undefined){
  //   globalProvider.on("accountsChanged", (accounts) => {
  //     loadWeb3()
  //   });
  //   globalProvider.on("chainChanged", (accounts) => {
  //     if(network !== "56") showSnackBar("info", "Please connect to BSC Mainnet")
  //   });
    
  // }
  
 
  const showSnackBar = (status, msg) => {
    refsnackbar.current.handleOpen(status, msg);
  }
  
  useEffect(() => {
    const controller = new AbortController();
    loadWeb3()
    return () => {
      controller.abort();
    }
  }, [portfolios, id, account]);

  const loadWeb3 = async () => {

    
    if(theWeb3 === undefined) return;
    
    // setLoading(true)
    let filterEnv = await xWinLib.getNetwork(theWeb3)
    setNetworkName(filterEnv)
    


    let filterport = []
    let myPorts = []
    let searchExcludeDiscontinuePort
    if(filterEnv !== "bscmain"){
      
      filterport = portfolios.filter(x=>x.env.toLowerCase() === "bscmain" && x.fundtype === fundtype)
      searchExcludeDiscontinuePort = filterport.filter(x=>x.category !== "discontinued")
      setFilterPortfoliosByCategory(searchExcludeDiscontinuePort)
    }else{
      
      filterport = portfolios.filter(x=>x.env.toLowerCase() === filterEnv.toLowerCase() && x.fundtype === fundtype)
      if(id === "all"){
        searchExcludeDiscontinuePort = filterport.filter(x=>x.category !== "discontinued")
        setFilterPortfoliosByCategory(searchExcludeDiscontinuePort)
        searchExcludeDiscontinuePort.sort(xWinLib.compareValues('category', 'asc'));
      }else{
        let searchPort = filterport.filter(x=>x.contractaddress === id)
        setFilterPortfoliosByCategory(searchPort)      
      }
    }

    let allprices = await xWinUtils.getAllPrices(web3static)
    setPrices(allprices)
    setFilterPortfolios(filterport)
    setFilterMyPortfolios(myPorts)
    // setLoading(false)


    
  }

  const displayPortfolios = () => {
    
    let funds = filterPortfolios
    funds = filterPortfoliosByCategory
    funds.sort(xWinLib.compareValues('category', 'asc'));
    return (
      funds.map(port => (
        
           <Farmlist 
            port={port}
            portfolios={funds}
            globalWeb3={theWeb3}
            web3static={web3static}
            account={account}
            selectedWallet={account}
            networkName={networkName}
            showMyFund={showMyFund}
            fundtype={fundtype}
            refreshAccount={refreshAccount}
            prices={prices}
            /> 
           
        )) 
    )    
  }

  const handleClickFilter = (event, value) => {
    
    if(value === null) return
    setFilterCategory(value)
    let tempFilter = filterPortfoliosByCategory
    if(value !== "all") {
      tempFilter = filterPortfolios.filter(x=>x.category === value)
    }else{
      tempFilter = filterPortfolios.filter(x=>x.category !== "discontinued")
    }
    setFilterPortfoliosByCategory(tempFilter)
  };

  const getSubHeader = () => {

    return (
      <div>
      <ToggleButtonGroup variant="contained" size="small" value={filterCategory} exclusive 
        onChange={handleClickFilter}
        >
      <Tooltip title={"All"} arrow={true}>
        <ToggleButton value={"all"} style={{outlineColor: 'green', outlineWidth: '1px', outlineStyle: 'solid', margin: '2px'}} >
          <Typography className={classes.titleWhite}>
            {"All"}
          </Typography>
        </ToggleButton>
      </Tooltip>
      <Tooltip title={"Yield"} arrow={true}>
          <ToggleButton value={"liquidityv2"} style={{outlineColor: 'green', outlineWidth: '1px', outlineStyle: 'solid', margin: '2px'}} >
            <AccountBalanceIcon color="secondary"/>
          </ToggleButton>
      </Tooltip>
      <Tooltip title={"Index V2"} arrow={true}>
          <ToggleButton value={"indexv2"} style={{outlineColor: 'green', outlineWidth: '1px', outlineStyle: 'solid', margin: '2px'}}>
            <InsertChartIcon color="secondary"/>
          </ToggleButton>
      </Tooltip>
      <Tooltip title={"Trading V2"} arrow={true}>
        <ToggleButton value={"tradingv2"} style={{outlineColor: 'green', outlineWidth: '1px', outlineStyle: 'solid', margin: '2px'}}>
          <PieChartIcon color="secondary" />
        </ToggleButton>
      </Tooltip>
      <Tooltip title={"Other Farm"} arrow={true}>
          <ToggleButton value={"others"} style={{outlineColor: 'green', outlineWidth: '1px', outlineStyle: 'solid', margin: '2px'}}>
            <PanoramaFishEyeIcon color="secondary" />
          </ToggleButton>
      </Tooltip>
      <Tooltip title={"Discontinued"} arrow={true}>
          <ToggleButton value={"discontinued"} style={{outlineColor: 'green', outlineWidth: '1px', outlineStyle: 'solid', margin: '2px'}}>
          <ArchiveIcon color="secondary" />
          </ToggleButton>
      </Tooltip>
      
    </ToggleButtonGroup>
      
    </div>
    )
  }

  return (
    <Container>
      <SnackbarMessage ref={refsnackbar} />
      <Grid container spacing={0}>
        <Grid item md={12}>
        {xWinUtils.getProgress(loading)}
          <Widget
            spacing={0}
            className={classes.newsHeader}
            title={fundtype.toUpperCase()}
            disableWidgetMenu
            inheritHeight
            enableWalletDisplay
            selectedWallet={account}
            globalWeb3={theWeb3}
            //searchField
          >
            {fundtype === "staking" ? "" : getSubHeader()}
            {displayPortfolios()}
          </Widget>
        </Grid>
      </Grid>
    </Container>
  );
}
