import React from "react";
import xWinLib from '../../../xWinLib';
import format from 'format-number';

import {
  Grid,
  Box,
  CardContent
} from "@material-ui/core";

// styles
import useStyles from "../styles";
import Widget from "../../../components/Widget/Widget";
import { Typography } from "../../../components/Wrappers/Wrappers";
import Dot from "../../../components/Sidebar/components/Dot";
import xWinUtils from "../../../xWinUtils";

export default function ManagerStats(props) {
  var classes = useStyles();
  const { fundData, loading } = props;
  
  return (
    <Widget
      disableWidgetMenu
      bodyClass={classes.fullHeightBody}
      className={classes.card}
      >
        {xWinUtils.getProgress(loading)}
        <CardContent>
        <Grid item>
          <Typography
              color="text"
              colorBrightness={"hint"}
              variant={"caption"}
              style={{ marginRight: 5 }}
            >
              Manager Exit Fee
            </Typography>
          <Box display="flex" alignItems="center" justifyContent={"center"}>
          <Dot size='medium' color="primary" />
          <Typography
            size="md"
            weight={"medium"}
            style={{ marginLeft: 8 }}
          >
            {format({prefix: '', suffix: '%'})(fundData.managerFee)}
          </Typography>
        </Box>
        </Grid>
        <Grid item>
            <Typography
              color="text"
              colorBrightness={"hint"}
              variant={"caption"}
              style={{ marginRight: 5 }}
            >
              Manager Wallet
            </Typography>
          <Box display="flex" alignItems="center" justifyContent={"center"}>
          
          <Dot size='medium' color="warning" />
          <Typography 
            color="text"
            colorBrightness={"hint"}
            variant={"caption"}
            style={{ marginLeft: 8 }}
            >
            <a className={classes.link} href={xWinLib.getEtherLink(fundData.networkName, fundData.fundmanager, "address")} target="blank">View on BSCScan</a>

          </Typography>
        </Box>
        </Grid>
      </CardContent>
  </Widget>
    
  );
}
