import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => (
  {
    hoverHighlight: {
      "&:hover": {
        boxShadow: theme.customShadows.widgetWide
      },
    },
    expanded: { 
      backgroundColor: theme.palette.background.default,
    },
    expansion: {
      backgroundColor: theme.palette.primary.light,
      color: 'white '
    },
    column1: {
      flexBasis: '10.00%',
      verticalAlign: 'center',
    },
    column2: {
      flexBasis: '20.00%',
      verticalAlign: 'center',
    },
    column3: {
      flexBasis: '40.00%',
      verticalAlign: 'center',
    },
    column4: {
      flexBasis: '20.00%',
      verticalAlign: 'center',
    },
    column5: {
      flexBasis: '25.00%',
      verticalAlign: 'bottom',
    },
    column6: {
      flexBasis: '40.00%',
      verticalAlign: 'bottom',
    },
    columnHide: {
      [theme.breakpoints.down('xs')]: {
        display: "none"
      }
    },
    columnShow: {
      display: "none",
      [theme.breakpoints.down('xs')]: {
        display: "inline"
      }
    },
    helper: {
      borderLeft: `2px solid ${theme.palette.divider}`,
      padding: theme.spacing(1, 2),
    },
    newsHeader: {
      textDecoration: 'none',
      textTransform: 'uppercase',
      color: '#536DFE',
      padding: theme.spacing(0, 0),
    },
    subTitle: {
      //textDecoration: 'none',
      //textTransform: 'uppercase',
      //fontWeight: 100,
      color: '#536DFE',
    },
    avatar: {
      height: 50,
      width: 50
    },
    card: {
      minWidth: "33%",
      backgroundImage: props => `url(${props.card})`,
      backgroundSize: "cover",
      backgroundPosition: "50%",
      color: "#fff",
      padding: theme.spacing(2,2,2,5),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(0,0,0,0),
      }
    },
    cardhide: {
      minWidth: "33%",
      backgroundImage: props => `url(${props.card})`,
      backgroundSize: "cover",
      backgroundPosition: "50%",
      color: "#fff",
      padding: theme.spacing(2,2,2,5),
      [theme.breakpoints.down('xs')]: {
        display: "none",
        padding: theme.spacing(0,0,0,0),
      }
    },
    cardMedia: {
      minWidth: "100%",
    },
    media: {
      height: "140px",
    },
    starIcon: {
      color: "#ffc247",
    },
    cardActions: {
      padding: theme.spacing(2)
    },
    snackbarWarning: {
      backgroundColor: theme.palette.warning.main,
    },
    snackbarSuccess: {
      backgroundColor: theme.palette.success.main,
      color: "black"
    },
    link: {
      display: 'flex',
      alignItems: 'center',
      //color: theme.palette.primary.main,
      fontWeight: 'bold',
      color: theme.palette.success.main,
      visited: {
        color: theme.palette.success.main,
      }
    },
  }
))