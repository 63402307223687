import React, { useContext, useEffect, useState } from 'react';
import classnames from 'classnames';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Grid,
  Typography,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { AppContext } from '../../../context/AppProvider';
import useStyles from '../styles';
import xWinUtils from '../../../xWinUtils';
import { useTheme } from '@material-ui/styles';

const LuckyDrawFaq2 = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  
  const { account, isConnected, theWeb3 } = useContext(AppContext);
  const [luckyDrawParameter, setLuckyDrawParameter] = React.useState({ 
    XWINLP: 5, 
    XWIN: 400, 
    numberOfPrizes: 3
  });
  
  useEffect(() => {
    if (props.protocol) {
      const controller = new AbortController();

      load();

      return () => {
        controller.abort();
      };
    }
  }, [props.protocol]);

  const load = async () => {
    if (! isConnected) return;

    // if (theWeb3 === undefined) return;

    // if (account === '---') return;

    if (! props.protocol) return;

    let minimalBalanceLP = await props.protocol.methods.minimalBalanceLP().call();
    let minimalBalanceXWIN = await props.protocol.methods.minimalBalanceXWIN().call();
    let numberOfPrizes = await props.protocol.methods.numberOfPrizes().call();
    setLuckyDrawParameter({
      XWINLP: Number(xWinUtils.convertFromWei(theWeb3, minimalBalanceLP)), 
      XWIN: Number(xWinUtils.convertFromWei(theWeb3, minimalBalanceXWIN)),
      numberOfPrizes: numberOfPrizes
    })  
  };

  return (
    <div className={classnames('py-5 px-3', theme.palette.type === "dark" ? classes.luckyDrawFaqContainer : classes.luckyDrawFaqContainerLight)}>
      <Grid container justify="center">
        <Grid item lg={6} md={8} sm={12} className="px-5">
          <Typography variant="h3" className="py-5 d-flex justify-content-center text-center">FAQ</Typography>

          <Accordion>
            <AccordionSummary className={classes.borderBottom} expandIcon={<ExpandMoreIcon />}>
              <Typography>1. How to participate in xWin FREE Lucky Draw?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Container>
              <Typography variant={'body2'} className={classes.subTitle}>
                  It's free and simple. As long as you have met the criteria as follow in your wallet:</Typography>
                <ul className="my-2">
                  <li><Typography variant={'body2'} className={classes.subTitle}>
                    {luckyDrawParameter.XWINLP} XWIN-BNB LP token in xWIN Farm OR</Typography></li>
                  <li><Typography variant={'body2'} className={classes.subTitle}>
                  {luckyDrawParameter.XWIN} XWIN tokens in xWIN Farm</Typography></li>
                </ul>
                <Typography variant={'body2'} className={classes.subTitle}>
                Then, click on "Register For Lucky Draw" button and sign the transaction. That's it. You have to register only once.</Typography>
              </Container>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary className={classes.borderBottom} expandIcon={<ExpandMoreIcon />}>
              <Typography>2. Do I have to register for each round of lucky draw?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Container>
              <Typography variant={'body2'} className={classes.subTitle}>
                No, one-off registration</Typography>
              </Container>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary className={classes.borderBottom} expandIcon={<ExpandMoreIcon />}>
              <Typography>3. How frequent does the lucky draw take place?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Container>
              <Typography variant={'body2'} className={classes.subTitle}>
                We are running lucky draw on weekly basis. It is run randomly on each week. It could be Monday for week 36th or Friday for week 37th. It is totally random.</Typography>
              </Container>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary className={classes.borderBottom} expandIcon={<ExpandMoreIcon />}>
              <Typography>4. How is the random number picked in the smart contract?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Container>
              <Typography variant={'body2'} className={classes.subTitle}>
                xWIN is working with Chainlink VRF to generate the random number on-chain. The numbers are totally random without any inteference from any third parties.</Typography>
              </Container>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary className={classes.borderBottom} expandIcon={<ExpandMoreIcon />}>
              <Typography>5. How many winners for each round of lucky draw?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Container>
              <Typography variant={'body2'} className={classes.subTitle}>
                The algorithm picks {luckyDrawParameter.numberOfPrizes} winners each time.</Typography>
              </Container>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary className={classes.borderBottom} expandIcon={<ExpandMoreIcon />}>
              <Typography>6. What is the prizes?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Container>
              <Typography variant={'body2'} className={classes.subTitle}>
                  You will win XWIN tokens each time, divided equally with the {luckyDrawParameter.numberOfPrizes} winners.
                  The quantity of the XWIN token is varied based on the accumulation from the xWIN protocol pools from the last drawn to the next round of the draw.
                </Typography>
              </Container>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary className={classes.borderBottom} expandIcon={<ExpandMoreIcon />}>
              <Typography>7. Any Fee incur?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Container>
              <Typography variant={'body2'} className={classes.subTitle}>
              Absolutely free. 10% of the prizes won by the users will be deducted and burned accordingly.</Typography>
              </Container>
            </AccordionDetails>
          </Accordion>
          <div className="py-5" />
        </Grid>
      </Grid>
    </div>
  );
};

export default LuckyDrawFaq2;
