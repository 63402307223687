import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => (
  {
    
    div: {
      padding: theme.spacing(1),
      [theme.breakpoints.up('xl')]: {
        padding: theme.spacing(0, 50, 0, 50)
      }
    },
    expanded: { backgroundColor: theme.palette.background.default },
    expansion: {
      backgroundColor: theme.palette.primary.light,
      color: 'white '
    },
    column1: {
      flexBasis: '10.00%',
      verticalAlign: 'center',
    },
    column2: {
      flexBasis: '20.00%',
      verticalAlign: 'center',
    },
    column3: {
      flexBasis: '25.00%',
      verticalAlign: 'center',
    },
    column4: {
      flexBasis: '20.00%',
      verticalAlign: 'center',
    },
    column5: {
      flexBasis: '25.00%',
      verticalAlign: 'bottom',
    },
    column6: {
      flexBasis: '50.00%',
      verticalAlign: 'bottom',
    },
    columnHide: {
      [theme.breakpoints.down('xs')]: {
        display: "none"
      }
    },
    columnShow: {
      [theme.breakpoints.up('xs')]: {
        display: "none"
      }
    },
    helper: {
      borderLeft: `2px solid ${theme.palette.divider}`,
      padding: theme.spacing(1, 2),
    },
    table: {
      padding: theme.spacing(30),
      [theme.breakpoints.up('xs')]: {
        padding: theme.spacing(1)
      }
    },
    newsHeader: {
      textDecoration: 'none',
      textTransform: 'uppercase',
      color: '#536DFE',
    },
    subTitle: {
      //textDecoration: 'none',
      //textTransform: 'uppercase',
      fontWeight: 100,
      color: '#536DFE',
    },
    avatar: {
      height: 50,
      width: 50
    },
    snackbarWarning: {
      backgroundColor: theme.palette.warning.main,
    },
    snackbarSuccess: {
      backgroundColor: theme.palette.success.main,
      color: "black"
    }
  }
))