import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
    root: {
        maxWidth: 400,
        flexGrow: 1
      },
      header: {
        display: "flex",
        alignItems: "center",
        height: 50,
        paddingLeft: theme.spacing(4),
        backgroundColor: theme.palette.background.default
      },
      img: {
        height: 255,
        display: "block",
        maxWidth: "100%",
        overflow: "hidden",
        width: "100%"
      },
    avatar: {
        height: 64,
        width: 64
      },
    card: {
        padding: theme.spacing(2,2),
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(1,1)
        },
        minHeight: 300,
      },
    primaryHeading: {
        color: theme.palette.primary.main,
        fontWeight: 100,
    },
    widgetLayout: {
      margin: '0 0 32px 0',
    },
    loaderWidgetLayout: {
      marginTop: '32px',
    },
    autoloadWidgetLayout: {
      margin: 0,
    },
    newsList: {
      marginBottom: 0,
      paddingLeft: 0,
    },
    listRow: {
      display: 'flex',
      listStyle: 'none',
      boxSizing: 'content-box',
      borderTop: `2px solid rgba(185, 185, 185, 0.3)`,
      padding: '12px 12px 12px 0',
      cursor: 'pointer',
      '&:last-child': {
        height: 'auto'
      }
    },
    iconBlock: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 60,
      height: 60,
      '@media (max-width: 425px)': {
        display: 'none',
      }
    },
    icon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 30,
      width: 60,
      height: 60,
      borderRadius: '50%',
      color: '#fff',
    },
    newsItemInfo: {
      marginLeft: '1rem',
    },
    newsHeader: {
      textDecoration: 'none',
      //textTransform: 'uppercase',
      color: '#536DFE',
      fontWeight: 100,
    },
    timeBlock: {
      marginTop: 10,
      fontSize: '0.9rem',
      color: theme.palette.text.secondary,
      display: 'block',
    }
}));
