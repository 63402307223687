import React, { useState, forwardRef, useImperativeHandle, useRef } from "react";
import xWinLib from '../../xWinLib';
import xWinUtils from '../../xWinUtils';
import format from 'format-number';
import "react-toastify/dist/ReactToastify.css";
import SnackbarMessage from '../snackbarmessage/SnackbarMessage'

import { Typography, Button } from "../../components/Wrappers/Wrappers";
import CancelIcon from '@material-ui/icons/Cancel';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DeleteIcon from '@material-ui/icons/Delete';
import HomeIcon from '@material-ui/icons/Home';
import EditIcon from '@material-ui/icons/Edit';

import {
  CardHeader,
  IconButton,
  TableRow,
  Table,
  TableCell,
  TableBody,
  Card,
  Dialog,
  DialogContent,
  DialogActions,
  ButtonGroup,
  TextField,
  InputAdornment,
  Tooltip
} from "@material-ui/core";
// components
import useStyles from "./styles";


const Rebalance = forwardRef((props, ref) => {
  const { fundData, tokensMaster, userData, myxWinProtocol, symbols, portfolios, selectedport, networkName, ...rest } = props;
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [openAdd, setOpenAdd] = React.useState(false);
  const [positions, setPositions] = useState([])
  const [slippageAmt, setSlippageAmt] = React.useState(1);
  const refsnackbar = useRef(null);
  const showSnackBar = (status, msg) => {
    refsnackbar.current.handleOpen(status, msg);
  }
  
  const [state, setState] = useState({
    symbol: '',
    fundname : '',
    baseccy : '',
    manageraddress : ''
  });
  const [errMsg, setErrmsg] = React.useState("");
  
  const [ticker, setTicker] = useState({
    symbol: '',
    weight: 0
  })

  const getTotalWeight = () => {
    let total = 0
    positions.forEach(p => {
      total = total + parseFloat(p.weight)
    })
    return xWinLib.roundTo(total, 3);
  }

  const getTotalWeightExceptThis = (pos) => {
    let total = 0
    const filteredItems = positions.filter(t => t.symbol !== pos.symbol)
    filteredItems.forEach(p => {
      total = total + parseFloat(p.weight)
    })
    return total;
  }

  const handleClickSame = event => {
    event.preventDefault();

    let p = []
    let baseaddress = xWinLib.GetBNB_ADDRESS() //networkName.toString().toLowerCase() === "bsctest"? xWinLib.GetBNB_ADDRESS() : xWinLib.GetETH_ADDRESS()
    let ethToken = fundData.tokenNames.find(x=>x.address === baseaddress)
    fundData.tokenNames.forEach(token => {
      if(token.address !== baseaddress){
        let tkName = xWinLib.getTokenName(tokensMaster, token.address, networkName.toString().toLowerCase())
        let fundweight = xWinLib.getTokenWeightExcludeETH(token, fundData.fundvalue, ethToken)
        
        if(tkName !== "ETH" && tkName !== "BNB"){
          p.push({
            symbol: tkName,
            weight: fundweight, //token.targetweight / 100,
            taddress: token.address
          })
        }
      }
      
    });
    setPositions(p)
  }

  const handleClickSameBM = event => {
    event.preventDefault();

    let p = []
    let baseaddress = xWinLib.GetBNB_ADDRESS() //networkName.toString().toLowerCase() === "bsctest"? xWinLib.GetBNB_ADDRESS() : xWinLib.GetETH_ADDRESS()
    fundData.tokenNames.forEach(token => {
      if(token.address !== baseaddress){
        let tkName = xWinLib.getTokenName(tokensMaster, token.address, networkName.toString().toLowerCase())
        if(tkName !== "BNB"){
          p.push({
            symbol: tkName,
            weight: token.targetweight / 100, 
            taddress: token.address
          })
        }
      }
    });
    setPositions(p)
  }


  const handleClickAdd = event => {
    event.preventDefault();
    let result = positions.filter(t => t.symbol === state.symbol)
    if(result.length > 0){
      return
    }
    setErrmsg("")
    let existingWgt = getTotalWeight()
    let totalweight = parseFloat(existingWgt) + parseFloat(state.weight)
    if(totalweight > 100){
      setErrmsg("Not more than 100% weight")
      return
    }  

    let p = []
    positions.forEach(element => {
      p.push({
        symbol: element.symbol,
        weight: element.weight,
        taddress: element.taddress,
      })
    });

    let address = xWinUtils.getAddress(ticker, tokensMaster, networkName)
    
    p.push({
      symbol: ticker.symbol,
      weight: state.weight,
      taddress: address.mainAddress,
    })
    setPositions(p)
  };

  const handleChange = name => event => {
    
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const handleOpen = () => {
    setOpenAdd(true);
  }
  
  const handleCloseAdd = () => {
    setLoading(false)
    setOpenAdd(false)
  }

  useImperativeHandle(ref, () => {
    return {
      handleOpen: handleOpen
    };
  });

  
  const handleClickRebalanceAllInOne = async event => {
    event.preventDefault();
    
    let totalweight = getTotalWeight()
    if(parseFloat(totalweight) !== 100){
      showSnackBar("error", "Total Weight Must be 100%")
      return
    }

    setLoading(true)
    xWinLib.RebalanceAllInOne(
       myxWinProtocol, 
        selectedport.contractaddress,
        userData.selectedWallet,
        slippageAmt,
        positions
      )
      .then(res =>
        { 
          setLoading(false)
          showSnackBar("success", res)
          props.parentCallback(true);
        }
      )
      .catch(err => {
        setLoading(false)
        showSnackBar("error", err)
        props.parentCallback(false);
        }
      )
    
  };

  const handleSlippageChange = () => event => {
    if(event.target.value < 0) return
    setSlippageAmt(event.target.value)
  }
  
  const handleClickUpdate = (symbol) => event => {
    event.preventDefault();
    const selected = positions.find(t => t.symbol === symbol)
    var weight = prompt("Input Weight", selected.weight);
    if (weight == null) return
    
    let existingWgt = getTotalWeightExceptThis(selected)
    let totalweight = parseFloat(existingWgt) + parseFloat(weight)
    if(totalweight > 100){
      setErrmsg("Not more than 100% weight")
      return
    }  

    selected.weight = weight
    let p = []
    positions.forEach(element => {
      p.push({
        symbol: element.symbol,
        weight: element.weight,
        taddress: element.taddress,
      })
    });
    setPositions(p)

  }
  
  const handleClickDelete = (symbol) => event => {
    event.preventDefault();
    const filteredItems = positions.filter(t => t.symbol !== symbol)
    setPositions(filteredItems)
  }
  
  
  const getSetTarget = (selectedport) => {

    return (
      <div> 
          <Table 
            size="small" 
          >
          <TableBody>
          <Tooltip title="How much price difference willing to accept during the swap compared to market fair price" arrow='true'>
            <TableRow className={classes.tableRow}>
              <TableCell colspan={2}>
                  <TextField
                    type="number" 
                    className={classes.inputText}
                    label={'Slippage'}
                    margin="dense"
                    name="slippage"
                    onChange={handleSlippageChange()}
                    required
                    variant="outlined"
                    value={slippageAmt}
                    fullWidth
                    InputProps={{
                      startAdornment: <InputAdornment position="start">%</InputAdornment>,
                    }}
                  />
              </TableCell>
              <TableCell colspan={2}>
              <Typography
                color="text"
                colorBrightness={"hint"}
                variant={"caption"}
                style={{ marginRight: 5 }}
              >
              *** Increase slippage infavor to your trades
              </Typography>
              </TableCell>
            </TableRow> 
          </Tooltip>
          <TableRow>
            <TableCell colSpan={5}>
                <Typography variant="h5" className={classes.newsHeader}>
                  Update Target Weight: 
                </Typography>
              </TableCell>
            </TableRow> 
          {positions.map((p, i) => ( 
            <TableRow
                hover
              >
                <TableCell>
                  <Typography variant="h3" className={classes.secondaryHeading}>
                    {xWinUtils.getIcons(p.symbol)}
                  </Typography>
                </TableCell>
                <TableCell>
                    {p.symbol}
                </TableCell>
                <TableCell>
                    {format({prefix: '', suffix: '%'})(p.weight)}
                </TableCell>
                
                <TableCell align="left">
                  <Typography
                    color="text"
                    colorBrightness={"hint"}
                    variant={"caption"}
                    style={{ marginRight: 5 }}
                  >
                    {p.taddress} 
                  </Typography> 
                </TableCell>
                <TableCell>
                <ButtonGroup size="small" color="secondary" aria-label="large outlined primary button group">
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.button}
                    startIcon={<EditIcon />}
                    onClick={handleClickUpdate(p.symbol)}
                  >
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.button}
                    startIcon={<DeleteIcon />}
                    onClick={handleClickDelete(p.symbol)}
                  >
                  </Button>
                </ButtonGroup>
              </TableCell>
              </TableRow>
            ))}
            <TableRow className={classes.tableRow}>
                <TableCell>
                    {/* {getIcons(ticker)} */}
                </TableCell>
              <TableCell>
                <Autocomplete
                  id="symbol"
                  style={{ width: 80 }}
                  name="symbol"
                  options={xWinUtils.getSupportedSymbols(tokensMaster, networkName, selectedport)}
                  getOptionLabel={option => option.symbol}
                  onChange={(event, newValue) => {
                    setTicker({
                      symbol: newValue == null? "" : newValue.symbol
                    });
                  }}
                  renderInput={params => <TextField {...params} label="Token" margin="dense" variant="outlined" />}
                />
              </TableCell>
              <TableCell>
                <TextField
                  type="number" 
                  className={classes.inputText}
                  label={'Weight %'}
                  margin="dense"
                  name="weight"
                  onChange={handleChange("weight")}
                  required
                  variant="outlined"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">%</InputAdornment>,
                  }}
                />
              </TableCell>
              
              <TableCell>
                <TextField
                  className={classes.inputText}
                  fullWidth
                  label={'Token Address'}
                  margin="dense"
                  name="taddress"
                  value={xWinUtils.getAddress(ticker, tokensMaster, networkName).mainAddress}
                  required
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <HomeIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </TableCell>
              
              <TableCell>
                <ButtonGroup size="small" color="primary" aria-label="large outlined primary button group">
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.button}
                    startIcon={<AddCircleIcon />}
                    onClick={handleClickAdd}
                  >
                  {""}
                    
                  </Button>
                </ButtonGroup>
              </TableCell>
            </TableRow>
            </TableBody>
          </Table>
      </div>
    )
  }

  return (
    <Card>
      <SnackbarMessage ref={refsnackbar} />
      <Dialog 
          open={openAdd} 
          //onClose={handleCloseAdd} 
          aria-labelledby="form-dialog-title"
          //fullWidth={true}
          fullScreen={false}
          maxWidth = {"md"}
          >
            <CardHeader
              className={classes.CardTitle}
              action={
                <IconButton 
                  className={classes.title}
                  onClick={handleCloseAdd}
                  aria-label="settings">
                  <CancelIcon />
                </IconButton>
              }
              title={selectedport?.name}
            />
          <DialogContent>
          {xWinUtils.getProgress(loading)}
            {getSetTarget(selectedport)}              
          </DialogContent>
          <DialogActions>
          <Typography variant="h5" className={classes.negativeNum}>
            {errMsg}
          </Typography> 
              
            <ButtonGroup size="small" color="primary" aria-label="large outlined primary button group">
              <Button 
                onClick={handleClickSameBM} 
                color="secondary"
                variant="contained"
                startIcon={<AddCircleIcon />}>
                Original Target %
              </Button>
              <Button 
                onClick={handleClickSame} 
                color="secondary"
                variant="contained"
                startIcon={<AddCircleIcon />}>
                Fund %
              </Button>
              <Button 
                onClick={handleClickRebalanceAllInOne} 
                color="secondary"
                variant="contained"
                startIcon={<AddCircleIcon />}>
                Update and Rebalance
              </Button>
            </ButtonGroup>
          </DialogActions>
        </Dialog>
    </Card>
  );
});


export default Rebalance;