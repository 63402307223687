import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => (
  {
    root: {
      padding: theme.spacing(0,5),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1,1)
      },
    },
    whitetext: {
      color: theme.palette.white,
      backcolor: theme.palette.success.main,
    },
    negativeNum: {
      color: theme.palette.error.main
    },
    secondaryHeading: {
      backcolor: theme.palette.success.main,
      color: theme.palette.success.main,
    },
    mainHeading: {
      backcolor: theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
    expansion: {
      backgroundColor: theme.palette.primary.light,
      color: 'white '
    },
    newsHeader: {
      textDecoration: 'none',
      textTransform: 'uppercase',
      color: '#536DFE',
    },
    subTitle: {
      color: "#ffc247",
    },
    avatar: {
      height: 50,
      width: 50
    },
    columnHide: {
      [theme.breakpoints.down('xs')]: {
        display: "none"
      }
    },
    notificationItem: {
      marginTop: theme.spacing(2),
    },
    snackbarWarning: {
      backgroundColor: theme.palette.warning.main,
    },
    snackbarSuccess: {
      backgroundColor: theme.palette.success.main,
      color: "black"
    }
  }
))