import React, { useState, useEffect, useRef  } from "react";
import xWinLib from '../../../xWinLib';
import format from 'format-number';
import clsx from 'clsx';
// styles
import useStyles from "../styles";
import { Typography, Button, Link } from "../../../components/Wrappers/Wrappers";
import Dot from "../../../components/Sidebar/components/Dot";
import xWinUtils from '../../../xWinUtils';
import { withRouter } from "react-router-dom";
import "aos/dist/aos.css"
import SnackbarMessage from '../../snackbarmessage/SnackbarMessage'
import BEP20 from '../../../abi/BEP20.json'
import TermCondition from '../../termcondition/termcondition'
import confetti from 'canvas-confetti'
// import ReactInterval from 'react-interval'

import {
  Grid,
  Box,
  InputAdornment,
  IconButton,
  TextField,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  TableRow,
  Table,
  TableCell,
  Slide,
  Tooltip,
  Fade
} from "@material-ui/core";
import {
  ExpandMore as ExpandMoreIcon,
  Help as HelpIcon
} from "@material-ui/icons";
import appGlobalConfig from "../../../appGlobalConfig";

const PrivateVaultlist = props => {

  var classes = useStyles();
  const {globalWeb3, web3static, selectedWallet, phase2, ETHBalance } = props;
  const MAX_ALLOWANCE = "115792089237316195423570985008687907853269984665640";
  
  // if(globalProvider !== undefined){
  //   globalProvider.on("accountsChanged", (accounts) => {
  //     loadFundData()
  //   });
  // }

  let phase = phase2[0]
  
  const [loading, setLoading] = React.useState(false);
  const [loadingTxn, setLoadingTxn] = React.useState(false);
  const [depositAmount, setDepositAmount] = React.useState("");
  const [withdrawAmount, setWithdrawAmount] = React.useState("");
  const [userData, setUserData] = useState({
    "walletETHBalance" :  0, 
    "selectedWallet" : "",
    "userfundbal" : 0,
    "referral" : ""
  });
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  const refTermCondition = useRef(null);
  
  const refsnackbar = useRef(null);
  const showSnackBar = (status, msg) => {
    refsnackbar.current.handleOpen(status, msg);
  }

  useEffect(() => {
    const controller = new AbortController();
    loadFundData()
    return () => {
      controller.abort();
    }
  }, [selectedWallet]);
  
  async function loadVaultData() {

    if(globalWeb3 === undefined) return
    
    try {
      if(selectedWallet !== "---" && phase.winStake !== undefined){
        let isapprove = await getIsApproved(web3static, "10000000000000000000000000000000", selectedWallet, phase.port.contractaddress)
        const sourceToken = new web3static.eth.Contract(BEP20, phase.port.sourceaddress);
        const toToken = new web3static.eth.Contract(BEP20, phase.port.toaddress);
        let rate = await xWinUtils.getExchangeRate(web3static, phase.port.FromToken, phase.port.ToToken)
        let myUSDTBalance = await sourceToken.methods.balanceOf(selectedWallet).call()
        let contractUSDTBalance = await sourceToken.methods.balanceOf(phase.port.contractaddress).call()
        let contractToBalance = await toToken.methods.balanceOf(phase.port.contractaddress).call()
        let currBalStake = await phase.winStakeDisplay.methods.getTotalStakedBalance().call()
        let stakeInfoInUSD = await phase.winStakeDisplay.methods.getBeforeAfterStakedLPAmountInTokenA(phase.pid).call({from: selectedWallet})
        let originalStakedAmount = xWinUtils.convertFromWei(web3static, stakeInfoInUSD.originalStakedAmount.toString())
        let afterStakedAmount = xWinUtils.convertFromWei(web3static, stakeInfoInUSD.afterStakedAmount.toString())
        contractUSDTBalance = xWinUtils.convertFromWei(web3static, contractUSDTBalance.toString())
        contractToBalance = xWinUtils.convertFromWei(web3static, contractToBalance.toString())
        let userInfo = await phase.winStakeDisplay.methods.userInfo(phase.pid, selectedWallet).call()
        let entitledInterestXWIN = await phase.winStakeDisplay.methods.getXWINRewards(phase.xwinPID).call({from: selectedWallet})
        let stakeBal = xWinUtils.convertFromWei(web3static, userInfo?.amount?.toString())
        //setWithdrawAmount(stakeBal)
        setUserData({
          "walletETHBalance" :  ETHBalance, 
          "selectedWallet" : selectedWallet,
          "userstakebal" : stakeBal, 
          "entitledInterestXWIN" : xWinUtils.convertFromWei(web3static, entitledInterestXWIN.toString()),
          "lpTokenStakedAmount" : xWinUtils.convertFromWei(web3static, userInfo.lpTokenStakedAmount.toString()),
          "currentBalance" : xWinUtils.convertFromWei(web3static, currBalStake.toString()),
          "originalStakedAmount" : xWinUtils.convertFromWei(web3static, stakeInfoInUSD.originalStakedAmount.toString()),
          "afterStakedAmount" :  xWinUtils.convertFromWei(web3static, stakeInfoInUSD.afterStakedAmount.toString()),
          "EarnedUSDT" : parseFloat(afterStakedAmount) - parseFloat(originalStakedAmount) + parseFloat(contractUSDTBalance) + (parseFloat(contractToBalance) * parseFloat(1/rate)),
          "isapprove" : isapprove,
          "myUSDTBalance" : xWinUtils.convertFromWei(web3static, myUSDTBalance.toString()),
        })  
      }
        
    } catch (error) {
      console.log(error)
    }
  }

  const loadFundData = async () => {

    if(!globalWeb3) {
      
      setUserData({
        "walletETHBalance" :  0, 
        "selectedWallet" : "---",
        "userfundbal" : 0,
        "userstakebal" : 0,
        "mylpBalance" : 0,
      })  
      return
    }
    setLoading(true)
    loadVaultData()
    setLoading(false)
  }
  

  const getIsApproved = async (globalWeb3, subsAmount, wallet, port) => {
    
    if(subsAmount === undefined) return false;
    if(parseFloat(subsAmount) === NaN) return false;
    
    const subsAmtInWei = subsAmount * 10 **18 // globalWeb3.utils.toWei(subsAmount.toString())
    const sourceToken = new globalWeb3.eth.Contract(BEP20, phase.port.sourceaddress);
    let contractAllowance = await sourceToken.methods
    .allowance(wallet, phase.port.contractaddress)
    .call();
    
    return parseFloat(contractAllowance) > parseFloat(subsAmtInWei)  

  }

  
  const handleMaxWithdrawAmt = async event => {
    setWithdrawAmount(userData.userstakebal)
  }

  const handleMaxDepositAmt = async event => {
    setDepositAmount(userData.myUSDTBalance)
    let isapprove = await getIsApproved(web3static, "10000000000000000000000000", selectedWallet, phase.port.contractaddress)
    setUserData({
      ...userData,
      "isapprove" : isapprove
    })  
  }

  const handleAmtDepositChange = event => {
    event.preventDefault()
    const amount = parseFloat(event.target.value)
    setDepositAmount(amount)
  }

  const handleAmtWithdrawChange = event => {
    event.preventDefault()
    const amount = parseFloat(event.target.value)
    setWithdrawAmount(amount)
  }

  const handleClickDeposit = event => {
    
    if(!xWinUtils.getAgreeTermConditionByPortfolio(appGlobalConfig.mainnetxWinUSDTStaking)){
      if(refTermCondition.current != null)  refTermCondition.current.handleOpen(appGlobalConfig.mainnetxWinUSDTStaking)
      return
    }

    if(depositAmount <= 0){
      showSnackBar("error", "Please specify deposit amount")
      return
    }

    setLoadingTxn(true)
    const subsAmtInWei = globalWeb3.utils.toWei(depositAmount.toString())
    xWinLib.StakeDepositAsync(
      phase.winStake,
      userData,
      phase.pid,
      subsAmtInWei
      )
    .then(res =>
      { 
        console.log(res)
        setLoadingTxn(false)
        showSnackBar("success", res)
        setDepositAmount("")
        confetti()
        loadFundData()
      }
    )
    .catch(err => {
      setLoadingTxn(false)
      console.log(err)
      showSnackBar("error", err)
    }
    )
    
  };

  const handleHarvestXWIN = event => {
    event.preventDefault();
    
    setLoadingTxn(true)
    xWinLib.harvestXWINPrivateVaultAsync(
      phase.winStake,
      userData,
      phase.pid
      )
    .then(res =>
      { 
        console.log(res)
        setLoadingTxn(false)
        showSnackBar("success", res)
        confetti()
        loadFundData()
      }
    )
    .catch(err => {
      setLoadingTxn(false)
      console.log(err)
      showSnackBar("error", err)
    }
    )
    
  };

  const handleClickWithdraw = event => {
    event.preventDefault();
    
    if(withdrawAmount > userData.userstakebal){
      showSnackBar("error", "You withdraw more than you own")
      return
    }

    if(withdrawAmount <= 0){
      showSnackBar("error", "Please specify withdrawal amount")
      return
    }

    const wAmtInWei = web3static.utils.toWei(withdrawAmount.toString())
    setLoadingTxn(true)
    xWinLib.UnStakePrivateVaultAsync(
      phase.winStake,
      userData,
      phase.pid,
      wAmtInWei
      )
    .then(res =>
      { 
        console.log(res)
        setLoadingTxn(false)
        showSnackBar("success", res)
        confetti()
        loadFundData()
      }
    )
    .catch(err => {
      setLoadingTxn(false)
      console.log(err)
      showSnackBar("error", err)
    }
    )
    
  };

  const handleClickApprove = async event => {
    event.preventDefault();
    
    setLoadingTxn(true)
    
    const sourceToken = new globalWeb3.eth.Contract(BEP20, phase.port.sourceaddress);
    
    xWinLib.approveContract(sourceToken, MAX_ALLOWANCE, phase.port.contractaddress, selectedWallet, globalWeb3)
    .then(res => {
      setLoadingTxn(false)
      setUserData({
        ...userData,
        "isapprove" : true
      })  

    }
  )
  .catch(err => {
    setLoadingTxn(false)
    console.log(err)
  })
    
  };

  const handleCallbackTermCondition = (refresh) =>{
    if(refresh) handleClickDeposit()
  }

  const getExpansionDetails = () =>{
    if(phase.port.investorWallet != selectedWallet) {
      return (
        <ExpansionPanelDetails classes={classes.expansion}>
          <div className={clsx(classes.column6, classes.columnHide)}>
            <Grid item>
            </Grid>
          </div>
          
          <div className={classes.panel}>
            <Typography color={"primary"} variant={"caption"}>
            This is private vault only can be staked by private investor.  
            The strategy incurred up-front cost and xWIN entry fee of 
            &nbsp;<a className={classes.link}>{format({prefix: "", suffix: '%'})(xWinLib.roundTo(phase.entryFee, 2))}</a>
            &nbsp;. In addition to the APR earned from the PCS farm, you are entitled for the XWIN APR as much as
            &nbsp;<a className={classes.link}>{format({prefix: "", suffix: '%'})(xWinLib.roundTo(phase.xwinAPR, 2))}</a>
            &nbsp;<a className={classes.link}>{format({prefix: "", suffix: '%'})(phase.burnFee)}</a> of your XWIN rewards proceeds will be deducted and burned during the withdrawal. 
                
            </Typography>
          </div>
          <div className={clsx(classes.column6, classes.columnHide)}>
            <Grid item>
            </Grid>
          </div>
        </ExpansionPanelDetails>
      )
    }
    return(
      <ExpansionPanelDetails classes={classes.expansion}>
      <div className={clsx(classes.column6, classes.columnHide)}>
        <Grid item>
        </Grid>
      </div>
      
      <div className={classes.panel}>
      <Typography color={"primary"} variant={"caption"}>
            This is private vault only can be staked by private investor.  
            The strategy incurred up-front cost and xWIN entry fee of 
            &nbsp;<a className={classes.link}>{format({prefix: "", suffix: '%'})(xWinLib.roundTo(phase.entryFee, 2))}</a>
            &nbsp;. In addition to the APR earned from the PCS farm, you are entitled for the XWIN APR as much as 
            &nbsp;<a className={classes.link}>{format({prefix: "", suffix: '%'})(xWinLib.roundTo(phase.xwinAPR, 2))}</a>
            &nbsp;<a className={classes.link}>{format({prefix: "", suffix: '%'})(phase.burnFee)}</a> of your XWIN rewards proceeds will be deducted and burned during the withdrawal. 
                
            </Typography>
        <br/>
        <Table size="small">
          <TableRow>
              <TableCell className={classes.tableHightlightPositive}>
                <Typography
                  color="text"
                  colorBrightness={"hint"}
                  variant={"caption"}
                  style={{ marginRight: 5 }}
                  //noWrap
                >
                  Original Staked LP 
                </Typography>
                <Box display="flex" alignItems="center" justifyContent={"left"}>
                  <Dot size='medium' color="secondary" />
                  <Typography
                    size="md"
                    weight={"medium"}
                    style={{ marginLeft: 8 }}
                  >
                    {format({prefix: "", suffix: ''})(xWinLib.roundTo(userData.lpTokenStakedAmount, 4))}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell className={classes.tableHightlightPositive}>
                <Typography
                  color="text"
                  colorBrightness={"hint"}
                  variant={"caption"}
                  style={{ marginRight: 5 }}
                  //noWrap
                >
                  Current Balance LP
                </Typography>
                <Box display="flex" alignItems="center" justifyContent={"left"}>
                  <Dot size='medium' color="success" />
                  <Typography
                    size="md"
                    weight={"medium"}
                    style={{ marginLeft: 8 }}
                  >
                   {format({prefix: "", suffix: ''})(xWinLib.roundTo(userData.currentBalance, 4))}
                  </Typography>
                </Box>
              </TableCell>
          </TableRow>
          <TableRow>
              <TableCell className={classes.tableHightlightPositive}>
                <Typography
                  color="text"
                  colorBrightness={"hint"}
                  variant={"caption"}
                  style={{ marginRight: 5 }}
                  // noWrap
                >
                  PCS Cake APR:
                </Typography>
                
                <Box display="flex" alignItems="center" justifyContent={"left"}>
                  <Dot size='medium' color="info" />
                  <Typography
                    size="md"
                    weight={"medium"}
                    style={{ marginLeft: 8 }}
                  >
                    {format({prefix: "", suffix: '%'})(xWinLib.roundTo(phase.PCSAPR, 2))}
                    
                  </Typography>
                  &nbsp;
                  <Tooltip 
                      TransitionComponent={Fade}  
                      title={
                        <h4 style={{ color: "lightblue" }}>
                          {"PCS Cake Yield Only. Calculated based on real earning in LP Token. Display will be reset whenever a new deposit"}</h4>}
                        arrow={true}>
                      <HelpIcon color={"primary"}/>
                    </Tooltip>
                </Box>
              </TableCell>
              <TableCell className={classes.tableHightlightPositive}>
                <Typography
                  color="text"
                  colorBrightness={"hint"}
                  variant={"caption"}
                  style={{ marginRight: 5 }}
                  //noWrap
                >
                  Est {phase.port.FromToken} Earned
                </Typography>
                <Box display="flex" alignItems="center" justifyContent={"left"}>
                  <Dot size='medium' color="warning" />
                  <Typography
                    size="md"
                    weight={"medium"}
                    style={{ marginLeft: 8 }}
                  >
                    {format({prefix: "", suffix: ''})(xWinLib.roundTo(userData.EarnedUSDT, 3))}
                  </Typography>
                  &nbsp;
                  <Tooltip 
                      TransitionComponent={Fade}  
                      title={
                        <h4 style={{ color: "lightblue" }}>
                          {"You will see negative earning in the beginning due to the up-front cost for swapping and fee"}</h4>}
                        arrow={true}>
                      <HelpIcon color={"primary"}/>
                    </Tooltip>
                </Box>
              </TableCell>
          </TableRow>
          <TableRow>
              <TableCell className={classes.tableHightlightPositive}>
                <Typography
                  color="text"
                  colorBrightness={"hint"}
                  variant={"caption"}
                  style={{ marginRight: 5 }}
                  // noWrap
                >
                  xWIN APR:
                </Typography>
                <Box display="flex" alignItems="center" justifyContent={"left"}>
                  <Dot size='medium' color="info" />
                  <Typography
                    size="md"
                    weight={"medium"}
                    style={{ marginLeft: 8 }}
                  >
                    {format({prefix: "", suffix: '%'})(xWinLib.roundTo(phase.xwinAPR, 2))}
                    
                  </Typography>
                </Box>
              </TableCell>
              <TableCell className={classes.tableHightlightPositive}>
                <Typography
                  color="text"
                  colorBrightness={"hint"}
                  variant={"caption"}
                  style={{ marginRight: 5 }}
                  //noWrap
                >
                  Earned XWIN (Per block)
                </Typography>
                <Box display="flex" alignItems="center" justifyContent={"left"}>
                  <Dot size='medium' color="warning" />
                  <Typography
                    size="md"
                    weight={"medium"}
                    style={{ marginLeft: 8 }}
                  >
                    {format({prefix: "", suffix: ''})(xWinLib.roundTo(userData.entitledInterestXWIN, 3))}
                  </Typography>
                  <Button 
                    // fullWidth
                    disabled={!(selectedWallet == phase.port.investorWallet)}
                    onClick={handleHarvestXWIN}
                    variant="outline" size="small" color="primary">
                          {"Harvest"}
                  </Button>
                </Box>
              </TableCell>
          </TableRow>
          <TableRow>
              <TableCell className={classes.tableHightlightPositive}>
                <Typography
                  color="text"
                  colorBrightness={"hint"}
                  variant={"caption"}
                  style={{ marginRight: 5 }}
                  // noWrap
                >
                  Entry Fee
                </Typography>
                <Box display="flex" alignItems="center" justifyContent={"left"}>
                  <Dot size='medium' color="info" />
                  <Typography
                    size="md"
                    weight={"medium"}
                    style={{ marginLeft: 8 }}
                  >
                    {format({prefix: "", suffix: '%'})(phase.entryFee)}
                    
                  </Typography>
                </Box>
              </TableCell>
              <TableCell className={classes.tableHightlightPositive}>
                <Typography
                  color="text"
                  colorBrightness={"hint"}
                  variant={"caption"}
                  style={{ marginRight: 5 }}
                  // noWrap
                >
                  Exit Fee
                </Typography>
                <Box display="flex" alignItems="center" justifyContent={"left"}>
                  <Dot size='medium' color="info" />
                  <Typography
                    size="md"
                    weight={"medium"}
                    style={{ marginLeft: 8 }}
                  >
                    {format({prefix: "", suffix: '%'})(0)}
                    
                  </Typography>
                </Box>
              </TableCell>
              
          </TableRow>
          <TableRow>
              <TableCell colSpan={2}>
              
              <TextField
                //label={'Deposit Amount'}
                type="number" 
                margin="dense"
                name="depositamount"
                placeholder={0}
                onChange={handleAmtDepositChange}
                variant="outlined"
                value={depositAmount}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleMaxDepositAmt}
                        //onMouseDown={handleMouseDownPassword}>
                       > 
                       <Typography color={"primary"} variant={"caption"}>MAX</Typography>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button 
                fullWidth
                disabled={!(selectedWallet == phase.port.investorWallet)}
                onClick={userData.isapprove ? handleClickDeposit: handleClickApprove}
                variant="contained" size="small" color="primary">
                      {userData.isapprove ? "+": "Enable"}
              </Button>
              
               
              </TableCell>
          </TableRow>
          <TableRow>
              <TableCell colSpan={2} className={classes.tableHightlightPositive}>
              <TextField
                type="number" 
                margin="dense"
                name="withdrawamount"
                onChange={handleAmtWithdrawChange}
                placeholder={0}
                variant="outlined"
                value={withdrawAmount}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleMaxWithdrawAmt}
                        //onMouseDown={handleMouseDownPassword}>
                       > 
                       <Typography color={"primary"} variant={"caption"}>STAKED</Typography>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button 
                fullWidth
                disabled={!(selectedWallet == phase.port.investorWallet)}
                onClick={handleClickWithdraw}
                variant="contained" size="small" color="secondary">
                  -
              </Button>
               
              </TableCell>
          </TableRow>
        
        </Table>
      </div>
      
      <div className={clsx(classes.column6, classes.columnHide)}>
        <Grid item>
        </Grid>
      </div>
      </ExpansionPanelDetails>
  
    )
  }

  
  return (
    <div>
        <TermCondition 
          ref={refTermCondition} 
          parentCallback={handleCallbackTermCondition}
        />
        {/* <ReactInterval timeout={10000} enabled={true}
          callback={() => loadVaultData()} /> */}
        <SnackbarMessage ref={refsnackbar} />
        {xWinUtils.getProgress(loadingTxn)}
        
          <ExpansionPanel defaultExpanded={selectedWallet == phase.port.investorWallet} square={false}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                classes={{ expanded: classes.expanded }}
              >
                <div className={clsx(classes.column1)}>
                  {xWinUtils.getIcons(phase.port.logo)}
                  {/* <img src={xWinUtils.getIcons(phase.port.logo)} className={classes.avatar}/> */}
                </div>
                <div className={clsx(classes.column2, classes.columnHide, classes.helper)}>
                  <Typography>{phase.port.name}</Typography>
                </div>
                <div className={clsx(classes.column3, classes.columnHide, classes.helper)}>
                <Typography
                      color="text"
                      colorBrightness={"hint"}
                      variant={"caption"}
                      style={{ marginRight: 5 }}
                    >
                      TVL
                    </Typography>
                  <Box display="flex" alignItems="center" justifyContent={"left"}>
                  
                  <Typography
                    size="md"
                    weight={"medium"}
                    style={{ marginRight: 8 }}
                  >   
                    {xWinUtils.displayBalance(loading, format({prefix: "$", suffix: ""})(xWinLib.roundTo(phase.totalLockedSupply, 2)))}
                  </Typography>
                  <Dot size='medium' color="success" />
                    {/* {!loading && userData.userstakebal > 0.000000001 ? <Dot size='medium' color="secondary" /> : ""}  */}
                  </Box>
                </div>
                <div className={clsx(classes.column4, classes.helper)}>
                <Typography
                      color="text"
                      colorBrightness={"hint"}
                      variant={"caption"}
                      style={{ marginRight: 5 }}
                    >
                      Total APR %
                    </Typography>
                  <Box display="flex" alignItems="center" justifyContent={"left"}>
                  
                  <Typography
                    size="md"
                    weight={"medium"}
                    style={{ marginRight: 8 }}
                  >   
                    {xWinUtils.displayBalance(loading, format({prefix: "", suffix: "%"})(xWinLib.roundTo(phase.totalAPR, 2)))}
                  </Typography>
                  <Dot size='medium' color="success" />
                  </Box>
                </div>
                <div className={clsx(classes.column5, classes.helper)}>
                  <Typography
                      color="text"
                      colorBrightness={"hint"}
                      variant={"caption"}
                      style={{ marginRight: 5 }}
                    >
                      Deposit
                    </Typography>
                  <Box display="flex" alignItems="center" justifyContent={"left"}>
                  
                  <Typography
                    size="md"
                    weight={"medium"}
                    style={{ marginRight: 8 }}
                  >   
                    {xWinUtils.displayBalance(loading, format({prefix: phase.port.FromToken+ " ", suffix: " "})(xWinLib.roundTo(userData.userstakebal,2)))}
                  </Typography>
                    {!loading && userData.userstakebal > 0.000000001 ? <Dot size='medium' color="secondary" /> : ""} 
                  </Box>
                 
                </div>
              </ExpansionPanelSummary>
              {getExpansionDetails()}
          </ExpansionPanel>
 
            
    </div>
  );
}


export default withRouter(PrivateVaultlist);