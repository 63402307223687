import React, { useState, useEffect, useRef, useContext } from "react";
import xWinLib from '../../xWinLib';
import xWinUtils from '../../xWinUtils';
// import xWinCompensation from '../../abi/xWinCompensation.json'
import xWinCompensation from '../../abi/xWinCompensationDummy.json'
import xWinWithdrawCompensation from '../../abi/xWinRobustCompensation.json'
import HomeIcon from '@material-ui/icons/Home';
import appGlobalConfig from '../../appGlobalConfig';
import format from 'format-number';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import TimerIcon from '@material-ui/icons/Timer';
import SnackbarMessage from '../snackbarmessage/SnackbarMessage'
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import {AppContext}  from '../../context/AppProvider';

import {
  Help as HelpIcon
} from "@material-ui/icons";

import {
  Grid,
  Button,
  Container,
  Box,
  TextField,
  InputAdornment,
  Chip,
  Tooltip,
  Fade
} from "@material-ui/core";

// styles
import useStyles from "./styles";

// components
import Widget from "../../components/Widget/Widget";
import { Typography, Avatar } from "../../components/Wrappers/Wrappers";

const Compensation = props => {
  var classes = useStyles();

  const getTimeSeconds = (time) => (minuteSeconds - time) | 0;
  const getTimeMinutes = (time) => ((time % hourSeconds) / minuteSeconds) | 0;
  const getTimeHours = (time) => ((time % daySeconds) / hourSeconds) | 0;
  const getTimeDays = (time) => (time / daySeconds) | 0;

  const minuteSeconds = 60;
  const hourSeconds = 3600;
  const daySeconds = 86400;

  const timerProps = {
    isPlaying: true,
    size: 90,
    strokeWidth: 8
  };

  const registerCountdown = xWinUtils.getCountDown(1625194800)
  const withdrawCountdown = xWinUtils.getCountDown(1657069965)


  const [loadingTxn, setLoadingTxn] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  
  const [userData, setUserData] = useState({
    walletETHBalance: 0,
    selectedWallet: ""
  });
  const [compensationProtocol, setCompensationProtocol] = useState("")
  const [withdrawCompensationProtocol, setWithdrawCompensationProtocol] = useState("")
  const [currentBlock, setCurrentBlock] = useState(0)
  const [endRegisterPeriod, setEndRegisterPeriod] = useState(0)
  const [endLockPeriod, setEndLockPeriod] = useState(0)
  const [isInBlackList, setIsInBlackList] = useState(true);
  const [hasWithdrawn, setHasWithdrawn] = useState(true);
  
  const { isConnected, ETHBalance, account, theWeb3, appMsg } = useContext(AppContext);

  const refsnackbar = useRef(null);
  

  useEffect(() => {
    const controller = new AbortController();
    loadWeb3();
    return () => {
      controller.abort();
    }
  }, [account]);

  const showSnackBar = (status, msg) => {
    refsnackbar.current.handleOpen(status, msg);
  }

  const loadWeb3 = async () => {
    
    // if(!isConnected) return
    if(theWeb3 === undefined) return
    
    setLoading(true)
    
    let myProtocol = new theWeb3.eth.Contract(xWinCompensation, appGlobalConfig.mainnetxWinCompensation);
    setCompensationProtocol(myProtocol)
    let myWithdrawProtocol = new theWeb3.eth.Contract(xWinWithdrawCompensation, appGlobalConfig.mainnetWinRobustCompensation);
    setWithdrawCompensationProtocol(myWithdrawProtocol)
    let tempEndRegisterPeriod = await myProtocol.methods.endRegisterPeriod().call();
    let releaseBlock = await myProtocol.methods.releaseBlock().call();
    let currBlock = await theWeb3.eth.getBlockNumber()
    setEndRegisterPeriod(tempEndRegisterPeriod)
    setCurrentBlock(currBlock)
    setEndLockPeriod(releaseBlock)

    let isRegistered
    let compensateBalance
    let finalEntitlement = 0
    if(account !== "---"){
      let userHasWithdrawn =  await myWithdrawProtocol.methods.hasWithdrawed(account).call()
      let userIsInBlackList = await myWithdrawProtocol.methods.blackList(account).call()
      setIsInBlackList(userIsInBlackList);
      setHasWithdrawn(userHasWithdrawn);
      isRegistered = await myProtocol.methods.userAddress(account).call();
      compensateBalance = await myProtocol.methods.viewMyCompensation(account).call();
      finalEntitlement = xWinUtils.convertFromWei(theWeb3, compensateBalance.toString())
    }

    setUserData({
      "walletETHBalance" :  ETHBalance, 
      "selectedWallet" : account,
      "amountXWIN" : 0,
      "amountLP" : 0,
      "xWINBalanceFromLP": 0,
      "totalEntitlement" : 0,
      "finalEntitlement" : finalEntitlement,
      "isRegistered" : isRegistered
    })  
    
    setLoading(false)
  };

  const isExpired = () => { return currentBlock > endRegisterPeriod }

  const handleRetrieveInfo = async event => {
    event.preventDefault();
    
    let myStakeInfo
    let amountXWIN = 0
    let amountLP = 0
    let xWINBalanceFromLP = 0
    let isRegistered
    let compensateBalance
    let finalEntitlement
    if(account !== "---"){

      myStakeInfo = await compensationProtocol.methods.getMemberStakingInfo(account).call();
      xWINBalanceFromLP = await compensationProtocol.methods.getXWINBalanceFromLP(account).call();
      isRegistered = await compensationProtocol.methods.userAddress(account).call();
      compensateBalance = await compensationProtocol.methods.viewMyCompensation(account).call();
      finalEntitlement = xWinUtils.convertFromWei(theWeb3, compensateBalance.toString())
      amountXWIN = xWinUtils.convertFromWei(theWeb3, myStakeInfo.amountXWIN.toString())
      amountLP = xWinUtils.convertFromWei(theWeb3, myStakeInfo.amountLP.toString())
      xWINBalanceFromLP = xWinUtils.convertFromWei(theWeb3, xWINBalanceFromLP.toString())
    }

    setUserData({
      "walletETHBalance" :  isExpired() ? 0 : ETHBalance, 
      "selectedWallet" : account,
      "amountXWIN" : isExpired() ? 0 : amountXWIN,
      "amountLP" : isExpired() ? 0 : amountLP,
      "xWINBalanceFromLP": isExpired() ? 0 : xWINBalanceFromLP,
      "totalEntitlement" : isExpired() ? 0 : parseFloat(xWINBalanceFromLP) + parseFloat(amountXWIN),
      "finalEntitlement" : finalEntitlement,
      "isRegistered" : isRegistered
    })  

    if(isExpired()) showSnackBar("error", "End Of Registration Period") 
  };


  // const handleClickRegister = event => {
  //   event.preventDefault();
    
  //   setLoadingTxn(true)
  //   xWinLib.CompensationRegisterAsync(
  //     compensationProtocol,
  //     userData,
  //     )
  //   .then(res =>
  //     { 
  //       console.log(res)
  //       setLoadingTxn(false)
  //       showSnackBar("success", res)
  //       loadWeb3()
  //     }
  //   )
  //   .catch(err => {
  //     setLoadingTxn(false)
  //     console.log(err)
  //     showSnackBar("error", err)
  //   }
  //   )
    
  // };

  const handleClickWithdraw = event => {
    event.preventDefault();
    
    setLoadingTxn(true)
    xWinLib.CompensationWithdrawAsync(
      //compensationProtocol,      // use Robust compansation when withdraw
      withdrawCompensationProtocol,
      userData,
      )
    .then(res =>
      { 
        console.log(res)
        setLoadingTxn(false)
        showSnackBar("success", res)
        loadWeb3()
      }
    )
    .catch(err => {
      setLoadingTxn(false)
      console.log(err)
      showSnackBar("error", err)
    }
    )
    
  };

  const getChipDisplay = () => {

    return (
      <Chip 
      size="large" 
      color="primary" avatar={<Avatar src="/images/icons/color/xwin.png" />} />
    )
  }

  const goToBSCscan = event => {
    window.open("https://bscscan.com/block/countdown/"+ endLockPeriod, "_blank")
	}

  const renderTime = (dimension, time) => {
    return (
      <div className="time-wrapper">
        <div lassName={classes.time}>{time}</div>
        <div>{dimension}</div>
      </div>
    );
  };

  return (
      
    <Container>
      {/* <Snackbar 
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        TransitionComponent={"TransitionUp"}
        open={openMsg} autoHideDuration={60000}>
          <Alert onClose={hideMessage} severity={"warning"}>
           Please connect your wallet or make sure you choose BSC Mainnet
          </Alert>
      </Snackbar> */}
      <SnackbarMessage ref={refsnackbar} />
      
      <Grid container spacing={3}>
        {xWinUtils.getProgress(loadingTxn)}
        {xWinUtils.getProgress(loading)}
        
      <Grid item lg={6} sm={12} xs={12}>
      <Typography
          color="text"
          colorBrightness={"hint"}
          variant={"h5"}
          style={{ marginRight: 5 }}
          noWrap
        >{"Countdown For Withdrawal"}</Typography>
        <div className={classes.App}>
          <CountdownCircleTimer
            {...timerProps}
            colors={[["#7E2E84"]]}
            duration={withdrawCountdown.daysDuration}
            initialRemainingTime={withdrawCountdown.remainingTime}
          >
          {({ elapsedTime }) =>
            renderTime("days", getTimeDays(withdrawCountdown.daysDuration - elapsedTime))
          }
        </CountdownCircleTimer>
        <CountdownCircleTimer
          {...timerProps}
          colors={[["#D14081"]]}
          duration={daySeconds}
          initialRemainingTime={withdrawCountdown.remainingTime % daySeconds}
          onComplete={(totalElapsedTime) => [
            withdrawCountdown.remainingTime - totalElapsedTime > hourSeconds
          ]}
        >
          {({ elapsedTime }) =>
            renderTime("hours", getTimeHours(daySeconds - elapsedTime))
          }
        </CountdownCircleTimer>
        <CountdownCircleTimer
          {...timerProps}
          colors={[["#EF798A"]]}
          duration={hourSeconds}
          initialRemainingTime={withdrawCountdown.remainingTime % hourSeconds}
          onComplete={(totalElapsedTime) => [
            withdrawCountdown.remainingTime - totalElapsedTime > minuteSeconds
          ]}
        >
        {({ elapsedTime }) =>
          renderTime("minutes", getTimeMinutes(hourSeconds - elapsedTime))
        }
      </CountdownCircleTimer>
      <CountdownCircleTimer
          {...timerProps}
          colors={[["#218380"]]}
          duration={minuteSeconds}
          initialRemainingTime={withdrawCountdown.remainingTime % minuteSeconds}
          onComplete={(totalElapsedTime) => [
            withdrawCountdown.remainingTime - totalElapsedTime > 0
          ]}
        >
        {({ elapsedTime }) =>
          renderTime("seconds", getTimeSeconds(elapsedTime))
        }
      </CountdownCircleTimer>
      </div>

      <Typography
          color="text"
          colorBrightness={"hint"}
          // variant={"h5"}
          style={{ marginRight: 5 }}
          // noWrap
        >
         Dear Our Users,
         <br/>
         <br/>
         Thank you for your support. It's been a tough experience last year where xWIN protocol was exploited.
         Yes, we learned from the lessons. We continued to enhance our platform with better security checks and evolving the protocol.
         Our team is working toward the version 2 that would bring a better user experience, 
         and better automated index vaults, trading vaults and other DEFI strategies to the users in near future.
         As promised, you can withdraw your fund after blocks 19306494 as long as you are entitled for the compensation. 
         xWIN Team would like to thank you for your continous support!
         <br/>
         <br/>
         Regards,
         xWIN Team
         </Typography>
  
      </Grid>
        <Grid item lg={6} sm={12} xs={12}>
          <Widget 
            className={classes.widgetLayout}
            title="Compensation"
            disableWidgetMenu
            enableWalletDisplay
            selectedWallet={userData.selectedWallet}
          >
            <br/>
            <Box display="flex" alignItems="center" justifyContent={"left"}>
                <TextField
                  label={'Address'}
                  margin="dense"
                  variant="outlined"
                  name="address"
                  //onChange={handleReferralChange()}
                  value={userData.selectedWallet}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <HomeIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <br/>
            </Box>
            <br/>
            {/* <Box display="flex" alignItems="center" justifyContent={"left"}>
              <Button
                disabled= {userData.isRegistered} 
                onClick={handleRetrieveInfo} 
                color="primary"
                variant="contained"
                fullWidth
                startIcon={<AddCircleIcon />}>
                  {userData.isRegistered ? "You've registered successfully!" : "Check My Entitlement"}
              </Button>
            </Box>
            <br/> */}
            {/* <Typography
              className={!userData.isRegistered ? classes.textShow : classes.textHide}
              color="text"
              //colorBrightness={"hint"}
              variant={"caption"}
              style={{ marginRight: 5 }}
              noWrap
            >
              {"Current Staked Balance in XWIN Farm"}
            </Typography>
            <br/> */}
            {/* <br/>
            <Box display="flex" alignItems="center" justifyContent={"left"} className={!userData.isRegistered ? classes.textShow : classes.textHide}>
              <TextField
                  label={'XWIN Token'}
                  value={format({prefix: "", suffix: ''})(xWinLib.roundTo(userData.amountXWIN, 4))}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    classes: {
                      input: classes.negativeNum,
                    },
                    readOnly: true,
                    style: { textAlign: "right" },
                    startAdornment: 
                      <InputAdornment position="start">
                        {getChipDisplay()}
                      </InputAdornment>,
                    endAdornment : 
                    <InputAdornment position="end">
                      <Tooltip 
                        TransitionComponent={Fade}  
                        title={
                          <h4 style={{ color: "lightblue" }}>
                            {"You staked "+ userData.amountXWIN + " $XWIN before flash loan attack"}</h4>}                    
                          arrow={true}>
                        <HelpIcon />
                      </Tooltip>
                    </InputAdornment>,
                  }}
                />
            </Box>
            <div className="py-2" />
            <Box display="flex" alignItems="center" justifyContent={"left"} className={!userData.isRegistered ? classes.textShow : classes.textHide}>
              <TextField
                label={'$XWIN Token from XWIN-BNB LP'}
                value={format({prefix: "", suffix: ''})(xWinLib.roundTo(userData.xWINBalanceFromLP, 4))}
                variant="outlined"
                fullWidth
                InputProps={{
                  classes: {
                    input: classes.negativeNum,
                  },
                  readOnly: true,
                  style: { textAlign: "right" },
                  startAdornment: 
                    <InputAdornment position="start">
                      <Chip 
                        size="large" 
                        color="primary" avatar={<Avatar src="/images/logos/bnb-xwin-lp.png" />} />
                    </InputAdornment>,
                    endAdornment : 
                    <InputAdornment position="end">
                      <Tooltip 
                        TransitionComponent={Fade}  
                        title={
                          <h4 style={{ color: "lightblue" }}>
                            {"You staked "+ xWinLib.roundTo(userData.amountLP, 4) + " XWIN-BNB LP Token before flash loan attack. The number of $XWIN token derived from the LP token is equivalent to "+ xWinLib.roundTo(userData.xWINBalanceFromLP, 4) +" of $XWIN"}</h4>}                    
                          arrow={true}>
                        <HelpIcon />
                      </Tooltip>
                    </InputAdornment>,
                }}
              />
            </Box> */}
            <div className="py-2" />
            <Box display="flex" alignItems="center" justifyContent={"left"} className={!userData.isRegistered ? classes.textShow : classes.textHide}>
              <TextField
                label={'Entitled Compensation'}
                value={format({prefix: "", suffix: ''})(xWinLib.roundTo(userData.totalEntitlement, 4))}
                variant="outlined"
                fullWidth
                InputProps={{
                  classes: {
                    input: classes.positiveNum,
                  },
                  readOnly: true,
                  style: { textAlign: "right" },
                  startAdornment: 
                    <InputAdornment position="start">
                      {getChipDisplay()}
                    </InputAdornment>,
                    endAdornment : 
                    <InputAdornment position="end">
                      <Tooltip 
                        TransitionComponent={Fade}  
                        title={
                          <h4 style={{ color: "lightblue" }}>
                            {"Total entitlement is derived from "+ xWinLib.roundTo(userData.xWINBalanceFromLP, 4) + " $XWIN from XWIN-BNB LP and "+ xWinLib.roundTo(userData.amountXWIN, 4) +" of $XWIN staked before flash loan attack"}</h4>}                    
                          arrow={true}>
                        <HelpIcon />
                      </Tooltip>
                    </InputAdornment>,
                }}
              />
            </Box>
            <div className="py-2" />
            <Box display="flex" alignItems="center" justifyContent={"left"} className={userData.isRegistered ? classes.textShow : classes.textHide}>
              <TextField
                label={'Registered Compensation Balance'}
                value={format({prefix: "", suffix: ''})(xWinLib.roundTo(userData.finalEntitlement, 4))}
                variant="outlined"
                fullWidth
                InputProps={{
                  classes: {
                    input: classes.positiveNum,
                  },
                  readOnly: true,
                  style: { textAlign: "right" },
                  startAdornment: 
                    <InputAdornment position="start">
                      {getChipDisplay()}
                    </InputAdornment>,
                    endAdornment : 
                    <InputAdornment position="end">
                      <Tooltip 
                        TransitionComponent={Fade}  
                        title={
                          <h4 style={{ color: "lightblue" }}>
                            {"Final registered entitlement "+ xWinLib.roundTo(userData.finalEntitlement, 4) + " $XWIN tokens"}</h4>}                    
                          arrow={true}>
                        <HelpIcon />
                      </Tooltip>
                    </InputAdornment>,
                }}
              />
            </Box>
            <div className="py-2" />
            <Box display="flex" alignItems="center" justifyContent={"left"}>
              <TextField
                label={'Release After Blocks'}
                value={endLockPeriod}
                variant="outlined"
                onClick={goToBSCscan}
                fullWidth
                InputProps={{
                  classes: {
                    input: classes.negativeNum,
                  },
                  readOnly: true,
                  style: { textAlign: "right" },
                  startAdornment : 
                    <InputAdornment position="end">
                      <TimerIcon />
                    </InputAdornment>,
                  endAdornment : 
                  <InputAdornment position="end">
                    Blocks
                  </InputAdornment>,
                  }
                }
              />
            </Box>
            <div className="py-2" />
            {/* <Box display="flex" alignItems="center" justifyContent={"left"} className={!userData.isRegistered ? classes.textShow : classes.textHide}>
              <Button 
                  disabled={(endRegisterPeriod < currentBlock) || userData.isRegistered || userData.totalEntitlement == 0}
                  onClick={handleClickRegister} 
                  color="primary"
                  variant="contained"
                  fullWidth
                  startIcon={<AddCircleIcon />}>
                    {endRegisterPeriod < currentBlock ? "Registration Period is Over!" : "Register Me!"}
                </Button>
            </Box> */}
            <div className="py-2" />
            <Box display="flex" alignItems="center" justifyContent={"left"} className={userData.isRegistered ? classes.textShow : classes.textShow}>
              <Button 
                  disabled={(endLockPeriod > currentBlock) || hasWithdrawn || isInBlackList}
                  onClick={handleClickWithdraw} 
                  color="primary"
                  variant="contained"
                  fullWidth
                  startIcon={<AddCircleIcon />}>
                    {endLockPeriod > currentBlock ? "Still In Locked Period!" :(hasWithdrawn ? "Withdrawed already!":"Withdraw!")}
                </Button>
            </Box>
          </Widget> 
        </Grid>
      </Grid>
    </Container>
  );
}


export default Compensation;
