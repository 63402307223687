import React, { useEffect, useState, useContext } from "react";
import PrivateVaultlist from "./components/PrivateVaultlist";
import PrivateVaultAlpacalist from "./components/PrivateVaultAlpacalist";
import {AppContext}  from '../../context/AppProvider';
import xWinStaking from '../../abi/xWinPrivateVault.json'
import xWinStakeAlpaca from '../../abi/xWinPrivateVaultAlpaca.json'
import xWinUtils from '../../xWinUtils';
import xWinProtocolV5 from '../../abi/xWinProtocolV5.json'
import ReactInterval from 'react-interval'

import {
  Container,
  Grid,
} from "@material-ui/core";

// components
import xWinLib from '../../xWinLib'
import Widget from "../../components/Widget/Widget";
import useStyles from "./styles";
import appGlobalConfig from "../../appGlobalConfig";

export default function PrivateVault(props) {
  
  const {fundtype } = props;
  const [phases, setPhases] = useState([])
  const [phasesAlpaca, setPhasesAlpaca] = useState([])
  const [winStake, setWinStake] = useState([])
  const [winStakeDisplay, setWinStakeDisplay] = useState([])
  const [winStakeAlpaca, setWinStakeAlpaca] = useState([])
  const [winStakeAlpacaDisplay, setWinStakeAlpacaDisplay] = useState([])
  
  const { ETHBalance, isConnected, globalProvider, account, theWeb3, web3static, refreshAccount } = useContext(AppContext);
  
  const classes = useStyles();

  
  useEffect(() => {
    const controller = new AbortController();
    loadWeb3()
    loadVaultAlpaca()
    return () => {
      controller.abort();
    }
  }, [account]);

  const loadBoth = async () => {
    loadWeb3()
    loadVaultAlpaca() 
  }

  const loadWeb3 = async () => {

    if(theWeb3 === undefined) return

    let allprices = await xWinUtils.getAllPrices(web3static)
    
    const promises = appGlobalConfig.privateVaults.map(async port => {
      let myPhases = []
      let xWinProtocol = new web3static.eth.Contract(xWinProtocolV5, port.protocoladdress);
      const myxWinStake= new theWeb3.eth.Contract(xWinStaking, port.contractaddress);  //"0x4AA221079eB1C24fBad38d3C61718eAe4B21a7ff"
      const myxWinStakeDisplay = new web3static.eth.Contract(xWinStaking, port.contractaddress);  //"0x4AA221079eB1C24fBad38d3C61718eAe4B21a7ff"
      setWinStake(myxWinStake)
      setWinStakeDisplay(myxWinStakeDisplay)
      let investorWallet = await myxWinStakeDisplay.methods.investorWallet().call()
      // let numberOfPool = await myxWinStake.methods.getNumberOfPools().call()
      let entryFee = await myxWinStakeDisplay.methods.entryFee().call()
      let xwinPID = await myxWinStakeDisplay.methods.xwinpid().call()
      let burnFee = await myxWinStakeDisplay.methods.burnFee().call()
      let xwinpoolInfo = await xWinProtocol.methods.poolInfo(xwinPID).call()   
      for(let i=0; i< 1; i++){
        let poolInfo = await myxWinStakeDisplay.methods.poolInfo(i).call()
        let currBalStake = await myxWinStakeDisplay.methods.getTotalStakedBalance().call()
        currBalStake = xWinUtils.convertFromWei(web3static, currBalStake.toString())
        let userInfo = await myxWinStakeDisplay.methods.userInfo(i, investorWallet).call()
        let origBal = xWinUtils.convertFromWei(web3static, userInfo.lpTokenStakedAmount.toString())
        let dailyRate = origBal == 0? 0 : parseFloat(currBalStake) / parseFloat(origBal) - 1
        let currBlock = await web3static.eth.getBlockNumber()
        let blockDiff = parseFloat(currBlock) - parseFloat(userInfo.lastHarvest)
        let PCSAPR = xWinUtils.calcReturns(dailyRate, blockDiff)
        let rate = await xWinUtils.getExchangeRateByAllAddress(web3static, port.sourceaddress, "0x55d398326f99059fF775485246999027B3197955")
        let tvlinlocal = xWinUtils.convertFromWei(web3static, poolInfo.totalLockedSupply.toString())
        let tempXWINARP = await getEstReturns(xwinpoolInfo, 10000, rate, allprices)

        myPhases.push({
          "burnFee" : parseFloat(burnFee) / 100,
          "interestPerDay" : 0,
          "interestPerYear" : 0,
          "PCSAPR" : PCSAPR.annualCompound,
          "totalAPR" : parseFloat(PCSAPR.annualCompound) + parseFloat(tempXWINARP),
          "xwinAPR" : tempXWINARP,
          "totalLockedSupply": parseFloat(rate) * parseFloat(tvlinlocal),
          "totalInterestPaid": xWinUtils.convertFromWei(web3static, poolInfo.totalInterestPaid.toString()),
          "entryFee" : entryFee / 100,
          "pid" : i,
          "winStake": myxWinStake,
          "winStakeDisplay": myxWinStakeDisplay,
          "xwinPID" : xwinPID,
          "port": port
        })
      }
      return myPhases
    });
    const tmpPhases = await Promise.all(promises);
    setPhases(tmpPhases)  
  }

  const loadVaultAlpaca = async () => {

    if(theWeb3 === undefined) return
    let allprices = await xWinUtils.getAllPrices(web3static)
    
    const promises = appGlobalConfig.privateVaultsAlpaca.map(async port => {
      let myPhases = []
      let xWinProtocol = new web3static.eth.Contract(xWinProtocolV5, port.protocoladdress);
      const myxWinStake= new theWeb3.eth.Contract(xWinStakeAlpaca, port.contractaddress);  //"0x4AA221079eB1C24fBad38d3C61718eAe4B21a7ff"
      const myxWinStakeDisplay = new web3static.eth.Contract(xWinStaking, port.contractaddress);  //"0x4AA221079eB1C24fBad38d3C61718eAe4B21a7ff"
      setWinStakeAlpaca(myxWinStake)
      setWinStakeAlpacaDisplay(myxWinStakeDisplay)
      let investorWallet = await myxWinStakeDisplay.methods.investorWallet().call()
      // let numberOfPool = await myxWinStakeDisplay.methods.getNumberOfPools().call()
      let entryFee = await myxWinStakeDisplay.methods.entryFee().call()
      let xwinPID = await myxWinStakeDisplay.methods.xwinpid().call()
      let burnFee = await myxWinStakeDisplay.methods.burnFee().call()
      let xwinpoolInfo = await xWinProtocol.methods.poolInfo(xwinPID).call()   

      for(let i=0; i< 1; i++){
        let poolInfo = await myxWinStakeDisplay.methods.poolInfo(i).call()

        let currBalStake = await myxWinStakeDisplay.methods.getTotalStakedBalance().call()
        currBalStake = xWinUtils.convertFromWei(web3static, currBalStake.toString())
        let userInfo = await myxWinStakeDisplay.methods.userInfo(i, investorWallet).call()
        let origBal = xWinUtils.convertFromWei(web3static, userInfo.lpTokenStakedAmount.toString())
        let dailyRate = origBal == 0? 0 : parseFloat(currBalStake) / parseFloat(origBal) - 1
        let currBlock = await web3static.eth.getBlockNumber()
        let blockDiff = parseFloat(currBlock) - parseFloat(userInfo.lastHarvest)
        let PCSAPR = xWinUtils.calcReturns(dailyRate, blockDiff)
        let rate = await xWinUtils.getExchangeRateByAllAddress(web3static, port.sourceaddress, "0x55d398326f99059fF775485246999027B3197955")
        let tvlinlocal = xWinUtils.convertFromWei(web3static, poolInfo.totalLockedSupply.toString())
        let tempXWINARP = await getEstReturns(xwinpoolInfo, 10000, rate, allprices)
        
        myPhases.push({
          "burnFee" : parseFloat(burnFee) / 100,
          "interestPerDay" : 0,
          "interestPerYear" : 0,
          "PCSAPR" : PCSAPR.annualCompound,
          "totalAPR" : parseFloat(PCSAPR.annualCompound) + parseFloat(tempXWINARP),
          "xwinAPR" : tempXWINARP,
          "totalLockedSupply": parseFloat(rate) * parseFloat(tvlinlocal),
          "totalInterestPaid": xWinUtils.convertFromWei(web3static, poolInfo.totalInterestPaid.toString()),
          "entryFee" : entryFee / 100,
          "pid" : i,
          "winStake": myxWinStake,
          "winStakeDisplay": myxWinStakeDisplay,
          "xwinPID" : xwinPID,
          "port": port
        })
      }
      return myPhases
    });
    const tmpPhases = await Promise.all(promises);
    setPhasesAlpaca(tmpPhases)  
  }

  const getEstReturns = async (xwinpoolInfo, xwinMultiplier, rate, prices) => {
    
    // let prices = await xWinUtils.getAllPrices(theWeb3)
    let oneTokenReward = getEstPerOneToken(xwinpoolInfo, xwinMultiplier)
    let proceeds = oneTokenReward * 365 * prices.xwinPriceInUSD
    return xWinLib.roundTo(proceeds / rate * 100, 2)
  }

  const getEstPerOneToken = (xwinpoolInfo, xwinMultiplier) => {
    let oneday = xWinLib.roundTo(xwinpoolInfo.rewardperblock *  28800 * xwinpoolInfo.multiplier/100 * xwinMultiplier / 10000, 0)
    return xWinUtils.convertFromWei(theWeb3, oneday.toString())
  };
 
  const displayPortfolios = () => {
    
    if(phases === undefined) return ""
    let funds = phases
    
    return (
      funds.map(phase => (
        <Grid
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
        > 
           <PrivateVaultlist 
            globalWeb3={theWeb3}
            web3static={web3static}
            selectedWallet={account}
            fundtype={fundtype}
            refreshAccount={refreshAccount}
            ETHBalance={ETHBalance}
            phase2={phase}
            // winStake={winStake}
            // winStakeDisplay={winStakeDisplay}
            // globalProvider={globalProvider}
            /> 
           
          </Grid> 
        )) 
    )    
  }

  const displayPortfolios2 = () => {
    
    if(phasesAlpaca === undefined) return ""
    let funds = phasesAlpaca
    
    return (
      funds.map(phase => (
        <Grid
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
        > 
           <PrivateVaultAlpacalist
            globalWeb3={theWeb3}
            web3static={web3static}
            selectedWallet={account}
            fundtype={fundtype}
            refreshAccount={refreshAccount}
            ETHBalance={ETHBalance}
            phase2={phase}
            // winStakeAlpaca={winStakeAlpaca}
            // winStakeAlpacaDisplay={winStakeAlpacaDisplay}
            // globalProvider={globalProvider}
            /> 
           
          </Grid> 
        )) 
    )    
  }



  return (
    <Container>
      <ReactInterval timeout={10000} enabled={true}
      callback={() => loadBoth()} />
      <Grid container spacing={3}>
        
        <Grid item md={12}>
          <Widget
            className={classes.newsHeader}
            title={"Private Vault Zone 1"}
            disableWidgetMenu
            inheritHeight
            enableWalletDisplay
            selectedWallet={account}
          >
             {displayPortfolios()}
            
          </Widget>
        </Grid>
        <Grid item md={12}>
          <Widget
            className={classes.newsHeader}
            title={"Private Vault Zone 2"}
            disableWidgetMenu
            inheritHeight
            enableWalletDisplay
            selectedWallet={account}
          >
             {displayPortfolios2()}
            
          </Widget>
        </Grid>
      
      </Grid>
    </Container>
  );
}
