const host = process.env.NODE_ENV === "development" ? "http://localhost" : "https://xwin.finance";
const port = process.env.NODE_ENV === "development" ? 8080 : ""
export default {
  analytics: {
    google: {
      id: 'UA-206578988-1'
    },
  },
  auth: {
    email: "admin@xwin.finance",
    password: "password"
  },
  firebaseConfig: {
    apiKey: "AIzaSyCZERUPFB4j2aS528VWc3Zi6E5LX-_sImU",
    authDomain: "xwininvestment.firebaseapp.com",
    databaseURL: "https://xwininvestment.firebaseio.com",
    projectId: "xwininvestment",
    storageBucket: "xwininvestment.appspot.com",
    messagingSenderId: "734863331629",
    appId: "1:734863331629:web:680cac4643227f67bf03a4",
    measurementId: "G-QBKR6761PY"
  },
  // staging
  // firebaseConfig: {
    //   apiKey: "AIzaSyAimOU59pfu9N9M76LT1C4gQNJK5EMYi7c",
    //   authDomain: "xwinstage.firebaseapp.com",
    //   projectId: "xwinstage",
    //   storageBucket: "xwinstage.appspot.com",
    //   messagingSenderId: "1034117015626",
    //   appId: "1:1034117015626:web:b9770d0eb4178e490d9eb3",
    //   measurementId: "G-8D80LN4VGG"
    // };
  hostApi: host,
  portApi: port,
  baseURLApi: `${host}${port ? `:${port}` : ``}/api`,
  redirectUrl: process.env.NODE_ENV === "development" ? "http://localhost:3000" : "https://xwin.finance",
  remote: "https://xwin.finance",
  isBackend: process.env.REACT_APP_BACKEND,
};