import React, { useRef  } from "react";
import xWinLib from '../../../xWinLib';
import format from 'format-number';
import { withRouter } from 'react-router-dom';
import SnackbarMessage from '../../snackbarmessage/SnackbarMessage'
import TimerIcon from '@material-ui/icons/Timer';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import xWinUtils from '../../../xWinUtils';

// styles
import useStyles from "../styles";
import { Typography, Button, Avatar } from "../../../components/Wrappers/Wrappers";
import {
  Box,
  InputAdornment,
  TextField,
  Chip
} from "@material-ui/core";

const Phase = props => {
  var classes = useStyles();
  const { userData, phase, poolID, myProtocol } = props;
  const [loadingTxn, setLoadingTxn] = React.useState(false);
  
  const refsnackbar = useRef(null);

  const showSnackBar = (status, msg) => {
    refsnackbar.current.handleOpen(status, msg);
  }

  const handleClickClaim = event => {
    event.preventDefault();
    
    setLoadingTxn(true)
    xWinLib.PrivateInvestorAsync(
        myProtocol,
        userData
      )
    .then(res =>
      { 
        console.log(res)
        setLoadingTxn(false)
        showSnackBar("success", res)
        //loadWeb3()
      }
    )
    .catch(err => {
      setLoadingTxn(false)
      console.log(err)
      showSnackBar("error", err)
    }
    )
    
  };

  const getChipDisplay = () => {

    return (
      <Chip 
      size="large" 
      label={"XWIN"}
      color="primary" avatar={<Avatar src="/images/icons/color/xwin.png" />} />
    )
  }

    const goToBSCscan =  event => {
        window.open("https://bscscan.com/block/countdown/"+ phase.releaseBlock, "_blank")
    }

    const getButtonStatus = () => {

        if(!phase.canRelease && poolID === phase.id) return "Not Yet Released"
        if(!phase.canRelease && poolID < phase.id) return "Not Yet Released"
        if(phase.canRelease && poolID > phase.id) return "Claimed"
        return "Get My Tokens"
    }

    const shouldDisable = () => {

      if(!phase.canRelease && poolID < phase.id) return true
      if(!phase.canRelease && parseFloat(poolID) === parseFloat(phase.id)) return true
      if(phase.canRelease && parseFloat(poolID) > parseFloat(phase.id)) return true
      return false
    }

  return (
    <div>  
          <SnackbarMessage ref={refsnackbar} />
          {xWinUtils.getProgress(loadingTxn)}
          <Typography
            className={classes.subTitle}
              //color="text"
              variant={"h3"}
              style={{ marginRight: 5 }}
              noWrap
            >{"Phase " + phase.id}</Typography>
          <br/>
          <Box display="flex" alignItems="center" justifyContent={"left"}>
            <TextField
                  variant="outlined"
                  value={format({prefix: "", suffix: ''})(xWinLib.roundTo(phase.releaseBalance, 2))}
                  fullWidth
                  InputProps={{
                    classes: {
                      input: classes.positiveNum,
                    },
                    readOnly: true,
                    style: { textAlign: "right" },
                    startAdornment: 
                      <InputAdornment position="start">
                        {getChipDisplay()}
                      </InputAdornment>,
                  }}
                />
          </Box>
          <br/>
          <Typography
              color="text"
              variant={"caption"}
              style={{ marginRight: 5 }}
              noWrap
            >{"Release After Blocks"}</Typography>
          <br/>
          
          <Box display="flex" alignItems="center" justifyContent={"left"}>
            <TextField
                  variant="outlined"
                  value={format({prefix: "", suffix: ''})(phase.releaseBlock)}
                  onClick={goToBSCscan}
                  fullWidth
                  InputProps={{
                    classes: {
                      input: classes.positiveNum,
                    },
                    readOnly: true,
                    style: { textAlign: "right" },
                    startAdornment : 
                      <InputAdornment position="end">
                        <TimerIcon />
                      </InputAdornment>,
                    endAdornment : 
                    <InputAdornment position="end">
                      Blocks
                    </InputAdornment>,
                    }
                  }
                />
          </Box>
          <br/>
          <Box display="flex" alignItems="center" justifyContent={"left"}>
            <Button 
                disabled={shouldDisable()}
                onClick={handleClickClaim} 
                color="primary"
                variant="contained"
                fullWidth
                startIcon={<AddCircleIcon />}>
                  {getButtonStatus()}
              </Button>
          </Box>
          
          </div>  
      );
}
export default withRouter(Phase);

