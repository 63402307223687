import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => (
  {
    hoverHighlight: {
      "&:hover": {
        boxShadow: theme.customShadows.widgetWide
      },
    },
    expanded: { backgroundColor: theme.palette.background.default },
    expansion: {
      backgroundColor: theme.palette.primary.light,
      color: 'white '
    },
    left20: {
      flexBasis: '20.00%',
      verticalAlign: 'center',
    },
    column1: {
      flexBasis: '8.00%',
      verticalAlign: 'center',
    },
    column2: {
      flexBasis: '30.00%',
      verticalAlign: 'center',
    },
    column3: {
      flexBasis: '25.00%',
      verticalAlign: 'center',
    },
    column4: {
      flexBasis: '25.00%',
      verticalAlign: 'center',
    },
    column5: {
      flexBasis: '12.00%',
      verticalAlign: 'bottom',
    },
    column6: {
      flexBasis: '35.00%',
      verticalAlign: 'bottom',
    },
    columnHide: {
      [theme.breakpoints.down('xs')]: {
        display: "none"
      }
    },
    columnShow: {
      // display: "inline",
      [theme.breakpoints.up('xs')]: {
        display: "none"
      }
    },
    panel: {
      padding: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(0),
      }
    },
    helper: {
      borderLeft: `2px solid ${theme.palette.divider}`,
      padding: theme.spacing(1, 2),
    },
    table: {
      padding: theme.spacing(1),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(0)
      }
    },
    newsHeader: {
      textDecoration: 'none',
      textTransform: 'uppercase',
      color: '#536DFE',
    },
    subTitle: {
      //textDecoration: 'none',
      //textTransform: 'uppercase',
      fontWeight: 100,
      color: '#536DFE',
    },
    textRight: {
      color: '#536DFE',
      textAlign: 'right',
    },
    blocks: {
      textAlign: 'right',
      color: '#dd951e',
    },
    textWhite: {
      color: 'white',
    },
    avatar: {
      height: 50,
      width: 50
    },
    snackbarWarning: {
      backgroundColor: theme.palette.warning.main,
    },
    snackbarSuccess: {
      backgroundColor: theme.palette.success.main,
      color: "black"
    },
    link: {
      color: theme.palette.success.main,
      fontWeight: 'bold'
    },
    inline: {
      display: "flex",
    }
  }
))