import React from 'react';
import {
  AccountBalance as AccountBalanceIcon,
  AllInclusive as AllInclusiveIcon,
  ConfirmationNumber as ConfirmationNumberIcon,
  History as HistoryIcon,
  Home as HomeIcon,
  Image as ImageIcon,
  InsertChart as InsertChartIcon,
  LocalAtm as LocalAtmIcon,
  People as PeopleIcon,
  PieChart as PieChartIcon,
  Stars as StarsIcon,
  VideoLibrary as VideoLibraryIcon,
  ViewHeadline as ViewHeadlineIcon, 
  FavoriteBorderOutlined as Favorite,
  PhotoLibrary as PhotoLibraryIcon 
} from '@material-ui/icons';



const structure = [
  {
    id: 0, label: 'Dashboard', link: '/app/dashboard', icon: <HomeIcon />,
  },
  { id: 99, type: 'divider' },
  {
    id: 90, label: 'Toppy Market', link: 'https://toppy.market', icon: <PhotoLibraryIcon />,
  },
  {
    id: 98, label: 'Lucky Draw', link: '/app/luckydraw', icon: <ConfirmationNumberIcon />,
  },
  // {
  //   id: 91, label: 'xWIN Charity', link: '/app/charity', icon: <Favorite />,
  // },
  { id: 97, type: 'divider' },
  { id: 15, type: 'title', label: 'xWIN Farming & Staking' },
  {
    id: 16, label: 'Farming', link: '/app/farming/all', icon: <ViewHeadlineIcon />,
  },
  {
    id: 96, label: 'Fixed Rate Staking', link: '/app/fixedrate', icon: <LocalAtmIcon />,
  },
  {
    id: 95, label: 'Private Vault', link: '/app/privatevault', icon: <PeopleIcon />,
  },

  { id: 4, type: 'divider' },
  { id: 5, type: 'title', label: 'VAULTS / FUNDS' },
  {
    id: 6,
    label: 'Index',
    link: '/app/tradings/index',
    icon: <InsertChartIcon />,
  },
  {
    id: 7,
    label: 'Trading',
    link: '/app/tradings/trading',
    icon: <PieChartIcon />,
  },
  {
    id: 8,
    label: 'Yields',
    link: '/app/yields/',
    icon: <AccountBalanceIcon />,
    children: [
      { label: 'PCS', link: '/app/yields/pcs' },
    ],
  },
  {
    id: 10,
    label: 'Discontinued Vault',
    link: '/app/tradings/old',
    icon: <HistoryIcon />,
    children: [
      { label: '(Old) LP Vault', link: '/app/tradings/old/liquidityold' },
      { label: '(Old) Yield Vault', link: '/app/yields/old/pcsold' },
      { label: '(Old) Index Vault', link: '/app/tradings/old/indexold' },
      { label: '(Old) Trading Vault', link: '/app/tradings/old/tradingold' },
    ],
  },
  { id: 7, type: 'divider' },
  {
    id: 88,
    label: 'XWIN',
    link: '/app/xwin/',
    icon: <StarsIcon />,
    children: [
      { label: 'Buy-Sell $XWIN', link: '/app/xwin/buysell' },
      { label: 'Get XWIN_BNB LP', link: '/app/xwin/buyLP' },
      { label: 'Claim Token & Bonus', link: '/app/xwin/bonusclaim' },
    ],
  },
  { id: 17, type: 'divider' },
  {
    id: 98, label: 'Compensation', link: '/app/compensation', icon: <AllInclusiveIcon />,
  },
  // { id: 17, type: 'divider' },
  // { id: 18, type: 'title', label: 'Others' },
  // {
  //   id: 22, label: 'Team Member', link: '/app/teammember', icon: <PeopleIcon />,
  // },
  // {
  //   id: 23, label: 'Toppy Gallery', link: '/app/toppy', icon: <ImageIcon />,
  // },
  // {
  //   id: 24, label: 'xWIN TV', link: '/app/xwinvideo', icon: <VideoLibraryIcon />,
  // },
  // {
  //   id: 25, label: 'xWIN News', link: '/app/xwinnews', icon: <ViewHeadlineIcon />,
  // },

];


export default structure;
