import React, { useState, useEffect, useRef  } from "react";
import xWinLib from '../../../xWinLib';
import format from 'format-number';
import clsx from 'clsx';
// styles
import useStyles from "../styles";
import { Typography, Button, Link } from "../../../components/Wrappers/Wrappers";
import Dot from "../../../components/Sidebar/components/Dot";
import xWinProtocolV5 from '../../../abi/xWinProtocolV5.json'
import xWinUtils from '../../../xWinUtils';
import xWinFarmV2 from '../../../abi/xWinFarmV2.json'
import Subscribe from '../../subscribe/subscribe'
import Redeem from '../../redeem/redeem'
import { Link as RouterLink } from 'react-router-dom';
import { withRouter } from "react-router-dom";
import "aos/dist/aos.css"
import SnackbarMessage from '../../snackbarmessage/SnackbarMessage'
import ReactInterval from 'react-interval'

import {
  Grid,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  TableRow,
  Table,
  TableCell,
  ExpansionPanelActions,
  Slide,
  Tooltip,
} from "@material-ui/core";
import {
  ExpandMore as ExpandMoreIcon,
  Favorite as FavoriteIcon,
  InsertChart as InsertChartIcon 
} from "@material-ui/icons";

const Yieldlist = props => {

  var classes = useStyles();
  const { portfolios, port, globalWeb3, web3static, ETHBalance, selectedWallet, gasPrices, networkName, showMyFund, fundtype, refreshAccount } = props;
  
  let tokensMaster = []
  let tmp = []
  const [fundData, setFundData] = useState({
    "fundvalue": 0,
    "tokenNames": tmp,
    "uniprice": 0,
    "unipriceInUSD": 0,
    "totalSupply":  0,
    "fundETHbalance": 0,
    "networkName": "bscmain"
  });
  const [loading, setLoading] = React.useState(false);
  const [myxWinProtocol, setMyxWinProtocol] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  
  const [userData, setUserData] = useState({
    "walletETHBalance" :  0, 
    "selectedWallet" : "",
    "userfundbal" : 0,
    "referral" : ""
  });
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  const refTermCondition = useRef(null);
  const refsubsdirect = useRef(null);
  const refredeem = useRef(null);
  

  const refsnackbar = useRef(null);
  const showSnackBar = (status, msg) => {
    refsnackbar.current.handleOpen(status, msg);
  }

  useEffect(() => {
    const controller = new AbortController();
    loadFundData()
    return () => {
      controller.abort();
    }
  }, [selectedWallet]);
  
  const loadFundData = async () => {

    if(!globalWeb3) {
      
      setUserData({
        "walletETHBalance" :  0, 
        "selectedWallet" : "---",
        "userfundbal" : 0,
        "userstakebal" : 0,
        // "referral" : referral,
        "mylpBalance" : 0,
      })  
      return
    }
    setLoading(true)
    loadVaultData()
    setLoading(false)
    
  }

  async function loadVaultData() {

    if(!globalWeb3) {
      return;
    }

    let xWinProtocol = new globalWeb3.eth.Contract(xWinProtocolV5, port.protocoladdress);
    let xWinProtocolDisplay = new web3static.eth.Contract(xWinProtocolV5, port.protocoladdress);
    setMyxWinProtocol(xWinProtocol)
    let poolInfo = await xWinProtocolDisplay.methods.poolInfo(port.pid).call()
    
    let prices = await xWinUtils.getAllPrices(web3static)
    
    const myxWinFunds = new web3static.eth.Contract(xWinFarmV2, port.contractaddress);
    let userstakebal = 0
    // let walletData = await xWinUtils.getUserWalletInfo(globalWeb3)
    let fundHolderData = await xWinUtils.getUserFundFarmHolding(web3static, selectedWallet, port.contractaddress)
    let referral = await xWinUtils.getReferral(xWinProtocol, selectedWallet) 
    if(port.xwinfarmpool && selectedWallet !== "---"){
      let userInfo = await xWinProtocolDisplay.methods.userInfo(port.pid, selectedWallet).call()
      userstakebal = xWinUtils.convertFromWei(web3static, userInfo?.amount.toString())
    }
    
    let dailyReturn = await myxWinFunds.methods.dailyReturn().call();
    let returnsResult = xWinUtils.calcReturns(xWinUtils.convertFromWei(web3static, dailyReturn.dailyRate.toString()), dailyReturn.blockdiff)
    let myFundData = await xWinUtils.getFundAllData(myxWinFunds)
    let tokenNames = []
    let totalMylpBalance = parseFloat(userstakebal) + parseFloat(fundHolderData.mylpBalance) 

    if(selectedWallet !== "---"){
      setUserData({
        "walletETHBalance" :  ETHBalance, 
        "selectedWallet" : selectedWallet,
        "userfundbal" : fundHolderData.userfundbal,
        "userstakebal" : userstakebal,
        "referral" : referral,
        "mylpBalance" : totalMylpBalance,
      })  
    }else{
      setUserData({
        "walletETHBalance" :  "0", 
        "selectedWallet" : "---",
        "userfundbal" : "0",
        "userstakebal" : "0",
        "referral" : "",
        "mylpBalance" : "0",
      })  
    }
    
    

    setFundData({
      ...fundData,
      "fundvalue": xWinUtils.convertFromWei(web3static, myFundData.fundvalue.toString()), //fundvalue / PRECISION,
      "tokenNames": tokenNames,
      "fundmanager": myFundData.fundmanager,
      "uniprice": xWinUtils.convertFromWei(web3static, myFundData.uniprice.toString()), //uniprice / PRECISION,
      "unipriceInUSD": xWinUtils.convertFromWei(web3static, myFundData.unipriceInUSD.toString()), //uniprice / PRECISION,
      "totalSupply":  xWinUtils.convertFromWei(web3static, myFundData.totalSupply.toString()), // totalSupply / PRECISION,
      "fundETHbalance": xWinUtils.convertFromWei(web3static, myFundData.fundETHbalance.toString()), //fundETHbalance / PRECISION
      "networkName": networkName,
      "compoundAPR" : returnsResult.annualCompound,
      "dailyRate" : returnsResult.dailyRate,
      "multiplier": poolInfo.multiplier,
      "rewardperblock" : poolInfo.rewardperblock,
      "xwinPriceInUSD" : prices.xwinPriceInUSD,
      "pendingCakeBal": fundHolderData.pendingCakeBal,
      "lpToken" : poolInfo.lpToken,
      "lpBalance": fundHolderData.lpBalance,
      "xWinFund" : fundHolderData.xWinFund,
      "owner": fundHolderData.owner
    })
  }
  const getTotalAPY = () => {
    if(port.xwinfarmpool) return parseFloat(fundData.compoundAPR) + parseFloat(getEstReturns())
    return parseFloat(fundData.compoundAPR)
  }

  const getEstPerOneToken = () => {
    
    if(globalWeb3 === undefined) return 0
    if(!port.xwinfarmpool) return "N/A"
    if(isNaN(fundData.rewardperblock)) return 0
    let oneday = fundData.rewardperblock *  28800 * fundData.multiplier/100
    return xWinUtils.convertFromWei(globalWeb3, oneday.toString())
  };


  const calcReturns = (dailyReturn) =>{

    if(globalWeb3 === undefined) return 0
    let partialRate = xWinUtils.convertFromWei(globalWeb3, dailyReturn.dailyRate.toString())
    let n = 28800 / parseFloat(dailyReturn.blockdiff) 
    let dailyRate = partialRate * n
    let annRate = Math.pow((dailyRate + 1), 365)-1
    
    return {
        annualCompound: xWinUtils.shouldDisable(fundtype)? 0 : xWinLib.roundTo(annRate * 100, 2),
        dailyRate: xWinUtils.shouldDisable(fundtype)? 0 : xWinLib.roundTo(dailyRate * 100, 2)
    };
}

  const getStakeFarmBalance = () => {
    return parseFloat(userData.userfundbal) + parseFloat(userData.userstakebal)
  }

  const getEstDailyReturns = () => {
    return xWinLib.roundTo(getEstReturns() / 365, 4)
  };

  const getEstReturns = () => {
    let oneTokenReward = getEstPerOneToken()
    let proceeds = oneTokenReward * 365 * fundData.xwinPriceInUSD
    return xWinLib.roundTo(proceeds / fundData.unipriceInUSD * 100, 2)
  };

  const handleClickSubsDirect = event => {
    
    if(port.showTnC && !xWinUtils.getAgreeTermConditionByPortfolio(port.symbol)){
      if(refTermCondition.current != null)  refTermCondition.current.handleOpen(port.symbol)
      return
    }
    refsubsdirect.current.handleOpen();
  }

  const handleClickRedeem = event => {

    if(port.xwinfarmpool && userData.userstakebal > 0 && userData.userfundbal <= 0.00000001){
      setOpen(true)
    }else{
      refredeem.current.handleOpen();
    }
  }


  const handleClose = () => {
    setOpen(false);
  };

  const handleCallback = (refresh) =>{
    
    if(refresh){
      refreshAccount()
      loadFundData()
    } 
  }
  
  const AddToMetamask = async event => {
    
    setLoading(true)
    xWinLib.AddToMetamask(port.contractaddress, port.symbol, 18)
      .then(res =>
        { 
          console.log(res)
          setLoading(false)
          //showSnackBar("success", res)
          showSnackBar("info", res)
        }
      )
      .catch(err => {
        setLoading(false)
        showSnackBar("error", err)
        }
      )
    
  };


  return (
    <div>
        <ReactInterval timeout={10000} enabled={true}
          callback={() => loadVaultData()} />

        <SnackbarMessage ref={refsnackbar} />
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">{"Unstake in xWIN Farm"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Please unstake from xWIN Farm first
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Link 
                  component={RouterLink}
                  to={{
                  pathname: "/app/farming/"+ port.contractaddress,
                }}>
            <Button color="primary">
                OK              
            </Button>
            </Link>
          </DialogActions>
        </Dialog>
        <Redeem 
          ref={refredeem} 
          port={port}
          fundData={fundData}
          userData={userData}
          myxWinProtocol={myxWinProtocol}
          globalWeb3={globalWeb3}
          networkName={networkName}
          parentCallback={handleCallback}
          />
        <Subscribe 
          ref={refsubsdirect} 
          port={port}
          fundData={fundData}
          userData={userData}
          myxWinProtocol={myxWinProtocol}
          globalWeb3={globalWeb3}
          networkName={networkName}
          parentCallback={handleCallback}
          />
          <ExpansionPanel defaultExpanded={false} square={false}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                classes={{ expanded: classes.expanded }}
              >
                <div className={clsx(classes.column1)}>
                  <img alt={port.symbol} src={xWinLib.getLogo(port.logo)} className={classes.avatar}/>
                </div>
                <div className={clsx(classes.column2, classes.columnHide, classes.helper)}>
                  <Typography>{port.name}</Typography>
                </div>
                <div className={clsx(classes.column3, classes.columnHide, classes.helper)}>
                  <Typography>{format({prefix: "TVL: ", suffix: ''})(xWinLib.getMarketCap(fundData))}</Typography>
                  <Typography variant={'body2'} className={classes.subTitle}>
                  {format({prefix: "Unit Price: $", suffix: ''})(xWinLib.roundTo(fundData.unipriceInUSD, 2))}
                  </Typography>
                </div>
                <div className={clsx(classes.column4, classes.helper)}>
                  <Grid item>
                    <Typography
                      color="text"
                      colorBrightness={"hint"}
                      variant={"caption"}
                      style={{ marginRight: 5 }}
                      noWrap
                    >
                      Total APR
                    </Typography>
                    <Box display="flex" alignItems="center" justifyContent={"left"}>
                      <Typography
                        size="md"
                        weight={"medium"}
                        style={{ marginRight: 8 }}
                      >
                        {format({prefix: "", suffix: '%'})(xWinLib.roundTo(getTotalAPY(), 2))}
                      </Typography>
                      <Dot size='medium' color="success" />
                    </Box>
                  </Grid>
                </div>
                <div className={clsx(classes.column5, classes.helper)}>
                <Typography
                      color="text"
                      colorBrightness={"hint"}
                      variant={"caption"}
                      style={{ marginRight: 5 }}
                    >
                      Balance
                    </Typography>
                  <Box display="flex" alignItems="center" justifyContent={"left"}>
                  
                  <Typography
                    size="md"
                    weight={"medium"}
                    style={{ marginRight: 8 }}
                  >   
                    {xWinUtils.displayBalance(loading, format({prefix: "", suffix: " "})(xWinLib.roundTo(getStakeFarmBalance(),4)))}
                  </Typography>
                    {!loading && getStakeFarmBalance() > 0.000000001 ? <Dot size='medium' color="secondary" /> : ""} 
                  </Box>
                  <Typography
                      color="text"
                      colorBrightness={"hint"}
                      variant={"caption"}
                      style={{ marginRight: 5 }}
                    >
                    {xWinUtils.displayBalance(loading, format({prefix: "Est $", suffix: ''})(xWinLib.roundTo(getStakeFarmBalance() * fundData.unipriceInUSD, 2)))}
                    </Typography>          
                  
                </div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails classes={classes.expansion}>
              <div className={clsx(classes.column6, classes.columnHide)}>
                <Grid item>
                </Grid>
              </div>
              <div className={classes.panel}>
                <Typography color={"primary"} variant={"caption"}>
                The vault stake and farm {port.name} in Pancakeswap. It auto compound the CAKE earned by harvesting, reinvest into the pool, and farm every day whenever a trigger of deposit or withdrawal. 
                The yield from the Pancakeswap pool is calculated based on the realized cake earned divided by the total supply of {port.name} vault during the compounding process
                Deposit in BNB and Withdraw in BNB. The vault takes care of all the swapping for you
                </Typography>
                <br/>
                <Table size="small">
                  <TableRow>
                      <TableCell className={classes.tableHightlightPositive}>
                        <Typography
                          color="text"
                          colorBrightness={"hint"}
                          variant={"caption"}
                          style={{ marginRight: 5 }}
                          //noWrap
                        >
                          PCS APY (Compound):
                        </Typography>
                        <Box display="flex" alignItems="center" justifyContent={"left"}>
                          <Dot size='medium' color="secondary" />
                          <Typography
                            size="md"
                            weight={"medium"}
                            style={{ marginLeft: 8 }}
                          >
                            {format({prefix: "", suffix: '% '})(xWinLib.roundTo(fundData.compoundAPR, 2))}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell className={classes.columnHide}>
                        <Typography
                          color="text"
                          colorBrightness={"hint"}
                          variant={"caption"}
                          style={{ marginRight: 5 }}
                          //noWrap
                        >
                          PCS Daily %
                        </Typography>
                        <Box display="flex" alignItems="center" justifyContent={"left"}>
                          <Dot size='medium' color="primary" />
                          <Typography
                            size="md"
                            weight={"medium"}
                            style={{ marginLeft: 8 }}
                          >
                             {format({prefix: "", suffix: '% '})(xWinLib.roundTo(fundData.dailyRate, 2))}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell className={classes.tableHightlightPositive}>
                        <Typography
                          color="text"
                          colorBrightness={"hint"}
                          variant={"caption"}
                          style={{ marginRight: 5 }}
                          //noWrap
                        >
                          Pending Cake in Vault
                        </Typography>
                        <Box display="flex" alignItems="center" justifyContent={"left"}>
                          <Dot size='medium' color="warning" />
                          <Typography
                            size="md"
                            weight={"medium"}
                            style={{ marginLeft: 8 }}
                          >
                            {format({prefix: "", suffix: ''})(xWinLib.roundTo(fundData.pendingCakeBal, 5))}
                          </Typography>
                        </Box>
                      </TableCell>
                  </TableRow>
                  <TableRow>
                      <TableCell className={classes.tableHightlightPositive}>
                        <Typography
                          color="text"
                          colorBrightness={"hint"}
                          variant={"caption"}
                          style={{ marginRight: 5 }}
                          // noWrap
                        >
                          xWIN APR:
                        </Typography>
                        <Box display="flex" alignItems="center" justifyContent={"left"}>
                          <Dot size='medium' color="info" />
                          <Typography
                            size="md"
                            weight={"medium"}
                            style={{ marginLeft: 8 }}
                          >
                            {port.xwinfarmpool? format({prefix: "", suffix: '% '})(xWinLib.roundTo(getEstReturns(), 2)): "N/A"}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell className={classes.columnHide}>
                        <Typography
                          color="text"
                          colorBrightness={"hint"}
                          variant={"caption"}
                          style={{ marginRight: 5 }}
                          // noWrap
                        >
                          xWIN Daily:
                        </Typography>
                        <Box display="flex" alignItems="center" justifyContent={"left"}>
                          <Dot size='medium' color="success" />
                          <Typography
                            size="md"
                            weight={"medium"}
                            style={{ marginLeft: 8 }}
                          >
                            {format({prefix: "", suffix: '% '})(getEstDailyReturns())}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell className={classes.tableHightlightPositive}>
                        <Typography
                          color="text"
                          colorBrightness={"hint"}
                          variant={"caption"}
                          style={{ marginRight: 5 }}
                          //noWrap
                        >
                          Total {port.name} in Vault
                        </Typography>
                        <Box display="flex" alignItems="center" justifyContent={"left"}>
                          <Dot size='medium' color="success" />
                          <Typography
                            size="md"
                            weight={"medium"}
                            style={{ marginLeft: 8 }}
                          >
                           {format({prefix: "", suffix: ''})(xWinLib.roundTo(fundData.lpBalance, 5))}
                          </Typography>
                        </Box>
                      </TableCell>
                  </TableRow>
                </Table>
              </div>
              <div className={clsx(classes.column6, classes.columnHide)}>
                <Grid item>
                </Grid>
              </div>
              </ExpansionPanelDetails>
              <ExpansionPanelActions >
              
              <Tooltip title="Add Token To Metamask">
                <Button variant="outlined" size="small" color="secondary">
                  <FavoriteIcon 
                    onClick={() => AddToMetamask()}
                    />
                </Button>
              </Tooltip>
              <Tooltip title="Deposit">
                <Button 
                  disabled="true"
                  // disabled={userData.selectedWallet === "" || xWinUtils.shouldDisable(fundtype)}
                  onClick={handleClickSubsDirect}
                  variant="contained" size="small" color="primary">
                        +
                </Button>
              </Tooltip>
              <Tooltip title="Redeem">
                <Button 
                  disabled={userData.selectedWallet === ""}
                  onClick={handleClickRedeem}
                  variant="contained" size="small" color="secondary">
                  -
                </Button>
              </Tooltip>
                <Link 
                  component={RouterLink}
                  to={{
                  pathname: "/app/funddetail/"+ port.contractaddress,
                  state: {
                    port: port,
                    tokensMaster: tokensMaster,
                    //gasPrices:gasPrices,
                    fundtype: fundtype
                  }
                }}>
                  <Button variant="contained" size="small" color="primary">
                    <InsertChartIcon/>
                  </Button>
                </Link>
                <div className={clsx(classes.column6, classes.columnShow)}>
                  <Grid item>
                  </Grid>
                </div>
              </ExpansionPanelActions>
            </ExpansionPanel>
 
            
    </div>
  );
}


export default withRouter(Yieldlist);