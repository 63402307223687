import React from "react";
import CountUp from 'react-countup';

import {
  Grid,
  Box,
  CardContent,
  Card
} from "@material-ui/core";

// styles
import useStyles from "../styles";
import Widget from "../../../components/Widget/Widget";
import { Typography } from "../../../components/Wrappers/Wrappers";
import Dot from "../../../components/Sidebar/components/Dot";


export default function TotalLockValue(props) {
  var classes = useStyles();
  const { totalValueLock } = props;

  return (
    <Card className={classes.card}>
      <CardContent>
        <Grid item>
          <Typography
              color="primary"
              colorBrightness={"hint"}
              variant={"caption"}
              style={{ marginRight: 5 }}
              justifyContent={"center"}
            >
              Total Value Locked
            </Typography>
          <Box display="flex" alignItems="center" justifyContent={"center"}>
          <Typography
            size="md"
            weight={"medium"}
            style={{ marginLeft: 8 }}
          >
            {/* {format({prefix: "", suffix: ''})(xWinLib.roundTo(xwinStats.rewardSupply,0))} */}
          </Typography>
        </Box>
        </Grid>
        <br/>
        <Grid item>
          <Typography
              color="text"
              colorBrightness={"hint"}
              variant={"caption"}
              style={{ marginRight: 5 }}
            >
              Across all Vaults and XWIN Farming Pools
            </Typography>
          <Box display="flex" alignItems="center" justifyContent={"center"}>
          
          <Dot size='medium' color="warning" />
          <Typography
            size="md"
            weight={"medium"}
            style={{ marginLeft: 8 }}
          >
            <CountUp 
              separator=","
              decimals={0}
              decimal="."
              prefix="$ "   
              duration={1.75}         
              end={totalValueLock} />
            {/* {format({prefix: "$", suffix: ''})(xWinLib.roundTo(totalValueLock,0))} */}
          </Typography>
        </Box>
        </Grid>
        
      </CardContent>
  </Card>
    
  );
}
