import React, { useState, useEffect  } from "react";
import {
  Grid,
  Card,
  CardActions,
  Container,
  CardHeader,
  CardContent,
  Snackbar,
  IconButton
} from "@material-ui/core";
import { Typography, Avatar } from "../../components/Wrappers/Wrappers";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { Alert } from '@material-ui/lab';
import xWinUtils from '../../xWinUtils'
import useStyles from "./styles";


const Referral = () => {
  const classes = useStyles();
  

  const [selectedWallet, setSelectedWallet] = useState("")
  const [copied, setCopied] = useState("")
  const [openMsg, setOpenMsg] = React.useState(false);
  
  useEffect(() => {
    const controller = new AbortController();
    loadWeb3()
    return () => {
      controller.abort();
    }
  }, []);

  const loadWeb3 = async () => {

    window.web3 = await xWinUtils.getWindowWeb3()
    const wallet = await xWinUtils.getWalletAccount(window.web3)
    setSelectedWallet(wallet)

  }
  
  const getReferralLink = () => {
    
    return `https://xwin.finance/app/referral/${ selectedWallet }`;
  }
  
  const hideMessage = () => {
    setOpenMsg(false);
  };

  const setCopy = event => {
    //event.preventDefault();
    setCopied(true); 
    setOpenMsg(true)
  };

  
  return (
    
      <Container>
      <Snackbar open={openMsg} autoHideDuration={1000} onClose={hideMessage}>
          <Alert onClose={hideMessage} severity={"success"}>
           Copied!
          </Alert>
      </Snackbar>
      <Grid
        container
        spacing={1}
      >
        <Grid
          item lg={12} sm={12} xs={12}>
            <CardContent>
              <Typography variant="h1" className={classes.whitetext}>
                Invite Friends.
                <br/>Earn xWIN Token Together
              </Typography>
            </CardContent>
           
        </Grid>
        <Grid
          item lg={12} sm={12} xs={12}>
          <Card>
            <CardHeader
              avatar={
                <Avatar 
                  src="../../images/background/referral.png" 
                  className={classes.avatar}>
                </Avatar>
              }
              title= {"Referral Link"}
              subheader={selectedWallet}
              titleTypographyProps={{variant:'h2' }}
              subheaderTypographyProps={{variant:'body1' }}
            />
            <CardContent>
              <Typography variant="body" className={classes.mainHeading} >
                For every 1 BNB deposited by your friend, 0.20 $XWIN token will be accumulated to your wallet.
                Referral ID would be your public wallet address. Paste the following link in your twitter or blogs today!
              </Typography>
              <Typography
                        color="text"
                        colorBrightness={"hint"}
                        variant={"caption"}
                        style={{ marginRight: 5 }}
                      >
                *** In order to allow xWIN Protocol to recognize your address, you need to at least subscribed to any of xWIN index, trading or yield vaults. 
              </Typography>
              <Typography variant="body" className={classes.secondaryHeading}>
                Copy My Referral Link:
                <CopyToClipboard text={getReferralLink()}
                  onCopy={setCopy}>
                    <IconButton 
                      aria-label="settings"
                      >
                        <FileCopyIcon/>
                    </IconButton>
                </CopyToClipboard>
              </Typography>
            </CardContent>
            <CardActions>
            <CardContent>
              <Typography variant="body" className={classes.secondaryHeading}>
                Invite Now..!
              </Typography>
              <br/>
              <Typography variant="body" className={classes.secondaryHeading}>
              <a href={"https://twitter.com/intent/tweet?text=Click on the link to kick start your portfolio&url="+getReferralLink()} add target="_blank" rel="noopener noreferrer"><img src="../../images/profile/twitter2.svg" alt={"twitter"} /></a>
              <a href={"https://www.facebook.com/sharer/sharer.php?u="+ getReferralLink()+"&t=xWIN%20Referral%20Program"} add target="_blank" rel="noopener noreferrer"><img src="../../images/profile/facebook2.svg" alt={"facebook"} /></a>
              <a href={"https://www.linkedin.com/sharing/share-offsite/?url="+ getReferralLink()} add target="_blank" rel="noopener noreferrer"><img src="../../images/profile/linkedin2.svg" alt={"LinkedIn"} /></a>
              </Typography>

              </CardContent>
              </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Referral;
