import React from "react";
import { 
  Grid, 
  MobileStepper,
  Card,
  CardHeader,
  CardContent,
  Avatar,
  IconButton,
  Container
 } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import useStyles from "./styles";

//components
import { Button, Typography } from "../../components/Wrappers/Wrappers";
import Widget from "../../components/Widget/Widget";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const members = [
  {
    name: "Calvin Thong",
    title: "Founder",
    linkedin: "https://www.linkedin.com/in/calvin-thong-b5b85116/",
    desc: "Calvin Thong, founder of xWIN, is experienced IT-skilled and ex-portfolio manager in ETF and Index funds in BlackRock. Calvin graduated with Masters in Applied Finance on Macquarie University and Bachelor degree in computer science",
    imgPath: "/images/avatars/calvin.jpg"
  },
  {
    name: "Fumihiro Arasawa",
    title: "Co-founder",
    linkedin: "https://www.linkedin.com/in/fumi-arasawa-7b5832118/",
    desc: "Fumihiro Arasawa, experienced in business consulting and acted as the president of Vietnam in Japanese consulting firm. Fumi contributed to globalization, strategy creation and organization making for his clients",
    imgPath: "/images/avatars/fumihiro.jpg"
  },
  {
    name: "Yuki",
    title: "Designer",
    linkedin: "#",
    desc: "Yuki graduated from Art University and had been working for an advertising agency",
    imgPath: "/images/avatars/yuki.jpg"
  }
];

const advisors = [
  {
    name: "Seiichiro Yonekura",
    title: "Advisor",
    linkedin: "https://www.linkedin.com/in/seiichiro-yonekura-58a00610/",
    desc: "Japanese business scholar. He specializes in business history. He is a specially appointed professor at Hitotsubashi University, an emeritus professor, a professor at Hosei University, and Ph at Harvard University",
    imgPath: "/images/avatars/yonekura.jpg"
  },
  {
    name: "Yokohama Kitanakadori Law Office",
    title: "Legal Advisor",
    linkedin: "http://www.ksm-lo.jp/",
    desc: "As a legal specialist, they handle a variety of cases. Corporate legal affairs such as labor measures, compliance support and measures against violence for companies, compensation for damages and measures against civil intervention violence, etc",
    imgPath: "/images/avatars/lawyer.jpg"
  },
  {
    name: "Dai Oshima",
    title: "Marketing Advisor",
    linkedin: "https://www.linkedin.com/in/dai-oshima-a21667207/",
    desc: "Mr. Oshima has worked at major Japanese company over 20 years as a marketing specilalist. He will use that knowledge to liven up the xWIN community",
    imgPath: "/images/avatars/oshima.jpg"
  },
  {
    name: "Daisuke Kobayashi, CFA",
    title: "Trading Advisor",
    linkedin: "https://www.linkedin.com/in/daisuke-kobayashi-cfa-47801a4/",
    desc: "KB has extensive experience in the financial sector in Japan, with over 20 years of trading experience. He is eager to act as a bridge between his trading skills and the Defi business",
    imgPath:  "/images/avatars/kobayashi.jpg"
  }
];

function TeamMember() {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [activeStepAdvisor, setActiveStepAdvisor] = React.useState(0);
  const maxSteps = members.length;
  const maxStepsAdvisor = advisors.length;

  const handleNextAdvisor = () => {
    setActiveStepAdvisor(prevActiveStep => prevActiveStep + 1);
  };

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBackAdvisor = () => {
    setActiveStepAdvisor(prevActiveStep => prevActiveStep - 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleStepAdvisorChange = step => {
    setActiveStepAdvisor(step);
  };

  const handleStepChange = step => {
    setActiveStep(step);
  };

  const handleClickLink = (linkname) => event => {
    window.open(linkname,"_new")
  };

  const getHeader = (myname, url) => {

    return (
      <div>
        <Typography variant="h3" >
        {myname}
        <IconButton 
              onClick={handleClickLink(url)}
              className={classes.mainHeading}
              aria-label="settings">
                    <img alt={myname} src={"/images/logos/linkedin-icon.png"} />
                  {/* <ArrowBackIcon /> */}
                  
                </IconButton>
        </Typography>  
      </div>
    )
      
  }

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item 
          lg={6}
          sm={12}
          xl={6}
          xs={12}>
            <Widget 
              className={classes.widgetLayout}
              title="Team Members"
              disableWidgetMenu
            >
            <AutoPlaySwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={activeStep}
              onChangeIndex={handleStepChange}
              enableMouseEvents
            >
              {members.map((step, index) => (
                <div key={step.name}>
                  {Math.abs(activeStep - index) <= 2 ? (
                    <Card className={classes.card}>
                      <CardHeader
                        avatar={
                          <Avatar
                            className={classes.avatar}
                            src={step.imgPath}
                            onClick={handleClickLink(step.linkedin)}
                          />
                        }
                        className={classes.primaryHeading}
                        title={getHeader(step.name, step.linkedin)}
                        subheader= {step.title}
                        titleTypographyProps={{variant:'h5' }}
                        subheaderTypographyProps={{variant:'subtitle1' }}
                      />
                      <CardContent>
                      <Typography variant="body2" color="textSecondary" component="p">
                        {step.desc}
                      </Typography>
                    </CardContent> 
                    
                    </Card>
                  ) : null}
                </div>
              ))}
            </AutoPlaySwipeableViews>
            <MobileStepper
              steps={maxSteps}
              position="static"
              variant="dots"
              activeStep={activeStep}
              nextButton={
                <Button
                  size="small"
                  onClick={handleNext}
                  disabled={activeStep === maxSteps - 1}
                >
                  Next
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                >
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                  ) : (
                    <KeyboardArrowLeft />
                  )}
                  Back
                </Button>
              }
            />
            </Widget>
        </Grid>
        <Grid item 
          lg={6}
          sm={12}
          xl={6}
          xs={12}>
            <Widget 
              className={classes.widgetLayout}
              title="Advisors"
              disableWidgetMenu
            >
            <AutoPlaySwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={activeStepAdvisor}
              onChangeIndex={handleStepAdvisorChange}
              enableMouseEvents
            >
              {advisors.map((step, index) => (
                <div key={step.name}>
                  {Math.abs(activeStepAdvisor - index) <= 2 ? (
                    <Card className={classes.card}>
                      <CardHeader
                        avatar={
                          <Avatar
                            className={classes.avatar}
                            src={step.imgPath}
                            onClick={handleClickLink(step.linkedin)}
                          />
                        }
                        className={classes.primaryHeading}
                        title={getHeader(step.name, step.linkedin)}
                        subheader= {step.title}
                        titleTypographyProps={{variant:'h5' }}
                        subheaderTypographyProps={{variant:'subtitle1' }}
                      />
                      <CardContent>
                      <Typography variant="body2" color="textSecondary" component="p">
                        {step.desc}
                      </Typography>
                    </CardContent> 
                    
                    </Card>
                  ) : null}
                </div>
              ))}
            </AutoPlaySwipeableViews>
            <MobileStepper
              steps={maxStepsAdvisor}
              position="static"
              variant="dots"
              activeStep={activeStepAdvisor}
              nextButton={
                <Button
                  size="small"
                  onClick={handleNextAdvisor}
                  disabled={activeStepAdvisor === maxStepsAdvisor - 1}
                >
                  Next
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  onClick={handleBackAdvisor}
                  disabled={activeStepAdvisor === 0}
                >
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                  ) : (
                    <KeyboardArrowLeft />
                  )}
                  Back
                </Button>
              }
            />
            </Widget>
        </Grid>      
      </Grid>
    </Container>
  );
}

export default TeamMember;
