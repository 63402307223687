import React, { useState, useEffect, useRef, useContext  } from "react";
import xWinLib from '../../xWinLib';
import xWinUtils from '../../xWinUtils';
//import xWinSale from '../../abi/xWinSale.json'
import xWinSale from '../../abi/xWinSaleV2.json'
import BEP20 from '../../abi/BEP20.json'
import appGlobalConfig from '../../appGlobalConfig';
import format from 'format-number';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SnackbarMessage from '../snackbarmessage/SnackbarMessage'
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import {
  Grid,
  Container,
  Box,
  TextField,
  InputAdornment,
  Chip,
  ButtonGroup,
  Fade,
} from "@material-ui/core";
import {
  Star as StarIcon,
  Help as HelpIcon
} from "@material-ui/icons";
import confetti from 'canvas-confetti'


// styles
import useStyles from "./styles";

// components
import Widget from "../../components/Widget/Widget";
import { Typography, Avatar, Button, Tooltip } from "../../components/Wrappers/Wrappers";
import {AppContext}  from '../../context/AppProvider';


const BuyLP = props => {
  
  var classes = useStyles();

  const [loadingTxn, setLoadingTxn] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [subsAmt, setsubsAmt] = useState("0");
  const [LPSubsAmt, setLPSubsAmt] = useState("0");
  const [tokenOutAmtForLP, setTokenOutAmtForLP] = useState("0");
  const [extraXWIN, setExtraXWIN] = useState("0");
  const [bonusLockPeriod, setBonusLockPeriod] = useState("0");
  
  const [fundData, setFundData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [xWinSaleProtocol, setxWinSaleProtocol] = useState("")
  const [xWinSaleProtocolDisplay, setxWinSaleProtocolDisplay] = useState("")
  const refsnackbar = useRef(null);
  
  const { isConnected, ETHBalance, account, theWeb3, web3static } = useContext(AppContext);
  // if(theWeb3 !== undefined){
  //   theWeb3.currentProvider.on("accountsChanged", (accounts) => {
  //   });
  //   theWeb3.currentProvider.on("chainChanged", (accounts) => {
  //     loadWeb3();
  //   });
  // }

  useEffect(() => {
    const controller = new AbortController();
    loadWeb3();
    return () => {
      controller.abort();
    }
  }, [account, ETHBalance]);

  const loadWeb3 = async () => {
    
    // if(!isConnected) return
    if(theWeb3 === undefined) return
    
    setLoading(true)
    let myProtocol = new theWeb3.eth.Contract(xWinSale, appGlobalConfig.mainnetxWinSale);
    let myProtocolDisplay = new web3static.eth.Contract(xWinSale, appGlobalConfig.mainnetxWinSale);
    setxWinSaleProtocol(myProtocol)
    setxWinSaleProtocolDisplay(myProtocolDisplay)
    let xWINBEP20 = new web3static.eth.Contract(BEP20, appGlobalConfig.mainnetXWIN);
    let xWINBalance = await xWINBEP20.methods.balanceOf(appGlobalConfig.mainnetxWinSale).call()
    let myXWINBalance = 0
    let userInfoLP
    if(account !== "---"){
      myXWINBalance = await xWINBEP20.methods.balanceOf(account).call()
      myXWINBalance = xWinUtils.convertFromWei(web3static, xWINBalance.toString())
      userInfoLP = await myProtocol.methods.userInfoLP(account).call()
    }
    let extraBonus = await myProtocolDisplay.methods.accLPBonusPercent().call()
    let lockPeriod = await myProtocolDisplay.methods.lockperiod().call()
    let lockperiodNextBuy = await myProtocolDisplay.methods.lockperiodNextBuy().call()
    let currentBlock = await web3static.eth.getBlockNumber()
    setFundData({
      "xWINBalance" : xWinUtils.convertFromWei(theWeb3, xWINBalance.toString()),
      "extraBonus" : extraBonus / 10000,
      "lockPeriod" : lockPeriod,
      "lockperiodNextBuy": lockperiodNextBuy
    })
    setUserData({
      "walletETHBalance" :  ETHBalance, 
      "selectedWallet" : account,
      "myXWINBalance" : myXWINBalance,
      "nextBuyBlock" : account === "---" ? "0" : userInfoLP?.nextBuyBlock,
      "currentBlock" : currentBlock,
      "allowToBuy" : account === "---" ? false : currentBlock > userInfoLP?.nextBuyBlock
    })  
    
    setLoading(false)
  };


  const handleAmtChange = () => event => {
    
    event.preventDefault()
    setExtraXWIN(0)
    const subsAmount = parseFloat(event.target.value)
    if(subsAmount >= 0 && subsAmount !== undefined){
      setsubsAmt(subsAmount)
      getBNBToXWIN(subsAmount)
      setLPSubsAmt(subsAmount / 2)
    }
  }

  
  const getBNBToXWIN = async (amount) => {
    
    try {

      if(account === "---") return
      if(amount === undefined) return 
      if(xWinSaleProtocolDisplay === "" || xWinSaleProtocolDisplay === undefined) return 
      const subsAmtInWei = web3static.utils.toWei(amount.toString())
      let getAmountOut = await xWinSaleProtocol.methods.getBNBToXWIN(subsAmtInWei).call()
      getAmountOut = xWinUtils.convertFromWei(web3static, getAmountOut.toString())
      setTokenOutAmtForLP(getAmountOut / 2)

      //let pPerBNB = amount / getAmountOut  

      let one = 1e18
      let getAmountOutFor1 = await xWinSaleProtocolDisplay.methods.getBNBToXWIN(one.toString()).call()
      getAmountOutFor1 = xWinUtils.convertFromWei(web3static, getAmountOutFor1.toString())
      let theoryAmountOut = amount * getAmountOutFor1
      //let priceImpact = getAmountOut / theoryAmountOut - 1 

      let extra = xWinLib.roundTo(getAmountOut / 2 * fundData.extraBonus, 4)
      setExtraXWIN(extra)

      let lockDays = xWinLib.roundTo(fundData.lockPeriod / 28800, 2)
      setBonusLockPeriod(lockDays)
      
    } catch (error) {
      console.log(error)
    }
    
    
  }

  const handleClickAmt = (ratio) => event => {
    event.preventDefault();

    if(userData.walletETHBalance <= 0){
      setExtraXWIN(0)
      setLPSubsAmt(0)
      setsubsAmt(0)
      setLPSubsAmt(0)  
      setExtraXWIN(0)


    }else{
      setExtraXWIN(0)
      setLPSubsAmt(0)
      const subAmount = xWinLib.roundTo(ratio * userData.walletETHBalance, 5)
      setsubsAmt(subAmount)
      getBNBToXWIN(subAmount)
      setLPSubsAmt(subAmount / 2)  
    } 

  };

  const handleClickClearAmt = () => event => {
    event.preventDefault();
    setsubsAmt(0)
    //setTokenOutAmt(0)
    setLPSubsAmt(0)
    setTokenOutAmtForLP(0)
    setExtraXWIN(0)
  };

  const handleClickBuy = event => {
    event.preventDefault();
    
    if(parseFloat(subsAmt) <= 0){
      showSnackBar("error", "Please input BNB")
      return
    } 

    //let totalXWINNeeded = parseFloat(tokenOutAmtForLP) + parseFloat(extraXWIN)
    let totalXWINNeeded = (parseFloat(tokenOutAmtForLP) * 2) + parseFloat(extraXWIN)
    if(totalXWINNeeded > parseFloat(fundData.xWINBalance)){
      showSnackBar("error", "Apologize. Out of inventory")
      return
    } 
    
    if(parseFloat(LPSubsAmt) > parseFloat(fundData.maxBuy)){
      showSnackBar("error", "Cannot buy more than " + fundData.maxBuy + " each time")
      return
    } 

    showSnackBar("info", "XWIN-BNB LP Token and $XWIN Bonus can be claimed in Claim Token & Bonus page after "+ bonusLockPeriod + " days")
        
    
    setLoadingTxn(true)
    const subsAmtInWei = theWeb3.utils.toWei(subsAmt.toString())
    xWinLib.BuyLPTokenAsync(
      xWinSaleProtocol, 
      subsAmtInWei,
      userData
      )
    .then(res =>
      { 
        console.log(res)
        setLoadingTxn(false)
        showSnackBar("success", res)
        confetti()
        setsubsAmt(0)
        //setTokenOutAmt(0)
        setLPSubsAmt(0)
        setTokenOutAmtForLP(0)
        setExtraXWIN(0)
        loadWeb3()
      }
    )
    .catch(err => {
      setLoadingTxn(false)
      console.log(err)
      showSnackBar("error", err)
    }
    )
  };

  const showSnackBar = (status, msg) => {
    refsnackbar.current.handleOpen(status, msg);
  }

  const displayStar = () => {

    if(extraXWIN > 0){
      return(
        <div>
        <StarIcon className={classes.starIcon} />
        <StarIcon className={classes.starIcon} />
        </div>
      )
    }
  }

  const getButtonAction = () => {

    if(fundData.xWINBalance <= 2){
       return (
        <Button 
          disabled={true}
          color="primary"
          variant="contained"
          fullWidth
          startIcon={<AddCircleIcon />}>
            {"Out of Stock"}
        </Button>
       )
    }else{

      return (
        <Button 
          disabled={fundData.xWINBalance <= 1}
          onClick= {userData.allowToBuy ? handleClickBuy : goToBSCscan} 
          color="primary"
          variant="contained"
          fullWidth
          startIcon={<AddCircleIcon />}>
            {userData.allowToBuy ? "Get XWIN-BNB PCS LP" : "Next Buying After Block " + userData.nextBuyBlock}
        </Button>
      )
    }
  }

  const goToBSCscan = event => {
    if(userData.nextBuyBlock > 0)	window.open("https://bscscan.com/block/countdown/"+ userData.nextBuyBlock, "_blank")
	}

  return (
    <Container>
      <SnackbarMessage ref={refsnackbar} />
    <Grid container spacing={3}>
      {xWinUtils.getProgress(loadingTxn)}

      <Grid item lg={8} sm={12} xs={12}>
        <Widget 
          className={classes.widgetLayout}
          title="Buy XWIN_BNB LP Token"
          disableWidgetMenu
          subtitle={"Available XWIN in Pool "+ format({prefix: "", suffix: " "})(xWinLib.roundTo(fundData.xWINBalance,4))}
          enableWalletDisplay
          selectedWallet={userData.selectedWallet}
        >
            
            <Box display="flex" alignItems="center" justifyContent={"center"}>
              <ButtonGroup fullWidth size="large" color="primary" aria-label="">
                  <Button
                    fullWidth
                    // disabled={true}
                    color="primary"
                    size="small"
                    variant="outlined"
                    onClick={handleClickAmt(0.25)}
                  >
                    {'25%'}
                  </Button>
                  <Button
                    fullWidth
                    // disabled={true}
                    color="primary"
                    size="small"
                    variant="outlined"
                    onClick={handleClickAmt(0.50)}
                  >
                    {'50%'}
                  </Button>
                  <Button
                    className={classes.hide}
                    // disabled={true}
                    fullWidth
                    color="primary"
                    size="small"
                    variant="outlined"
                    onClick={handleClickAmt(0.75)}
                  >
                    {'75%'}
                  </Button>
                  <Button
                    fullWidth
                    // disabled={true}
                    color="primary"
                    size="small"
                    variant="outlined"
                    onClick={handleClickAmt(0.99)}
                  >
                    {'100%'}
                  </Button>
                  <Button
                    // disabled={true}
                    fullWidth
                    color="primary"
                    size="small"
                    variant="outlined"
                    onClick={handleClickClearAmt()}
                  >
                  {'Clear'}
                </Button>
                </ButtonGroup>   
          </Box>
          <div className="mb-1 mt-3">
          <Typography
              color="text"
              colorBrightness={"hint"}
              variant={"caption"}
              style={{ marginRight: 5 }}
              noWrap
            >{"Total BNB "}</Typography>
          </div>
          <Box display="flex" alignItems="center" justifyContent={"left"}>
            <TextField
              type="number" 
              name="weight"
              //disabled={true}
              onChange={handleAmtChange()}
              required
              variant="outlined"
              value={subsAmt}
              fullWidth
              InputProps={{
                classes: {
                  input: classes.positiveNum,
                },
                style: { textAlign: "right" },
                startAdornment: 
                  <InputAdornment position="start">
                    <Chip 
                      size="large" 
                      //variant="contained"
                      label={xWinUtils.displayBalance(false, format({prefix: "", suffix: " "})(xWinLib.roundTo(userData.walletETHBalance,4)))}
                      color="primary" avatar={<Avatar src="/images/icons/color/bnb.png" />} />
                  </InputAdornment>,
              }}
            />
          </Box>
          <br/>
          <Box display="flex" alignItems="center" justifyContent={"center"}><ArrowDropDownCircleIcon/></Box>
          <div className="mb-1">
            <Typography
              color="text"
              colorBrightness={"hint"}
              variant={"caption"}
              style={{ marginRight: 5 }}
              noWrap
            >
              {"BNB will be split into 50%"}
            </Typography>
          </div>

          <Box display="flex" alignItems="center" justifyContent={"left"}>
              <TextField
                  variant="outlined"
                  value={xWinUtils.displayBalance(loading, format({prefix: "", suffix: ''})(xWinLib.roundTo(LPSubsAmt, 4)))}
                  fullWidth
                  InputProps={{
                    classes: {
                      input: classes.positiveNum,
                    },
                    readOnly: true,
                    style: { textAlign: "right" },
                    startAdornment: 
                      <InputAdornment position="start">
                        <Chip 
                        size="large" 
                        //label={xWinUtils.displayBalance(false, format({prefix: "", suffix: " "})(xWinLib.roundTo(userData.myXWINBalance,4)))}
                        color="primary" avatar={<Avatar src="/images/icons/color/bnb.png" />} />
                      </InputAdornment>,
                  }}
                />
                <div className="px-2">
                  <AddCircleIcon />
                </div>
              <TextField
                  variant="outlined"
                  value={xWinUtils.displayBalance(loading, format({prefix: "", suffix: ''})(xWinLib.roundTo(tokenOutAmtForLP, 2)))}
                  fullWidth
                  InputProps={{
                    classes: {
                      input: classes.positiveNum,
                    },
                    readOnly: true,
                    style: { textAlign: "right" },
                    startAdornment: 
                      <InputAdornment position="start">
                        <Chip 
                        size="large" 
                        //label={xWinUtils.displayBalance(false, format({prefix: "", suffix: " "})(xWinLib.roundTo(userData.myXWINBalance,4)))}
                        color="primary" avatar={<Avatar src="/images/icons/color/xwin.png" />} />
                      </InputAdornment>,
                  }}
                />
          </Box>
          <br/>
          
            <Typography
              color="text"
              colorBrightness={"hint"}
              variant={"caption"}
              style={{ marginRight: 5 }}
              noWrap
            >{"via XWIN Pool + Get "+ format({prefix: "", suffix: "%"})(fundData.extraBonus * 100) +" Bonus"}</Typography>
          
          
          <br/>
          
          <Box display="flex" alignItems="center" justifyContent={"center"}>
          {displayStar()}
            <Typography
              color="text"
              //colorBrightness={"hint"}
              variant={"medium"}
              style={{ marginRight: 5 }}
              className={classes.subTitle}
              noWrap
            >
              {"Est Bonus XWIN "+ format({prefix: "", suffix: ""})(extraXWIN)}
            </Typography>
            {displayStar()}
            
          </Box>
          <br/>
            <Box display="flex" alignItems="center" justifyContent={"left"}>
            <Typography
              color="text"
              colorBrightness={"hint"}
              variant={"caption"}
              style={{ marginRight: 5 }}
            >
              <a className={classes.link}>
              {"*** " + bonusLockPeriod +" days"} </a> 
              locked period applied to the XWIN-BNB LP + Bonus token. Timer reset everytime you buy from the platform
            </Typography>
            <Tooltip 
              TransitionComponent={Fade}  
              title={
                <h4 style={{ color: "lightblue" }}>
                  {"XWIN-BNB LP Token and $XWIN Bonus can be claimed in Claim Token & Bonus page after "+ bonusLockPeriod + " days"}</h4>
              }
                 
                arrow={true}>
              <HelpIcon />
            </Tooltip>
          </Box>
          <br/>
          <Box display="flex" alignItems="center" justifyContent={"left"}>
          {getButtonAction()}
          </Box>
          <br/>
            <Box display="flex" alignItems="center" justifyContent={"left"}>
            <Typography
              color="text"
              colorBrightness={"hint"}
              variant={"caption"}
              style={{ marginRight: 5 }}
            >
                <a className={classes.link} href={xWinLib.getEtherLink(fundData.networkName, appGlobalConfig.mainnetxWinSale, "address")} target="blank">Contract</a>
            </Typography>
          </Box>
            
        </Widget> 
      </Grid>
      
    </Grid>
    </Container>
  );
}


export default BuyLP;
