import React, { useState, useEffect, useContext, useRef } from "react";
import { Link } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  IconButton,
  Tooltip,
  Button,
  Typography,
  MenuItem,
  Menu,
  ListItemIcon,
  ListItemText
} from "@material-ui/core";
import SendIcon from '@material-ui/icons/Send';
import xWinLib from '../../xWinLib';
import format from 'format-number';
import SnackbarMessage from '../../pages/snackbarmessage/SnackbarMessage'
import ReactInterval from 'react-interval'

import { useTheme } from "@material-ui/styles";
import {
  Menu as MenuIcon,
  ArrowBack as ArrowBackIcon,
  Stop as StopIcon
} from "@material-ui/icons";
import classNames from "classnames";
import xWinUtils from '../../xWinUtils';

import xwinlogo from "../../images/logo/xwinlogo.jpg";
import {AppContext}  from '../../context/AppProvider';

// styles
import useStyles from "./styles";

// components
import { Avatar } from "../Wrappers/Wrappers";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar
} from "../../context/LayoutContext";


export default function Header(props) {
  var classes = useStyles();
  var theme = useTheme();
  const { ETHBalance, isConnected, account, theWeb3, appMsg, connectNetwork, disconnectNetwork, setAppMsg } = useContext(AppContext);
  // global
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  const [isSmall, setSmall] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const refsnackbar = useRef(null);
  const showSnackBar = (status, msg) => {
    refsnackbar.current.handleOpen(status, msg, "bottom");
  }

  // if(theWeb3 !== undefined){
  //   theWeb3.currentProvider.on("accountsChanged", (accounts) => {
  //     //console.log(accounts);
  //   });
  // }

  
  useEffect(function() {
    if(!isConnected){
      if(refsnackbar.current !== null) showSnackBar("info", "Please connect your wallet to BSC Mainnet")
    }else{
      if(refsnackbar.current !== null) refsnackbar.current.hideMessageTxn();
    }
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  const handleClickConnect = async event => {
    event.preventDefault();
    
    if (!isConnected) {
			connect()
		} else {
			disconnect()
      setAnchorEl(null)
		}
  };
  
  const connect = () => {
		connectNetwork().then(() => {
			setAppMsg(["info", "Connected"])
		}).catch(err => {
			if (err === undefined || err.message === undefined) {
				setAppMsg(["info", "Connect Request Cancelled"])
			} else {
				setAppMsg(["error", `Error in Connect Network: ${err.message}`])
			}
		})
	}

	const disconnect = () => {
		disconnectNetwork().then(() => {
			setAppMsg(["info", "Disconnected"])
		}).catch(err => {
			setAppMsg(["error", `Error in Disconnect Network: ${err.message}`])
		})
	}

  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;
    setSmall(isSmallScreen);
  }

  const getWalletMessage = () => {

    if (!isConnected) {
      return (
        <Tooltip>
          <Button onClick={handleClickConnect}>
            <Typography
              className={classes.positiveNum}
              //color="text"
              //colorBrightness={"hint"}
              variant={"caption"}
              style={{ marginRight: 5 }}
              noWrap
            >
              Connect Wallet
            </Typography>
          </Button>
        </Tooltip>
      ) 
    } else {
      return (
        <IconButton
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={handleClick}
        >
          <Avatar
            alt="xWIN"
            src={xwinlogo}
            //classes={{ root: classes.headerIcon }}
          />
         <Typography
              color="text"
              colorBrightness={"hint"}
              variant={"caption"}
              style={{ marginLeft: 8 }}
              noWrap
            >
          {getAddressShort(account)}
        </Typography>
        </IconButton>
      )
    } 
  }
  const getAddressShort = (selectedWallet) => {

    if(selectedWallet === undefined) return ""
    return xWinUtils.getAddressShort(selectedWallet)
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeBar = () => {
    refsnackbar.current.hideMessageTxn();
  }

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <SnackbarMessage ref={refsnackbar} />
        <ReactInterval timeout={5000} enabled={true}
          callback={() => closeBar()} />

        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButton,
            classes.headerMenuButtonCollapse
          )}
        >
          {(!layoutState.isSidebarOpened && isSmall) ||
          (layoutState.isSidebarOpened && !isSmall) ? (
            <ArrowBackIcon
              classes={{
                root: classNames(classes.headerIcon, classes.headerIconCollapse)
              }}
            />
          ) : (
            <MenuIcon
              classes={{
                root: classNames(classes.headerIcon, classes.headerIconCollapse)
              }}
            />
          )}
        </IconButton>
          <Link to="/" classNames={classes.profileLabel}>
            <img src={"../../../images/background/xWin Logo.png"} alt="logo" height={40} />
          </Link>
          <div className={classes.grow} />
          {getWalletMessage()}
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <SendIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={format({prefix: "BNB ", suffix: ''})(xWinLib.roundTo(ETHBalance,4)) } />
              </MenuItem>
            <MenuItem onClick={handleClickConnect}>
              <ListItemIcon>
                <StopIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={"Disconnect"} />
              </MenuItem>
          </Menu>
      </Toolbar>
    </AppBar>
  );
}
