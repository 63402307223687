const appGlobalConfig = {
  actionCodeSettingsURL: 'https://xlending.cc/sign-in',
  //testnetXWIN: '0xaF2620433f0D485730A7D855911fBa8B098e2A74',
  testnetXWIN: '0xa83575490D7df4E2F47b7D38ef351a2722cA45b9',
  mainnetXWIN: '0xd88ca08d8eec1E9E09562213Ae83A7853ebB5d28',
  mainnetXWINBNBLPPool: '0x2D74b7DbF2835aCadd8d4eF75B841c01E1a68383',
  mainnetXWINUSDTLPPool: '0x2E5946E39687FdE415aFEa24fF7272231F69adAF',
  mainnetXWINUSDTAPELPPool: '0x4F263c2F03d8dcd7Dea928de0224e34cEbD9F03c',
  mainnetXWINBNBLPPoolV2: '0xD4A3Dcf47887636B19eD1b54AAb722Bd620e5fb4',
  mainnetXWINBNBLPPoolApeSwap: '0x9f1762b15e740704f40a3606b31dccfbe5318d8b',
  mainnetxWinLuckyDraw: '0x6F995c3e2001D45e2ACa7d6f537ce6a106767Beb', //'0x61Bc448a71b01e0693EB3ABA630Ab046D7C13108', // Testnet
  mainnetxWinProtocol: '0x1Bf7fe7568211ecfF68B6bC7CCAd31eCd8fe8092',
  mainnetxWinProtocolDummy: '0x21B323a2Ac030095A7f9509B3b53f52367B76D94',
  mainnetxWinProtocolTestnet: '0x788E09a42b64d5C4D5887eB426da2CA810a93D8e',
  mainnetxWinSalev1: '0x78bd8dad87D668180BD77c34131eDe04963CbD97',
  mainnetxWinSale: '0x2AD5a4213cCd4569Ec82E7Dc5C0dEc91DB95bbD2',
  mainnetxWinSaleBonus: '0x83c346A57ed1CEAa03f954bE0142044773980e61',
  mainnetxWinSaleBonusv1: '0x4ee8909Bd14c572738514B5f92AB44353AC42779',
  mainnetxWinTeamRewards: '0x8672B1e168053927d8d39C55902d1Ed60498ED53',
  /*
   xWInCompensation addresses 
   Replace the following 2 lines when you release on a production.  
  */
  // mainnetxWinCompensation: '0xB722B10c219442501f60d014FCD41A9C7fe65B75',  // Dummy Compensation on TestNet
  // mainnetWinRobustCompensation: '0x1Bb012894779D5e5A51f8504291A6a93f3D6815E',  // Address of xWinRobustCompensation in Testnet
  mainnetxWinCompensation: '0xB295306381456Cf4f86db2d4E3AcEF03D1aAb793',  // use this line when mainnet
  mainnetWinRobustCompensation: '0xb8c695A62a0D663dE4D7Fe81A460B3a6642321E0',      // use this line when mainnet 
  mainnetxWinUSDTStaking: '0x95088e705BC6bE78642f2F20A3286190af000685',
  mainnetxWinPrivateUSDT: '0xd2DC56F4BcAb9377FBfC689ED57777F2aC3DFd36',
  mainnetJPYC: '0x158e63f5afa64c84b07e462c3e45fe62c5419803',
  mainnetxWINMaster: '0xCA9e31975E37f9E29BFC81B6E0eb5D571610d387', 
  apiGetWinStats: 'https://us-central1-xwininvestment.cloudfunctions.net/getXWINStats',
  apiGetTVL: 'https://us-central1-xwininvestment.cloudfunctions.net/getTotalLockValueV2',
  apiGetJPYUSD: 'https://us-central1-xwininvestment.cloudfunctions.net/getJpyUsdExchangeRate',
  gasPrice: '5000000000',  // for Main Net
  // gasPrice:  '30000000000',   // for Test Net
  privateVaults: [
    // {
    //   name: 'Naka Special',
    //   sourceaddress: '0x55d398326f99059fF775485246999027B3197955',
    //   toaddress: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
    //   protocoladdress: '0x1Bf7fe7568211ecfF68B6bC7CCAd31eCd8fe8092',
    //   contractaddress: '0x0F26932b49E5b714E2dcbBA75B0AfD3f44DfA4b6',
    //   investorWallet: '0x2c7Fd43F2db8B9E0ab0cfA31DBb8c9e20598fed9',
    //   FromToken: 'USDT',
    //   ToToken: 'USDC',
    //   LPTokenname: 'USDT_USDC',
    //   logo: 'usdt',
    // },
    // {
    //   name: 'ADA Special',
    //   sourceaddress: '0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47',
    //   toaddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    //   protocoladdress: '0x1Bf7fe7568211ecfF68B6bC7CCAd31eCd8fe8092',
    //   contractaddress: '0x2406CAe6a3a49efB3bC09A7b8173c198437DcD8F',
    //   investorWallet: '0x2c7Fd43F2db8B9E0ab0cfA31DBb8c9e20598fed9',
    //   FromToken: 'ADA',
    //   ToToken: 'WBNB',
    //   LPTokenname: 'ADA_WBNB',
    //   logo: 'ada',
    // },
    // {
    //   name: 'ADA Special (Calvin)',
    //   sourceaddress: '0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47',
    //   toaddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    //   protocoladdress: '0x21B323a2Ac030095A7f9509B3b53f52367B76D94',
    //   contractaddress: '0x655Be4847C60cA230495499A860e81191e6Ce14a',
    //   investorWallet: '0x5D450b7A2bAFbc071cF1313099C3744f91c3BC24',
    //   FromToken: 'ADA',
    //   ToToken: 'WBNB',
    //   LPTokenname: 'ADA_WBNB',
    //   logo: 'ada',
    // },
    {
      name: 'ADA Special NEW',
      sourceaddress: '0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47',
      toaddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      protocoladdress: '0x1Bf7fe7568211ecfF68B6bC7CCAd31eCd8fe8092',
      contractaddress: '0xE18C2BD9712e6077A61fCdf1c262dAf197Bd51A4',
      investorWallet: '0x08558CFfC624d9914d726c3Ca9eaf2699604DdeA',
      FromToken: 'ADA',
      ToToken: 'WBNB',
      LPTokenname: 'ADA_WBNB',
      logo: 'ada',
    },
    // {
    //   name: 'GORO Special',
    //   sourceaddress: '0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47',
    //   toaddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    //   protocoladdress: '0x1Bf7fe7568211ecfF68B6bC7CCAd31eCd8fe8092',
    //   contractaddress: '0x6ff854645126604f9044Ae4BDb7e382F621E3acA',
    //   investorWallet: '0x6De821797efEd7Bd8F57A4f1669ae1Ba5ed3a6fD',
    //   FromToken: 'ADA',
    //   ToToken: 'WBNB',
    //   LPTokenname: 'ADA_WBNB',
    //   logo: 'ada',
    // },
  ],
  fixedRateVaults: [
    // {
    //   name: 'USDT Fixed Rate',
    //   FromToken: 'USDT',
    //   sourceaddress: '0x55d398326f99059fF775485246999027B3197955',
    //   toaddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    //   protocoladdress: '0x21B323a2Ac030095A7f9509B3b53f52367B76D94',
    //   contractaddress: '0xf525288d23FfE78fF1Ad0e85a37c8F49c459AAfC',
    //   logo: 'usdt',
    //   sourceToken: 'USDT',
    // },
    // {
    //   name: 'DOT Fixed Rate',
    //   FromToken: 'DOT',
    //   sourceaddress: '0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402',
    //   toaddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    //   protocoladdress: '0x21B323a2Ac030095A7f9509B3b53f52367B76D94',
    //   contractaddress: '0x4f1179e3Ad877dAe3908251E10a181FB23E8EA7b',
    //   logo: 'dot',
    //   sourceToken: 'DOT',
    // },
    {
      name: 'USDT Fixed Rate (Honban)',
      FromToken: 'USDT',
      sourceaddress: '0x55d398326f99059fF775485246999027B3197955',
      toaddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      protocoladdress: '0x1Bf7fe7568211ecfF68B6bC7CCAd31eCd8fe8092',
      contractaddress: '0x36692f5282a77415ff82D886DE65dFc838B1B3cC',
      logo: 'usdt',
      sourceToken: 'USDT',
    },
    {
      name: 'BUSD Fixed Rate (Honban)',
      FromToken: 'BUSD',
      sourceaddress: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      toaddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      protocoladdress: '0x1Bf7fe7568211ecfF68B6bC7CCAd31eCd8fe8092',
      contractaddress: '0x076CB2200F7904eD51348145623720a315054C40',
      logo: 'busd',
      sourceToken: 'BUSD',
    },
    // {
    //   name: 'DOT Fixed Rate (Honban)',
    //   FromToken: 'DOT',
    //   sourceaddress: '0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402',
    //   toaddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    //   protocoladdress: '0x1Bf7fe7568211ecfF68B6bC7CCAd31eCd8fe8092',
    //   contractaddress: '0x52e59268343eb0ca88a397a18B46ed88964E439B',
    //   logo: 'dot',
    //   sourceToken: 'DOT',
    // },
    // {
    //   name: 'ETH Fixed Rate (Honban)',
    //   FromToken: 'ETH',
    //   sourceaddress: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    //   toaddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    //   protocoladdress: '0x1Bf7fe7568211ecfF68B6bC7CCAd31eCd8fe8092',
    //   contractaddress: '0x3BEBFcb7509c3761431f071578fD6E8F993c42b1',
    //   logo: 'eth',
    //   sourceToken: 'ETH',
    // },
  ],
  fixedRateRetiredVaults: [
    {
      name: 'USDT Fixed Rate (Retired)',
      FromToken: 'USDT',
      sourceaddress: '0x55d398326f99059fF775485246999027B3197955',
      toaddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      protocoladdress: '0x1Bf7fe7568211ecfF68B6bC7CCAd31eCd8fe8092',
      contractaddress: '0xa81663079d5314A22a4346b28A7382501B0CF24f',
      logo: 'usdt',
      sourceToken: 'USDT',
    },
    {
      name: 'BUSD Fixed Rate (Retired)',
      FromToken: 'BUSD',
      sourceaddress: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      toaddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      protocoladdress: '0x1Bf7fe7568211ecfF68B6bC7CCAd31eCd8fe8092',
      contractaddress: '0x2c8814D62096e85A98Bd019A3147bfF862E0AED8',
      logo: 'busd',
      sourceToken: 'BUSD',
    },
  ],
  charity: [
    {
      charityName: 'xWin Charity Restaurant',
      FromToken: 'BUSD',
      sourceaddress: '0xa83575490D7df4E2F47b7D38ef351a2722cA45b9',
      adminAddress: '0x0ABf52D706F8512befbd2d4db2BcF2779f967f41',
      protocoladdress: '0x1Bf7fe7568211ecfF68B6bC7CCAd31eCd8fe8092',
      contractaddress: '0x4024C57731509262b5c64EC713CC498c56F9D6ca',
      logo: 'busd',
      image: 'charity01.png',
      sourceToken: 'BUSD',
      descs: [
        'The picture above is a sample. should be replaced or deleted.',
        'Sushi Charity1 desc 2nd row',
        'Sushi Charity1 desc 3rd row',
        'Sushi Charity1 desc 4th row',
      ],
    },
  ],
  privateVaultsAlpaca: [
    {
      name: 'USDT Special NEW',
      sourceaddress: '0x55d398326f99059fF775485246999027B3197955',
      toaddress: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
      protocoladdress: '0x1Bf7fe7568211ecfF68B6bC7CCAd31eCd8fe8092',
      contractaddress: '0x3fD7bA9e32b9eCc4f4308620C0084aA3BC575b95',
      investorWallet: '0x08558CFfC624d9914d726c3Ca9eaf2699604DdeA',
      FromToken: 'USDT',
      ToToken: 'WBNB',
      LPTokenname: 'USDT_USDC',
      logo: 'usdt',
    },
    // {
    //   name: 'BUSD Special',
    //   sourceaddress: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    //   toaddress: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
    //   protocoladdress: '0x1Bf7fe7568211ecfF68B6bC7CCAd31eCd8fe8092',
    //   contractaddress: '0x9dDE0185C2d8b174daF3275953677f065D28b731',
    //   investorWallet: '0x08558CFfC624d9914d726c3Ca9eaf2699604DdeA',
    //   FromToken: 'BUSD',
    //   ToToken: 'WBNB',
    //   LPTokenname: 'USDT_USDC',
    //   logo: 'busd',
    // },
    // {
    //   name: 'XWIN AM',
    //   sourceaddress: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    //   toaddress: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
    //   protocoladdress: '0x1Bf7fe7568211ecfF68B6bC7CCAd31eCd8fe8092',
    //   contractaddress: '0x8A88e97aaEFa8F8E267Ea5e5129e430eaa805E4A',
    //   investorWallet: '0x73a4C2d21282dd9f9f2DF2aD3e06fd25aB487C9c',
    //   FromToken: 'CAKE',
    //   ToToken: '',
    //   LPTokenname: 'CAKE',
    //   logo: 'xwinsg',
    // },
    // {
    //   name: 'XWIN TECH',
    //   sourceaddress: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    //   toaddress: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
    //   protocoladdress: '0x1Bf7fe7568211ecfF68B6bC7CCAd31eCd8fe8092',
    //   contractaddress: '0xB486c700a72A55cf9A8Ab8d547f64c405049624A',
    //   investorWallet: '0x172dd057c8aC614457cD263bACa3de8C9759A574',
    //   FromToken: 'CAKE',
    //   ToToken: '',
    //   LPTokenname: 'CAKE',
    //   logo: 'xwintech',
    // },
    // {
    //   name: 'XWIN AM BANANA',
    //   sourceaddress: '0x603c7f932ed1fc6575303d8fb018fdcbb0f39a95',
    //   toaddress: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
    //   protocoladdress: '0x1Bf7fe7568211ecfF68B6bC7CCAd31eCd8fe8092',
    //   contractaddress: '0x30bE158B974a3c53C202F0B75f0f6633b1a6d526',
    //   investorWallet: '0x73a4C2d21282dd9f9f2DF2aD3e06fd25aB487C9c',
    //   FromToken: 'BANANA',
    //   ToToken: '',
    //   LPTokenname: 'BANANA',
    //   logo: 'banana',
    // },
    // {
    //   name: 'XWIN TECH BANANA',
    //   sourceaddress: '0x603c7f932ed1fc6575303d8fb018fdcbb0f39a95',
    //   toaddress: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
    //   protocoladdress: '0x1Bf7fe7568211ecfF68B6bC7CCAd31eCd8fe8092',
    //   contractaddress: '0x9F99391609d8Bf3f82BFaad3dc1D3cD992F8043f',
    //   investorWallet: '0x172dd057c8aC614457cD263bACa3de8C9759A574',
    //   FromToken: 'BANANA',
    //   ToToken: '',
    //   LPTokenname: 'BANANA',
    //   logo: 'banana',
    // },
  ],
};

export default appGlobalConfig;

