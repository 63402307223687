import React from "react";
import ApexCharts from "react-apexcharts";
import { useTheme } from "@material-ui/styles";
import xWinLib from '../../../xWinLib';



export default function ApexLineChart(props) {
  var theme = useTheme();
  const { fundData, mainData } = props;

  const getDateFormat = (_date) => {

    let year = _date.substring(0, 4)
    let month = _date.substring(4, 6)
    let day = _date.substring(6, 8)
    return new Date(year + "-" + month + "-" +  day).toString("yyyy/mm/dd")
  }

  let dateArr = []
  let dataArr = []
  let finalUPData  = []
  if(mainData !== undefined){
    Object.keys(mainData).forEach(function (key) {
      if(key !== "env"){
        dataArr.push(xWinLib.roundTo(mainData[key], 2))
        dateArr.push(getDateFormat(key))
      }
    })
  }
  finalUPData.push({
    name: fundData.symbol,
    data: dataArr,
  })

  return (
    <ApexCharts
      options={themeOptions(theme, dateArr)}
      series={finalUPData}
      type="area"
      height={300}
    />
  );
}

// ############################################################
function themeOptions(theme, dateArr) {
  return {
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "datetime",
      categories: dateArr
    },
    tooltip: {
      theme: false,
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
    fill: {
      colors: [theme.palette.primary.light, theme.palette.success.light],
    },
    colors: [theme.palette.primary.main, theme.palette.success.main],
    chart: {
      toolbar: {
        show: false,
      },
    },
    legend: {
      show: true,
    },
  };
}
