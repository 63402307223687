import WalletConnectProvider from "@walletconnect/web3-provider";
import { useEffect, useState } from 'react';
import Web3 from 'web3';
import Web3Modal from "web3modal";
import Torus from "@toruslabs/torus-embed";
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';
import Authereum from "authereum";

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      rpc: {
        56: 'https://bsc-dataseed.binance.org/'
      },
      network: 'binance',
      // infuraId: process.env.REACT_APP_INFURA_ID,
    }
  },
  torus: {
    package: Torus,
    options: {
      networkParams: {
        host: "https://bsc-dataseed.binance.org/", // optional
        chainId: 56, // optional
        networkId: 56 // optional
      },
    }
  },
  authereum: {
    package: Authereum // required
  }
}



const web3Modal = new Web3Modal({
  cacheProvider: true,
  providerOptions
})

function useNetwork() {
  const [wallet, setWallet] = useState("---")
  const [network, setNetwork] = useState("---")
  const [account, setAccount] = useState("---")
  const [theWeb3, setWeb3] = useState(new Web3("https://bsc-dataseed1.binance.org:443"))
  const [web3static, setWeb3static] = useState(new Web3("https://bsc-dataseed1.binance.org:443"))
  const [isConnected, setIsConnected] = useState(false)
  const [ETHBalance, setETHBalance] = useState(0)
  const [globalProvider, setGlobalProvider] = useState(undefined)

  useEffect(() => {
    // if ethereum-compatible browsers
    if (typeof window.ethereum !== 'undefined') {
      setWallet(window.ethereum?.isMetaMask ? "MetaMask" : "OtherWallet")
    } else {
      setWallet("None")
    }
  }, [])

  const connectNetwork = async () => {
    const provider = await web3Modal.connect()
    setIsConnected(true)
    setGlobalProvider(provider)
    const web3static_ = new Web3("https://bsc-dataseed1.binance.org:443")
    const web3_ = new Web3(provider)    
    setWeb3(web3_)
    setWeb3static(web3static_)
    setupNetwork(web3_)
    setupAccount(web3_)
  }

  const refreshAccount = async () => {
    const web3_ = new Web3(globalProvider)
    setupAccount(web3_)
  }

  const setupNetwork = async (web3_) => {
    const nodeInfo = await web3_.eth.getNodeInfo()
    setWallet(nodeInfo.split(" ", 1)[0])

    const chainId = await web3_.eth.getChainId()
    const networkId = await web3_.eth.net.getId()
    const networkType = await web3_.eth.net.getNetworkType()
      .catch(err => {
        console.log(`Error in getNetworkType: ${err.message}`)
        return "unknown"
      })

      // console.log(`${chainId} ${networkId} (${networkType})`)
      setNetwork(chainId)
  }

  const setupAccount  = async (web3_) => {
    
    let accounts = await web3_.eth.getAccounts()
    setAccount(accounts[0])
    let balance = await web3_.eth.getBalance(accounts[0])
    balance = web3_.utils.fromWei(balance.toString())
    setETHBalance(balance)
  }

  
  const disconnectNetwork = async () => {
    if (theWeb3 && theWeb3.currentProvider && theWeb3.currentProvider.close) {
      await theWeb3.currentProvider.close()
    }
    setGlobalProvider(undefined)
    web3Modal.clearCachedProvider()

    setNetwork("---")
    setAccount("---")
    setWeb3(undefined)
    setWeb3static(undefined)
    setIsConnected(false)
  }

  return [{ wallet, network, account, theWeb3, web3static, isConnected, ETHBalance, globalProvider }, refreshAccount, connectNetwork, disconnectNetwork]
}

export default useNetwork
