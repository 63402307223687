import React, { useState, useEffect, useRef  } from "react";
import xWinLib from '../../../xWinLib';
import format from 'format-number';
import clsx from 'clsx';
// styles
import useStyles from "../styles";
import { Typography, Button, Link } from "../../../components/Wrappers/Wrappers";
import Dot from "../../../components/Sidebar/components/Dot";
//import xWinProtocolV5 from '../../../abi/xWinProtocolV5.json'
import xWinUtils from '../../../xWinUtils';
import { withRouter } from "react-router-dom";
import "aos/dist/aos.css"
import SnackbarMessage from '../../snackbarmessage/SnackbarMessage'
import BEP20 from '../../../abi/BEP20.json'
import TermCondition from '../../termcondition/termcondition'
import ReactInterval from 'react-interval'
import xWinStaking from '../../../abi/xWinStaking.json'
import confetti from 'canvas-confetti'

import {
  Grid,
  Box,
  InputAdornment,
  IconButton,
  TextField,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  TableRow,
  Table,
  TableCell,
  Slide,
} from "@material-ui/core";
import {
  ExpandMore as ExpandMoreIcon,
} from "@material-ui/icons";
import appGlobalConfig from "../../../appGlobalConfig";

const FixedRatelist = props => {

  var classes = useStyles();
  const { port, globalWeb3, web3static, selectedWallet, phase, ETHBalance, discontinued, loadWeb3 } = props;
  const MAX_ALLOWANCE = "115792089237316195423570985008687907853269984665640";
  const [currentBlock, setCurrentBlock] = useState(0)
  const [winStake, setWinStake] = useState([])
  
  const [loading, setLoading] = React.useState(false);
  const [loadingTxn, setLoadingTxn] = React.useState(false);
  const [depositAmount, setDepositAmount] = React.useState("");
  const [totalLockInUSD, setTotalLockInUSD] = React.useState(0);
  const [userData, setUserData] = useState({
    "walletETHBalance" :  0, 
    "selectedWallet" : "",
    "userfundbal" : 0,
    "referral" : ""
  });
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  const refTermCondition = useRef(null);
  
  const refsnackbar = useRef(null);
  const showSnackBar = (status, msg) => {
    refsnackbar.current.handleOpen(status, msg);
  }
  useEffect(() => {
    const controller = new AbortController();
    loadFundData()
    return () => {
      controller.abort();
    }
  }, [selectedWallet, phase]);
  
    async function loadVaultData() {

    if(globalWeb3 === undefined) return

    try {
      let currBlock = await web3static.eth.getBlockNumber()
      setCurrentBlock(currBlock)
      if(selectedWallet !== "---"){
        let isapprove = await getIsApproved(globalWeb3, selectedWallet, phase.port.contractaddress)
        
        const sourceToken = new web3static.eth.Contract(BEP20, phase.port.sourceaddress);
        let myUSDTBalance = await sourceToken.methods.balanceOf(selectedWallet).call()
        const mywinStake= new globalWeb3.eth.Contract(xWinStaking, phase.port.contractaddress); 
        const mywinStakeDisplay = new web3static.eth.Contract(xWinStaking, phase.port.contractaddress); 
        setWinStake(mywinStake)
        let userInfo = await mywinStakeDisplay.methods.userInfo(phase.pid, selectedWallet).call()
        // console.log(userInfo)
        let entitledInterestUSDT = await mywinStakeDisplay.methods.entitledInterest(phase.pid).call({from: selectedWallet})
        let entitledInterestXWIN = await mywinStakeDisplay.methods.getMyXWINRewards(phase.pid).call({from: selectedWallet})
        myUSDTBalance = xWinUtils.convertFromWei(web3static, myUSDTBalance.toString())
        setUserData({
          "walletETHBalance" :  ETHBalance, 
          "selectedWallet" : selectedWallet,
          "userstakebal" : xWinUtils.convertFromWei(web3static, userInfo?.amount?.toString()), 
          "lockedEnd" : userInfo?.amount == 0? 0 : userInfo?.lockedEnd, 
          "entitledInterestUSDT" : xWinUtils.convertFromWei(web3static, entitledInterestUSDT.toString()),
          "entitledInterestXWIN" : xWinUtils.convertFromWei(web3static, entitledInterestXWIN.toString()),
          "isapprove" : isapprove,
          "myUSDTBalance" : myUSDTBalance,
          "isUnlocked" : currBlock > userInfo?.lockedEnd
        })  
      }  
    } catch (error) {
      console.log(error)
    }
    
  }

  const loadFundData = async () => {

    if(!globalWeb3) {
      
      setUserData({
        "walletETHBalance" :  0, 
        "selectedWallet" : "---",
        "userfundbal" : 0,
        "userstakebal" : 0,
        // "referral" : referral,
        "mylpBalance" : 0,
      })  
      return
    }
    setLoading(true)
    loadVaultData()
    setLoading(false)
  }
  

  const getIsApproved = async (globalWeb3, wallet, port) => {
    
    try {
      const subsAmtInWei = MAX_ALLOWANCE// * 10 **18 // globalWeb3.utils.toWei(subsAmount.toString())
      const sourceToken = new globalWeb3.eth.Contract(BEP20, phase.port.sourceaddress);
      let contractAllowance = await sourceToken.methods
      .allowance(wallet, phase.port.contractaddress)
      .call();
      
      return parseFloat(contractAllowance) >= parseFloat(MAX_ALLOWANCE)          
    } catch (error) {
      console.log(error)
    }
    return false

  }

  const handleMaxDepositAmt = async event => {
    setDepositAmount(userData.myUSDTBalance)
    let isapprove = await getIsApproved(globalWeb3, selectedWallet, port)
    setUserData({
      ...userData,
      "isapprove" : isapprove
    })  
  }

  const handleAmtDepositChange = event => {
    event.preventDefault()
    const amount = parseFloat(event.target.value)
    setDepositAmount(amount)
  }

  
  const handleClickDeposit = event => {
    
    if(userData.userstakebal > 0){
      showSnackBar("error", "You have already staked. Please stake for another phase")
      return
    }

    if(Number(depositAmount) <= 0 || depositAmount.toString().trim() ==  ""){
      showSnackBar("error", "Please specify amount")
      return
    }

    if(!xWinUtils.getAgreeTermConditionByPortfolio(phase.port.contractaddress)){
      if(refTermCondition.current != null)  refTermCondition.current.handleOpen(phase.port.contractaddress)
      return
    }

    setLoadingTxn(true)
    const subsAmtInWei = web3static.utils.toWei(depositAmount.toString())
    xWinLib.StakeDepositAsync(
      winStake,
      userData,
      phase.pid,
      subsAmtInWei
      )
    .then(res =>
      { 
        console.log(res)
        setLoadingTxn(false)
        showSnackBar("success", res)
        confetti()
        loadWeb3()
        // loadFundData()
      }
    )
    .catch(err => {
      setLoadingTxn(false)
      console.log(err)
      showSnackBar("error", err)
    }
    )
    
  };

  const handleClickWithdraw = event => {
    event.preventDefault();
    
    if(Number(userData.userstakebal) <= 0){
      showSnackBar("error", "You do not have staked balance")
      return
    }

    
    setLoadingTxn(true)
    xWinLib.UnStakeDepositAsync(
      winStake,
      userData,
      phase.pid
      )
    .then(res =>
      { 
        console.log(res)
        setLoadingTxn(false)
        showSnackBar("success", res)
        loadFundData()
      }
    )
    .catch(err => {
      setLoadingTxn(false)
      console.log(err)
      showSnackBar("error", err)
    }
    )
    
  };

  const handleClickApprove = async event => {
    event.preventDefault();
    
    setLoadingTxn(true)
    
    const sourceToken = new globalWeb3.eth.Contract(BEP20, phase.port.sourceaddress);
    
    xWinLib.approveContract(sourceToken, MAX_ALLOWANCE, phase.port.contractaddress, selectedWallet, globalWeb3)
    .then(res => {
      setLoadingTxn(false)
      //loadFundData()
      setUserData({
        ...userData,
        "isapprove" : true
      })  

    }
  )
  .catch(err => {
    setLoadingTxn(false)
    console.log(err)
  })
    
  };

  const handleCallbackTermCondition = (refresh) =>{
    if(refresh) handleClickDeposit()
  }
  
  return (
    <div>
        <TermCondition 
          ref={refTermCondition} 
          parentCallback={handleCallbackTermCondition}
        />
        <ReactInterval timeout={20000} enabled={true}
          callback={() => loadVaultData()} />
        <SnackbarMessage ref={refsnackbar} />
        {xWinUtils.getProgress(loadingTxn)}
        
          <ExpansionPanel square={true}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                classes={{ expanded: classes.expanded }}
              >
                <div className={clsx(classes.column1)}>
                  {xWinUtils.getIcons(phase.port.logo)}
                </div>
                <div className={clsx(classes.column2, classes.columnHide, classes.helper)}>
                  <Typography>{phase.name}&nbsp;<a className={classes.link}>{phase.port.sourceToken}</a></Typography>
                  <Typography variant={'body2'} className={classes.subTitle}>
                  {format({prefix: "Est ", suffix: ' days'})(phase.lockDays)}
                  </Typography>
                </div>
                <div className={clsx(classes.column3, classes.columnHide, classes.helper)}>
                  <Typography>{format({prefix: "TVL: $ ", suffix: ''})(xWinLib.roundTo(phase.totalLockedSupply, 2))}</Typography>
                  <Typography variant={'body2'} className={classes.subTitle}>
                  {format({prefix: "Duration ", suffix: ' blocks'})(phase.DurationBlocks)}
                  </Typography>
                </div>
                <div className={clsx(classes.column4, classes.helper)}>
                <Typography
                      color="text"
                      colorBrightness={"hint"}
                      variant={"caption"}
                      style={{ marginRight: 5 }}
                    >
                      Total APR %
                    </Typography>
                  <Box display="flex" alignItems="center" justifyContent={"left"}>
                  
                  <Typography
                    size="md"
                    weight={"medium"}
                    style={{ marginRight: 8 }}
                  >   
                    {xWinUtils.displayBalance(loading, format({prefix: "", suffix: "%"})(xWinLib.roundTo(phase.totalAPR, 2)))}
                  </Typography>
                  <Dot size='medium' color="success" />
                  </Box>
                </div>
                <div className={clsx(classes.column5, classes.helper)}>
                  <Typography
                      color="text"
                      colorBrightness={"hint"}
                      variant={"caption"}
                      style={{ marginRight: 5 }}
                    >
                      Deposit
                    </Typography>
                  <Box display="flex" alignItems="center" justifyContent={"left"}>
                  
                  <Typography
                    size="md"
                    weight={"medium"}
                    style={{ marginRight: 8 }}
                  >   
                    {xWinUtils.displayBalance(loading, format({prefix: "", suffix: " "})(xWinLib.roundTo(userData.userstakebal,3)))}
                  </Typography>
                    {!loading && userData.userstakebal > 0.000000001 ? <Dot size='medium' color="secondary" /> : ""} 
                  </Box>
                 
                </div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails classes={classes.expansion}>
              <div className={clsx(classes.column6, classes.columnHide)}>
                <Grid item>
                </Grid>
              </div>
              <div className={classes.panel}>
                <Typography color={"primary"} variant={"caption"}>
                The protocol provide a fixed rate of APR
                <a className={classes.link}>{format({prefix: " ", suffix: '% '})(xWinLib.roundTo(phase.interestPerYear, 2))}</a>
                in {phase.port.sourceToken} staking. The interest is calculated based on daily or every 
                &nbsp;<a className={classes.link}>{phase.oneDayBlock}</a> blocks.
                The protocol accept maximum deposit limit of 
                &nbsp;<a className={classes.link}>{format({prefix: "", suffix: ' '})(xWinLib.roundTo(phase.maxLimit, 0))}&nbsp;{phase.port.sourceToken}</a>
                &nbsp;Your {phase.port.sourceToken} will be unlocked after &nbsp;
                <a className={classes.link} href={"https://bscscan.com/block/countdown/"+ userData.lockedEnd} rel="noreferrer" target="_blank" >{userData.lockedEnd}</a> blocks.
                You will be only be able to withdraw and harvest after the locked period.
                &nbsp;<a className={classes.link}>2.5%</a> of your XWIN rewards proceeds will be deducted and burned during the withdrawal. 
                </Typography>
                <br/>
                <Table size="small">
                  <TableRow>
                      <TableCell className={classes.tableHightlightPositive}>
                        <Typography
                          color="text"
                          colorBrightness={"hint"}
                          variant={"caption"}
                          style={{ marginRight: 5 }}
                          //noWrap
                        >
                          Fixed APR ({phase.port.sourceToken}):
                        </Typography>
                        <Box display="flex" alignItems="center" justifyContent={"left"}>
                          <Dot size='medium' color="secondary" />
                          <Typography
                            size="md"
                            weight={"medium"}
                            style={{ marginLeft: 8 }}
                          >
                            {format({prefix: "", suffix: '% '})(xWinLib.roundTo(phase.interestPerYear, 2))}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell className={classes.tableHightlightPositive}>
                        <Typography
                          color="text"
                          colorBrightness={"hint"}
                          variant={"caption"}
                          style={{ marginRight: 5 }}
                          //noWrap
                        >
                          Earned {phase.port.sourceToken} (Per {phase.oneDayBlock} blocks)
                        </Typography>
                        <Box display="flex" alignItems="center" justifyContent={"left"}>
                          <Dot size='medium' color="success" />
                          <Typography
                            size="md"
                            weight={"medium"}
                            style={{ marginLeft: 8 }}
                          >
                           {format({prefix: "", suffix: ''})(xWinLib.roundTo(userData.entitledInterestUSDT, 5))}
                          </Typography>
                        </Box>
                      </TableCell>
                  </TableRow>
                  <TableRow>
                      <TableCell className={classes.tableHightlightPositive}>
                        <Typography
                          color="text"
                          colorBrightness={"hint"}
                          variant={"caption"}
                          style={{ marginRight: 5 }}
                          // noWrap
                        >
                          xWIN APR:
                        </Typography>
                        <Box display="flex" alignItems="center" justifyContent={"left"}>
                          <Dot size='medium' color="info" />
                          <Typography
                            size="md"
                            weight={"medium"}
                            style={{ marginLeft: 8 }}
                          >
                            {format({prefix: "", suffix: '%'})(xWinLib.roundTo(phase.xwinAPR, 2))}
                            
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell className={classes.tableHightlightPositive}>
                        <Typography
                          color="text"
                          colorBrightness={"hint"}
                          variant={"caption"}
                          style={{ marginRight: 5 }}
                          //noWrap
                        >
                          Earned XWIN (Per block)
                        </Typography>
                        <Box display="flex" alignItems="center" justifyContent={"left"}>
                          <Dot size='medium' color="warning" />
                          <Typography
                            size="md"
                            weight={"medium"}
                            style={{ marginLeft: 8 }}
                          >
                            {format({prefix: "", suffix: ''})(xWinLib.roundTo(userData.entitledInterestXWIN, 5))}
                          </Typography>
                        </Box>
                      </TableCell>
                  </TableRow>
                  <TableRow>
                      <TableCell className={classes.tableHightlightPositive}>
                        <Typography
                          color="text"
                          colorBrightness={"hint"}
                          variant={"caption"}
                          style={{ marginRight: 5 }}
                          // noWrap
                        >
                          Entry Fee
                        </Typography>
                        <Box display="flex" alignItems="center" justifyContent={"left"}>
                          <Dot size='medium' color="info" />
                          <Typography
                            size="md"
                            weight={"medium"}
                            style={{ marginLeft: 8 }}
                          >
                            {format({prefix: "", suffix: '%'})(phase.entryFee)}
                            
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell className={classes.tableHightlightPositive}>
                        <Typography
                          color="text"
                          colorBrightness={"hint"}
                          variant={"caption"}
                          style={{ marginRight: 5 }}
                          // noWrap
                        >
                          Exit Fee
                        </Typography>
                        <Box display="flex" alignItems="center" justifyContent={"left"}>
                          <Dot size='medium' color="info" />
                          <Typography
                            size="md"
                            weight={"medium"}
                            style={{ marginLeft: 8 }}
                          >
                            {format({prefix: "", suffix: '%'})(0)}
                            
                          </Typography>
                        </Box>
                      </TableCell>
                      
                  </TableRow>
                  <TableRow>
                      <TableCell colSpan={2}>
                      
                      <TextField
                        disabled={discontinued}
                        //label={'Deposit Amount'}
                        type="number" 
                        margin="dense"
                        name="depositamount"
                        placeholder={discontinued ? "...Discontinued..." : 0}
                        onChange={handleAmtDepositChange}
                        variant="outlined"
                        value={depositAmount}
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <IconButton
                                aria-label='toggle password visibility'
                                onClick={handleMaxDepositAmt}
                                disabled={discontinued}
                                //onMouseDown={handleMouseDownPassword}>
                               > 
                               <Typography color={"primary"} variant={"caption"}>MAX</Typography>
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      {/* </TableCell>
                      <TableCell className={classes.tableHightlightPositive}> */}
                      <Button 
                        fullWidth
                        disabled={discontinued || userData.isapprove === ""}
                        onClick={userData.isapprove ? handleClickDeposit: handleClickApprove}
                        variant="contained" size="small" color="primary">
                              {userData.isapprove ? "+": "Enable"}
                      </Button>
                       
                      </TableCell>
                  </TableRow>
                  <TableRow>
                      <TableCell colSpan={2} className={classes.tableHightlightPositive}>
                      <TextField
                        type="number" 
                        margin="dense"
                        name="withdrawamount"
                        placeholder={0}
                        variant="outlined"
                        value={xWinLib.roundTo(userData.userstakebal, 5)}
                        fullWidth
                        disabled={true}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <IconButton
                                aria-label='toggle password visibility'
                                //onClick={handleMaxWithdrawAmt}
                                //onMouseDown={handleMouseDownPassword}>
                               > 
                               <Typography color={"primary"} variant={"caption"}>STAKED</Typography>
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      {/* </TableCell>
                      <TableCell className={classes.tableHightlightPositive}> */}
                      <Button 
                        disabled={!userData.isUnlocked}
                        fullWidth
                        onClick={handleClickWithdraw}
                        variant="contained" size="small" color="secondary">
                        {userData.isUnlocked ? "-" : "Locked" }
                      </Button>
                       
                      </TableCell>
                  </TableRow>
                
                </Table>
              </div>
              <div className={clsx(classes.column6, classes.columnHide)}>
                <Grid item>
                </Grid>
              </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
 
            
    </div>
  );
}


export default withRouter(FixedRatelist);