import React from "react";
import xWinLib from '../../../xWinLib';
import format from 'format-number';
import xWinUtils from "../../../xWinUtils";

import {
  Grid,
  Box,
  CardContent
} from "@material-ui/core";

// styles
import useStyles from "../styles";
import Widget from "../../../components/Widget/Widget";
import { Typography } from "../../../components/Wrappers/Wrappers";
import Dot from "../../../components/Sidebar/components/Dot";




export default function SupplyStats(props) {
  var classes = useStyles();
  const { fundData, loading } = props;
  
  return (
    <Widget
      disableWidgetMenu
      bodyClass={classes.fullHeightBody}
      className={classes.card}
      >
        {xWinUtils.getProgress(loading)}
      <CardContent>
        <Grid item>
          <Typography
              color="text"
              colorBrightness={"hint"}
              variant={"caption"}
              style={{ marginRight: 5 }}
            >
              Total Supply
            </Typography>
          <Box display="flex" alignItems="center" justifyContent={"center"}>
          <Dot size='medium' color="primary" />
          <Typography
            size="md"
            weight={"medium"}
            style={{ marginLeft: 8 }}
          >
            {format({prefix: "", suffix: ''})(xWinLib.roundTo(fundData.totalSupply,4))}
          </Typography>
        </Box>
        </Grid>
        <Grid item>
            <Typography
              color="text"
              colorBrightness={"hint"}
              variant={"caption"}
              style={{ marginRight: 5 }}
            >
              Total Market Value
            </Typography>
          <Box display="flex" alignItems="center" justifyContent={"center"}>
          
          <Dot size='medium' color="warning" />
          <Typography
            size="md"
            weight={"medium"}
            style={{ marginLeft: 8 }}
          >
            {xWinLib.getMarketCap(fundData)}
          </Typography>
        </Box>
        </Grid>
      </CardContent>
  </Widget>
    
  );
}
