import React from "react";
import xWinLib from '../../../xWinLib';
import CountUp from 'react-countup';
import xWinUtils from "../../../xWinUtils";

import {
  Box,
  CardContent,
  Card,
  CardHeader
} from "@material-ui/core";

// styles
import useStyles from "../styles";
import { Typography, Avatar } from "../../../components/Wrappers/Wrappers";
import Dot from "../../../components/Sidebar/components/Dot";


export default function MarketCap(props) {
  var classes = useStyles();
  const { xwinStats, overallStat } = props;

  const marketCapValue = xwinStats.publicCirculation * overallStat.xwinPrice
  
  return (
    <Card>
      <CardHeader
        subheader= {"$XWIN"}
        title={"Total Market Cap"}
        avatar={
          <Avatar 
            aria-label="" 
            className={classes.avatar}>
            {xWinUtils.getIcons("xwin")}
           </Avatar>
        }
        titleTypographyProps={{variant:'body1' }}
        subheaderTypographyProps={{variant:'body2' }}
      />
      <CardContent>
          <Box display="flex" alignItems="center" justifyContent={"center"}>
            <Dot size='medium' color="info" />
            <Typography
              size="md"
              weight={"medium"}
              style={{ marginLeft: 8 }}
            >
              <CountUp 
                separator=","
                decimals={0}
                decimal="."
                prefix="$"   
                duration={1.75}         
                end={xWinLib.roundTo(marketCapValue,0)} />
            </Typography>
          </Box>
        </CardContent>
  </Card>
    
  );
}
