import React, { forwardRef, useImperativeHandle, useRef } from "react";
import xWinLib from '../../xWinLib';
import xWinUtils from '../../xWinUtils';
import "react-toastify/dist/ReactToastify.css";

import { Typography, Button } from "../../components/Wrappers/Wrappers";
import CancelIcon from '@material-ui/icons/Cancel';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import HomeIcon from '@material-ui/icons/Home';
import SnackbarMessage from '../snackbarmessage/SnackbarMessage'

import {
  CardHeader,
  IconButton,
  TableRow,
  Table,
  TableCell,
  Card,
  Dialog,
  DialogContent,
  DialogActions,
  ButtonGroup,
  TextField,
  InputAdornment,
  Tooltip
} from "@material-ui/core";
// components
import useStyles from "./styles";


const MoveNonBM = forwardRef((props, ref) => {
  const { userData, myxWinProtocol, selectedport} = props;
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [openAdd, setOpenAdd] = React.useState(false);
  const [slippageAmt, setSlippageAmt] = React.useState(1);
  const [tokenAddress, setTokenAddress] = React.useState("");
  const refsnackbar = useRef(null);
  const showSnackBar = (status, msg) => {
    refsnackbar.current.handleOpen(status, msg);
  }

  const handleOpen = () => {
    setOpenAdd(true);
  }
  
  const handleCloseAdd = () => {
    setLoading(false)
    setOpenAdd(false)
  }

  useImperativeHandle(ref, () => {
    return {
      handleOpen: handleOpen
    };
  });

  const handleClickMoveNonBM = event => {
    event.preventDefault();
    
    setLoading(true)
    xWinLib.MoveNonBMAsync(
      myxWinProtocol, 
      selectedport.contractaddress, 
      userData.selectedWallet, 
      slippageAmt,
      tokenAddress
      )
    .then(res =>
      { 
        console.log(res)
        setLoading(false)
        showSnackBar("success", res)
        props.parentCallback(true);
      }
    )
    .catch(err => {
      setLoading(false)
      console.log(err)
      showSnackBar("error", err)
      props.parentCallback(false);
      }
    )
  };


  const handleTokenAddressChange = () => event => {
    event.preventDefault()
    setTokenAddress(event.target.value)
  }
  
  const handleSlippageChange = () => event => {
    if(event.target.value < 0) return
    setSlippageAmt(event.target.value)
  }

  return (
    <Card>
      <SnackbarMessage ref={refsnackbar} />
      <Dialog 
          open={openAdd} 
          aria-labelledby="form-dialog-title"
          fullScreen={false}
          maxWidth = {"md"}
          >
            <CardHeader
              className={classes.CardTitle}
              action={
                <IconButton 
                  className={classes.title}
                  onClick={handleCloseAdd}
                  aria-label="settings">
                  <CancelIcon />
                </IconButton>
              }
              title={"Swap Token Into BNB"}
            />
          <DialogContent>
          {xWinUtils.getProgress(loading)}
          <Table size="small">                  
                  
                  <TableRow className={classes.tableRow}>
                     <TableCell colSpan={2}>
                       <TextField
                           //type="number" 
                           className={classes.inputText}
                           label={'Token Address'}
                           margin="dense"
                           name="tokenAddress"
                           onChange={handleTokenAddressChange()}
                           required
                           variant="outlined"
                           value={tokenAddress}
                           fullWidth
                           InputProps={{
                             startAdornment: (
                               <InputAdornment position="start">
                                 <HomeIcon />
                               </InputAdornment>
                             ),
                           }}
                         />
                     </TableCell>
                  </TableRow> 
                  <Tooltip title="How much price difference willing to accept during the swap compared to market fair price" arrow='true'>
                  <TableRow className={classes.tableRow}>
                     <TableCell>
                         <TextField
                           type="number" 
                           className={classes.inputText}
                           label={'Slippage'}
                           margin="dense"
                           name="slippage"
                           onChange={handleSlippageChange()}
                           required
                           variant="outlined"
                           value={slippageAmt}
                           fullWidth
                           InputProps={{
                             startAdornment: <InputAdornment position="start">%</InputAdornment>,
                           }}
                         />
                     </TableCell>
                     <TableCell>
                      <Typography
                          color="text"
                          colorBrightness={"hint"}
                          variant={"caption"}
                          style={{ marginRight: 5 }}
                        >
                      *** Increase slippage infavor to your trades
                     </Typography>
                     </TableCell>
                   </TableRow> 
                 </Tooltip>
                 </Table>       
          </DialogContent>
          <DialogActions>
            <ButtonGroup size="small" color="primary" aria-label="large outlined primary button group">
              <Button 
                onClick={handleClickMoveNonBM} 
                color="secondary"
                variant="contained"
                startIcon={<AddCircleIcon />}>
              {'Swap'}
              </Button>
            </ButtonGroup>
          </DialogActions>
        </Dialog>
    </Card>
  );
});


export default MoveNonBM;