import React, { useState, forwardRef, useImperativeHandle, useRef } from "react";
import Dot from "../../components/Sidebar/components/Dot";
import xWinLib from '../../xWinLib';
import xWinUtils from '../../xWinUtils';
import format from 'format-number';
import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone';
import { Typography, Button, Avatar } from "../../components/Wrappers/Wrappers";
import CancelIcon from '@material-ui/icons/Cancel';
import SnackbarMessage from '../snackbarmessage/SnackbarMessage'


import {
  Box,
  CardHeader,
  IconButton,
  TableRow,
  Table,
  TableCell,
  Dialog,
  DialogContent,
  DialogActions,
  ButtonGroup,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import useStyles from "./styles";

const redeemfarm = forwardRef((props, ref) => {
  const { className, fundData, tokensMaster, userData, myxWinProtocol, port, globalWeb3, networkName, uniswapV2FactoryProtocol, ...rest } = props;
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [openAdd, setOpenAdd] = React.useState(false);
  const [subsAmt, setsubsAmt] = useState(0);
  const [isApproval, setIsApproval] = useState(false);

  const refsnackbar = useRef(null);
  const showSnackBar = (status, msg) => {
    refsnackbar.current.handleOpen(status, msg);
  }

  const handleOpen = () => {
    setOpenAdd(true);
    props.parentCallback(false);
  }

  const getOpenAdd = () => {
    return openAdd
  }

  const handleCloseAdd = () => {
    setLoading(false)
    setOpenAdd(false)
  }

  useImperativeHandle(ref, () => {
    return {
      handleOpen: handleOpen,
      getOpenAdd: getOpenAdd,
      openAdd : openAdd
    };
  });

  const handleClickRedeem = async event => {
    event.preventDefault();
    
    if(subsAmt <= 0){
      showSnackBar("error", "Please input withdraw amount")
      return
    } 

    setLoading(true)
    
    const amtInWei = globalWeb3.utils.toWei(subsAmt.toString())
    
    xWinLib.WithdrawFarmAsync(
        myxWinProtocol, 
        port.pid,
        amtInWei,
        userData.selectedWallet
      )
    .then(res => {
        setLoading(false)
        showSnackBar("success", res)
        setOpenAdd(false)
        props.parentCallback(true);
      }
    )
    .catch(err => {
      setLoading(false)
      console.log(err)
      showSnackBar("error", err)
      props.parentCallback(false);
    })

  };


  const isTokenApproved = async (subsAmount) => {
    
    if(parseFloat(subsAmount) === NaN) return false
    let isapprove = await xWinUtils.getIsApproved(globalWeb3, subsAmount, userData.selectedWallet, port)
    return isapprove
  }

  const handleClickClearAmt = () => event => {
    event.preventDefault();
    setsubsAmt(0)
  };


  const handleAmtChange = () => async event => {
    
    if(event.target.value === "") return

    const val = parseFloat(event.target.value)
    setsubsAmt(val)
    const isApp = await isTokenApproved(val)
    setIsApproval(isApp)
  }


  const handleClickAmt = (ratio) => async event => {
    event.preventDefault();
    
    const redeemAmt = xWinLib.roundDown(ratio * userData.userstakebal, 8)
    setsubsAmt(redeemAmt)
    const isApp = await isTokenApproved(redeemAmt)
    setIsApproval(isApp)
  }

  const getSubHeader = () => {

    return (
      <Typography className={classes.subTitle}>
        {"Available ("+ port.symbol +"): " + format({prefix: " ", suffix: ''})(xWinLib.roundTo(userData.userstakebal,10))}
      </Typography>
    )
  }

  return (
    
    <Dialog 
          open={openAdd} 
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          fullScreen={false}
          //maxWidth = {'sm'}
          >
            <SnackbarMessage ref={refsnackbar} />
            <CardHeader
              action={
                <IconButton 
                  className={classes.title}
                  onClick={handleCloseAdd}
                  aria-label="settings">
                  <CancelIcon />
                </IconButton>
              }
              avatar={
                <Avatar variant="square"> 
                  {<img alt={port.symbol} src={"../../../images/logos/"+port.sublogo} className={classes.avatar}/>}
                 </Avatar>
              }
              subheader={getSubHeader()}
            />
          <DialogContent>
              {xWinUtils.getProgress(loading)}
                  
                <Table size="small">                  
                <TableRow>
                    <TableCell align="right">
                      <ButtonGroup size="small" color="primary" aria-label="" >
                          <Button
                            className={classes.columnHide}
                            fullWidth
                            color="primary"
                            size="small"
                            variant="outlined"
                            onClick={handleClickAmt(0.25)}
                          >
                            {'25%'}
                          </Button>
                          <Button
                            fullWidth
                            color="primary"
                            size="small"
                            variant="outlined"
                            onClick={handleClickAmt(0.50)}
                          >
                            {'50%'}
                          </Button>
                          <Button
                            className={classes.columnHide}
                            fullWidth
                            color="primary"
                            size="small"
                            variant="outlined"
                            onClick={handleClickAmt(0.75)}
                          >
                            {'75%'}
                          </Button>
                          <Button
                            fullWidth
                            color="primary"
                            size="small"
                            variant="outlined"
                            onClick={handleClickAmt(1)}
                          >
                            {'100%'}
                          </Button>
                          <Button
                          fullWidth
                          color="primary"
                          size="small"
                          variant="outlined"
                          onClick={handleClickClearAmt()}
                        >
                          {'Clear'}
                        </Button>
                        </ButtonGroup>
                      </TableCell>
                   </TableRow>  

                   <TableRow className={classes.tableRow}>
                      <TableCell>
                        
                        <TextField
                            type="number" 
                            className={classes.text}
                            label={'Amount'}
                            margin="dense"
                            name="weight"
                            onChange={handleAmtChange()}
                            required
                            variant="outlined"
                            value={subsAmt}
                            fullWidth
                            InputProps={{
                              classes: {
                                input: classes.negativeNum,
                              },
                              style: { textAlign: "right" },
                              startAdornment: <InputAdornment position="start">{port.symbol}</InputAdornment>,
                            }}
                          />
                         
                          
                      
                      </TableCell>
                   </TableRow> 
                   <TableRow className={classes.tableRow}>
                      <TableCell>
                      <Typography
                        color="text"
                        colorBrightness={"hint"}
                        variant={"caption"}
                        style={{ marginRight: 5 }}
                        noWrap
                      >{"Pending XWIN"}</Typography>
                      <Box display="flex" alignItems="center" justifyContent={"right"}>
                        <Typography
                          size="md"
                          weight={"medium"}
                          style={{ marginRight: 8 }}
                          className={classes.subTitle}
                        >
                          {format({prefix: " ", suffix: ''})(xWinLib.roundTo(userData.pendingXwin,5))}
                        </Typography>
                        {userData.pendingXwin > 0 ? <Dot size='medium' color="success" /> : ""} 
                      </Box>
                      </TableCell>
                   </TableRow> 
                  </Table>
          
          </DialogContent>
          <DialogContent>
          </DialogContent>
          <DialogActions>
              
            <ButtonGroup size="small" color="primary" aria-label="large outlined primary button group">
              <Button 
                // disabled={!isApproval}
                onClick={handleClickRedeem} 
                color="secondary"
                variant="contained"
                startIcon={<AddCircleTwoToneIcon />}
                >
              {'Withdraw'}
              </Button>
            </ButtonGroup>
            
          </DialogActions>
        </Dialog>
    
  );
});


export default redeemfarm;
