import React, { useState, useEffect, useRef  } from "react";
import xWinLib from '../../../xWinLib';
import format from 'format-number';
import clsx from 'clsx';
// styles
import useStyles from "../styles";
import { Typography, Button, Link } from "../../../components/Wrappers/Wrappers";
import Dot from "../../../components/Sidebar/components/Dot";
import Widget from "../../../components/Widget/Widget";
//import xWinProtocolV5 from '../../../abi/xWinProtocolV5.json'
import xWinUtils from '../../../xWinUtils';
import { withRouter } from "react-router-dom";
import "aos/dist/aos.css"
import SnackbarMessage from '../../snackbarmessage/SnackbarMessage'
import BEP20 from '../../../abi/BEP20.json'
import TermCondition from '../../termcondition/termcondition'
import ReactInterval from 'react-interval'
import xWinStaking from '../../../abi/xWinStaking.json'
//import xWinStaking from '../../../abi/xWinCharity.json'
import xWinCharity from '../../../abi/xWinCharity.json'
import confetti from 'canvas-confetti'
import Moment from 'moment';


import {
  Grid,
  Box,
  InputAdornment,
  IconButton,
  TextField,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  TableRow,
  Table,
  TableCell,
  Slide,
} from "@material-ui/core";
import {
  ExpandMore as ExpandMoreIcon,
} from "@material-ui/icons";
import appGlobalConfig from "../../../appGlobalConfig";
import { ComposableMap } from "react-simple-maps";

const CharityDetails = props => {
  var classes = useStyles();
  const { port, globalWeb3, selectedWallet, phase, ETHBalance, theWeb3 , loadWeb3} = props;
  const MAX_ALLOWANCE = "115792089237316195423570985008687907853269984665640";
  const [currentBlock, setCurrentBlock] = useState(0)
  const [winStake, setWinStake] = useState([])
  const [winCharity, setWinCharity] = useState([])
  

  const [loading, setLoading] = React.useState(false);
  const [loadingTxn, setLoadingTxn] = React.useState(false);
  const [depositAmount, setDepositAmount] = React.useState("");

  const [daysToDue, setDaysToDue] = React.useState(1);
  const [targetAmount, setTargetAmount] = React.useState("1");  
  const [charityName, setCharityName] = React.useState(""); 
  const [totalLockInUSD, setTotalLockInUSD] = React.useState(0);
  const [donateAmount, setDonateAmount] = React.useState(1);
  const [withdrawAmount, setWithdrawAmount] = React.useState(1);
  const [userData, setUserData] = useState({
    "walletETHBalance" :  0, 
    "selectedWallet" : "",
    "userfundbal" : 0,
    "referral" : "",
    "myXWINBalance": 0
  });
  
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  const refTermCondition = useRef(null);
  
  const refsnackbar = useRef(null);
  const showSnackBar = (status, msg) => {
    refsnackbar.current.handleOpen(status, msg);
  }

  // const remainingDays = (dueBlock) => {
  //   let blocksOfADay = 28800;
  //   return dueBlock < currentBlock ? 'Closed': Math.round((dueBlock  - currentBlock) / blocksOfADay) + ' Days';
  // }

  const getDueDateInfo = (dueBlock) => {
    let today = new Date();
    let remainBLocks = dueBlock - currentBlock;
    let diffDays = Math.floor(remainBLocks / 28800);
    let remainDays = diffDays;
    let dueDate = today.setDate(today.getDate() + diffDays);
    let info = "";
    if (diffDays < 0) info = 'Closed.';
    if (diffDays == 0) info = `${remainBLocks} blocks left to close.`;
    if (diffDays > 1) info = `${remainDays} days left to close.`;
    let isClosed = diffDays < 0 ? true: false;
    const info02 = {
      "remainBLocks" : remainBLocks ,
      "remainDays" : remainDays,
      "dueDate" : dueDate,
      "isClosed" : isClosed
    }

    return info02;
  }
  const getDateOfBlock =(blockNum) => {
    let today = new Date();
    let diffDays = Math.floor((blockNum - currentBlock) / 28800);
    let theDay = today.setDate(today.getDate() + diffDays);
    return theDay;
  }


  useEffect(() => {
    const controller = new AbortController();
    loadFundData()
    return () => {
      controller.abort();
    }
  }, [selectedWallet, phase]);
  
    async function loadVaultData() {

    if(globalWeb3 === undefined) return
    

    let currBlock = await globalWeb3.eth.getBlockNumber()
    setCurrentBlock(currBlock)

    if(selectedWallet !== "---"){

      let isapprove = await getIsApproved(globalWeb3, selectedWallet, phase.port.contractaddress)
      phase.isApproved = isapprove;
      
      const sourceToken = new globalWeb3.eth.Contract(BEP20, phase.port.sourceaddress);
      let myXWINBalance = await sourceToken.methods.balanceOf(selectedWallet).call();
      const mywinCharity = new globalWeb3.eth.Contract(xWinCharity, phase.port.contractaddress); 
      setWinCharity(mywinCharity);
      let numOfCharities = await mywinCharity.methods.getNumberOfCharities().call()

        let charityInfo = await mywinCharity.methods.charityInfo(phase.pid).call();
        let xWinCharityOwner = await mywinCharity.methods.charityOwnerWallet().call()
        let donatedTotal = xWinUtils.convertFromWei(globalWeb3, charityInfo.donatedTotal)
        let stakedTotal  = xWinUtils.convertFromWei(globalWeb3, charityInfo.stakedTotal);
        let adminOwner = await mywinCharity.methods.owner().call()
        phase.stakedTotal = stakedTotal;
        phase.donatedTotal = donatedTotal;
        setWithdrawAmount(phase.stakedTotal);

      myXWINBalance = xWinUtils.convertFromWei(globalWeb3, myXWINBalance.toString())
      setUserData({
        "walletETHBalance" :  ETHBalance, 
        "selectedWallet" : selectedWallet,
        "isapprove" : isapprove,
        "myXWINBalance" : myXWINBalance,
      })  
    }
  }

  const loadFundData = async () => {

    if(!globalWeb3) {
      
      setUserData({
        "walletETHBalance" :  0, 
        "selectedWallet" : "---",
        "userfundbal" : 0,
        "userstakebal" : 0,
        // "referral" : referral,
        "mylpBalance" : 0,
        "donateAmount": 0,
      })  

      
      return
    }
    setLoading(true)
    loadVaultData()
    setLoading(false)
  }
  

  const getIsApproved = async (globalWeb3, wallet, port) => {
    
    const subsAmtInWei = MAX_ALLOWANCE// * 10 **18 // globalWeb3.utils.toWei(subsAmount.toString())
    const sourceToken = new globalWeb3.eth.Contract(BEP20, phase.port.sourceaddress);
    let contractAllowance = await sourceToken.methods
    .allowance(wallet, phase.port.contractaddress)
    .call();
    
    return parseFloat(contractAllowance) >= parseFloat(MAX_ALLOWANCE)  

  }

  const handleMaxDonateAmt = async event => {
    let sourceToken = new globalWeb3.eth.Contract(BEP20, phase.port.sourceaddress);
    let myXWINBalance = await sourceToken.methods.balanceOf(selectedWallet).call();
    myXWINBalance = xWinUtils.convertFromWei(globalWeb3, myXWINBalance);
    setDonateAmount(myXWINBalance);
  }

  const handleMaxWithdrawAmt = async event => {
    setWithdrawAmount(phase.stakedTotal);
  }


  /* Add Charity Handles */
  const handleCharityNameChange = event => {
    event.preventDefault()
    const name = event.target.value
    setCharityName(name)
  }

  const handleCharityDaysToDueChange = event => {
    event.preventDefault()
    const days = parseInt(event.target.value)
    setDaysToDue(days < 1 ? 1: days)
  }

  const handleCharityTargetAmountChange = event => {
    event.preventDefault()
    const amount = parseInt(event.target.value)
    setTargetAmount(amount < 1 ? 1: amount)
  }

  const addCharityParamCheck = () => {
    return (
      (charityName !== "" && daysToDue >= 1 && targetAmount >= 1) ? true : false 
    );
  }

  const handleAmtDonateChange = event => {
    event.preventDefault()
    const amount = parseFloat(event.target.value)
    setDonateAmount(amount <= 0 ? 1:amount)
  }

  const handleAmtWithdrawChange = event => {
    event.preventDefault()
    const amount = parseFloat(event.target.value)
    setWithdrawAmount(amount <= 0 ? 1:amount)
  }

  const isTargetClear = () => {
    return(phase.targetAmount < phase.stakedTotal ? true : false);
  }
 
  const handleClickAddCharity = event => {
    setLoadingTxn(true)
    const subsAmtInWei = globalWeb3.utils.toWei(targetAmount.toString())
    xWinLib.AddCharity(
      winCharity,
      userData, 
      charityName, 
      daysToDue, 
      subsAmtInWei,
      )
    .then(res =>
      { 
        console.log(res)
        setLoadingTxn(false)
        showSnackBar("success", res)
        confetti()
        //loadFundData()
        loadWeb3()
      }
    )
    .catch(err => {
      setLoadingTxn(false)
      console.log(err)
      showSnackBar("error", err)
    }
    )
    
  };

  const handleClickDonate = event => {
    setLoadingTxn(true)
    const subsAmtInWei = globalWeb3.utils.toWei(donateAmount.toString())
    xWinLib.CharityDonateAsync(
      winCharity,
      userData,
      phase.pid,
      subsAmtInWei
      )
    .then(res =>
      { 
        console.log(res)
        setLoadingTxn(false)
        showSnackBar("success", res)
        confetti()
        //loadFundData()
        loadWeb3()
      }
    )
    .catch(err => {
      setLoadingTxn(false)
      console.log(err)
      showSnackBar("error", err)
    }
    )
    
  };

  const handleClickWithdraw = event => {
    event.preventDefault();
    const subsAmtInWei = globalWeb3.utils.toWei(withdrawAmount.toString())

    setLoadingTxn(true)
    xWinLib.CharityWithdrawByCharityOwner(
      winCharity,
      userData,
      phase.pid,
      subsAmtInWei
      )
    .then(res =>
      { 
        console.log(res)
        setLoadingTxn(false)
        showSnackBar("success", res)
        //loadFundData()
        loadWeb3()
      }
    )
    .catch(err => {
      setLoadingTxn(false)
      console.log(err)
      showSnackBar("error", err)
    }
    )
  };

  const handleClickHarvest = event => {

    //return;
    event.preventDefault();
    const subsAmtInWei = globalWeb3.utils.toWei(withdrawAmount.toString())

    setLoadingTxn(true)
    xWinLib.CharityHarvestAsync(
      winCharity,
      userData
      )
    .then(res =>
      { 
        console.log(res)
        setLoadingTxn(false)
        showSnackBar("success", res)
        //loadFundData()
        loadWeb3()
      }
    )
    .catch(err => {
      setLoadingTxn(false)
      console.log(err)
      showSnackBar("error", err)
    }
    )
  };

  const handleClickApprove = async event => {
    event.preventDefault();
    
    setLoadingTxn(true)
    
    const sourceToken = new globalWeb3.eth.Contract(BEP20, phase.port.sourceaddress);

    
    xWinLib.approveContract(sourceToken, MAX_ALLOWANCE, phase.port.contractaddress, selectedWallet, globalWeb3)
    .then(res => {
      setLoadingTxn(false)
      //loadFundData()
      setUserData({
        ...userData,
        "isapprove" : true
      })  

    }
  )
  .catch(err => {
    setLoadingTxn(false)
    console.log(err)
  })
    
  };

  const handleCallbackTermCondition = (refresh) =>{
 
    if(refresh) handleClickDonate()
    
  }

  const dueDateInfo = getDueDateInfo(phase.dueBlock);

  // check selectedWallet is charityAdmin
  const isCharityAdmin = () => {
    return selectedWallet == phase.port.adminAddress ? true : false;
  }

  const isCharityOwnerWallet = () => {
    return selectedWallet == phase.xWinCharityOwner ? true : false;
  }

  const isWithdrawOpen = () => {
      return (
        (isCharityOwnerWallet() && (dueDateInfo.isClosed || isTargetClear())) ? true : false
      );  
  }

 
  const charityDescs = phase.port.descs.map((charityDescs) => {
      return (
        <div>
          <Typography style={{ marginLeft: 20 }} color={"primary"} variant={"caption"}>
            {charityDescs}
          </Typography>
          </div>
      );
  });


  const charityTitleBar = (pid) => {
    // show Charity title only the first row
    if (pid != 0 ){
      return "";
    } else {  
      return (
        <>
    {/* only charity admin owner can open main panel and add new charity of the contract */}
    {!isCharityOwnerWallet() ?
        <>
        <Grid container spacing={3}>  
          <Grid item lg={4} sm={4} xs={12}>
            {xWinUtils.getImages(phase.port.image)}
          </Grid>
          <Grid item lg={8} sm={8} xs={12}>
            {charityDescs}
          </Grid>
        </Grid>
        </>
      :
      <ExpansionPanel square={true}>
          {/* expand only contract creator */}
              <ExpansionPanelSummary
                expandIcon={isCharityOwnerWallet() ? <ExpandMoreIcon />: <></>}
                aria-controls="panel1a-content"
                id="charity-header"
                classes={{ expanded: classes.expanded }}
                disableRipple={true}
              >
                <Grid container spacing={3}>  
                  <Grid item lg={4} sm={4} xs={12}>
                    {xWinUtils.getImages(phase.port.image)}
                  </Grid>
                  <Grid item lg={8} sm={8} xs={12}>
                    {charityDescs}
                  </Grid>
                  <Grid item lg={12} sm={12} xs={12}>
                    <Typography  style={{textAlign:"center", fontSize:"0.7rem"}}>CLICK HERE! to add a new charity. </Typography>
                  </Grid>
                </Grid>
              </ExpansionPanelSummary>

              <ExpansionPanelDetails classes={classes.expansion}>

              <Grid container 
                spacing={3}
                alignItems="center"
                justifyContent="center"
              >  
                <Grid item lg={12} sm={12} xs={12}>
                  <Typography 
                      size="xl"
                      weight={"heavy"}
                    >
                      Fill in the following form and click "Add Charity" button.</Typography>
                    
                </Grid> 
                <Grid item lg={12} sm={12} xs={12}>
                  <Box display="flex" alignItems="center" justifyContent={"left"}>
                          <Dot size='medium' color="warning" />
                          <Typography
                            weight={"medium"}
                            style={{ fontSize: "0.75rem", marginLeft: 4 ,paddingLeft: 2}}
                          >
                            Name:
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent={"left"}>
                        <TextField
                        //label={'Deposit Amount'}
                          type="text" 
                          margin="dense"
                          name="charityname"
                          placeholder={"Name of the charity to add"}
                          onChange={handleCharityNameChange}
                          variant="outlined"
                          value={charityName}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>

                              </InputAdornment>
                            ),
                          }}
                        />
                        </Box>
                </Grid> 
                <Grid item lg={4} sm={4} xs={12}>
                  <Box display="flex" alignItems="center" justifyContent={"left"}>
                    <Dot size='medium' color="warning" />
                    <Typography
                      weight={"medium"}
                      style={{ fontSize: "0.75rem", marginLeft: 4 ,paddingLeft: 2}}
                    >
                      Duration:
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center" justifyContent={"left"}>
                  <TextField
                    //label={'Deposit Amount'}
                    type="number" 
                    margin="dense"
                    name="daystodue"
                    placeholder={1}
                    onChange={handleCharityDaysToDueChange}
                    variant="outlined"
                    value={daysToDue}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                          > 
                          <Typography color={"primary"} variant={"caption"}>Days</Typography>
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  </Box>
                </Grid> 
                <Grid item lg={4} sm={4} xs={12}>
                  <Box display="flex" alignItems="center" justifyContent={"left"}>
                          <Dot size='medium' color="warning" />
                          <Typography
                            weight={"medium"}
                            style={{ fontSize: "0.75rem", marginLeft: 4 ,paddingLeft: 2}}
                          >
                            Target:
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent={"left"}>
                        <TextField
                          //label={'Deposit Amount'}
                          type="number" 
                          margin="dense"
                          name="targetamount"
                          placeholder={1}
                          onChange={handleCharityTargetAmountChange}
                          variant="outlined"
                          value={targetAmount}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                <IconButton
                                  aria-label='toggle password visibility'
                                  // onClick={handleMaxDepositAmt}
                                  //onMouseDown={handleMouseDownPassword}>
                                > 
                                <Typography color={"primary"} variant={"caption"}>XWIN</Typography>
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        </Box>
                        
                </Grid>
                <Grid 
                    item lg={4} sm={4} xs={12}>
                    <Button 
                        fullWidth
                        disabled={!addCharityParamCheck()}
                        onClick={handleClickAddCharity}
                        
                        variant="contained" size="small" color="primary">
                              {"Add charity"}
                      </Button>
                </Grid>
                <Grid item lg={6} sm={12} xs={12}>
                  <Box display="flex" alignItems="center" justifyContent={"left"}>
                    <Dot size='medium' color="success" />
                    <Typography
                      weight={"medium"}
                      style={{ fontSize: "1.05rem", marginLeft: 4 ,paddingLeft: 2}}
                    >
                      Total Earned: 
                      {xWinUtils.displayBalance(loading, format({prefix: " ", suffix: " "})(xWinLib.roundTo(phase.xWINRewards,6)))}
                    </Typography>
                  </Box>
                  
                </Grid>
                <Grid item lg={6} sm={12} xs={12}>
                  <Button 
                    disabled={!isCharityOwnerWallet()}
                    fullWidth
                    onClick={handleClickHarvest}
                    variant="contained" size="small" color="secondary">
                    {"HARVEST" }
                  </Button>
                </Grid>
              </Grid> 
              {/* <Grid container spacing={3}>  
                
              </Grid> */}
            
              </ExpansionPanelDetails>


      </ExpansionPanel>
}
      </>
      );

    }
  }


  return (
    <div>
      <TermCondition 
          ref={refTermCondition} 
          parentCallback={handleCallbackTermCondition}
        />
        <ReactInterval timeout={10000} enabled={true}
          callback={() => loadVaultData()} />
        <SnackbarMessage ref={refsnackbar} />
        {xWinUtils.getProgress(loadingTxn)}
    
      {charityTitleBar(phase.pid)}        
          <ExpansionPanel square={true}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                classes={{ expanded: classes.expanded }}
              >
                <div className={clsx(classes.column2)}>
                <Typography
                      color="text"
                      colorBrightness={"hint"}
                      variant={"caption"}
                      style={{ marginRight: 5 }}
                    >
                      Name
                    </Typography>
                  <Typography>{phase.name}</Typography>
                  
                    <Typography  
                      size="md"
                      weight={"medium"}
                      style={{ marginRight: 5 }}
                      className={classes.blocks}>{dueDateInfo.isClosed ? 'Closed ' : dueDateInfo.remainDays >= 1 ?  dueDateInfo.remainDays + " Days." : dueDateInfo.remainBLocks + ' blocks.' }
                    </Typography>
                </div>
                <div className={clsx(classes.column3, classes.columnHide, classes.helper)}>
                  <Typography
                      color="text"
                      colorBrightness={"hint"}
                      variant={"caption"}
                      style={{ marginRight: 5 }}
                    >
                      Due Date
                    </Typography>
                    <Box display="flex" alignItems="center" justifyContent={"left"}>
                      <Dot size='medium' color="info" />
                      <Typography 
                        style={{ marginLeft: 8 }} 
                      > {Moment(getDateOfBlock(phase.dueBlock)).format('YYYY/MM/DD')}</Typography>
                    </Box>
                </div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails classes={classes.expansion}>
                  <Grid 
                    alignItems="center"
                    justifyContent="center"                    
                    container spacing={3}>  
                    <Grid item lg={5} sm={12} xs={12}>
                      
                      <Grid container spacing={3}>
                        <Grid item lg={4} sm={4} xs={4}>
                            <Typography
                            color="text"
                            colorBrightness={"hint"}
                            variant={"caption"}
                            style={{ marginRight: 5 }}
                            noWrap
                          >
                            Target Amount
                          </Typography>
                          <Box display="flex" alignItems="center" justifyContent={"left"}>
                            <Dot size='medium' color="info" />
                            <Typography 
                              size="md"
                              weight={"medium"}
                              // style={{ marginRight: 5 }}
                              style={{ marginLeft: 8 }} 
                              className={classes.textRight}
                              noWrap
                            >{format({prefix: " ", suffix: ' '})(xWinLib.roundTo(phase.targetAmount, 2))}</Typography>
                          </Box>

                        </Grid>
                        <Grid item lg={4} sm={4} xs={4}>
                        <Typography
                            color="text"
                            colorBrightness={"hint"}
                            variant={"caption"}
                            style={{ marginRight: 5 }}
                          >
                            Burn Fee
                          </Typography>
                          <Box display="flex" alignItems="center" justifyContent={"left"}>
                              <Dot size='medium' color="secondary" />
                              <Typography 
                              size="md"
                              weight={"medium"}
                              // style={{ marginRight: 5 }}
                              style={{ marginLeft: 8 }} 
                              className={classes.textRight}
                              >{format({prefix: " ", suffix: '%'})(xWinLib.roundTo(phase.burnFee, 1))}
                              </Typography>
                          </Box>
                        
                        </Grid>
                        <Grid item lg={4} sm={4} xs={4}>
                            <Typography
                            color="text"
                            colorBrightness={"hint"}
                            variant={"caption"}
                            style={{ marginRight: 5 }}
                            noWrap
                          >
                            Total Donated
                          </Typography>
                          <Box display="flex" alignItems="center" justifyContent={"left"}>
                            <Dot size='medium' color="success" />
                            <Typography 
                              size="md"
                              weight={"medium"}
                              // style={{ marginRight: 5 }}
                              style={{ marginLeft: 8 }} 
                              className={classes.textRight}
                              noWrap
                            >{xWinUtils.displayBalance(loading, format({prefix: " ", suffix: " "})(xWinLib.roundTo(phase.donatedTotal,2)))}</Typography>
                          </Box>

                        </Grid>
                      </Grid>
                      
                      

                    </Grid>
                    <Grid item lg={5} sm={12} xs={12}>
                      <TextField
                        disabled={dueDateInfo.isClosed}
                        type="number" 
                        margin="dense"
                        name="donateamount"
                        placeholder={0}
                        onChange={handleAmtDonateChange}
                        variant="outlined"
                        value={donateAmount}
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <IconButton
                                aria-label='toggle password visibility'
                                onClick={handleMaxDonateAmt}
                              > 
                              <Typography color={"primary"} variant={"caption"}>MAX</Typography>
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item lg={2} sm={12} xs={12}>
                      <Button 
                        disabled={dueDateInfo.isClosed}
                        fullWidth
                        onClick={phase.isApproved ? handleClickDonate : handleClickApprove}
                        variant="contained" size="medium" color="primary">
                              {!phase.isApproved ? "Enable": "Donate"}
                      </Button>
                    </Grid>
                  </Grid>                       
                  {isCharityOwnerWallet() && (
                    <Grid container spacing={3} className={classes.hoverHighlight}>  
                      <Grid item lg={6} sm={12} xs={12}>
                      <TextField
                        disabled={!isWithdrawOpen()}
                        type="number" 
                        margin="dense"
                        name="withdrawamount"
                        placeholder={0}
                        onChange={handleAmtWithdrawChange}
                        variant="outlined"
                        value={withdrawAmount}
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <IconButton
                                aria-label='toggle password visibility'
                                onClick={handleMaxWithdrawAmt}
                              > 
                               <Typography color={"primary"} variant={"caption"}>MAX</Typography>
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      </Grid>
                      <Grid item lg={6} sm={12} xs={12}>

                      <Button 
                        disabled={!isWithdrawOpen()}
                        fullWidth
                        onClick={handleClickWithdraw}
                        variant="contained" size="small" color="secondary">
                        {"Withdraw" }
                      </Button>
                      </Grid>
                    </Grid>                       

                  )}
              </ExpansionPanelDetails>
            </ExpansionPanel>
 
            
    </div>
  );
}


export default withRouter(CharityDetails);