import React, { useState, useEffect, useContext  } from "react";
import xWinLib from '../../xWinLib';
import xWinUtils from '../../xWinUtils';
import Tradinglist from "./components/Tradinglist";
import xWinFundV4 from '../../abi/xWinFundV4.json'
import {AppContext}  from '../../context/AppProvider';

import {
  Grid,
  Container,
} from "@material-ui/core";

// components
import Widget from "../../components/Widget/Widget";

export default function Tradings(props) {
  const { portfolios, tokensMaster, fundtype } = props;
  const [filterPortfolios, setFilterPortfolios] = useState([])
  const { ETHBalance, isConnected, account, theWeb3, web3static, globalProvider, refreshAccount } = useContext(AppContext);
    
  // if(globalProvider !== undefined){
  //   globalProvider.on("accountsChanged", (accounts) => {
  //     loadWeb3();
  //   });
  // }

  useEffect(() => {
    const controller = new AbortController();
    loadWeb3()
    return () => {
      controller.abort();
    }
  }, [portfolios, fundtype, account]);

  const loadWeb3 = async () => {

    let filterEnv = await xWinLib.getNetwork(theWeb3)
    let filterport = []
    let myPorts = []
    if(filterEnv !== "bscmain"){
      filterport = portfolios.filter(x=>x.env.toLowerCase() === "bscmain" && x.fundtype === fundtype)
    }else{
      filterport = portfolios.filter(x=>x.env.toLowerCase() === filterEnv.toLowerCase() && x.fundtype === fundtype)
      if(account !== "---") myPorts = await xWinUtils.getMyPorts(filterport, account, xWinFundV4, theWeb3) 
    }
    setFilterPortfolios(filterport)
  }
  
  const displayPortfolios = () => {
    
    if(filterPortfolios === undefined) return ""

    let funds = filterPortfolios
    // if(showMyFund) funds = filterMyPortfolios

    return (
      funds.map(port => (
        
           <Tradinglist 
            port={port}
            portfolios={funds}
            tokensMaster={tokensMaster}
            globalWeb3={theWeb3}
            web3static={web3static}
            selectedWallet={account}
            fundtype={fundtype}
            ETHBalance={ETHBalance}
            refreshAccount={refreshAccount}
            /> 
           
        )) 
    )    
  }

  const getTitle = () => {

    if(fundtype === "tradingv2") return "Trading Vault"
    else if(fundtype === "indexv2") return "Sector Index Vault"
    else if(fundtype === "index") return "(Old) Sector Index Vault"
    else if(fundtype === "trading") return "(Old) Trading Vault"
    else if(fundtype === "liquidity") return "(Old) LP Vault"
     
  }

  return (
    <Container>
      {/* <Snackbar 
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        TransitionComponent={"TransitionUp"}
        open={openMsg} autoHideDuration={60000} >
          <Alert onClose={hideMessage} severity={"warning"}>
          Please connect to Binance Smart Chain Mainnet
          </Alert>
      </Snackbar> */}
      <Grid>
        <Grid item md={12}>
          <Widget
            title={getTitle()}
            disableWidgetMenu
            inheritHeight
            enableWalletDisplay
            selectedWallet={account}
          >
            {displayPortfolios()}
          </Widget>
        </Grid>
      </Grid>
    </Container>
  );
}
