/* eslint-disable no-nested-ternary */
import React, {
  useContext, useEffect, useRef, useState,
} from 'react';

import ReactInterval from 'react-interval'
import CheckWinner from './component/CheckWinner';
import LuckyDrawFaq from './component/LuckyDrawFaq';
import PreviousLuckyDraw from './component/PreviousLuckyDraw';
import RegisterLuckyDraw from './component/RegisterLuckyDraw';
import SnackbarMessage from '../snackbarmessage/SnackbarMessage';
import appGlobalConfig from '../../appGlobalConfig';
import useStyles from './styles';
import xWinLuckyDraw from '../../abi/xWinLuckyDraw.json';
import xWinProtocol from '../../abi/xWinProtocolV5.json';
import xWinUtils from '../../xWinUtils';
import { AppContext } from '../../context/AppProvider';


const LuckyDraw = () => {
  const classes = useStyles();
  const {
    isConnected, account, theWeb3, web3static
  } = useContext(AppContext);

  const [loading, setLoading] = React.useState(false);
  const [protocol, setProtocol] = React.useState(null);
  const [protocolDisplay, setProtocolDisplay] = React.useState(null);
  const [userStakeInfo, setUserStakeInfo] = React.useState(null);
  const [latestPoolWon, setLatestPoolWon] = React.useState(null);

  const [round, setRound] = useState({
    nextID: 0,
    lastDrawnID: 0,
  });

  const refsnackbar = useRef(null);

  // if (theWeb3 !== undefined) {
  //   theWeb3.currentProvider.on('accountsChanged', () => {
  //     loadWeb3();
  //   });

  //   theWeb3.currentProvider.on('chainChanged', () => {
  //     loadWeb3();
  //   });
  // }

  useEffect(() => {
    const controller = new AbortController();

    loadWeb3();

    return () => {
      controller.abort();
    };
  }, [account]);

  const loadWeb3 = async () => {

    if (theWeb3 === undefined) return;

    try {

      setLoading(true);
      const contract = new theWeb3.eth.Contract(xWinLuckyDraw, appGlobalConfig.mainnetxWinLuckyDraw);
      const contractDisplay = new web3static.eth.Contract(xWinLuckyDraw, appGlobalConfig.mainnetxWinLuckyDraw);
      const xwinDEFI = new web3static.eth.Contract(xWinProtocol, appGlobalConfig.mainnetxWinProtocol);

      if(account !== "---"){
        const xwinLPInfo = await xwinDEFI.methods.userInfo(34, account).call();
        const xwinTokenInfo = await xwinDEFI.methods.userInfo(0, account).call();
        let minimalBalanceLP = await contractDisplay.methods.minimalBalanceLP().call();
        let minimalBalanceXWIN = await contractDisplay.methods.minimalBalanceXWIN().call();
        minimalBalanceLP = xWinUtils.convertFromWei(web3static, minimalBalanceLP?.toString())
        minimalBalanceXWIN = xWinUtils.convertFromWei(web3static, minimalBalanceXWIN?.toString())  
        
        setUserStakeInfo({
          LPamount: xwinLPInfo?.amount,
          XWINamount: xwinTokenInfo?.amount,
          isEligible: parseFloat(xwinLPInfo?.amount) >= parseFloat(minimalBalanceLP) || parseFloat(xwinTokenInfo?.amount) >= parseFloat(minimalBalanceXWIN),
        });
      }else{
        setUserStakeInfo({
          LPamount: "0",
          XWINamount: "0",
          isEligible: false,
        });

      }

      setProtocol(contract);
      setProtocolDisplay(contractDisplay);
      await getPoolInfo(contractDisplay);
      setLoading(false);
      
    } catch (error) {
      console.log(error)
      setLoading(false);
    }
    
  };

  const getPoolInfo = async (contract) => {
    if (contract === undefined ) return;
    if (contract === null ) return;

    if (theWeb3 === undefined) return;
    const activeID = await contract.methods.activePool().call();
    const previoudID = parseFloat(activeID) == 0 ? 0 : parseFloat(activeID) - 1;
    const poolInfo = await contract.methods.poolInfo(previoudID).call();
    const totalWon = Number(xWinUtils.convertFromWei(web3static, poolInfo?.totalTobeWon.toString()));

    setLatestPoolWon(totalWon);
    setRound({
      nextID: activeID,
      lastDrawnID: previoudID,
      displayNextID: Number(activeID) + 1,
      displayLastDrawnID: Number(activeID) === 0? 0 : Number(previoudID) + 1,
    });
  };

  const refresh = async () => {

    if (protocolDisplay === undefined) return;
    try {
      await getPoolInfo(protocolDisplay);        
    } catch (error) {
      console.log(error)
    }
  };

  return (
    <div className={classes.container}>
      <SnackbarMessage ref={refsnackbar} />
      <ReactInterval timeout={20000} enabled callback={() => refresh()} />
      {xWinUtils.getProgress(loading)}
      <div className="d-flex flex-column">
        <RegisterLuckyDraw round={round} protocol={protocol} snackbar={refsnackbar} userStakeInfo={userStakeInfo} />
        <CheckWinner round={round} protocolDisplay={protocolDisplay} protocol={protocol} snackbar={refsnackbar} latestPoolWon={latestPoolWon} />
        <PreviousLuckyDraw round={round} protocolDisplay={protocolDisplay} protocol={protocol} snackbar={refsnackbar} />
        <LuckyDrawFaq protocol={protocol}/>
      </div>
    </div>
  );
};

export default LuckyDraw;
